import React from 'react';
import { Box, theme } from '@fivehealth/botero';
import { BoxProps } from 'rebass';
import { OutlinedBox } from '@/components/Box/OutlinedBox';

type CardOutlineBoxProps = {
  outlinedBoxProps?: BoxProps;
  contentBoxProps?: BoxProps;
  headerBoxProps?: BoxProps;
  footerBoxProps?: BoxProps;
  children: React.ReactNode;
  onClick?: (param?: any) => void;
  isSelected?: boolean;
  isStatic?: boolean;
  renderHeader?: (param?: any) => void;
  renderFooter?: (param?: any) => void;
};

export const CardOutlineBox: React.FunctionComponent<CardOutlineBoxProps> = ({
  isStatic = true,
  children,
  outlinedBoxProps,
  contentBoxProps,
  headerBoxProps,
  footerBoxProps,
  onClick,
  isSelected = false,
  renderHeader,
  renderFooter,
}) => (
  <OutlinedBox
    isStatic={isStatic}
    isSelected={isSelected}
    onClick={onClick}
    boxProps={{ p: 0, ...outlinedBoxProps }}>
    {renderHeader && typeof renderHeader === 'function' && (
      <Box
        style={{ borderBottom: `1px solid ${theme.colors.mediumShade}` }}
        height="auto"
        p={2}
        {...headerBoxProps}>
        {renderHeader()}
      </Box>
    )}

    <Box {...contentBoxProps}>{children}</Box>

    {renderFooter && typeof renderFooter === 'function' && (
      <Box style={{ borderTop: `1px solid ${theme.colors.mediumShade}` }} {...footerBoxProps}>
        {renderFooter()}
      </Box>
    )}
  </OutlinedBox>
);
