import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useIsView } from '@/helpers/utils';
import { getCookieData } from '@/helpers/utils/storage.utils';
import { StaffProfileAccountType } from '@/gql/generated/graphql';
import moment from 'moment/moment';
import { DATE_FORMAT_TXT } from '@/config/constants/misc.constants';

/**
 * TODO: ?
 */
export type RenderForViewProps =
  | {
      mobile?: React.ReactNode;
      tablet?: React.ReactNode;
      desktop: React.ReactNode;
    }
  | [React.ReactNode, React.ReactNode, React.ReactNode]
  | [React.ReactNode, React.ReactNode];

/**
 * TODO: ?
 */
export const useRenderForView = () => {
  const { isMobile, isTablet } = useIsView();
  const renderForView = (arg: RenderForViewProps) => {
    if (Array.isArray(arg)) {
      // TODO: Fix this mobile resonsive check, there should be a better way.
      const getTableVal = isTablet ? 1 : 2;
      const getMobileVal = arg[isMobile ? 0 : getTableVal];
      return arg.length === 3 ? getMobileVal : arg[isMobile ? 0 : 1];
    }
    if (isMobile) {
      return arg.mobile || arg.tablet || arg.desktop;
    }
    if (isTablet) {
      return arg.tablet || arg.desktop;
    }
    return arg.desktop;
  };
  return { renderForView };
};

/**
 * Check if its a valid token.
 * @param token
 */
export const isValidSessionToken = (token: string) => token && !/null/i.test(token);

/**
 * Get a unique device id.
 */
export const getDeviceUID = () => uuidv4();

/**
 * Check if current user login of what account type.
 */
export const isCurrentUserType = {
  doctor: () =>
    getCookieData()?.user?.accountType?.toLowerCase() ===
    StaffProfileAccountType.Doctor.toString().toLowerCase(),
  admin: () =>
    getCookieData()?.user?.accountType?.toLowerCase() ===
    StaffProfileAccountType.Admin.toString().toLowerCase(),
  superAdmin: () =>
    getCookieData()?.user?.accountType?.toLowerCase() ===
    StaffProfileAccountType.Superadmin.toString().toLowerCase(),
};

// Result from a day (16) to latest date
// createdOn_Gte: "2023-09-16T00:00:00+08:00"
//
// Result for today (specific date)
// createdOn_Gt: "2023-09-17T00:00:00+08:00"
// createdOn_Lt:  "2023-09-17T23:59:00+08:00"
//
// Result for yesterday (specific date)
// createdOn_Gt: "2023-09-16T00:00:00+08:00"
// createdOn_Lt:  "2023-09-16T23:59:00+08:00"
//
// Result for Last 7 days
// createdOn_Lte: "2023-09-17T23:59:00+08:00"
// createdOn_Gte:  "2023-09-11T23:59:00+08:00"
//
// Result 30 days
// createdOn_Lte: "2023-09-17T23:59:00+08:00"
// createdOn_Gte:  "2023-08-17T00:00:00+08:00"
//
// Last Month
// createdOn_Lte: "2023-08-30T23:59:00+08:00"
// createdOn_Gte:  "2023-08-01T23:59:00+08:00"
export const currFormattedDate = moment(`${moment().format(DATE_FORMAT_TXT.YYYY_MM_DD_DASH)} 00:00`).format();
export const endFormattedDate = moment(`${moment().format(DATE_FORMAT_TXT.YYYY_MM_DD_DASH)} 23:59`).format();
export const dateTimeISOParser = (date: Date | string, isStartOfDay = true) =>
  moment(
    `${moment(date).format(DATE_FORMAT_TXT.YYYY_MM_DD_DASH)} ${isStartOfDay ? '00:00' : '23:59'}`
  ).format();
export const isSameOrBeforeCurrDate = (date: Date) => moment(date).isSameOrBefore(moment(currFormattedDate));
export const isSameCurrDate = (date: string) => moment(date).isSame(moment(new Date()).format(), 'date');
export const isPastDate = (date: Date) => moment(date).isBefore(moment(currFormattedDate));

export const isDateTimeOneHourBefore = (dateTime: string) => {
  const dataTimeParam = moment(dateTime); // '2023-09-29 11:00'
  const dateTimeCurrent = moment();
  const dateTimeOneHourOffset = moment(dataTimeParam).subtract(1, 'hours');
  return dateTimeCurrent.isSameOrBefore(dataTimeParam) && dateTimeCurrent.isSameOrAfter(dateTimeOneHourOffset);
};
