import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import 'react-loading-skeleton/dist/skeleton.css';
import App from './App';
import { ToastContainer } from 'react-toastify';

ReactDOM.render(
  <Router>
    <App />
    <ToastContainer
      style={{ minWidth: 400 }}
      autoClose={2000}
      hideProgressBar
      theme="light"
      position="top-center"
    />
  </Router>,
  document.getElementById('root')
);
