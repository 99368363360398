import React from 'react';
import { Box, Flex, H1, Body, Button, FAIcon } from '@fivehealth/botero';
import { faAngleLeft as backIcon } from '@fortawesome/pro-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';

type PageHeaderProps = {
  loading?: boolean;
  title: string;
  subtitle?: string;
  rightElements?: React.ReactNode;
  hasBackButton?: boolean;
  backButtonProps?: {
    label?: string;
    onClick: () => void;
  };
  boxProps?: React.ComponentProps<typeof Box>;
};

const PageHeader: React.FC<PageHeaderProps> = ({
  loading,
  title,
  subtitle,
  rightElements,
  hasBackButton,
  backButtonProps,
  boxProps = {} as PageHeaderProps['boxProps'],
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box style={{ userSelect: 'none' }} pt={hasBackButton ? 4 : 0} mb={0} {...boxProps}>
      <Flex justifyContent="space-between">
        <Box position="relative">
          {hasBackButton ? (
            <Button
              position="absolute"
              top={-40}
              data-testid="back-button"
              style={{ userSelect: 'none' }}
              bg="transparent"
              px={0}
              onClick={backButtonProps?.onClick || (() => navigate(-1))}
              startIcon={<FAIcon icon={backIcon} mr={2} fontWeight={900} fontSize={16} />}>
              <Body small fontWeight={700} color="darkestShade">
                {t(backButtonProps?.label || 'Back')}
              </Body>
            </Button>
          ) : null}
          {loading ? <Skeleton height={40} width={200} /> : <H1 data-testid={title}> {t(title)}</H1>}
        </Box>
        {rightElements && loading ? <Skeleton height={40} width={200} /> : rightElements}
      </Flex>
      {subtitle ? (
        <Body mt={2} mb={4} color="fullShade">
          {loading ? <Skeleton height={20} width={300} /> : t(subtitle)}
        </Body>
      ) : null}
    </Box>
  );
};

export default PageHeader;
