import { useEffect, memo } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Flex } from '@fivehealth/botero';
import { AppConfig } from '@/config/app.config';
import { ROUTES } from '@/routes/Router';
import useAuthHandler from '@/hooks/useAuthHandler';
import { getCookieData, nullTextParser, unsecureRedirectPath } from '@/helpers/utils';
import AnimatedLoadingLottie from '@/components/Loader/AnimatedLoadingLottie';
import { NavigateOrderStateType } from '@/helpers/types/ui.types';
import { isEmpty } from 'lodash';

const AuthCallback = () => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const sessionToken = nullTextParser(searchParams.get('session'));
  const errMsgParam = nullTextParser(searchParams.get('reason'));
  const cookieTokenName = AppConfig.STORAGE.COOKIE_TOKEN_KEY;
  const [cookies, setCookie] = useCookies([cookieTokenName]);
  const cookieToken = nullTextParser(cookies?.[cookieTokenName] as string);
  const { data: authData, isLoading } = useAuthHandler(sessionToken || '');
  const navigate = useNavigate();

  const redirect = (path = '', delay = 0) => unsecureRedirectPath(path, delay);

  useEffect(() => {
    if (sessionToken) {
      setCookie(cookieTokenName, sessionToken, { path: ROUTES.ROOT });
    }
    const isAuthorized =
      document.location.pathname === AppConfig.REDIRECT_AUTH_CALLBACK_PATH &&
      sessionToken &&
      cookieToken &&
      !isEmpty(getCookieData()?.user);

    if (isAuthorized) {
      setTimeout(() => {
        console.info(`%c😊🔐✅[AUTH] - Valid authorized token.`, 'background: #000000; color: green');
        redirect();
      }, 100);
    }
    if (errMsgParam) {
      console.info(
        `%c😭🔐❌️[AUTH]: Invalid session (${sessionToken}) - [${errMsgParam}] `,
        'background: #000000; color: magenta'
      );
      setTimeout(() => {
        navigate(ROUTES.LOGIN.ROOT, {
          state: {
            error: {
              session: sessionToken,
              message: errMsgParam,
            },
          } as NavigateOrderStateType,
        });
      }, 0);
    }
  }, [
    authData,
    cookieToken,
    cookieTokenName,
    errMsgParam,
    isLoading,
    navigate,
    pathname,
    sessionToken,
    setCookie,
  ]);

  return (
    <Flex flexDirection="column" height="100vh" justifyContent="center" alignItems="center">
      <AnimatedLoadingLottie flexProps={{ mt: 50 }} />
    </Flex>
  );
};

export default memo(AuthCallback);
