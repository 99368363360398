import React from 'react';
import {
  faClock,
  faEnvelope,
  faPhoneVolume,
  faTimes as closeIcon,
  faQuestionCircle as emailLinkSupport,
} from '@fortawesome/pro-regular-svg-icons';
import { emailParkway, getDeviceUID } from '@/helpers/utils';
import {
  Flex,
  Box,
  H2,
  Body,
  PrimaryButton,
  FAIcon,
  H4,
  theme,
  Text,
  SecondaryOutlinedButton,
} from '@fivehealth/botero';
import { useTranslation } from 'react-i18next';
import Modal from '@/components/Modals/Modal';
import { HELP_SUPPORT } from '@/config/constants/misc.constants';
import { useAuthContext } from '@/context';

type ContactSupportModalProps = {
  open: boolean;
  onClose: () => void;
};

const ContactSupportModal: React.FC<ContactSupportModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const authCtx = useAuthContext();

  const SpanTextBold = (param: React.ComponentProps<any>) => (
    // eslint-disable-next-line react/destructuring-assignment
    <span style={{ fontWeight: 600, fontSize: 14 }} {...param?.props}>
      {/* eslint-disable-next-line react/destructuring-assignment */}
      {param?.children}
    </span>
  );

  return (
    <Modal
      modalProps={{
        style: {
          padding: 0,
          maxWidth: 640,
          width: 'calc(100% - 32px)',
        },
      }}
      open={open}
      onClose={onClose}>
      <Box maxWidth={640} minWidth={250} p={4} borderRadius={16}>
        <Flex alignItems="center" justifyContent="space-between" mb={4}>
          <H2>{t('Contact Parkway')}</H2>
          <Box cursor="pointer" onClick={onClose} p="12px">
            <FAIcon icon={closeIcon} onClick={onClose} fontSize={20} />
          </Box>
        </Flex>

        <Body mb={6}>
          <H4 fontSize={16} fontWeight={600} mb={4}>
            Radiology Call Center
          </H4>

          <Flex mb={3}>
            <Box width={40}>
              <FAIcon
                icon={faPhoneVolume}
                onClick={onClose}
                fontSize={18}
                style={{ color: theme.colors.primary }}
              />
            </Box>
            <Box fontSize={14}>
              <SpanTextBold
                onClick={() => {
                  window.open(`tel:${HELP_SUPPORT.PARKWAY_PHONE}`);
                }}
                style={{ textDecoration: 'none', width: '300px', cursor: 'pointer' }}>
                {'Call +65 6388 4333 '}
              </SpanTextBold>
              for enquiries on your appointment or preparations needed or to change an appointment
            </Box>
          </Flex>

          <Flex mb={3}>
            <Box width={32}>
              <FAIcon
                icon={faEnvelope}
                onClick={onClose}
                fontSize={18}
                style={{ color: theme.colors.primary }}
              />
            </Box>
            <SpanTextBold
              style={{
                textDecoration: 'none',
                cursor: 'pointer',
              }}>
              {'Email: '}
            </SpanTextBold>

            <Body as="u" color="primary" cursor="pointer" onClick={emailParkway} pl={1} fontSize={14}>
              info@parkwayradiology.com.sg
            </Body>
          </Flex>

          <Flex mb={4}>
            <Box width={32}>
              <FAIcon icon={faClock} onClick={onClose} fontSize={16} style={{ color: theme.colors.primary }} />
            </Box>
            <Box>
              <Text fontSize={14}>
                <SpanTextBold>Weekday Operating Hours: </SpanTextBold>
                {HELP_SUPPORT.PARKWAY_OPERATING_HOURS.WEEKDAY}
              </Text>
              <Text fontSize={14}>
                <SpanTextBold>Saturday Operating Hours: </SpanTextBold>
                {HELP_SUPPORT.PARKWAY_OPERATING_HOURS.SATURDAY}
              </Text>
              <Text fontSize={14}>
                <SpanTextBold>Closed on Sundays and Public Holidays</SpanTextBold>
              </Text>
            </Box>
          </Flex>

          <H4 fontSize={16} fontWeight={600} mb={2}>
            Technical Support
          </H4>

          <Flex mb={3}>
            <Box width={32}>
              <FAIcon
                icon={emailLinkSupport}
                onClick={onClose}
                fontSize={18}
                style={{ color: theme.colors.primary }}
              />
            </Box>
            <span
              style={{
                textDecoration: 'none',
                cursor: 'pointer',
                fontSize: 14,
              }}>
              Click
            </span>
            <Box fontSize={14}>
              <Body
                fontSize={14}
                as="u"
                color="primary"
                cursor="pointer"
                onClick={() => {
                  const url = HELP_SUPPORT.BOTMD_SUPPORT_LINK_POST_LOGIN({
                    uid: authCtx?.authState?.data?.user?.uid as string,
                    deviceUid: getDeviceUID(),
                    institution: authCtx?.authState?.data?.user?.clinic?.name as string,
                  });
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  window.open(url, '_blank').focus();
                }}
                pl="4px"
                pb="1px">
                here
              </Body>
              {t('  to report any technical issues with the platform.')}
            </Box>
          </Flex>
        </Body>
        <Flex pt={0} justifyContent="end" alignContent="flex-end">
          <Box>
            <SecondaryOutlinedButton
              as="a"
              maxWidth="fit-content"
              onClick={() => {
                onClose();
              }}
              borderRadius={8}
              marginLeft="auto"
              style={{ textDecoration: 'none' }}>
              {t('Close')}
            </SecondaryOutlinedButton>
          </Box>
          <Box pl={2}>
            <PrimaryButton
              as="a"
              maxWidth="fit-content"
              href={HELP_SUPPORT.PARWKAY_WHATS_URL}
              borderRadius={8}
              marginLeft="auto"
              style={{
                textDecoration: 'none',
                background: '#25D366',
                color: 'white',
                fontSize: 16,
                fontWeight: 500,
              }}
              startIcon={<FAIcon icon={faPhoneVolume} style={{ color: 'white' }} />}>
              {t('WhatsApp')}
            </PrimaryButton>
          </Box>
        </Flex>
      </Box>
    </Modal>
  );
};

export default ContactSupportModal;
