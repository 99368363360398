import { useState } from 'react';
import { Box } from '@fivehealth/botero';
import { useAuthContext, useDataContext } from '@/context';
import { FaradayClinic } from '@/gql/generated/graphql';
import { localWorkspace } from '@/helpers/utils/storage.utils';
import { WorkspaceItemType, WorkspaceSelect } from '@/components/Select/WorkspaceSelect';
import useSessionExchange from '@/hooks/useSessionExchange';
import { BoxProps } from 'rebass';
import { CookieDataTypes } from '@/helpers/types/system.types';
import SpinningIcon from '@/components/Loader/SpinningIcon';

const WorkspaceClinicSelector = ({ boxProps, data }: { boxProps?: BoxProps; data: CookieDataTypes }) => {
  const activeWorkspaceStorage = localWorkspace.getActive();
  const [isDisabled, setIsDisabled] = useState(false);

  const {
    data: { clinicList },
  } = useDataContext();

  const {
    authState: { token, isLoading: authLoading },
    setIsLoading: authSetLoading,
  } = useAuthContext();

  const defaultSelection: WorkspaceItemType = {
    value: clinicList?.find((o) => o.uid === activeWorkspaceStorage?.clinic?.uid) as FaradayClinic,
    label: clinicList
      ?.find((o) => o.uid === activeWorkspaceStorage?.clinic?.uid)
      ?.name?.toUpperCase() as string,
  };

  const [selectedWorkspace, setSelectedWorkspace] = useState<WorkspaceItemType | null>(defaultSelection);
  const { mutate: mutator } = useSessionExchange();

  const onSelect = async (param: WorkspaceItemType) => {
    if (token && param && authSetLoading) {
      authSetLoading(true);
      const resp = await mutator(param?.value, token);

      if (resp?.session?.uid) {
        setTimeout(() => {
          window.location.reload();
        }, 0);
      }
    }
  };

  return (
    <Box mt={1} ml={-2} {...boxProps}>
      {authLoading ? (
        <Box ml={2}>
          <SpinningIcon spin />
        </Box>
      ) : (
        <WorkspaceSelect
          activeWorkspaceStorage={activeWorkspaceStorage}
          clinicList={clinicList}
          selectedWorkspace={selectedWorkspace?.value ? selectedWorkspace : defaultSelection}
          setSelectedWorkspace={setSelectedWorkspace}
          user={data?.user}
          onSelect={(item) => {
            setIsDisabled(true);
            onSelect(item as WorkspaceItemType);
          }}
          selectProps={{
            components: {
              IndicatorSeparator: () => null,
            },
            isClearable: false,
            isDisabled,
            hideSelectedOptions: true,
            styles: {
              valueContainer: (base) => ({ ...base, minWidth: 'auto', fontSize: 16, fontWeight: 600 }),
              placeholder: (base) => ({ ...base, fontSize: 18 }),
              control: (base) => ({ ...base, border: 0, minWidth: 200, boxShadow: 'none' }),
              // groupHeading: (base) => ({ ...base, borderColor: 'red' }),
            },
          }}
        />
      )}
    </Box>
  );
};

export default WorkspaceClinicSelector;
