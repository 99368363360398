import { useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { CookiesProvider } from 'react-cookie';
import { SkeletonTheme } from 'react-loading-skeleton';
import { ThemeProvider, LogContextProvider } from '@fivehealth/botero';

import Router from '@/routes/Router';
import theme from '@/theme/theme';
import { DataProvider, AuthProvider, ModalProvider } from '@/context';
import { onErrorCallback, onRenderErrorFallback } from '@/pages/Error/ErrorPage';
import { getCookieData, getCookieToken } from '@/helpers/utils/storage.utils';
import { useDataHandler } from '@/hooks';
import 'react-toastify/dist/ReactToastify.css';
import 'semantic-ui-css/semantic.min.css';
import './index.css';

const getQueryClient = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        retry: 0,
        refetchOnMount: false,
        refetchOnReconnect: false,
        staleTime: 1000 * 60 * 60 * 3,
        refetchOnWindowFocus: false, // NOTE: We may need to disable this feature to avoid redundant api call on window focus? Default value is true
      },
    },
  });

const queryClient = getQueryClient();

function AppContainer() {
  useDataHandler();
  return (
    <ThemeProvider theme={theme}>
      <LogContextProvider>
        <SkeletonTheme baseColor={theme.colors.mediumShade} highlightColor={theme.colors.lightestShade}>
          <ModalProvider>
            <Router />
          </ModalProvider>
        </SkeletonTheme>
      </LogContextProvider>
    </ThemeProvider>
  );
}

function App() {
  const [errorStackTrace, setErrorStackTrace] = useState<any>(null);
  const cookieToken = getCookieToken();
  const cookieData = getCookieData();
  const CustomErrorBoundary: React.FC<React.ComponentProps<any>> = ({ children }) => (
    <ErrorBoundary
      fallbackRender={({ resetErrorBoundary }) => {
        const user = {
          name: cookieData?.user?.fullName || '...',
        };
        return onRenderErrorFallback({ resetErrorBoundary, errorStackTrace, user });
      }}
      onError={(error: any, info: any) => onErrorCallback(error, info, setErrorStackTrace)}>
      {children}
    </ErrorBoundary>
  );

  return (
    <AuthProvider token={cookieToken} data={cookieData || ({} as never)}>
      <CookiesProvider>
        <CustomErrorBoundary>
          <QueryClientProvider client={queryClient}>
            <DataProvider>
              <AppContainer />
              {window.location.href.includes('localhost') && <ReactQueryDevtools initialIsOpen={false} />}
            </DataProvider>
          </QueryClientProvider>
        </CustomErrorBoundary>
      </CookiesProvider>
    </AuthProvider>
  );
}
export default App;
