import { lazy, useCallback, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from '@/pages/Layout/Layout';
import AuthCallback from '@/pages/Login/AuthCallback';
import ErrorPage from '@/pages/Error/ErrorPage';
import RouteValidator from '@/routes/RouteValidator';
import { useAuthContext } from '@/context';
import { getCookieToken, localWorkspace } from '@/helpers/utils/storage.utils';
import LoginRedirect from '@/pages/Login/LoginRedirect';
import Orders from '@/pages/Orders/Orders';
import RootPage from '@/pages';
import { gotoReplaceUrlLink, isCurrentUserType } from '@/helpers/utils';
import { isEmpty } from 'lodash';

const OrderCreate = lazy(async () => import('@/pages/OrderCreate/OrderCreate'));
const PatientList = lazy(async () => import('@/pages/PatientList/PatientList'));
const ClinicalAdmin = lazy(async () => import('@/pages/ClinicalAdmin/ClinicalAdmin'));
const PriceList = lazy(async () => import('@/pages/PriceList/PriceList'));
const Patient = lazy(async () => import('@/pages/Patient/Patient'));

export const ROUTES = {
  ROOT: '/',
  LOGIN: {
    ROOT: '/login',
    AUTH: 'auth/callback',
  },
  ORDERS: {
    ROOT: '/orders',
    SINGLE_ORDER: '/orders/:id',
  },
  ORDERSX: {
    ROOT: '/ordersx',
    SINGLE_ORDER: '/ordersx/:id',
  },
  ORDER_CREATE: {
    ROOT: '/order-create',
  },
  PATIENTS_LIST: {
    ROOT: '/patient-list',
    PATIENT: '/patient-list/patient/:uid',
  },
  CLINICAL_ADMIN: {
    ROOT: '/clinical-admin',
  },
  PRICE_LIST: {
    ROOT: '/price-list',
  },
  ERROR: {
    ROOT: '/error',
  },
} as const;

export const createRouteWithParam = (route: string, val: string) => route.replace(/:([0-9_a-z]+)/gi, val);

const Router = () => {
  const {
    authState: { isAuthenticated, isLoading },
    setIsLoading,
  } = useAuthContext();
  const activeWorkspace = localWorkspace.getActive();
  const sessionToken = getCookieToken();

  const hasValidSession = !isEmpty(activeWorkspace) && sessionToken !== null;

  const workspaceHandler = useCallback(() => {
    if (!activeWorkspace && isAuthenticated && setIsLoading && !isLoading) {
      setIsLoading(true);
    }
  }, [activeWorkspace, isAuthenticated, isLoading, setIsLoading]);

  useEffect(() => {
    workspaceHandler();
  }, [workspaceHandler]);

  useEffect(() => {
    const isLoginPath = document.location.pathname === ROUTES.LOGIN.ROOT;
    if (isEmpty(activeWorkspace) && sessionToken === null && !isLoginPath) {
      gotoReplaceUrlLink(ROUTES.LOGIN.ROOT);
    }
  }, [activeWorkspace, sessionToken]);

  return (
    <Routes>
      <Route path={ROUTES.LOGIN.AUTH} element={<AuthCallback />} />
      <Route element={<Layout />}>
        <Route path={ROUTES.ROOT} element={<RootPage />} />
        <Route path={ROUTES.LOGIN.ROOT} element={<LoginRedirect />} />

        {hasValidSession && <Route path="*" element={<ErrorPage headerTitle="Page not found" />} />}

        <Route path={ROUTES.ERROR.ROOT} element={<ErrorPage headerTitle="Server Error" />} />

        <Route
          path={ROUTES.ORDERS.ROOT}
          element={
            <RouteValidator>
              <Orders />
            </RouteValidator>
          }
        />

        <Route
          path={ROUTES.PATIENTS_LIST.ROOT}
          element={
            <RouteValidator>
              <PatientList />
            </RouteValidator>
          }
        />
        <Route
          path={ROUTES.PATIENTS_LIST.PATIENT}
          element={
            <RouteValidator>
              <Patient />
            </RouteValidator>
          }
        />
        {isCurrentUserType.superAdmin() && (
          <Route
            path={ROUTES.CLINICAL_ADMIN.ROOT}
            element={
              <RouteValidator>
                <ClinicalAdmin />
              </RouteValidator>
            }
          />
        )}

        <Route
          path={ROUTES.PRICE_LIST.ROOT}
          element={
            <RouteValidator>
              <PriceList />
            </RouteValidator>
          }
        />
        <Route
          path={ROUTES.ORDER_CREATE.ROOT}
          element={
            <RouteValidator>
              <OrderCreate />
            </RouteValidator>
          }
        />
      </Route>
    </Routes>
  );
};

export default Router;

createRouteWithParam(ROUTES.ORDERS.SINGLE_ORDER, '');
