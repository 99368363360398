import React, { useCallback, useRef, useState } from 'react';
import { BoxProps } from 'rebass';
import styled from 'styled-components';
import { Box, theme, Flex, FAIcon, Button } from '@fivehealth/botero';
import {
  faTimes as faClose,
  faExclamationTriangle,
  faCheck as faSuccess,
} from '@fortawesome/pro-regular-svg-icons';
import { CSSTransition } from 'react-transition-group';

const StyledMessageBanner = styled(Box)`
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    opacity: 1;
    background: rgba(37, 107, 247, 0.2);
    transition: border-color 0.5s ease-in-out;

`;

export type MessageBannerProps = {
  bannerProps?: BoxProps;
  content?: React.ReactNode | string | null | undefined;
  open: boolean;
  type?: 'default' | 'error' | 'success';
  sticky?: boolean;
  hideClose?: boolean;
};

export const MessageBanner = ({
  bannerProps,
  content,
  open = false,
  type = 'default',
  sticky = false,
  hideClose = false,
}: MessageBannerProps) => {
  const nodeRef = useRef(null);
  const [show, setShow] = useState(open);

  const getBannerTypeBgColor = useCallback(() => {
    let res = {
      background: 'rgba(37, 107, 247, 0.2)',
      color: theme.colors.primary,
    };
    if (type === 'error') {
      res = {
        background: '#ffebeb',
        color: 'red',
      };
    }
    if (type === 'success') {
      res = {
        background: '#ecf9ed',
        color: '#0c8916',
      };
    }
    return res;
  }, [type]);

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={show}
      timeout={300}
      classNames="basicTransition"
      unmountOnExit
      appear
      onEnter={() => setShow(true)}
      onExited={() => setShow(false)}>
      <StyledMessageBanner
        p={1}
        ref={nodeRef}
        {...bannerProps}
        style={{ ...getBannerTypeBgColor() }}
        onClick={() => {
          if (!sticky) {
            setShow(false);
          }
        }}>
        <Flex flexDirection="row" justifyContent="space-between" width="auto">
          <Box p={1} fontSize={14} fontWeight={400}>
            <Flex flexDirection="row" justifyContent="space-between" width="auto">
              <FAIcon
                icon={type === 'success' ? faSuccess : faExclamationTriangle}
                fontWeight={300}
                fontSize={14}
                style={{ color: getBannerTypeBgColor().color, paddingRight: 10 }}
              />
              <Box>{content}</Box>
            </Flex>
          </Box>
          {!hideClose && (
            <Box>
              <Button
                bg="transparent"
                color={getBannerTypeBgColor().color}
                onClick={() => {
                  setShow(false);
                }}>
                <FAIcon icon={faClose} fontWeight={300} color={getBannerTypeBgColor().color} />
              </Button>
            </Box>
          )}
        </Flex>
      </StyledMessageBanner>
    </CSSTransition>
  );
};

export default MessageBanner;
