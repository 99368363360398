import { theme } from '@fivehealth/botero';
import { merge } from 'lodash';

// TODO: Need to create type definition for the themes and maybe put this in the config constants?
export default merge(theme, {
  // local theming
  colors: {
    US: '#207567',
    USWithOpacity: { '08': '#E1F7F3' },
    CT: '#3651AF',
    CTWithOpacity: { '08': '#EBEEF9' },
    MRI: '#5033FF',
    MRIWithOpacity: { '08': '#F0EEFF' },
    XR: '#750CB0',
    XRWithOpacity: { '08': '#F9F0FE' },
    BMD: '#722F2F',
    BMDWithOpacity: { '08': '#F7EDED' },
    VAS: '#0154B6',
    VASWithOpacity: { '08': '#EAF4FF' },
    MG: '#E58484',
    MGWithOpacity: { '08': '#FFE8E8' },
  },
  //
});

export const StatusColorSchemeConst = {
  danger: 'danger',
  warning: 'warning',
  primary: 'primary',
  darkestShade: 'darkestShade',
  lightestShade: 'lightestShade',
  success: 'success',
} as const;

export type StatusColorSchemeConstType<T> =
  typeof StatusColorSchemeConst[keyof typeof StatusColorSchemeConst] extends string ? T : T;
