import { IS_PRODUCTION } from '@/config/app.config';

export const HELP_SUPPORT = {
  PARKWAY_RADIOLOGY_EMAIL: 'sg.radiology@parkwaypantai.com',
  PARKWAY_HELP_GUIDE_URL: 'https://help.botmd.com/collection/246-parkway',
  PARKWAY_PHONE: '+65 6388 4333',
  PARWKAY_WHATS_URL: 'https://wa.me/6587988008',
  PARKWAY_OPERATING_HOURS: {
    WEEKDAY: '8:30AM to 5PM',
    SATURDAY: '8:30AM to 1PM',
  },
  BOTMD_CUSTOMER_SUPPORT_EMAIL: 'support@botmd.io',
  BOTMD_CUSTOMER_SUPPORT_TYPEFORM_LINK: `https://bot-md.typeform.com/to/oY7hqhDA?typeform-source=${window.location.origin}`,
  BOTMD_SUPPORT_LINK_PRE_LOGIN: IS_PRODUCTION()
    ? 'https://forms.botmd.io/65151461e4973f001d429506'
    : 'https://forms.staging.botmd.io/654c8ca5613b38001ca8a414',

  BOTMD_SUPPORT_LINK_POST_LOGIN: ({
    uid,
    deviceUid,
    institution,
  }: {
    uid?: string;
    deviceUid: string;
    institution?: string;
  }) =>
    `https://bot-md.typeform.com/to/oY7hqhDA#user_uuid=${uid}&device_uuid=${deviceUid}&institution=${institution}`,
};

export const DATE_FORMAT_TXT = {
  MM_DD_YYYY_DASH: 'MM-DD-YYYY',
  YYYY_MM_DD_DASH: 'YYYY-MM-DD',
  YYYY_MM_DD_DASH_TIME: 'YYYY-MM-DD @ HH:mm',
  YYYY_MM_DD_DASH_TIME_1: 'YYYY-MM-DD HH:mm',
  DD_MM_YYYY_SLASH: 'DD/MM/YYYY',
  DD_MMM_YYYY_SPACE: 'DD MMM YYYY', // 14 Nov 2022, 12.30pm
  DD_MMM_YYYY_HHMM_SPACE: 'DD MMM YYYY HH:mm',
  FORMAL_1: 'D MMM YYYY, h:mm a', // 14 Nov 2022, 12.30pm
  FORMAL_2: 'ddd, D MMM YYYY', // Monday, 14 Nov 2022
  FORMAL_3: 'dddd, D MMM YYYY - HH:mm', // FORMAL_3

  DD_MM_YYYY_SLASH_TIME_1: 'DD/MM/YYYY, HH:mm', // 30/01/2022, HH:mm
  DD_MM_YYYY_SLASH_TIME_2: 'DD/MM/YYYY @ HH:mm', // 30/01/2022 @ HH:mm
  DATE_TIME_1: 'HH:mm',
  DATE_TIME_1_CLEAN: 'HHmm',
} as const;
