import MessageBanner from '@/components/Banner/MessageBanner';
import { DATE_FORMAT_TXT } from '@/config/constants/misc.constants';
import { Box, Text, theme } from '@fivehealth/botero';
import moment from 'moment';

export const TemporaryDowtimeBanner: React.FC<{ isHideable?: boolean }> = ({ isHideable }) => {
  // TODO: This is how we schedule a down time. Please remove this component later to properly take out the banner
  const currDate = moment(moment().format(DATE_FORMAT_TXT.YYYY_MM_DD_DASH_TIME_1));
  const endDate = moment('2024-10-05T19:00:00+08:00'); // Oct. 5, 2024 7pm

  const isHideBanner = currDate.isAfter(endDate);

  return !isHideBanner ? (
    <MessageBanner
      open
      sticky
      type="error"
      hideClose={!isHideable}
      content={
        <Box>
          <Text as="span" color={theme.colors.danger}>
            Due to scheduled Parkway maintenance, orders for new patients will be unavailable via Bot MD on
            Saturday, 5th October 2024, from 2:00pm SGT to 7:00pm SGT. We apologize for any inconvenience.
          </Text>
        </Box>
      }
      bannerProps={{ mb: 2, mt: -2 }}
    />
  ) : null;
};
