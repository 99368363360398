import { useCallback, useEffect, useState } from 'react';
import { useQueries } from '@tanstack/react-query';
import { ClinicDocument, ClinicsDocument, StaffProfileDocument } from '@/gql/generated/graphql-hooks';
import graphqlRequestClient from '@/api/client';
import {
  FaradayClinic,
  FaradayModality,
  FaradayStaffProfile,
  ModalitiesAllDocument,
  StaffProfileAccountType,
  StaffProfilesDocument,
} from '@/gql/generated/graphql';
import { DataCtxStateTypes, useAuthContext, useDataContext } from '@/context';
import { ROUTES } from '@/routes/Router';
import { useCookies } from 'react-cookie';
import { AppConfig } from '@/config/app.config';
import { getCookieToken } from '@/helpers/utils';

export type DataHandlerResultsTypes = DataCtxStateTypes;

export const queryKeyConst = {
  clinic: ['clinicQueryKey', 1],
  clinicList: ['clinicsQueryKey', 2],
  staffProfile: ['staffProfileQueryKey', 3],
  staffProfileList: ['staffProfilesDoctorsQueryKey', 4],
  modalitiesQueryKey: ['modalitiesQueryKey', 5],
};

export function useDataHandler() {
  const {
    setUser,
    setClinic,
    setClinicList,
    setDoctorList,
    setModalityList,
    data: { user, clinic, clinicList, doctorList, modalityList },
  } = useDataContext();

  const initResults: DataHandlerResultsTypes = {
    user: null,
    clinic: null,
    clinicList: [],
    doctorList: [],
    modalityList: [],
  };
  const [results, setResults] = useState<DataHandlerResultsTypes>(initResults);
  const cookieDataName = AppConfig.STORAGE.COOKIE_DATA_KEY;
  const [, setCookie] = useCookies([cookieDataName]);
  const cookieToken = getCookieToken();

  const {
    authState: { isAuthenticated },
  } = useAuthContext();

  const allQueries = useQueries({
    queries: [
      {
        queryKey: queryKeyConst.clinic,
        keepPreviousData: true,
        enabled: true,
        queryFn: async () => {
          if (!isAuthenticated) return {} as FaradayClinic;
          const client = graphqlRequestClient();
          const { faradayClinic } = await client.request(ClinicDocument, {});
          return faradayClinic;
        },
        onSuccess: (data: any) => {
          setResults({ ...results, clinic: data });
        },
        select: (datar: any) => datar,
      },
      {
        queryKey: queryKeyConst.clinicList,
        retryOnMount: true,
        retry: true,
        enabled: true,
        networkMode: 'offlineFirst',

        queryFn: async () => {
          if (!isAuthenticated) return [] as FaradayClinic[];
          const client = graphqlRequestClient();
          const { faradayClinics } = await client.request(ClinicsDocument, {});
          return faradayClinics?.edges?.map((o: any) => o?.node as FaradayClinic) as FaradayClinic[];
        },
        onSuccess: (data: any) => {
          setResults({ ...results, clinicList: data });
        },
      },
      {
        queryKey: queryKeyConst.staffProfile,
        keepPreviousData: true,
        enabled: true,
        queryFn: async () => {
          if (!isAuthenticated) return {} as FaradayStaffProfile;
          const client = graphqlRequestClient();
          const { faradayStaffProfile } = await client.request(StaffProfileDocument, {});
          return faradayStaffProfile;
        },
        onSuccess: (data: any) => {
          setCookie(cookieDataName, { user: data }, { path: ROUTES.ROOT });
          setResults({ ...results, user: data });
        },
      },
      {
        queryKey: queryKeyConst.staffProfileList,
        keepPreviousData: true,
        enabled: true,
        queryFn: async () => {
          if (!isAuthenticated) return [] as FaradayStaffProfile[];

          const client = graphqlRequestClient();
          const { faradayStaffProfiles } = await client.request(StaffProfilesDocument, {
            accountType_In: [StaffProfileAccountType.Doctor],
          });
          return faradayStaffProfiles?.edges?.map(
            (o: any) => o?.node as FaradayStaffProfile
          ) as FaradayStaffProfile[];
        },
        onSuccess: (data: any) => {
          setResults({ ...results, doctorList: data });
        },
      },
      {
        queryKey: queryKeyConst.modalitiesQueryKey,
        keepPreviousData: true,
        networkMode: 'offlineFirst',
        enabled: true,
        queryFn: async () => {
          if (!isAuthenticated) return [] as FaradayModality[];
          const client = graphqlRequestClient();
          const { faradayModalities } = await client.request(ModalitiesAllDocument, {});
          return faradayModalities?.edges?.map((o: any) => o?.node as FaradayModality) as FaradayModality[];
        },
        onSuccess: (data: any) => {
          setResults({ ...results, modalityList: data });
        },
      },
    ],
  });

  const isAllFinished = useCallback(() => allQueries.every((query) => query.isSuccess), [allQueries]);

  useEffect(() => {
    if (isAllFinished() && cookieToken && isAuthenticated) {
      if (setClinic && !clinic) {
        setClinic(allQueries[0].data);
      }
      if (setClinicList && !clinicList) {
        setClinicList(allQueries[1].data);
      }
      if (setUser && !user) {
        setUser(allQueries[2].data);
      }
      if (setDoctorList && !doctorList) {
        setDoctorList(allQueries[3].data);
      }
      if (setModalityList && !modalityList) {
        setModalityList(allQueries[4].data);
      }
    }
  }, [
    allQueries,
    clinic,
    clinicList,
    cookieToken,
    doctorList,
    isAllFinished,
    isAuthenticated,
    modalityList,
    setClinic,
    setClinicList,
    setDoctorList,
    setModalityList,
    setUser,
    user,
  ]);

  return { data: allQueries || null, isLoading: !isAllFinished(), isSuccess: isAllFinished() };
}

export default useDataHandler;
