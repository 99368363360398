import React, { useState } from 'react';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionWidget,
  Badge,
  Body,
  Box,
  Button,
  Drawer,
  FAIcon,
  Flex,
  H2,
  H3,
  H5,
  SecondaryOutlinedButton,
  Text,
  Tooltip,
} from '@fivehealth/botero';
import { isEmpty, isEqual, startCase } from 'lodash';
import moment from 'moment';
import {
  displayPhoneText,
  formatPhoneNumber,
  getCookieToken,
  getDoctorListLabelValues,
  openExternalURL,
  useIsView,
} from '@/helpers/utils';
import {
  faChevronDown as chevronDownIcon,
  faChevronUp as chevronUpIcon,
  faEllipsisV,
  faEye as imageReportIcon,
  faEyeSlash as imageReportIconSlash,
  faFileAlt as fileIcon,
  faTimes as closeIcon,
  faUser as userIcon,
  IconDefinition,
} from '@fortawesome/pro-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import { createRouteWithParam, ROUTES } from '@/routes/Router';
import {
  FaradayOrder,
  FaradayOrderExam,
  FaradayPatientProfile,
  OrderExamStatus,
  StaffProfileAccountType,
} from '@/gql/generated/graphql';
import { OrderStateMetadataType } from '@/pages/OrderCreate/OrderCreate';
import { handleOpenURL, OrderModalProps, OrderStatusBadger } from '@/pages/Orders/OrdersCard';
import { CardOutlineBox } from '@/components/Box/CardOutlineBox';
import { DATE_FORMAT_TXT } from '@/config/constants/misc.constants';
import { getStatusBadgeColor } from '@/components/Badge/StatusBadge';
import ExamBadge from '@/components/Badge/ExamBadge';
import { NavigateOrderStateType } from '@/helpers/types/ui.types';
import { OrderActionMenus, OrderExamActionMenus } from '@/pages/Orders/OrdersCardMenus';

type SidePanelIconsProps = {
  onClick?: () => void;
  icon?: IconDefinition;
  size?: number | string;
  component?: any;
  description?: string;
  disabled?: boolean;
};

function SidePanelIcon({ onClick, icon, size, component, description, disabled }: SidePanelIconsProps) {
  return (
    component || (
      <Tooltip tooltip={disabled ? 'Not available' : description}>
        <Button bg="transparent" onClick={onClick} disabled={disabled}>
          <FAIcon icon={icon} color="fullShade" fontSize={size} />
        </Button>
      </Tooltip>
    )
  );
}

interface OrdersFlyoutProps extends OrderModalProps {
  order: FaradayOrder;
  open: boolean;
  onClose: () => void;
  setSelectedExam?: React.Dispatch<React.SetStateAction<FaradayOrderExam>>;
  selectedExam: FaradayOrderExam;
  setErrorMsg?: React.Dispatch<React.SetStateAction<React.ReactElement<any, any> | undefined>>;
}

function OrdersFlyout({
  order,
  selectedExam,
  open,
  onClose,
  setCancelOrderModalOpen,
  setMessageModalOpen,
  setCancelExamModalOpen,
  setSelectedExam,
  setErrorMsg,
  patient: _patient,
}: OrdersFlyoutProps) {
  const { isMobile } = useIsView();
  const navigate = useNavigate();
  const [examsOpen, setExamsOpen] = useState(true);

  // eslint-disable-next-line no-restricted-globals
  const navState: NavigateOrderStateType = { patient: order.patient, parentUrl: location.href };
  const { patient, orderExams } = order;

  let flyoutTopRighButtons = [
    {
      icon: fileIcon,
      description: 'View PDF Order Form',
      size: 18,
      disabled: isEmpty(order?.pdfForms),
      onClick: () => {
        openExternalURL(`${order?.pdfForms[0]?.pdfForm}?session=${getCookieToken()}`, '_blank');
      },
    },
    {
      icon: userIcon,
      description: 'View Patient Profile',
      size: 18,
      onClick: () =>
        navigate(createRouteWithParam(ROUTES.PATIENTS_LIST.PATIENT, patient.uid), {
          state: { ...navState },
        }),
    },
    {
      icon: faEllipsisV,
      description: 'Order Menus',
      size: 18,
      onClick: () => {},
      component: (
        <OrderActionMenus
          order={order}
          exam={selectedExam}
          renderLabel={() => (
            <SecondaryOutlinedButton color="fullShade" width={12} p={0} height={20} border={0}>
              <FAIcon color="darkestShade" icon={faEllipsisV} fontSize={20} />
            </SecondaryOutlinedButton>
          )}
          setMessageModalOpen={setMessageModalOpen}
          setCancelOrderModalOpen={setCancelOrderModalOpen}
          patient={patient}
          setErrorMsg={setErrorMsg}
        />
      ),
    },
    {
      description: 'Close',
      icon: closeIcon,
      size: 22,
      onClick: onClose,
    },
  ] as Partial<SidePanelIconsProps>[];

  flyoutTopRighButtons = [...flyoutTopRighButtons].filter(
    (o) => o.description !== 'View Patient Profile' || isEmpty(_patient)
  );

  const {
    firstName,
    lastName,
    nationalId,
    nationality,
    phone,
    isClaustrophobic,
    doctors,
    dateOfBirth,
    gender,
  } = order?.patient || ({} as FaradayPatientProfile);

  const patientDetails = [
    // NOTE: UID is use for component key to avoid react warning in botero accordion widget
    { 'Patient ID': nationalId },
    { Nationality: nationality },
    { DOB: dateOfBirth },
    { Gender: gender?.toLowerCase() === 'm' ? 'Male' : 'Female' },
    { Claustrophobic: isClaustrophobic ? 'Yes' : 'No' },
    { Mobile: displayPhoneText(phone || '') },
    { 'Doctor(s) I/C': getDoctorListLabelValues(doctors)?.map((o) => o.label) },
  ] as const;

  const orderDetails = [
    { 'Created On': moment(order.createdOn).format(DATE_FORMAT_TXT.FORMAL_1) },
    { 'Updated On': moment(order.updatedOn).format(DATE_FORMAT_TXT.FORMAL_1) },
    {
      'Ordered By': order?.createdBy?.fullName,
    },
    {
      'Ordered For': `Dr. ${startCase(order?.createdFor?.fullName.toString()?.toLowerCase())}`,
    },
  ];

  const {
    image,
    payment,
    despatch,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    insurance_provider,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    return_appointment,
    urgent,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    clinical_findings,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    additional_notes,
  } = order?.metadata as
    | Partial<OrderStateMetadataType> & {
        image: string;
        payment: string;
        despatch: string;
      };
  const additionalDetails = [
    // NOTE: UID is use for component key to avoid react warning in botero accordion widget
    {
      'Ordered by':
        order?.createdBy?.accountType === StaffProfileAccountType.Doctor
          ? `Dr. ${order?.createdBy?.fullName}`
          : order?.createdBy?.fullName,
    },
    {
      'Return Appointment': return_appointment
        ? moment(return_appointment).format(DATE_FORMAT_TXT.FORMAL_1)
        : '-',
    },
    {
      'Clinical Findings': clinical_findings,
    },
    { Urgent: urgent ? 'Yes' : 'No' },
    { Images: startCase(image) },
    { Despatch: startCase(despatch) },
    { Payment: startCase(payment) },
    { 'Insurance Provider': insurance_provider },
    {
      'Additional Notes': additional_notes,
    },
  ] as const;

  const getOrderExamDetails = (exam: FaradayOrderExam) => [
    {
      Status: <OrderStatusBadger orderExam={exam} labelProps={{ fontWeight: 600 }} />,
    },
    {
      Location: exam?.room?.facility?.name || '-',
    },
    {
      'Schedule Date': moment(exam?.scheduledFor).format(DATE_FORMAT_TXT.FORMAL_2),
    },
    {
      'Schedule Time': moment(exam?.scheduledFor).format(DATE_FORMAT_TXT.DATE_TIME_1),
    },
    {
      Radiologist: exam?.metadata?.attention_radiologist || '-',
    },
    {
      Comments: exam?.metadata?.comments || '-',
    },
    {
      'Is Cardiac': exam?.exam?.metadata?.is_cardiac ? 'Yes' : 'No',
    },
  ];

  const createAccordionItems = <T extends readonly Record<string, any>[]>(param: T) =>
    Object.entries(param).map(([key, value], index) => ({
      uid: `accordion_item-${key}_${index}`,
      item: value,
    }));

  const renderAccordionItem = (data: Record<string, any>) => {
    const obj = Object.entries(data.item).map(([key, val]) => ({
      label: key,
      value: val,
    }));

    return (
      <Flex justifyContent="space-between" key={data.uid} id={`accordion_item_content-${data.uid}`} px={1}>
        <Box width={144} pr={1}>
          <H5 color="darkestShade">{obj[0]?.label}</H5>
        </Box>

        <Box style={{ textAlign: 'end' }}>
          <H5 id={`accordion_item_value-${data.uid}`}>{isEmpty(obj[0]?.value) ? '-' : obj[0]?.value}</H5>
        </Box>
      </Flex>
    );
  };

  // NOTE: Pls forgive me for using 'any' for there is no type definitions for the botero widget
  const renderDetails = (detailsData: any) => (
    <AccordionWidget
      cursor="default"
      data={detailsData}
      headerProps={{ px: 1, py: '3px', cursor: 'default', key: order.uid }}
      renderHeader={renderAccordionItem}
      renderBody={() => {}}
      width="100%"
    />
  );
  const isExamReady = (paramStatus: string) =>
    isEqual(paramStatus, OrderExamStatus.ReportReady.toString()) ||
    isEqual(paramStatus, OrderExamStatus.ImageReady.toString());

  return (
    <Box>
      <Drawer width={isMobile ? 'calc(100% - 32px)' : 520} open={open} onClose={onClose} p={[2, 4]}>
        {order ? (
          <Box mb={5}>
            <Flex justifyContent="space-between" alignItems="center">
              <H2>
                {firstName} {lastName}
              </H2>
              <Flex alignItems="center">
                {flyoutTopRighButtons.map((btn, index) => (
                  <SidePanelIcon key={`sidepanel_${index}_${btn.size}`} {...btn} />
                ))}
              </Flex>
            </Flex>
            <Body mt={1} color="darkestShade">
              {formatPhoneNumber(`+${order?.patient?.phone || ''}`)}
            </Body>

            <Flex mt={3} flexDirection="column">
              {renderDetails(createAccordionItems<typeof patientDetails>(patientDetails))}
            </Flex>

            <Box my={3}>
              <Flex pb={2} justifyContent="space-between">
                <H3>Order Details</H3>
              </Flex>
              {renderDetails(createAccordionItems<typeof orderDetails>(orderDetails))}
            </Box>
            <Accordion open={examsOpen}>
              <AccordionHeader
                onToggle={() => setExamsOpen(!examsOpen)}
                render={(isOpen: boolean) => (
                  <Flex justifyContent="space-between" alignItems="center">
                    <Flex alignItems="center">
                      <H3>Exams Details</H3>
                      <Badge borderRadius={8}>{orderExams?.length || 0}</Badge>
                    </Flex>
                    <FAIcon
                      icon={isOpen ? chevronUpIcon : chevronDownIcon}
                      color="darkestShade"
                      fontSize={16}
                    />
                  </Flex>
                )}
              />
              {order &&
                orderExams &&
                orderExams?.map((exam) => (
                  <AccordionBody key={`accordion_body-${exam.uid}`}>
                    <CardOutlineBox
                      outlinedBoxProps={{
                        mt: 2,
                        style: {
                          borderTop: `4px solid ${getStatusBadgeColor(exam.status)}`,
                        },
                      }}
                      headerBoxProps={{ p: 0, my: 1, style: { border: 0 } }}
                      renderHeader={() => (
                        <Flex
                          justifyContent="space-between"
                          px={2}
                          pl={1}
                          mt={2}
                          id={`exam_details_header-${exam.uid}`}>
                          <ExamBadge variant="badge" modality={exam.exam.modality.shortName}>
                            {`${exam.exam.modality.shortName} - ${exam.exam.name}`}
                          </ExamBadge>
                          <Flex alignItems="center" fontWeight={600} mr={1}>
                            {exam.accessionNo ? `#${exam.accessionNo}` : '-'}
                          </Flex>
                        </Flex>
                      )}
                      renderFooter={() => (
                        <Box px={2} py={0}>
                          <Flex justifyContent="space-between" alignItems="center">
                            <Box ml={-2}>
                              <SecondaryOutlinedButton
                                disabled={
                                  !exam?.vueMotionUrl
                                  // exam?.status !== OrderExamStatus.ReportReady ||
                                  // // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
                                  // exam?.status !== OrderExamStatus.ImageReady.toString()
                                }
                                border={0}
                                onClick={(e: React.MouseEvent) =>
                                  handleOpenURL(e, exam.vueMotionUrl as string)
                                }
                                startIcon={
                                  <FAIcon
                                    color="darkestShade"
                                    icon={!exam?.vueMotionUrl ? imageReportIconSlash : imageReportIcon}
                                    fontSize={18}
                                  />
                                }>
                                <Text color="darkestShade" fontSize={14} fontWeight={600}>
                                  View images/report
                                </Text>
                              </SecondaryOutlinedButton>
                            </Box>
                            <Body mt="4px" mr={-2}>
                              {!isExamReady(exam.status) && (
                                <OrderExamActionMenus
                                  setCancelExamModalOpen={setCancelExamModalOpen}
                                  setSelectedExam={setSelectedExam}
                                  exam={exam}
                                  order={order}
                                  setErrorMsg={setErrorMsg}
                                  renderLabel={() => (
                                    <SecondaryOutlinedButton
                                      p={0}
                                      border={0}
                                      startIcon={
                                        <FAIcon color="darkestShade" icon={faEllipsisV} fontSize={18} />
                                      }>
                                      <Text color="darkestShade" fontSize={14} fontWeight={600}>
                                        More
                                      </Text>
                                    </SecondaryOutlinedButton>
                                  )}
                                />
                              )}
                            </Body>
                          </Flex>
                        </Box>
                      )}>
                      <Box p={2} pb={3}>
                        {renderDetails(
                          createAccordionItems<ReturnType<typeof getOrderExamDetails>>(
                            getOrderExamDetails(exam)
                          )
                        )}
                      </Box>
                    </CardOutlineBox>
                  </AccordionBody>
                ))}
            </Accordion>

            <Box>
              <Flex mt={4} mb={2} justifyContent="space-between">
                <H3>Additional Details</H3>
                {
                  // <TextLink text="Edit" />
                }
              </Flex>
              <AccordionWidget
                cursor="default"
                data={createAccordionItems<typeof additionalDetails>(additionalDetails)}
                headerProps={{ px: 1, py: '2px', cursor: 'default', key: order.uid }}
                renderHeader={renderAccordionItem}
                renderBody={() => {}}
                width="100%"
              />
            </Box>
          </Box>
        ) : null}
      </Drawer>
    </Box>
  );
}

export default OrdersFlyout;
