import graphqlRequestClient from '@/api/client';
import BasicSortActionMenu from '@/components/ActionMenus/BasicSortActionMenu';
import TabBadge from '@/components/Badge/TabBadge';
import Filter, { FilterObjectType, FilterOption, FilterStateType } from '@/components/Filter/Filter';
import FilterButton from '@/components/Filter/FilterButton';
import SpinningIcon from '@/components/Loader/SpinningIcon';
import PageHeader from '@/components/PageHeader/PageHeader';
import SearchBar from '@/components/Search/Search';
import { AppConfig } from '@/config/app.config';
import EventsConstants from '@/config/constants/events.constants';
import { useDataContext } from '@/context';
import {
  FaradayModality,
  FaradayOrder,
  FaradayParkwayOrder,
  FaradayPatientProfile,
  FaradayStaffProfile,
  OrderExamStatus,
  OrderOrderBy,
  OrdersQueryVariables,
  ParkwayOrdersDocument,
  ParkwayOrdersQuery,
  ParkwayOrdersQueryVariables,
  StaffProfileOrderBy,
} from '@/gql/generated/graphql';
import {
  StaffProfilesQueryVariables,
  useOrdersQuery,
  useParkwayOrdersQuery,
  useStaffProfilesQuery,
} from '@/gql/generated/graphql-hooks';
import { DeepReadonly, NonEmptyArray } from '@/helpers/types/object.types';
import { LabelValueOptionsType } from '@/helpers/types/ui.types';

import {
  appendObjToQueryParams,
  currFormattedDate,
  dateTimeISOParser,
  enumToArrayMap,
  getDoctorListLabelValues,
  getExamModalityLabelValues,
  localWorkspace,
  queryParamsToObj,
  queryUtil,
  useIsView,
} from '@/helpers/utils';
import { useFilterQuery } from '@/hooks';
import { OrdersCardList } from '@/pages/Orders/OrdersCardList';
import OrdersParkway from '@/pages/Orders/OrdersParkway';
import { ROUTES } from '@/routes/Router';
import {
  ActionMenu,
  ActionMenuItem,
  Box,
  FAIcon,
  Flex,
  PrimaryButton,
  SecondaryOutlinedButton,
  Tab,
  Tabs,
  Text,
} from '@fivehealth/botero';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { InfiniteData, useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { isEmpty, isEqual, omit, sortBy } from 'lodash';
import get from 'lodash/get';
import moment from 'moment/moment';
import React, { memo, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

type OrdersTabsProps = {
  activeTab: number;
  handleTabChange: (tabIndex: number) => void;
  ordersCount?: number | string;
  ordersParkwayCount?: number | string;
  pathname?: string;
  isPatientEmpty?: boolean;
  ordersDataIsLoading: boolean;
  orderDataParkwayIsLoading: boolean;
};

const OrdersTabs: React.FC<PropsWithChildren<OrdersTabsProps>> = ({
  children,
  activeTab,
  handleTabChange,
  ordersCount,
  ordersParkwayCount,
  pathname,
  isPatientEmpty,
  ordersDataIsLoading,
  orderDataParkwayIsLoading,
}) => (
  <Tabs
    panelProps={{
      mt: 0,
    }}
    activeTabIndex={activeTab}
    onChange={handleTabChange}
    headerProps={{
      overflowX: 'auto',
      width: '100%',
      className: 'scrollbar-invisible',
      style: {
        whiteSpace: 'nowrap',
        borderColor: !pathname?.includes('patient-list') && isPatientEmpty ? '#d4d4d4' : 'white',
      },
    }}>
    {!pathname?.includes('patient-list') && isPatientEmpty && (
      <Tab
        logEventProps={{
          page: 'Patient list',
          eventName: EventsConstants.LIST_ALL_ORDERS,
        }}
        data-testid="orders_list_tab_exams"
        style={{
          flexShrink: 0,
        }}>
        <Flex>
          Bot MD Orders
          {ordersDataIsLoading ? (
            <Text ml={2}>
              <SpinningIcon spin={ordersDataIsLoading} />
            </Text>
          ) : (
            <TabBadge tabId="orders_list_tab_exams_badge" number={ordersCount || 0} />
          )}
        </Flex>
      </Tab>
    )}

    {isPatientEmpty && (
      <Tab
        logEventProps={{
          page: 'Price list',
          eventName: EventsConstants.LIST_ALL_ORDERS_NON_BOTMD,
        }}
        data-testid="orders_list_tab_exams_nonbotmd"
        style={{
          flexShrink: 0,
        }}>
        <Flex>
          Non-Bot MD Orders
          {orderDataParkwayIsLoading ? (
            <Text ml={2}>
              <SpinningIcon spin={orderDataParkwayIsLoading} />
            </Text>
          ) : (
            <TabBadge tabId="orders_list_tab_exams_nonbotmd_badge" number={ordersParkwayCount || 0} />
          )}
        </Flex>
      </Tab>
    )}

    {children}
  </Tabs>
);

export const queryKeyConst = {
  ordersList: ['ordersQueryKey'],
  order: ['orderQueryKey'],
  ordersListParkway: ['ordersParkwayQueryKey'],
};

export const initDefaultQueryVars: Partial<OrdersQueryVariables> = {
  sortBy: OrderOrderBy.CreatedOn,
  sortDesc: true,
  orderSearch: '',
  first: AppConfig.PAGINATION_SETTINGS.FIRST_LIMIT_M,
  offset: 0,
};

type OrderProps = {
  patient?: FaradayPatientProfile;
  setPatientOrdersCount?: React.Dispatch<React.SetStateAction<number | string>>;
};

const Orders: React.FC<OrderProps> = ({ patient }) => {
  const navigate = useNavigate();
  const { state: locationState, pathname } = useLocation();
  const { isTablet } = useIsView();
  const { t } = useTranslation();
  const [qUrlParams] = useSearchParams();
  const apiClient = graphqlRequestClient();
  const queryClient = useQueryClient();
  const [orderListCount, setOrderListCount] = useState(0);
  const [activeTab, setActiveTab] = useState(patient ? 0 : Number(queryParamsToObj().tab || 0));
  const activeWorkspace = localWorkspace.getActive();

  const {
    data: { doctorList, modalityList },
  } = useDataContext();

  const defaultStaffQueryVariables: StaffProfilesQueryVariables = {
    sortBy: StaffProfileOrderBy.FullName,
    sortDesc: false,
    staffSearch: '',
    first: AppConfig.PAGINATION_SETTINGS.FIRST_LIMIT_F,
  };

  const { data: staffProfilesDataLabelValue } = useStaffProfilesQuery(apiClient, defaultStaffQueryVariables, {
    staleTime: 0,
    cacheTime: 0,
    select: ({ faradayStaffProfiles }) => {
      const meStaff = {
        label: `Me (${activeWorkspace?.name})`,
        value: activeWorkspace?.uid,
      };
      const list = queryUtil
        .edgeNodeOmitter<typeof faradayStaffProfiles, FaradayStaffProfile[]>(faradayStaffProfiles)
        ?.filter((f) => f?.uid !== activeWorkspace?.uid)
        ?.map((o) => ({
          label: `${o?.fullName}`,
          value: o?.uid,
        }));
      return [...[meStaff], ...list];
    },
  });

  const orderExamStatusLabelValue = enumToArrayMap<typeof OrderExamStatus, LabelValueOptionsType<string>>(
    OrderExamStatus,
    'label',
    'value'
  ).filter(
    (o) =>
      isEqual(o.value, OrderExamStatus.PendingSchedule.toString()) ||
      isEqual(o.value, OrderExamStatus.Scheduled.toString()) ||
      isEqual(o.value, OrderExamStatus.Rescheduled.toString()) ||
      isEqual(o.value, OrderExamStatus.PendingReschedule.toString()) ||
      isEqual(o.value, OrderExamStatus.PendingCancel.toString()) ||
      isEqual(o.value, OrderExamStatus.Cancelled.toString()) ||
      isEqual(o.value, OrderExamStatus.ImageReady.toString()) ||
      isEqual(o.value, OrderExamStatus.ReportReady.toString()) ||
      isEqual(o.value, OrderExamStatus.Error.toString()) ||
      isEqual(o.value, OrderExamStatus.Duplicate.toString()) ||
      isEqual(o.value, OrderExamStatus.RisModified.toString()) ||
      isEqual(o.value, OrderExamStatus.RisCancelled.toString()) ||
      isEqual(o.value, OrderExamStatus.RisScheduled.toString()) ||
      isEqual(o.value, OrderExamStatus.RisRescheduled.toString())
  );

  const orderExamModalityLabelValue = useMemo(
    () => getExamModalityLabelValues(modalityList as FaradayModality[]),
    [modalityList]
  );

  const doctorListLabelValue = useMemo(
    () => getDoctorListLabelValues(doctorList as FaradayStaffProfile[]),
    [doctorList]
  );

  const sortByLabelValue = sortBy(
    enumToArrayMap<typeof OrderOrderBy, LabelValueOptionsType<string>>(OrderOrderBy, 'label', 'value'),
    ['label']
  );

  const initialFiltersArray: NonEmptyArray<FilterObjectType> = useMemo(
    () => [
      {
        title: 'Ordered By',
        value: 'orderedby',
        options: (staffProfilesDataLabelValue as FilterOption[]) || [],
      },
      {
        title: 'Doctors',
        value: 'doctors',
        options: (doctorListLabelValue as FilterOption[]) || [],
      },
      {
        title: 'Created On Date',
        value: 'daterange',
        options: [
          { label: 'Today', value: 'today' },
          { label: 'Yesterday', value: 'yesterday' },
          { label: 'Last 7 days', value: 'last-7-days' },
          { label: 'Last 30 days', value: 'last-30-days' },
          { label: 'Last Month', value: 'last-month' },
        ],
      },
      {
        title: 'Exam Status',
        value: 'status',
        options: (orderExamStatusLabelValue as FilterOption[]) || [],
      },

      {
        title: 'Modalities',
        value: 'modality',
        options:
          (orderExamModalityLabelValue?.map((o) => ({ label: o.label, value: o.label })) as FilterOption[]) ||
          [],
      },
    ],
    [doctorListLabelValue, orderExamModalityLabelValue, orderExamStatusLabelValue, staffProfilesDataLabelValue]
  );

  // const { filter, filterDefault, filtersArray } = useFilterQuery(initialFiltersArray);
  const filterQuery = useFilterQuery(initialFiltersArray);

  const getFilterQueryVariables = (qFilter: FilterStateType) => {
    const selectedModalities = qFilter[initialFiltersArray[3]?.value as string] as string[];
    const qFilterModaliltyUids = orderExamModalityLabelValue
      ?.filter((o) => selectedModalities?.includes(o.label))
      .map((k) => k.value?.uid);

    const dateRangeArr = qFilter?.daterange; // qUrlParams.get('daterange')?.split(',');
    const dateRangeDefOpts = initialFiltersArray[1]?.options;
    let dateRangeValue = {};

    if (!isEmpty(dateRangeArr)) {
      const getDateRangeObj = (val: string) =>
        dateRangeDefOpts?.find((f) => f.value === val) || ({} as FilterOption);

      dateRangeArr?.forEach((o) => {
        if (o === getDateRangeObj('today').value) {
          dateRangeValue = {
            createdOn_Lte: dateTimeISOParser(moment(currFormattedDate).format(), false),
            createdOn_Gt: dateTimeISOParser(moment(currFormattedDate).format()),
          };
        }

        if (o === getDateRangeObj('yesterday').value) {
          dateRangeValue = {
            createdOn_Lte: dateTimeISOParser(moment(currFormattedDate).subtract(24, 'hours').format(), false),
            createdOn_Gte: dateTimeISOParser(moment(currFormattedDate).subtract(24, 'hours').format()),
          };
        }

        if (o === getDateRangeObj('last-7-days').value) {
          dateRangeValue = {
            createdOn_Lt: dateTimeISOParser(moment(currFormattedDate).format(), false),
            createdOn_Gte: dateTimeISOParser(moment(currFormattedDate).subtract(7, 'days').format()),
          };
        }

        if (o === getDateRangeObj('last-30-days').value) {
          dateRangeValue = {
            createdOn_Lt: dateTimeISOParser(moment(currFormattedDate).format(), false),
            createdOn_Gte: dateTimeISOParser(moment(currFormattedDate).subtract(30, 'days').format()),
          };
        }

        if (o === getDateRangeObj('last-month').value) {
          dateRangeValue = {
            createdOn_Lt: currFormattedDate,
            createdOn_Gte: dateTimeISOParser(moment(currFormattedDate).subtract(2, 'months').format()),
          };
        }
      });
    }

    const tQueryVars = {
      orderSearch: qUrlParams.get('search'),
      sortBy: qUrlParams.get('sortBy'),
      offset: qUrlParams.get('offset') || 0,
      createdBy_Uid_In: qFilter[initialFiltersArray[0]?.value] as string[],
      createdFor_Uid_In: qFilter[initialFiltersArray[1]?.value as string] as string[],
      exams_Status_In: qFilter[initialFiltersArray[3]?.value as string] as string[],
      exams_Exam_Modality_Uid_In: qFilterModaliltyUids,
      ...dateRangeValue,
    } as OrdersQueryVariables;

    const res: Record<string, any[] | number | string> = {};
    const keys = Object.keys(
      omit(
        tQueryVars,
        isEmpty(dateRangeValue) ? ['createdOn_Gt', 'createdOn_Lt', 'createdOn_Gte', 'createdOn_Lte'] : []
      )
    );
    for (const key of keys) {
      const val = tQueryVars[key];
      if (!isEmpty(val)) {
        res[key] = val;
      }
    }

    return res as Partial<OrdersQueryVariables>;
  };

  const defaultQueryVars = useMemo(
    () =>
      !isEmpty(patient) ? { ...initDefaultQueryVars, patient_Uid_In: patient?.uid } : initDefaultQueryVars,
    [patient]
  );

  const [queryVariables, setQueryVariables] = useState<DeepReadonly<Partial<OrdersQueryVariables>>>({
    ...defaultQueryVars,
    ...getFilterQueryVariables(filterQuery.filter.get()),
  });

  const defaultQueryVarsParkway = useMemo<Partial<ParkwayOrdersQueryVariables>>(
    () => ({
      first: AppConfig.PAGINATION_SETTINGS.FIRST_LIMIT_F,
      parkwayOrderSearch: '',
      procedureCode: '',
    }),
    []
  );

  const [queryVariablesParkway, setQueryVariablesParkway] = useState<
    DeepReadonly<Partial<ParkwayOrdersQueryVariables>>
  >({ ...defaultQueryVarsParkway });

  const {
    data: ordersData,
    refetch: ordersDataRefetch,
    isLoading: ordersDataIsLoading,
    error: ordersDataError,
  } = useOrdersQuery(apiClient, queryVariables as OrdersQueryVariables, {
    queryKey: queryKeyConst.ordersList,
    enabled: true,
    select: ({ faradayOrders }) => {
      const count = (faradayOrders as any)?.totalCount;
      if (count !== orderListCount) {
        setOrderListCount(count);
      }
      return queryUtil.edgeNodeOmitter<typeof faradayOrders, FaradayOrder[]>(faradayOrders);
    },
  });

  const { data: ordersDataParkwayCount, isLoading: ordersDataParkwayCountIsLoading } = useParkwayOrdersQuery(
    apiClient,
    omit(queryVariablesParkway as ParkwayOrdersQueryVariables, ['offset', 'first']),
    {
      queryKey: queryKeyConst.ordersListParkway,
      enabled: true,
      select: ({ faradayParkwayOrders }) => faradayParkwayOrders?.totalCount,
    }
  );

  const {
    data: ordersParkwayData,
    refetch: ordersParkwayDataRefetch,
    hasNextPage: ordersParkwayDataFetchHasNextPage,
    isFetchingNextPage: ordersParkwayDataIsFetchNextPage,
    fetchNextPage: ordersParkwayDataFetchNextPage,
    isFetching: ordersParkwayDataIsFetching,
  } = useInfiniteQuery({
    queryKey: queryKeyConst.ordersListParkway,
    enabled: true,
    keepPreviousData: true,

    queryFn: async ({ pageParam = 0 }) => {
      const updatedQueryVars = { ...queryVariablesParkway, offset: pageParam };
      const resp = await queryUtil.asyncQueryFnResponse<typeof queryVariables, any>(
        apiClient,
        ParkwayOrdersDocument,
        'faradayParkwayOrders',
        updatedQueryVars
      );
      setQueryVariablesParkway(updatedQueryVars);
      return resp;
    },
    getNextPageParam: ({ faradayParkwayOrders }, allPages) =>
      queryUtil.pageParamLength(faradayParkwayOrders, allPages, 'faradayParkwayOrders'),

    select: (data: InfiniteData<ParkwayOrdersQuery>) =>
      queryUtil.paginatedDataMapper<typeof data>(data, 'faradayParkwayOrders'),
  });

  const mappedOrdersParkwayData = queryUtil
    .flattenPages<FaradayParkwayOrder, typeof ordersParkwayData>(ordersParkwayData)
    ?.map((o) => ({
      ...o,
    }));

  const handleSetQueryVariablesParkway = (value: ParkwayOrdersQueryVariables) => {
    setQueryVariablesParkway({ ...value });
  };

  function handleTabChange(tab: number) {
    setTimeout(() => {
      setActiveTab(tab);
      appendObjToQueryParams({ tab });
    }, 0);
  }

  function handleSearch(search: number | string) {
    appendObjToQueryParams({ search });
    if (search !== queryVariables.orderSearch) {
      setQueryVariables({ ...queryVariables, orderSearch: search as string });
      setTimeout(() => {
        ordersDataRefetch();
      }, 500);
    }
  }

  function handleReload() {
    setTimeout(() => {
      window.location.reload();
    }, 0);
  }

  function handleSortBy(sortByParam: OrderOrderBy, sortDesc: boolean) {
    appendObjToQueryParams({ sortBy: sortByParam });
    setQueryVariables({ ...queryVariables, sortBy: sortByParam, sortDesc });
  }

  function handleSubmitFilter(qFilter: FilterStateType) {
    const isReset = isEmpty(qFilter);
    // NOTE: We need to clear all the paginated url param when using filters to avoid anomalies
    appendObjToQueryParams({ offset: 0, page: 1 });
    const newOffset = queryVariables.offset !== 0 ? 0 : queryVariables.offset;

    const { daterange, doctors, modality, status, orderedby } = qFilter;
    const hasFilterVals =
      isEmpty(daterange) && isEmpty(doctors) && isEmpty(modality) && isEmpty(status) && isEmpty(orderedby);

    if (isReset || hasFilterVals) {
      setQueryVariables({ ...defaultQueryVars, offset: newOffset });
    } else {
      const qVarsFilterOmit = omit(queryVariables, [
        'createdOn_Gt',
        'createdOn_Lt',
        'createdOn_Gte',
        'createdOn_Lte',
      ]);

      const qVars = { ...qVarsFilterOmit, ...getFilterQueryVariables(qFilter), offset: newOffset };
      setQueryVariables(qVars);
    }
  }

  // TODO: Sorry for using useEffect here, I dont have much choice for now.
  useEffect(() => {
    if (locationState?.reload) {
      appendObjToQueryParams({ search: '' });
      // NOTE: Reset query variables every time we go to /orders path
      setQueryVariables({ ...defaultQueryVars, orderSearch: '' });
    }
  }, [defaultQueryVars, locationState, locationState?.reload, navigate]);

  return (
    <Box mb={8}>
      {isEmpty(patient) && (
        <PageHeader
          title="Orders"
          subtitle="You can create a new order and view past & current orders here."
          rightElements={
            isTablet ? (
              <ActionMenu
                label={
                  <PrimaryButton borderRadius={8} px="10px">
                    <FAIcon icon={faChevronDown} color="emptyShade" />
                  </PrimaryButton>
                }>
                <ActionMenuItem
                  onClick={() => navigate(ROUTES.ORDER_CREATE.ROOT)}
                  data-testid="create_new_order"
                  logEventProps={{
                    page: 'Orders',
                    eventName: EventsConstants.CREATE_NEW_ORDER,
                  }}>
                  {t(`Create new order`)}
                </ActionMenuItem>
              </ActionMenu>
            ) : (
              <PrimaryButton
                onClick={() => navigate(ROUTES.ORDER_CREATE.ROOT)}
                data-testid="create_new_order"
                logEventProps={{
                  page: 'Orders',
                  eventName: EventsConstants.CREATE_NEW_ORDER,
                }}
                borderRadius={8}>
                {t(`Create new order`)}
              </PrimaryButton>
            )
          }
        />
      )}

      <Box mt={-2} mb={2}>
        <OrdersTabs
          activeTab={activeTab}
          ordersCount={orderListCount}
          ordersParkwayCount={Number(ordersDataParkwayCount)}
          handleTabChange={handleTabChange}
          isPatientEmpty={isEmpty(patient)}
          ordersDataIsLoading={ordersDataIsLoading}
          orderDataParkwayIsLoading={ordersDataParkwayCountIsLoading}
        />

        {activeTab === 0 && (
          <Flex style={{ gap: 16 }} height={88} alignItems="center" justifyContent="space-between">
            <SearchBar
              value={(get(queryParamsToObj(), 'search.0', '') as string) || ''}
              onSearch={handleSearch}
              data-testid="orders_search_input"
              placeholder={t(`Search by ${patient ? '' : 'patient name or'} accession #`)}
            />

            <SecondaryOutlinedButton
              color="fullShade"
              borderColor="mediumShade"
              borderRadius={8}
              alt="Reload"
              style={{ opacity: ordersDataIsLoading ? 0.7 : 1 }}
              onClick={handleReload}>
              <SpinningIcon spin={ordersDataIsLoading} />
            </SecondaryOutlinedButton>

            <BasicSortActionMenu<OrderOrderBy, typeof queryVariables.sortDesc>
              handleOnSort={handleSortBy}
              sortBy={queryVariables.sortBy as OrderOrderBy}
              sortOrderDsc={Boolean(queryVariables.sortDesc)}
              options={sortByLabelValue}
              isDisabled={ordersDataIsLoading}
            />

            <Flex style={{ gap: 16 }}>
              <Filter
                maxWidth={1300}
                setFilter={filterQuery.filter.set}
                filter={filterQuery.filter.get()}
                filters={initialFiltersArray}
                onSubmit={handleSubmitFilter}
                label={(toggleFilter) => (
                  <FilterButton
                    onClick={() => {
                      toggleFilter();
                    }}
                    disabled={!(doctorList && doctorList?.length > 0)}
                    logEventProps={{
                      page: 'Orders',
                      eventName: EventsConstants.FILTER_ORDERS,
                    }}
                  />
                )}
              />
            </Flex>
          </Flex>
        )}
      </Box>

      {activeTab === 0 && (
        <OrdersCardList
          data={ordersData as FaradayOrder[]}
          isLoading={ordersDataIsLoading}
          queryClient={queryClient}
          queryVariables={queryVariables as OrdersQueryVariables}
          setQueryVariables={setQueryVariables}
          orderListCount={orderListCount}
          orderListError={ordersDataError}
          refetch={ordersDataRefetch}
          patient={patient}
        />
      )}

      {activeTab === 1 && !pathname?.includes('patient-list') && (
        <OrdersParkway
          defaultQueryVariables={defaultQueryVarsParkway}
          queryVariables={queryVariablesParkway as ParkwayOrdersQueryVariables}
          setQueryVariables={handleSetQueryVariablesParkway as any}
          refetch={ordersParkwayDataRefetch}
          isFetchingNextPage={ordersParkwayDataIsFetchNextPage}
          fetchNextPage={ordersParkwayDataFetchNextPage}
          isFetching={ordersParkwayDataIsFetching}
          hasNextPage={ordersParkwayDataFetchHasNextPage as boolean}
          queryClient={queryClient}
          orderListCount={Number(ordersDataParkwayCount)}
          ordersData={mappedOrdersParkwayData}
        />
      )}
    </Box>
  );
};

export default memo(Orders);
