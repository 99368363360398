import React, { PropsWithRef, forwardRef } from 'react';
import { Box, LogEvent } from '@fivehealth/botero';
import { LogEventProps } from '@/helpers/types/ui.types';

export type InputFieldProps = PropsWithRef<JSX.IntrinsicElements['input']> & {
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  width?: number | string;
  maxWidth?: number | string;
  logEventProps?: LogEventProps;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const InputField: React.FC<InputFieldProps> = forwardRef(
  (
    { startIcon, endIcon, width, maxWidth, logEventProps, onChange, ...props },
    ref // TODO <--- forwardRef to boteros InputField.js
  ) => (
    <LogEvent
      logEventProps={logEventProps}
      actionProps={{ onClick: { action: 'typing' } }}
      elementType="input">
      <Box position="relative" width={width} maxWidth={maxWidth}>
        {startIcon ? (
          <Box position="absolute" top="12px" left="12px">
            {startIcon}
          </Box>
        ) : null}
        <Box
          as="input"
          style={{ boxSizing: 'border-box' }}
          ref={ref} // TODO <--- Add this line to boteros InputField.js
          py="12px"
          pl={startIcon ? '36px' : '12px'}
          pr={endIcon ? '36px' : '12px'}
          borderRadius={8}
          border="1px solid"
          borderColor="mediumShade"
          fontSize={14}
          color="fullShade"
          onChange={onChange}
          {...props}
          width="100%"
        />
        {endIcon ? (
          <Box position="absolute" top="12px" right="12px">
            {endIcon}
          </Box>
        ) : null}
      </Box>
    </LogEvent>
  )
);

export default InputField;
