import { useCallback, useState } from 'react';
import { useCookies } from 'react-cookie';
import { AppConfig } from '@/config/app.config';
import {
  FaradayClinic,
  FaradayStaffProfile,
  HeimdallAuthorizationFlow,
  HeimdallAuthorizationFlowInput,
  StaffProfileDocument,
} from '@/gql/generated/graphql';
import graphqlRequestClient from '@/api/client';
import { useHeimdallAuthorizationFlowExchangeMutation } from '@/gql/generated/graphql-hooks';
import { ROUTES } from '@/routes/Router';
import { saveWorkspace } from '@/helpers/utils/storage.utils';
import { nullTextParser } from '@/helpers/utils';

export const useSessionExchange = () => {
  const cookieTokenName = AppConfig.STORAGE.COOKIE_TOKEN_KEY;
  const cookieDataName = AppConfig.STORAGE.COOKIE_DATA_KEY;
  const [cookies, setCookie] = useCookies([cookieTokenName, cookieDataName]);
  const cookieToken = nullTextParser(cookies?.[cookieTokenName] as string);
  const apiClient = graphqlRequestClient({ token: cookieToken as string });
  const [data, setData] = useState<HeimdallAuthorizationFlow | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { mutateAsync, error } = useHeimdallAuthorizationFlowExchangeMutation(apiClient, {});

  const mutate = useCallback(
    async (clinic: FaradayClinic, token: string | null | undefined) => {
      try {
        setIsLoading(true);
        if (clinic?.domain && token) {
          const input = {
            providerApplicationUid: AppConfig.CLEO_SESSION_EXCHANGE_PROVIDER_UID,
            providerInput: {
              session: token,
            },
            applicationInput: {
              domain: clinic?.domain,
            },
          } as HeimdallAuthorizationFlowInput;

          const { heimdallAuthorizationFlow: sessionExchangeResp } = await mutateAsync({
            heimdallAuthorizationFlowInput: input,
          });
          const newToken = sessionExchangeResp?.session?.uid || '';

          if (newToken) {
            setCookie(cookieTokenName, newToken, { path: ROUTES.ROOT });
            const newApiClient = graphqlRequestClient();
            const { faradayStaffProfile } = await newApiClient.request(StaffProfileDocument, {});
            const staffProfileData = faradayStaffProfile as FaradayStaffProfile;
            await saveWorkspace(staffProfileData);
            setCookie(cookieDataName, { user: staffProfileData }, { path: ROUTES.ROOT });
            setData(sessionExchangeResp as HeimdallAuthorizationFlow);
            setIsLoading(false);
          }
          return sessionExchangeResp as HeimdallAuthorizationFlow;
        }
        return null;
      } catch (e) {
        console.log(`%c😭❌️[ERROR] - Unable to switch workspace: ${e}`, 'background: #000000; color: orange');
        setData(null);
        setIsLoading(false);
        return null;
      }
    },
    [cookieDataName, cookieTokenName, mutateAsync, setCookie]
  );

  return { mutate, data, isLoading, error };
};

export default useSessionExchange;
