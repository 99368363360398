import React, { FocusEventHandler, FormEventHandler, MouseEventHandler } from 'react';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import {
  FaradayExam,
  FaradayFacilityAvailability,
  FaradayOrder,
  FaradayOrderExam,
  FaradayPatientProfile,
  FaradayStaffProfile,
  OrderExamStatus,
} from '@/gql/generated/graphql';
import { enumToObject } from '@/helpers/utils';
import { OrderStateType } from '@/pages/OrderCreate/OrderCreate';

// =====================================================================================

/**
 * React props conditional types for functional and class base component
 */
export type ComponentConditionalProps<T> = T extends React.FC<infer Props>
  ? Props
  : T extends React.Component<infer Props>
  ? Props
  : never;

// =====================================================================================

/**
 * Use for basic component form event types.
 */
export interface ComponentEventProps {
  onClick: MouseEventHandler;
  onFocus: FocusEventHandler;
  onBlur: FocusEventHandler;
  onChange: FormEventHandler;
}

// =====================================================================================
/**
 * Logevent props types for analytics.
 */
export interface LogEventProps {
  subSource?: string;
  eventName: string;
  page: string;
}

// =====================================================================================

/**
 * MenuItem type for rebase or botero action menu component.
 */
export interface MenuItem {
  id: string;
  testId: string;
  label: string;
  icon: IconDefinition;
  onClick: (item: MenuItem) => void;
  path: string;
  logEventProps?: LogEventProps;
  labelProps?: React.CSSProperties;
  iconProps?: React.CSSProperties;
  toolTipElementProps?: React.CSSProperties;
}

export interface AdditionalMenuItem extends MenuItem {
  isLink?: boolean;
}

export interface FooterItem extends Omit<MenuItem, 'onClick' | 'path'> {
  onClick: () => void;
}

// =====================================================================================

/**
 * Related to generic label and value types use for dropdown or any rebass/botero component
 */

export type LabelValueOptionsType<TValue, TLabel = string> = {
  value: TValue;
  label: TLabel;
};

export type DoctorLabelValueType = LabelValueOptionsType<Partial<FaradayStaffProfile>>;

export type PatientLabelValueType = LabelValueOptionsType<Partial<FaradayPatientProfile>>;

export type ExamLabelValueType = LabelValueOptionsType<
  Partial<FaradayExam>,
  JSX.Element | string | null | undefined
>;

export interface FacilityAvailabilityValueType
  extends LabelValueOptionsType<
    Partial<FaradayFacilityAvailability>,
    JSX.Element | string | null | undefined
  > {
  keyword?: string; // NOTE: Using this as a search keyword instead of jsx element label field
  isAllExamsAvailable?: boolean;
}

// ================================================================

/**
 * Related to order exam type from the schema enum. Converting enum to object and then using the key and value for type-strictness.
 */
type OrderExamStatusKeyType = {
  [key in OrderExamStatus]: string;
};

export const OrderExamStatusValueType = enumToObject<typeof OrderExamStatus, OrderExamStatusKeyType>(
  OrderExamStatus
);
export type OrderExamStatusConstType =
  (typeof OrderExamStatusValueType)[keyof typeof OrderExamStatusValueType];

// ================================================================

export type NavigateOrderStateType = {
  order?: FaradayOrder;
  orderExams?: FaradayOrderExam[];
  exam?: FaradayOrderExam;
  patient?: FaradayPatientProfile;
  parentUrl?: string;
  page?: { tab: number };
  step?: OrderStateType['step'];
  reload?: boolean;
  error?: {
    session: number | string;
    message: React.ReactNode | string;
  };
};

// ================================================================

export type GQLErrorsType = {
  message: string;
  errorUid: string;
  requestLogUid: string;
  exc_type: string;
};

export type APIResponseType<TVars = Record<any, any>, TData = Record<any, any>> = {
  request: {
    query: string;
    variables: TVars;
  };
  response: {
    data: TData;
    errors: GQLErrorsType[];
    headers: Record<any, any>;
    status: number;
  };
};

// ================================================================

export const StepDynamicActionConst = {
  EDIT_EXAM: 'EDIT_EXAM',
  ADD_EXAM: 'ADD_EXAM',
  EDIT_ORDER: 'EDIT_ORDER',
} as const;

export type StepDynamicActionConstType = (typeof StepDynamicActionConst)[keyof typeof StepDynamicActionConst];

// ================================================================
