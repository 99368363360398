import get from 'lodash/get';
import find from 'lodash/find';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { SideBar, theme, Box } from '@fivehealth/botero';

import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import {
  faUserFriends,
  faUser,
  faQuestionCircle,
  faEnvelope,
  faFileAlt,
  faBookMedical,
} from '@fortawesome/pro-regular-svg-icons';

import BOTMD_LOGO_LARGE from '@/assets/logo-large.svg';
import BOTMD_LOGO_SMALL from '@/assets/logo-small.svg';
import EventsConstants from '@/config/constants/events.constants';
import { ROUTES } from '@/routes/Router';
import { MenuItem, AdditionalMenuItem, FooterItem } from '@/helpers/types/ui.types';
import { useCallback, useMemo, useState } from 'react';
import { gotoReplaceUrlLink, isCurrentUserType, tweakPageName } from '@/helpers/utils';
import { NonEmptyArray, ImmutableObject } from '@/helpers/types/object.types';
import styled from 'styled-components';
import { TRANSITION } from '@/config/constants/styles.constants';
import ContactSupportModal from '../Modals/ContactSupportModal';
import { HELP_SUPPORT } from '@/config/constants/misc.constants';
import { useAuthContext } from '@/context';
import packageJson from '../../../package.json';
import { IS_PRODUCTION } from '@/config/app.config';

const SidebarWrapper = styled.div({
  whiteSpace: 'nowrap',
  '& div': {
    transition: `svg,path,width ${TRANSITION}}`,
  },
});

const APP_BUILD = `v${packageJson.version}`;
// eslint-disable-next-line @typescript-eslint/dot-notation
window['APP_BUILD'] = IS_PRODUCTION()? APP_BUILD : `${APP_BUILD}(dev)`;

function Sidebar({ ...props }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const authCtx = useAuthContext();

  const [contactUsModalOpen, setContactUsModalOpen] = useState(false);
  const [isExpandSideBar, setIsExpandSideBar] = useState(false);
  const [isShowVer, setIsShowVer] = useState(true);
  const pageName = useMemo(() => location.pathname.split('/')[1] || '', [location.pathname]);

  const handleMenuClick = useCallback(
    (item: MenuItem) => {
      if (!authCtx?.authState?.isLoading && authCtx?.authState?.isAuthenticated) {
        if (item.id === 'orders') {
          setIsExpandSideBar(true);
          // NOTE: Use navigate state to set a flag to indicate the page needs to reload orders page (reset all url params and filters)
          // navigate(get(item, 'path'), {
          //   state: { reload: true } as NavigateOrderStateType,
          // });
          gotoReplaceUrlLink('/');
        } else if (item.id === 'createNewOrder' && pageName === 'order-create') {
          navigate(get(item, 'path'), {
            state: null,
          });
          setTimeout(() => {
            window.location.reload();
          }, 0);
        } else {
          setIsExpandSideBar(true);
          navigate(get(item, 'path'));
        }
      }
    },
    [authCtx?.authState?.isAuthenticated, authCtx?.authState?.isLoading, navigate, pageName]
  );

  const headers = useMemo(
    () => [
      {
        id: 'botmd',
        logo: (expand: boolean) => (expand ? BOTMD_LOGO_LARGE : BOTMD_LOGO_SMALL),
      },
    ],
    []
  );

  const menus: NonEmptyArray<ImmutableObject<MenuItem>> = [
    {
      id: 'orders',
      testId: 'orders-menu',
      label: t('Orders'),
      icon: faFileAlt,
      onClick: handleMenuClick,
      path: '/orders',
      logEventProps: {
        page: tweakPageName(pageName),
        subSource: 'Sidebar',
        eventName: EventsConstants.LIST_PATIENT_MONITORING,
      },
    },
    {
      id: 'patient-list',
      testId: 'patient-list-menu',
      label: t('Patient List'),
      icon: faUserFriends,
      onClick: handleMenuClick,
      path: '/patient-list',
      logEventProps: {
        page: tweakPageName(pageName),
        subSource: 'SideBar',
        eventName: EventsConstants.LIST_PATIENT_LIST,
      },
    },

    {
      id: 'clinical-admin',
      testId: 'clinical-admin-menu',
      label: t('Clinical Admin'),
      icon: faUser,
      onClick: handleMenuClick,
      path: ROUTES.CLINICAL_ADMIN.ROOT,
      logEventProps: {
        page: tweakPageName(pageName),
        subSource: 'SideBar',
        eventName: EventsConstants.LIST_CLINICAL_ADMIN,
      },
    },
    {
      id: 'price-list',
      testId: 'price-list-menu',
      label: t('Radiology Catalogue'),
      icon: faBookMedical,
      onClick: handleMenuClick,
      path: ROUTES.PRICE_LIST.ROOT,
      logEventProps: {
        page: tweakPageName(pageName),
        subSource: 'SideBar',
        eventName: EventsConstants.LIST_PRICE_LIST,
      },
      labelProps: {
        userSelect: 'none',
      },
    },
  ];

  const additionalMenus: NonEmptyArray<ImmutableObject<AdditionalMenuItem>> = [
    {
      id: 'createNewOrder',
      testId: 'createNewOrderMenu',
      label: t('Create new order'),
      path: ROUTES.ORDER_CREATE.ROOT,
      isLink: true,
      icon: faPlusCircle,
      logEventProps: {
        page: tweakPageName(pageName),
        subSource: 'SideBar',
        eventName: EventsConstants.CREATE_NEW_ORDER,
      },
      onClick: handleMenuClick,
      //   () => {
      //   navigate(ROUTES.ORDER_CREATE.ROOT);
      // },
      iconProps: {
        color: theme.colors.primary,
      },
      labelProps: {
        color: theme.colors.primary,
        fontWeight: 600,
      },
      toolTipElementProps: {
        color: 'white',
      },
    },
  ];

  // import rebass style props types
  const footers: NonEmptyArray<ImmutableObject<FooterItem>> = [
    {
      id: 'helpGuide',
      testId: 'helpGuideMenu',
      logEventProps: {
        page: tweakPageName(pageName),
        subSource: 'SideBar',
        eventName: EventsConstants.READ_HELP_GUIDE,
      },
      label: t('Help Guide'),
      icon: faQuestionCircle,
      onClick: () => {
        const url = HELP_SUPPORT.PARKWAY_HELP_GUIDE_URL;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        window.open(url, '_blank').focus();
      },
      iconProps: {
        color: theme.colors.darkestShade,
      },
      labelProps: {
        color: theme.colors.darkestShade,
        fontWeight: 600,
      },
      toolTipElementProps: {
        color: 'white',
      },
    },
    {
      id: 'contactUs',
      testId: 'contactUsMenu',
      label: t('Contact Us'),
      icon: faEnvelope,
      onClick: () => setContactUsModalOpen(true),
      iconProps: {
        color: theme.colors.darkestShade,
      },
      labelProps: {
        color: theme.colors.darkestShade,
        fontWeight: 600,
      },
      toolTipElementProps: {
        color: 'white',
      },
      logEventProps: {
        page: tweakPageName(pageName),
        subSource: 'SideBar',
        eventName: EventsConstants.OPEN_CONTACT_US,
      },
    },
  ];

  const regex = new RegExp(`^/${location.pathname.split('/').filter(Boolean)[0]}`);

  return (
    <>
      {!IS_PRODUCTION() && isShowVer && (
        <Box
          style={{
            height: 'fit-content',
            position: 'fixed',
            zIndex: 2,
            top: -10,
            left: 100,
            marginLeft: -15,
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 10,
            fontSize: 11,
            color: 'white',
            borderRadius: 7,
            cursor: 'pointer',
            backgroundColor: 'rgb(71 127 237)',
          }}
          onClick={() => {
            setIsShowVer(false);
          }}>
          {APP_BUILD}
        </Box>
      )}

      <SidebarWrapper>
        <SideBar
          zIndex={1}
          activeTab={find(menus, (menu) => regex.test(menu.path))?.id || 'overview'}
          headers={headers}
          menus={!isCurrentUserType.superAdmin() ? menus.filter((o) => o.id !== 'clinical-admin') : menus}
          additionalMenus={additionalMenus}
          footers={footers}
          defaultExpand={isExpandSideBar}
          {...props}
        />
      </SidebarWrapper>
      <ContactSupportModal open={contactUsModalOpen} onClose={() => setContactUsModalOpen(false)} />
    </>
  );
}

export default Sidebar;
