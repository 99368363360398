import React, { memo, useState } from 'react';
import { faExternalLink as imageReportIcon, faFilePdf as pdfIcon } from '@fortawesome/pro-regular-svg-icons';

import {
  FaradayOrder,
  FaradayOrderExam,
  FaradayPatientProfile,
  OrderExamStatus,
} from '@/gql/generated/graphql';
import { CardOutlineBox } from '@/components/Box/CardOutlineBox';
import { Box, FAIcon, Flex, SecondaryOutlinedButton, Text, Tooltip } from '@fivehealth/botero';
import { BoxProps } from 'rebass';
import { OutlinedBox } from '@/components/Box/OutlinedBox';
import moment from 'moment/moment';
import { DATE_FORMAT_TXT } from '@/config/constants/misc.constants';
import { useTranslation } from 'react-i18next';
import {
  displayPhoneText,
  getCookieToken,
  getOrderExamStatusText,
  openExternalURL,
  textEllipsis,
  useIsView,
} from '@/helpers/utils';
import StatusBadge from '@/components/Badge/StatusBadge';
import { isEmpty, isEqual, startCase, trim } from 'lodash';
import OrdersFlyout from '@/pages/Orders/OrdersFlyout';
import ExamBadge from '@/components/Badge/ExamBadge';
import { OrderActionMenus, OrderExamActionMenus } from '@/pages/Orders/OrdersCardMenus';
import SendOrderFormModal from '@/components/Modals/SendOrderFormModal';
import OrderCancelModal from '@/pages/Orders/OrderCancelModal';
import OrderCancelExamModal from '@/pages/Orders/OrderCancelExamModal';
import { queryKeyConst } from '@/pages/Orders/Orders';
import { OrdersCardBaseProps } from '@/pages/Orders/OrdersCardList';
import { DialogError } from '@/pages/OrderCreate/helpers';
import { useNavigate } from 'react-router-dom';

export interface OrderModalProps {
  setCancelOrderModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setMessageModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setCancelExamModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setSendOrderModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  patient?: FaradayPatientProfile;
}

export function handleOpenURL(e: React.MouseEvent, url: string) {
  e.stopPropagation();
  openExternalURL(url ?? '', '_blank');
}

interface CardHeaderProps extends OrderModalProps {
  data: FaradayOrder;
  exam: FaradayOrderExam;
  setErrorMsg?: React.Dispatch<React.SetStateAction<React.ReactElement<any, any> | undefined>>;
}

const CardHeader: React.FC<CardHeaderProps> = ({
  data,
  exam,
  setMessageModalOpen,
  setCancelOrderModalOpen,
  setSendOrderModalOpen,
  patient,
  setErrorMsg,
}) => (
  <Flex justifyContent="space-between" height={20}>
    <Flex fontWeight={500}>
      <Text color="fullShade" fontWeight={600} width={210}>
        {moment(data.updatedOn).format(DATE_FORMAT_TXT.DD_MM_YYYY_SLASH_TIME_1)}
      </Text>
      <Text color="darkestShade" fontWeight={400}>
        Doctor:
        <Text as="span" color="fullShade" pl={1} fontWeight={500}>
          {data?.createdFor?.fullName}
        </Text>
      </Text>
      <Text color="darkestShade" pl={4} fontWeight={400}>
        Ordered by:
        <Text as="span" color="fullShade" pl={1} fontWeight={500}>
          {data?.createdBy?.fullName}
        </Text>
      </Text>
    </Flex>
    <Flex justifyContent="flex-end" mr="7px">
      {!isEmpty(data?.pdfForms) && (
        <Tooltip tooltip="View PDF Order Form">
          <SecondaryOutlinedButton
            onClick={(e: React.MouseEvent) =>
              handleOpenURL(e, `${data?.pdfForms[0]?.pdfForm}?session=${getCookieToken()}`)
            }
            mt="-7px"
            ml={1}
            color="fullShade"
            width={30}
            border={0}>
            <FAIcon color="darkestShade" icon={pdfIcon} fontSize={18} />
          </SecondaryOutlinedButton>
        </Tooltip>
      )}

      <OrderActionMenus
        order={data}
        exam={exam}
        setCancelOrderModalOpen={setCancelOrderModalOpen}
        setMessageModalOpen={setMessageModalOpen}
        setSendOrderModalOpen={setSendOrderModalOpen}
        patient={patient}
        setErrorMsg={setErrorMsg}
      />
    </Flex>
  </Flex>
);

export const OrderStatusBadger = ({
  orderExam,
  labelProps,
}: {
  orderExam: FaradayOrderExam;
  labelProps?: BoxProps;
}) => {
  const getStatusText = (status: string) => {
    let err;
    let statusTxt = status;
    if (status?.toLowerCase() === OrderExamStatus.Duplicate.toString().toLowerCase()) {
      statusTxt = 'Error - Duplicate';
    }
    if (status?.toUpperCase()?.includes('RIS') && status?.length > 0) {
      statusTxt = `RIS ${status.split(' ')[1]}`;
    }
    // Rescheduled by Parkway
    if (orderExam?.metadata?.error_code) {
      err = (
        <span style={{ fontWeight: 400, color: '#404040' }}>
          ({trim(startCase(orderExam?.metadata?.error_code?.toLowerCase()))})
        </span>
      );
    }
    return (
      <span>
        {statusTxt} {err}
      </span>
    );
  };

  return (
    <StatusBadge
      status={getOrderExamStatusText(OrderExamStatus, orderExam?.status)}
      variant="dot"
      renderLabel={(status) => getStatusText(status)}
      props={{ fontWeight: 600 }}
      labelProps={{ ...labelProps }}
    />
  );
};

export type OrdersCardProps = Omit<OrdersCardBaseProps, 'data'> & {
  data: FaradayOrder;
  cardProps?: BoxProps;
};

const OrdersCard: React.FunctionComponent<OrdersCardProps> = ({
  data,
  queryClient,
  refetch,
  cardProps,
  patient,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openFlyout, setOpenFlyout] = useState(false);
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [cancelOrderModalOpen, setCancelOrderModalOpen] = useState(false);
  const [cancelExamModalOpen, setCancelExamModalOpen] = useState(false);
  const [sendOrderModalOpen, setSendOrderModalOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState<React.ReactElement<any, any>>();
  const [selectedExam, setSelectedExam] = useState<FaradayOrderExam>({} as FaradayOrderExam);
  const { isTablet } = useIsView(['40em', '1400px']);

  function handleShowOrderDetails(e: React.MouseEvent) {
    e.stopPropagation();
    setOpenFlyout(true);
  }

  const getExamStatusTootip = (exam: FaradayOrderExam) => {
    let result;
    if (exam.status === OrderExamStatus.PendingSchedule) {
      result = 'Order received. Pending confirmation.';
    } else if (exam.status === OrderExamStatus.Cancelled) {
      result = 'Order is canceled successfully.';
    } else if (exam.status === OrderExamStatus.PendingCancel) {
      result = 'Cancelation is pending confirmation.';
    } else if (exam.status === OrderExamStatus.Scheduled) {
      result = 'Order received and confirmed.';
    } else if (exam.status === OrderExamStatus.Rescheduled) {
      result = 'Order successfully rescheduled.';
    } else if (exam.status === OrderExamStatus.ImageReady) {
      result = 'View PACS by clicking icon.';
    } else if (exam.status === OrderExamStatus.ReportReady) {
      result = 'Report is ready.';
    } else if (exam.status === OrderExamStatus.Duplicate) {
      result = 'Duplicate booking found. Need to schedule again.';
    } else if (
      isEqual(exam.status, OrderExamStatus.RisModified) ||
      isEqual(exam.status, OrderExamStatus.RisCancelled) ||
      isEqual(exam.status, OrderExamStatus.RisScheduled) ||
      isEqual(exam.status, OrderExamStatus.RisRescheduled)
    ) {
      result = 'Cannot be modified. Call Parkway to modify exam.';
    } else if (
      exam.status === OrderExamStatus.Error ||
      exam.status === OrderExamStatus.ErrorSchedule ||
      exam.status === OrderExamStatus.ErrorReschedule
    ) {
      if (exam.accessionNo) {
        result = 'Error in scheduling and original slot still valid. Try to reschedule again.';
      } else {
        result = 'Error in scheduling exam.';
      }
    } else {
      result = startCase(exam.status.toLowerCase());
    }

    return result;
  };

  const renderExamBadge = (orderExam: FaradayOrderExam) => (
    <ExamBadge rootProps={{ fontSize: 13 }} variant="badge" modality={orderExam.exam.modality.shortName}>{`${
      orderExam.exam.name?.length > 27 && isTablet
        ? textEllipsis(orderExam.exam.name, 27)
        : orderExam.exam.name
    }`}</ExamBadge>
  );

  return (
    <Box key={`order_card_box-${data.uid}`}>
      <CardOutlineBox
        onClick={(e: React.MouseEvent) => {
          handleShowOrderDetails(e);
        }}
        renderHeader={() => (
          <CardHeader
            data={data}
            setMessageModalOpen={setMessageModalOpen}
            setCancelOrderModalOpen={setCancelOrderModalOpen}
            setSendOrderModalOpen={setSendOrderModalOpen}
            exam={selectedExam}
            patient={patient}
            setErrorMsg={setErrorMsg}
          />
        )}
        isStatic={false}
        outlinedBoxProps={{ mt: 1, mb: 2, fontSize: 14, color: 'fullShade', ...cardProps }}
        headerBoxProps={{ pt: 1, pb: 1 }}>
        <Flex justifyContent="start" flexWrap={['wrap', 'inherit']} flexDirection="row">
          <Box mt="4px" p={2} width={250}>
            <Text fontWeight={600} fontSize={14} color="fullShade">
              {data?.patient?.fullName}
            </Text>
            <Text mt="6px" fontWeight={400} color="darkestShade">
              {displayPhoneText(data?.patient?.phone || '')}
            </Text>
          </Box>
          {/* NOTE: Body Exams list */}
          <Box px={2} pt={2} pb={1} width="100%">
            {data?.orderExams?.map((orderExam) => (
              <OutlinedBox
                boxProps={{ pl: 2, pr: '4px', pb: '10px', pt: '10px', mb: 1 }}
                key={`order_exam_outlined_box-${orderExam.uid}`}
                isStatic={false}
                staticProps={{ backgroundColorHover: '#f0f4fe' }}>
                <Flex
                  justifyContent="start"
                  flexWrap={['wrap', 'inherit']}
                  flexDirection="row"
                  style={{ gap: 8 }}>
                  <Box width="15%" mt="4px">
                    {orderExam.accessionNo ? `#${orderExam.accessionNo}` : '-'}
                  </Box>
                  <Box width="40%" pr={2} mt="2px">
                    {orderExam?.exam?.name?.length > 27 && isTablet ? (
                      <Tooltip tooltip={`${orderExam.exam.modality.shortName} - ${orderExam.exam.name}`}>
                        {renderExamBadge(orderExam)}
                      </Tooltip>
                    ) : (
                      renderExamBadge(orderExam)
                    )}
                  </Box>
                  <Box width="25%" pr={2} mt="2px">
                    {`${orderExam.room?.facility?.name} `}
                  </Box>
                  <Box width="25%" pr={2} mt="4px">
                    {moment(orderExam.scheduledFor).format(DATE_FORMAT_TXT.DD_MM_YYYY_SLASH_TIME_2)}
                  </Box>
                  <Box width="25%" mt="4px">
                    <Tooltip tooltip={getExamStatusTootip(orderExam)}>
                      <OrderStatusBadger orderExam={orderExam} />
                    </Tooltip>
                  </Box>
                  <Box width={80} mt="2px">
                    <Flex justifyContent="flex-end">
                      {orderExam?.vueMotionUrl && (
                        <Tooltip
                          tooltip={orderExam?.vueMotionUrl ? 'Open VueMotion' : 'No Available VueMotion'}>
                          <SecondaryOutlinedButton
                            disabled={!orderExam?.vueMotionUrl}
                            border={0}
                            onClick={(e: React.MouseEvent) =>
                              handleOpenURL(e, orderExam.vueMotionUrl as string)
                            }
                            width={15}
                            height={30}
                            style={{ marginRight: -4 }}>
                            <FAIcon color="darkestShade" icon={imageReportIcon} fontSize={14} />
                          </SecondaryOutlinedButton>
                        </Tooltip>
                      )}
                      <Box>
                        <OrderExamActionMenus
                          exam={orderExam}
                          order={data}
                          setCancelExamModalOpen={setCancelExamModalOpen}
                          setSelectedExam={setSelectedExam}
                          setErrorMsg={setErrorMsg}
                        />
                      </Box>
                    </Flex>
                  </Box>
                </Flex>
              </OutlinedBox>
            ))}
          </Box>
        </Flex>
      </CardOutlineBox>

      <OrdersFlyout
        order={data}
        open={openFlyout}
        onClose={() => {
          setOpenFlyout(false);
        }}
        setCancelOrderModalOpen={setCancelOrderModalOpen}
        setMessageModalOpen={setSendOrderModalOpen}
        setCancelExamModalOpen={setCancelExamModalOpen}
        selectedExam={selectedExam}
        setSelectedExam={setSelectedExam}
        setErrorMsg={setErrorMsg}
        patient={patient}
      />

      <SendOrderFormModal
        open={messageModalOpen}
        onClose={() => {
          setMessageModalOpen(false);
        }}
        patientProfileData={data?.patient}
        order={data}
      />

      <OrderCancelModal
        open={cancelOrderModalOpen}
        onClose={() => {
          setCancelOrderModalOpen(false);
          queryClient?.invalidateQueries(queryKeyConst.ordersList);
        }}
        data={data}
        queryClient={queryClient}
        refetch={refetch}
      />

      <OrderCancelExamModal
        open={cancelExamModalOpen}
        onClose={() => {
          setCancelExamModalOpen(false);
          setSelectedExam({} as FaradayOrderExam);
          queryClient?.invalidateQueries(queryKeyConst.ordersList);
        }}
        data={selectedExam}
        refetch={refetch}
      />

      <DialogError
        t={t}
        navigate={navigate}
        open={Boolean(errorMsg)}
        onClose={setErrorMsg as any}
        message={errorMsg}
        title="Edit Order Not Allowed"
        goBackOpt={false}
      />

      <SendOrderFormModal
        open={sendOrderModalOpen}
        onClose={() => {
          setSendOrderModalOpen(false);
        }}
        patientProfileData={data.patient}
        order={data}
      />
    </Box>
  );
};

export default memo(OrdersCard);
