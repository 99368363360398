import { get, startCase } from 'lodash';
import { NonEmptyArray, ValueOf } from '@/helpers/types/object.types';
import { FilterObjectType } from '@/components/Filter/Filter';
import { GraphQLClient, RequestDocument } from 'graphql-request';
import { LabelValueOptionsType } from '@/helpers/types/ui.types';

/**
 * Queries array of objects by key:value pair in ___idObj___ and return the object with the updated values inside ___obj___
 * @param arr
 * @param idObj
 * @param obj
 * @param preventMutation
 */
export const deepEditObjInArr = (
  arr: NonEmptyArray<FilterObjectType>,
  idObj: Record<string, any>,
  obj: Record<string, any>,
  preventMutation?: boolean // TODO: Whats this arg for?
): NonEmptyArray<FilterObjectType> => {
  // TODO: Fix this, you should pass value directly to an argument
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,no-param-reassign
  if (preventMutation) arr = JSON.parse(JSON.stringify(arr));
  return arr as unknown as NonEmptyArray<FilterObjectType>;
};

/**
 * Use in traversing Object.keys(...) with a specific type
 * Ex: objectKeys(myObjectList).forEach((key)=> ....)
 * @param obj
 */
export const objectKeys = <TObj>(obj: TObj): (keyof TObj)[] =>
  Object.keys(obj as unknown as {}) as (keyof TObj)[];

/**
 * This will map and paginate the graphql response payload.
 * @param data
 * @param documentName
 * @param mapNode
 * @param params
 */
const paginatedDataMapper = <TData>(
  data: TData,
  documentName: string,
  mapNode?: (data: TData, param?: any) => void,
  params?: Record<string, any>
) => {
  const pages = get(data, 'pages', []) as any[];
  return {
    ...data,
    totalCount: get(data, `pages[0].${documentName}.totalCount`),
    pageInfo: get(data, `pages[0].${documentName}.pageInfo`, {}),
    pages: pages?.map(({ [documentName]: graphResponse }) => {
      const { edges } = graphResponse || {};
      return edges?.map(({ node }: any) => (mapNode ? mapNode(node, params) : node));
    }),
  };
};

/**
 * Omit edge node and map query payload.
 * @param payload
 */
const edgeNodeOmitter = <TPayload extends { edges: any } | null | undefined, TResult>(
  payload: TPayload
): TResult => payload?.edges?.map((o: any) => o?.node);

/**
 * Query function async callback using updated query variables with the updated page param offset.
 * @param client
 * @param document
 * @param documentName
 * @param queryVariables
 */
const asyncQueryFnResponse = async <TQueryVariables, TResponse>(
  client: GraphQLClient,
  document: RequestDocument,
  documentName: string,
  queryVariables: TQueryVariables
): Promise<Record<string, { edges: any; pageInfo: any; totalCount: any }> | TResponse> => {
  const updatedQueryVars = { ...queryVariables } as any;
  const res = await client.request(document, {
    ...updatedQueryVars,
  });
  return {
    [documentName]: {
      edges: res[documentName]?.edges,
      pageInfo: res[documentName]?.pageInfo,
      totalCount: res[documentName]?.totalCount,
    },
  };
};

/**
 * Get the next page param length for query pagination.
 * @param data
 * @param allPages
 * @param documentName
 */
const pageParamLength = (data: unknown, allPages: unknown[], documentName: string) => {
  const hasNextPagex = get(data, 'pageInfo.hasNextPage', false);
  if (!hasNextPagex) {
    return undefined;
  }
  const res = allPages?.flatMap((o: any) => get(o[documentName], 'edges', []));
  return res.length;
};

/**
 * Flattens the data query response for pagination.
 * @param data
 */
const flattenPages = <TObj, TData extends { pages: any } | null | undefined>(data: TData) =>
  data?.pages?.flatMap((o: any) => o as TObj) as TObj[];

export const queryUtil = {
  paginatedDataMapper,
  edgeNodeOmitter,
  asyncQueryFnResponse,
  pageParamLength,
  flattenPages,
};

/**
 * Get and maps list to a label and value mapping.
 * @param list
 * @param getLabelFn
 * @param getValueFn
 */
export const getLabelValuesMapper = <T>(
  list: T[],
  getLabelFn: (param: T) => string,
  getValueFn: (param: T) => Partial<T>
): LabelValueOptionsType<Partial<T>>[] =>
  list?.map((o) => ({
    label: getLabelFn(o),
    value: getValueFn(o),
  }));

/**
 * Converts enum type to an array map object with specific key value pair prefix.
 * @param enumType
 * @param keyPrefix
 * @param valuePrefix
 */
export const enumToArrayMap = <T extends {}, R extends {}>(
  enumType: T,
  keyPrefix: string,
  valuePrefix: any
): R[] =>
  Object.entries(enumType).map(([key, value]) => ({
    [keyPrefix]: startCase(key),
    [valuePrefix]: value,
  })) as R[];

/**
 * Converts enum type to an arbitrary object.
 * @param enumType
 */
export const enumToObject = <T extends {}, R extends {}>(enumType: T) => {
  const result: {} = {};
  for (const [key, value] of Object.entries(enumType)) {
    result[value as string] = startCase(key);
  }
  return result as R;
};

/**
 * Get the value of an enum type by enum key.
 * @param myEnum
 * @param enumValue
 */
export function getEnumKeyByEnumVal<R extends number | string, T extends Record<string, R>>(
  myEnum: T,
  enumValue: ValueOf<T>
): string {
  const keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);
  return keys && keys?.length > 0 ? (keys[0] as string) : '';
}
