import React, { useEffect, useMemo, useState } from 'react';
import {
  ActionMenu,
  Flex,
  H4,
  Button,
  Box,
  H5,
  Checkbox,
  PrimaryButton,
  SecondaryOutlinedButton,
} from '@fivehealth/botero';
import { useTranslation } from 'react-i18next';
import { merge, map, includes, split, filter as _filter } from 'lodash';
import { useRenderForView } from '@/helpers/utils';
import { NonEmptyArray } from '@/helpers/types/object.types';

/**
 * @description
 * This function returns null filters object for the given active _(which are not disabled)_ filters.
 * @param filters
 * @returns
 * @example
 * const filters = [
 * { label: 'Filter 1', value: 'filter1', disabled: false },
 * ];
 * const nullFilters = getNullFilters(filters);
 * // nullFilters = { filter1: [], filter2: [], filter3: [] }
 * @example
 * const filters = [
 * { label: 'Filter 1', value: 'filter1', disabled: false },
 * ];
 * const nullFilters = getNullFilters(filters);
 * // nullFilters = { filter1: [], filter2: [], filter3: [] }
 */
export const getFilterReducer = (filters: FilterObjectType[]) =>
  filters
    .map((f) => !f.disabled && f.value)
    .filter(Boolean)
    .reduce((acc, curr) => ({ ...acc, [curr as string]: [] }), {});

export type FilterOption = {
  label: string;
  value: string;
  radio?: boolean;
  disabled?: boolean;
};

export type FilterObjectType = {
  title: string;
  value: string;
  disabled?: boolean;
  radio?: boolean;
  options: FilterOption[];
};

export type FilterProps = {
  label: (toggleFilter: () => void) => React.ReactNode;
  filters: NonEmptyArray<FilterObjectType>;
  filter: FilterStateType;
  setFilter: React.Dispatch<FilterStateType>;
  width?: number | string;
  minWidth?: number | string;
  maxWidth?: number | string;
  maxRows?: number;
  onSubmit?: (filter: FilterStateType) => void;
};

export type FilterStateType = Readonly<Record<string, string[]>>;

const Filter: React.FC<FilterProps> = ({
  filters,
  label,
  filter,
  setFilter,
  maxRows = 12,
  width = '100%',
  maxWidth = 1252,
  onSubmit,
  ...props
}) => {
  const filterDefault: FilterStateType = useMemo(() => getFilterReducer(filters), [filters]);
  const initialFilter: FilterStateType = useMemo(() => merge(filter, filterDefault), [filterDefault, filter]);

  const [filterOpen, setFilterOpen] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [localFilter, setLocalFilter] = useState<FilterStateType>(initialFilter);

  const { t } = useTranslation();
  const { renderForView } = useRenderForView();

  const toggleArrayValue = (arr: string[], val: string) => {
    if (includes(arr, val)) {
      return _filter(arr, (v) => v !== val);
    }
    return [...arr, val];
  };

  function handleFilterChange(e: React.ChangeEvent<HTMLInputElement>) {
    setIsReset(false);
    const [filterKey, filterValue] = split(e.target.value, ':') as [string, string];
    setLocalFilter((prev) => ({
      ...prev,
      [filterKey]: toggleArrayValue(prev[filterKey] as string[], filterValue),
    }));
  }

  function onResetFilters() {
    setIsReset(true);
    setLocalFilter(filterDefault);
    setFilter(filterDefault);
  }

  function onApplyFilters() {
    setFilter(localFilter);
    if (onSubmit) {
      onSubmit(isReset ? {} : localFilter);
    }
    setFilterOpen(false);
  }
  const toggleFilter = () => setFilterOpen(!filterOpen);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setFilterOpen(false);
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []);

  return (
    <ActionMenu
      mx={0}
      p={0}
      open={filterOpen}
      label={label(toggleFilter)}
      width={width}
      maxWidth={maxWidth}
      {...props}>
      <Flex py={1} px={2} alignItems="center" justifyContent="space-between">
        <H4 color="fullShade">{t('Filter by')}</H4>
        <Button bg="transparent" color="darkestShade" onClick={onResetFilters}>
          {t('Reset filters')}
        </Button>
      </Flex>
      <Box bg="lightestShade" borderTop="1px solid" borderBottom="1px solid" borderColor="mediumShade" py={3}>
        <Flex>
          {map(
            filters,
            (f, i) =>
              !f.disabled && (
                <Box
                  borderLeft={i !== 0 ? '1px solid' : 'none'}
                  borderColor="mediumShade"
                  key={i}
                  pl={3}
                  justifyContent="space-between"
                  // minWidth={f.options.length > maxRows ? 450 : 'initial'}
                  flex={1}>
                  <Box>
                    <H5 mb={2} color="darkestShade">
                      {t(f.title)}
                    </H5>
                    <Flex
                      style={{ overflowY: 'auto', userSelect: 'none', gap: 8 }}
                      // flexWrap="wrap"
                      flexDirection="column"
                      // INFO: 40 is the height of the checkbox component + the gap
                      maxHeight={maxRows * 40}>
                      {map(f.options, (o, idx) => (
                        <Checkbox
                          mt={1}
                          mb={1}
                          key={idx}
                          color="primary"
                          labelProps={{
                            fontSize: 14,
                          }}
                          label={o.label}
                          value={t(`${f.value}:${o.value}`) || ''}
                          onChange={handleFilterChange}
                          checked={includes(localFilter[f.value], o.value)}
                        />
                      ))}
                    </Flex>
                  </Box>
                </Box>
              )
          )}
        </Flex>
      </Box>

      <Flex
        p={3}
        alignItems="center"
        justifyContent={renderForView(['space-between', 'end'])}
        style={{ gap: 24 }}>
        <SecondaryOutlinedButton data-testid="cancel-filters" borderRadius={8} onClick={toggleFilter}>
          {t('Cancel')}
        </SecondaryOutlinedButton>
        <PrimaryButton data-testid="apply-filters" borderRadius={8} onClick={onApplyFilters}>
          {t('Apply filters')}
        </PrimaryButton>
      </Flex>
    </ActionMenu>
  );
};

export default Filter;
