import React from 'react';
import styled from 'styled-components';
import { Box, theme } from '@fivehealth/botero';
import { BoxProps } from 'rebass';

export const StyledOutlinedBox = styled(Box)`
  border: ${(props) =>
    props.isSelected
      ? `${props.border || '1px'} solid ${theme.colors.primary}`
      : `${props.border || '1px'} solid ${theme.colors.mediumShade}`};
  background: ${(props) => (props.isSelected ? `#F4F6F8` : 'white')};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '8px')};
  cursor: ${(props) => (props.isStatic ? null : 'pointer')};
  &:hover {
    opacity: 1;
    border-color: ${(props) => (props.isStatic ? null : '#bfcdf7')};
    background: ${(props) => {
      let result: string | null = '#fafdff';
      if (props?.isStatic) {
        result = null;
      }
      if (!props?.isStatic && props?.backgroundColorHover) {
        result = props?.backgroundColorHover;
      }
      return result;
    }};
    transition: border-color 0.5s ease-in-out;
  }

  &:active {
    border-color: ${(props) => (props.isStatic ? null : theme.colors.primary)};
    background: ${(props) => (props.isStatic ? null : '#F4F6F8')};
    transition: border-color 0.5s ease-in-out;
  }
`;

type OutlinedBoxProps = {
  boxProps?: BoxProps;
  children: React.ReactNode;
  onClick?: (param?: any) => void;
  isSelected?: boolean;
  isStatic?: boolean;
  staticProps?: {
    color?: string;
    backgroundColor?: string;
    backgroundColorHover?: string;
    borderColor?: string;
  };
};

export const OutlinedBox: React.FunctionComponent<OutlinedBoxProps> = ({
  isStatic = true,
  children,
  boxProps,
  onClick,
  isSelected = false,
  staticProps,
}) => (
  <StyledOutlinedBox
    p={2}
    isStatic={isStatic}
    isSelected={isSelected}
    onClick={onClick}
    {...boxProps}
    {...staticProps}>
    {children}
  </StyledOutlinedBox>
);
