import React, { createContext, useContext, useState, useCallback } from 'react';
import { isEmpty, isString } from 'lodash';
import { AppConfig } from '@/config/app.config';
import { ROUTES } from '@/routes/Router';
import { useCookies } from 'react-cookie';
import { CookieDataTypes } from '@/helpers/types/system.types';
import { getCookieData } from '@/helpers/utils';

export type AuthCtxProps = {
  children: React.ReactNode;
  props?: any;
  token: string;
  data: CookieDataTypes;
};

export type AuthCtxStateTypes = {
  isAuthenticated?: boolean;
  token?: string | null | undefined;
  isLoading?: boolean; // NOTE: This is used to check if the auth sync call loading status
  data?: AuthCtxProps['data'];
};

export type AuthCtxValueTypes = {
  authState: AuthCtxStateTypes;
  setAuthState?: (param: AuthCtxStateTypes) => void;
  setIsLoading?: (val: boolean) => void;
  setData?: (data: CookieDataTypes) => void;
  setToken?: (val: string) => void;
};

const AuthContext = createContext<AuthCtxValueTypes>({} as AuthCtxValueTypes);
const cookieTokenName = AppConfig.STORAGE.COOKIE_TOKEN_KEY;
const cookieDataName = AppConfig.STORAGE.COOKIE_DATA_KEY;

const AuthProvider: React.FC<AuthCtxProps> = ({ children, token, data = {} as CookieDataTypes, ...props }) => {
  const [, setCookie] = useCookies([cookieTokenName, cookieDataName]);
  // const cookieClientData = ;
  const [authState, setAuthState] = useState<AuthCtxStateTypes>({
    data: isEmpty(data) ? (getCookieData() as CookieDataTypes) : data,
    token: isString(token) ? token : '',
    isAuthenticated: Boolean(isString(token) && token),
    isLoading: !isString(token),
  });

  const setData = useCallback(
    (cookieData: CookieDataTypes) => {
      setCookie(cookieDataName, cookieData, { path: ROUTES.ROOT });
      setAuthState({ ...authState, isAuthenticated: true, isLoading: false, data: cookieData });
    },
    [authState, setCookie]
  );

  const setIsAuthenticated = useCallback(
    (value: boolean) => {
      setAuthState({ ...authState, isAuthenticated: value });
    },
    [authState]
  );

  const setToken = useCallback(
    (value: string) => {
      setCookie(cookieTokenName, value, { path: ROUTES.ROOT });
      setAuthState({ ...authState, isAuthenticated: true, isLoading: false, token: value });
    },
    [authState, setCookie]
  );

  const setIsLoading = useCallback(
    (value: boolean) => {
      setAuthState({ ...authState, isLoading: value });
    },
    [authState]
  );
  const handleAuthState = useCallback(
    (state: AuthCtxStateTypes) => {
      setCookie(cookieTokenName, state?.token, { path: ROUTES.ROOT });
      setCookie(cookieDataName, state.data, { path: ROUTES.ROOT });
      setAuthState({ ...state, isAuthenticated: true, isLoading: false });
    },
    [setCookie]
  );

  const getCtxValues = useCallback(
    (): AuthCtxValueTypes =>
      ({
        authState,
        setData,
        setIsAuthenticated,
        setToken,
        setIsLoading,
        setAuthState: handleAuthState,
      } as AuthCtxValueTypes),
    [authState, setData, setIsAuthenticated, setToken, setIsLoading, handleAuthState]
  );

  return (
    <AuthContext.Provider value={getCtxValues()} {...props}>
      {children}
    </AuthContext.Provider>
  );
};

/**
 * User authentication context.
 */
const useAuthContext = (): AuthCtxValueTypes => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('The useAuthContext hook must be used within a AuthProvider');
  }
  return context;
};

export { AuthProvider, useAuthContext };
