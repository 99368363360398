import { GraphQLClient } from 'graphql-request';
import { AppConfig, AppConfigValType } from '@/config/app.config';
import { getCookieToken } from '@/helpers/utils/storage.utils';
import { PatchedRequestInit } from 'graphql-request/dist/types';

type GQLRequestAdapterType = {
  endpoint?: string;
  options?: PatchedRequestInit;
  token?: string;
};

/**
 * Get instance of graphql client using graphql-request client library.
 * @param param - GQLRequestAdapterType - this is optional
 */
function graphqlRequestClient(param: GQLRequestAdapterType = {}): GraphQLClient {
  const cookieToken = getCookieToken();
  const token = cookieToken || param?.token;
  const endpoint: AppConfigValType = param.endpoint ?? (AppConfig.GQL_ENDPOINT as string);
  const gqlClient = new GraphQLClient(endpoint, param.options);
  gqlClient.setHeader('X-SESSION', token as string);

  return gqlClient;
}

export default graphqlRequestClient;
