// FIXME
export const EventsConstants = {
  CREATE_NEW_ORDER: 'Create New Order',
  LIST_ALL_ORDERS: 'List All Orders',
  LIST_ALL_ORDERS_NON_BOTMD: 'List All Orders NonBotmd',
  LIST_TO_BE_SCHEDULED_ORDERS: 'List To Be Scheduled Orders',
  LIST_REPORT_READY_ORDERS: 'List Report Ready Orders',
  LIST_PATIENT_BROCHURES: 'List Patient Brochures',
  LIST_EXAMS: 'List Exams',
  CANCEL_EXAM: 'Cancel Exam',
  RESCHEDULE_EXAM: 'Reschedule Exam',
  SEARCH_ORDERS: 'Search Orders',
  SORT_ORDERS: 'Sort Orders',
  FILTER_ORDERS: 'Filter Orders',
  SEARCH_PATIENTS: 'Search Patients',
  ADD_NEW_PATIENT: 'Add New Patient',
  CHANGE_PROFILE_PICTURE: 'Change Profile Picture',
  LOGOUT: 'Log Out',
  SHOW_PATIENT_PROFILE: 'Show Patient Profile',
  CLICK_SEND_MESSAGE: 'Click Send Message',
  LIST_USERS: 'List Users',
  LIST_PENDING_REQUESTS_FOR_USERS: 'List Pending Requests for Users',

  DOWNLOAD_PATIENT_SUBMISSIONS: 'Download Patient Submissions',
  DISCHARGE_PATIENT: 'Discharge Patient',
  ENROLL_PATIENT: 'Enroll Patient',
  VIEW_GRAPHS: 'View Graphs',
  VIEW_PATIENT_FORM: 'View Patient Form',
  EDIT_PATIENT_PROFILE: 'Edit Patient Profile',
  LIST_OVERVIEW: 'List Overview',
  LIST_PATIENT_MONITORING: 'List Patient Monitoring',
  LIST_PATIENT_LIST: 'List Patient List',
  LIST_CLINICAL_ADMIN: 'List Clinical Admin',
  LIST_PRICE_LIST: 'List Price List',
  READ_HELP_GUIDE: 'Read Help Guide',
  OPEN_CONTACT_US: 'Open Contact Us',
  LIST_CLINICAL_ALERTS: 'List Clinical Alerts',
  ADD_CLINICAL_ADMIN: 'Add Clinical Admin',
  EXPORT_USER_LIST: 'Export User List',
  REFRESH_RESULTS: 'Refresh Results',
  APPLY_FILTERS: 'Apply Filters',
  RESET_FILTERS: 'Reset Filters',
  LIST_PATIENT_SUBMISSIONS: 'List Patient Submissions',
  VIEW_CLINICAL_SETTINGS: 'View Clinical Settings',
  VIEW_CLINICAL_SETTINGS_EDIT_CLINICAL_USER: 'View Clinical Settings - Edit Clinical User',
  VIEW_CLINICAL_SETTINGS_EDIT_ACTIVATE_PAUSE_REMINDER: 'View Clinical Settings - Activate/Pause Reminder',
  VIEW_CLINICAL_SETTINGS_EDIT_SEND_TPL_MSG: 'View Clinical Settings - Send template message',
  VIEW_CLINICAL_SETTINGS_DISCHARGE_PATIENT: 'View Clinical Settings - Discharge patient',
  VIEW_CLINICAL_SETTINGS_EDIT_REMINDER: 'View Clinical Settings - Edit reminder',
  VIEW_CLINICAL_SETTINGS_EDIT_FREQ_REMINDER: 'View Clinical Settings - Edit reminder frequency',
  VIEW_CLINICAL_SETTINGS_EDIT_DURATION_REMINDER: 'View Clinical Settings - Edit reminder duration',
  VIEW_CLINICAL_SETTINGS_EDIT_DELETE_REMINDER: 'View Clinical Settings - Delete reminder',
  VIEW_CLINICAL_SETTINGS_EDIT_ALERT_THRESHOLD: 'View Clinical Settings - Edit alert threshold',
};
export default EventsConstants;
