import { NonEmptyArray } from '@/helpers/types/object.types';
import { useEffect, useMemo, useState } from 'react';
import { merge, omitBy } from 'lodash';
import { appendObjToQueryParams, queryParamsToObj } from '@/helpers/utils';
import includes from 'lodash/includes';
import { FilterObjectType, FilterStateType, getFilterReducer } from '@/components/Filter/Filter';

/**
 * @description
 * This hook is used to manage the state of the filters and also to update the query params.
 * This is optional and you can handle the filters state yourself and just use the Filter component
 * if you need more control over the filters state
 * @param initialFiltersArray
 * @returns  object with filter states which can be set using the set function and value can be accessed using the value property
 */
export const useFilterQuery = (initialFiltersArray: NonEmptyArray<FilterObjectType>) => {
  // const filterDefault = useMemo(() => getFilterReducer(initialFiltersArray), [initialFiltersArray]);
  const filterDefault = getFilterReducer(initialFiltersArray);
  const filtersFromParams = omitBy(queryParamsToObj(), (_, key) => !includes(Object.keys(filterDefault), key));

  const [filter, setFilter] = useState<FilterStateType>(
    merge(getFilterReducer(initialFiltersArray), filtersFromParams)
  );
  const [filtersArray, setFiltersArray] = useState(initialFiltersArray);

  const disabledKeys = useMemo(
    () => filtersArray.map((f) => f.disabled && f.value).filter(Boolean),
    [filtersArray]
  );

  useEffect(() => {
    appendObjToQueryParams(omitBy(filter, (_, key) => includes(disabledKeys, key)));
  }, [disabledKeys, filter]);

  return {
    filter: {
      get() {
        return filter;
      },
      set: setFilter,
      clearAll: () => {
        setFilter(getFilterReducer(initialFiltersArray));
      },
    },
    filtersArray: {
      get() {
        return filtersArray;
      },
      set: setFiltersArray,
    },
    filterDefault,
  };
};

export default useFilterQuery;
