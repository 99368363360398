import { isNull, omit, omitBy } from 'lodash';
import { HELP_SUPPORT } from '@/config/constants/misc.constants';

/**
 * URLSearchParams object to an arbitrary query param object.
 */
export const queryParamsToObj = (): Record<string, string[]> => {
  const urlParams = new URLSearchParams(window.location.search);
  const obj: Record<string, string[]> = {};
  urlParams.forEach((value, key) => {
    obj[key] = value.split(',');
  });
  return obj;
};

/**
 * Convert the array of query param arbitrary object to interpolated url query param string.
 * @param objParam
 */
const objToQueryParams = <T extends Record<string, string[]>>(objParam: T): string => {
  const obj = omitBy(objParam, (value) => isNull(value) || value.length === 0);
  const result = Object.keys(obj)
    .map((key) => `${key}=${encodeURIComponent(`${obj[key]}`)}`)
    .join('&');
  window.history.pushState({}, '', `?${result}`);
  return result;
};

/**
 * Appending arbitrary object to the string url query parameters.
 * @param obj
 */
export const appendObjToQueryParams = (obj: Record<string, any>) => {
  const existingQueries = queryParamsToObj();
  return objToQueryParams({ ...existingQueries, ...obj });
};

/**
 * Rmoved arbitrary object to the string url query parameters.
 * @param keyName
 */
export const removeObjFromQueryParams = (keyName: string) => {
  const existingQueries = omit(queryParamsToObj(), [keyName]);
  return objToQueryParams({ ...existingQueries });
};
/**
 * Redirect to path that are not secure or not part of the route validator.
 * @param pathname
 * @param delay
 */
export const unsecureRedirectPath = (pathname = '', delay = 500): void => {
  setTimeout(() => {
    window.location.replace(`${window.location.origin}${pathname}`);
  }, delay);
};

/**
 * Goto a specific url.
 * @param url
 * @param delay
 */
export const gotoReplaceUrlLink = (url = '', delay = 0): void => {
  setTimeout(() => {
    window.location.replace(url);
  }, delay);
};

/**
 * Deprecated
 * Text and action for parkway email customer support.
 * @param subject
 */
export const emailCustomerSupport = (subject = 'Botmd Support') => {
  const url = new URL(`mailto:${HELP_SUPPORT.BOTMD_CUSTOMER_SUPPORT_EMAIL}`);
  url.searchParams.set('subject', encodeURIComponent(subject));
  window.open(decodeURI(url.toString()));
};

/**
 * Text and action for parkway email
 * @param subject
 */
export const emailParkway = (subject = 'Parkway - Support') => {
  const url = new URL(`mailto:${HELP_SUPPORT.PARKWAY_RADIOLOGY_EMAIL}`);
  url.searchParams.set('subject', encodeURIComponent(subject));
  window.open(decodeURI(url.toString()));
};

/**
 * Text and action for parkway email
 * @param url
 * @param target
 * @param opts
 */
export const openExternalURL = (url: string, target: '_blank' | '_self' = '_blank', opts?: any) => {
  window.open(url, target, opts);
};

/**
 * Open typeform link for customer support
 */
export const openTypeformCustomerSupport = () => {
  openExternalURL(HELP_SUPPORT.BOTMD_CUSTOMER_SUPPORT_TYPEFORM_LINK);
};
