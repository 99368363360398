import React, { useState } from 'react';
import moment from 'moment';
import { Body, Box, Button, DataTableColumnsConfigTypes, FAIcon, Text, Tooltip } from '@fivehealth/botero';
import Skeleton from 'react-loading-skeleton';
import DataTable from '@/components/DataTable/DataTable';
import {
  FaradayParkwayOrder,
  FaradayPatientProfile,
  OrderExamStatus,
  OrderOrderBy,
  ParkwayOrdersQueryVariables,
} from '@/gql/generated/graphql';
import { getOrderExamStatusText, openExternalURL } from '@/helpers/utils';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT_TXT } from '@/config/constants/misc.constants';
import StatusBadge from '@/components/Badge/StatusBadge';
import { startCase } from 'lodash';
import { faExternalLink as externalUrlIcon } from '@fortawesome/pro-regular-svg-icons';
import ExamBadge from '@/components/Badge/ExamBadge';

export const getColumnsSettings = (
  t: any,
  isLoading = false
): DataTableColumnsConfigTypes<Partial<FaradayParkwayOrder>>[] => [
  {
    id: 'patientFullName',
    accessor: 'patientFullName',
    Header: 'Patient',
    width: '13%',
    Cell: isLoading
      ? () => <Skeleton style={{ maxWidth: '80px' }} height={15} count={2} />
      : ({ row }) => (
          <Box>
            <Text fontWeight={500}>{row?.original?.patientFullName || ''}</Text>
            <Body small color="darkestShade">
              {row?.original?.patientMrnNo || '-'}
            </Body>
          </Box>
        ),
  },
  {
    id: 'exam',
    accessor: 'exam',
    Header: 'Examinations',
    width: '19%',
    Cell: isLoading
      ? () => <Skeleton style={{ maxWidth: '50px' }} height={15} count={1} />
      : (data) => (
          <ExamBadge
            rootProps={{ fontSize: 13 }}
            variant="badge"
            modality={data?.row?.original?.modality?.shortName as string}>
            {t(data?.value) || '-'}
          </ExamBadge>
        ),
  },
  {
    id: 'accessionNo',
    accessor: 'accessionNo',
    Header: 'Accession No',
    width: '10%',
    disableSortBy: true,
    Cell: isLoading
      ? () => <Skeleton style={{ maxWidth: '50px' }} height={15} count={1} />
      : ({ value }) => (
          <Body small color="fullShade">
            #{value}
          </Body>
        ),
  },
  {
    id: 'scheduledFor',
    accessor: 'scheduledFor',
    Header: 'Booking Details',
    width: '15%',
    Cell: isLoading
      ? () => <Skeleton style={{ maxWidth: '40px' }} height={15} count={1} />
      : ({ row }) => (
          <Body small color="fullShade">
            {`${row.original?.facility?.shortCode || ''} ${moment(row.original?.scheduledFor || '-').format(
              DATE_FORMAT_TXT.DD_MM_YYYY_SLASH_TIME_2
            )}`}
          </Body>
        ),
  },
  {
    id: 'referringDoctor',
    accessor: 'referringDoctor',
    Header: 'Referring Doctor',
    disableSortBy: true,
    width: '30%',
    Cell: isLoading
      ? () => <Skeleton style={{ maxWidth: '40px' }} height={15} count={1} />
      : ({ row }) => (
          <Body small color="fullShade">
            {startCase(row.original?.referringDoctor?.fullName?.toLowerCase())}
          </Body>
        ),
  },
  {
    id: 'status',
    accessor: 'status',
    Header: 'Status',
    width: '15%',
    Cell: isLoading
      ? () => <Skeleton style={{ maxWidth: '80px' }} height={15} count={1} />
      : ({ value }: any) => (
          <StatusBadge status={getOrderExamStatusText(OrderExamStatus, value)} variant="dot" />
        ),
  },
  {
    id: 'formUrl',
    width: '7%',
    Cell: isLoading
      ? () => <Skeleton style={{ maxWidth: '40px' }} height={15} count={1} />
      : ({ row }) => (
          <Tooltip tooltip="Open VueMotion">
            <Button
              px={1}
              bg="transparent"
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                openExternalURL(row.original?.vueMotionUrl as string);
                e.stopPropagation();
              }}>
              <FAIcon icon={externalUrlIcon} color="darkestShade" fontSize={16} />
            </Button>
          </Tooltip>
        ),
  },
];

type OrdersParkwayTableSkeletonProps = {
  count?: number;
};

const OrdersParkwayTableSkeleton: React.FC<OrdersParkwayTableSkeletonProps> = ({ count = 12 }) => (
  <Box>
    <DataTable
      rootProps={{
        tableStyle: {
          minWidth: 500,
        },
      }}
      columns={getColumnsSettings(null, true)}
      data={Array.from(Array(count), (_, index) => ({
        uid: index,
      }))}
      enableRowEdit="true"
      initialSortBy={[{ id: 'last order date', desc: false }]}
      renderRowEdit={() => <Skeleton circle width={20} height={20} />}
    />
  </Box>
);

type OrdersParkwayTableProps = {
  onClickPatient?: (patient: FaradayPatientProfile) => void;
  ordersData: FaradayPatientProfile[];
  hasNextPage?: boolean;
  isLoading?: boolean;
  count?: number;
  queryVariables?: ParkwayOrdersQueryVariables;
  setQueryVariables?: (value: ParkwayOrdersQueryVariables) => void;
  refetch?: () => void;
  isFetchingNextPage?: boolean;
  fetchNextPage?: () => unknown;
};

const OrdersParkwayTable: React.FC<OrdersParkwayTableProps> = ({
  ordersData,
  isLoading,
  queryVariables,
  setQueryVariables,
  refetch,
  isFetchingNextPage,
  hasNextPage,
  fetchNextPage,
}) => {
  const { t } = useTranslation();

  const getColumnSortId = (val: any) => {
    if (val?.sortBy === OrderOrderBy.Patient) return 'patientFullName';
    if (val?.sortBy === OrderOrderBy.Exams) return 'exam';
    if (val?.sortBy === OrderOrderBy.Status) return 'status';
    if (val?.sortBy === OrderOrderBy.ScheduledFor) return 'scheduledFor';
    return 'patientFullName';
  };

  // NOTE: Default sort config
  const [sortBy, setSortBy] = useState({
    id: getColumnSortId(queryVariables),
    desc: queryVariables?.sortDesc,
  });

  return (
    <Box>
      <DataTable
        key="parkway_orders_list_table"
        rootProps={{
          tableStyle: {
            minWidth: 500,
          },
        }}
        // enableRowEdit="true"
        columns={getColumnsSettings(t, isLoading)}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        onFetchNextPage={fetchNextPage}
        data={ordersData || []}
        initialSortBy={sortBy}
        onFetchData={(data) => {
          const colId = data?.sortBy[0]?.id;
          const sortByOrder = data?.sortBy[0]?.desc;
          const qVarSortByOrder = queryVariables?.sortDesc;

          if (setQueryVariables && sortByOrder !== qVarSortByOrder) {
            if (colId === 'patientFullName') {
              setQueryVariables({
                ...queryVariables,
                sortBy: OrderOrderBy.Patient,
                sortDesc: sortByOrder,
              });
            } else if (colId === 'exam') {
              setQueryVariables({
                ...queryVariables,
                sortBy: OrderOrderBy.Exams,
                sortDesc: sortByOrder,
              });
            } else if (colId === 'status') {
              setQueryVariables({
                ...queryVariables,
                sortBy: OrderOrderBy.Status,
                sortDesc: sortByOrder,
              });
            } else if (colId === 'scheduledFor') {
              setQueryVariables({
                ...queryVariables,
                sortBy: OrderOrderBy.ScheduledFor,
                sortDesc: sortByOrder,
              });
            }
            setTimeout(() => {
              if (refetch) {
                refetch();
              }
              const updated = { ...sortBy, id: colId, desc: sortByOrder };
              setSortBy(updated);
            }, 0);
          }
        }}
      />
    </Box>
  );
};

type OrdersParkwayTableLoaderProps = React.ComponentProps<typeof OrdersParkwayTable> &
  React.ComponentProps<typeof OrdersParkwayTableSkeleton> & {
    isLoading?: boolean;
  };

export const OrdersParkwayTableLoader: React.FC<OrdersParkwayTableLoaderProps> = ({
  isLoading,
  count,
  ...props
}) => (isLoading ? <OrdersParkwayTableSkeleton /> : <OrdersParkwayTable {...props} />);

export default OrdersParkwayTable;
