import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { isEmpty, omit } from 'lodash';
import { AuthCtxStateTypes, useAuthContext, useDataContext } from '@/context';
import { AppConfig } from '@/config/app.config';
import { ROUTES } from '@/routes/Router';
import { FaradayStaffProfile, LogoutSessionDocument, StaffProfileDocument } from '@/gql/generated/graphql';
import graphqlRequestClient from '@/api/client';
import { CookieDataTypes, WorkspaceStorageTypes } from '@/helpers/types/system.types';
import { localWorkspace, saveWorkspace } from '@/helpers/utils/storage.utils';
import { NavigateOrderStateType } from '@/helpers/types/ui.types';
import { nullTextParser } from '@/helpers/utils';

export const useAuthHandler = (sessionToken?: string) => {
  const cookieTokenName = AppConfig.STORAGE.COOKIE_TOKEN_KEY;
  const cookieDataName = AppConfig.STORAGE.COOKIE_DATA_KEY;
  const [cookies, setCookie, removeCookie] = useCookies([cookieTokenName, cookieDataName]);
  const [response, setResponse] = useState<CookieDataTypes | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const {
    setAuthState,
    setIsLoading: setAuthStateLoading,
    authState: { data: authData },
  } = useAuthContext();
  const { setUser } = useDataContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const cookieToken = nullTextParser(cookies?.[cookieTokenName] as string);
  const propToken = nullTextParser(sessionToken as string) || cookieToken;

  const urlRedirectParams = useMemo(
    () => ({
      session: nullTextParser(searchParams.get('session')),
      message: searchParams.get('reason'),
    }),
    [searchParams]
  );

  const invalidateData = useCallback(
    (callbackFn?: () => void) => {
      setResponse(null);
      setTimeout(() => {
        localWorkspace?.set(
          localWorkspace.getByUid(authData?.user?.clinic?.uid as string) as WorkspaceStorageTypes,
          {
            active: false,
          }
        );
      }, 0);
      setIsLoading(false);
      removeCookie(cookieTokenName, { path: '/' });
      removeCookie(cookieDataName, { path: '/' });
      // NOTE: To avoid anomalies we also delete all data in the storage
      localWorkspace.deleteAll();
      setTimeout(() => {
        if (callbackFn) callbackFn();
      }, 1000);
    },
    [authData?.user?.clinic?.uid, cookieDataName, cookieTokenName, removeCookie]
  );

  const redirectToLogin = useCallback(() => {
    setTimeout(() => {
      navigate(ROUTES.LOGIN.ROOT, {
        state: {
          error: {
            session: urlRedirectParams.session,
            message: urlRedirectParams.message || 'Invalid user session.',
          },
        } as NavigateOrderStateType,
      });
    }, 0);
  }, [navigate, urlRedirectParams.message, urlRedirectParams.session]);

  useEffect(() => {
    const onLogin = async (tokenParam?: string | null) => {
      const token = (tokenParam || cookieToken) as string;
      try {
        const apiClient = graphqlRequestClient({ token });
        const { faradayStaffProfile } = await apiClient.request(StaffProfileDocument, {});
        if (!isEmpty(faradayStaffProfile) && setAuthState && setUser) {
          const staffProfileData = faradayStaffProfile as FaradayStaffProfile;
          // NOTE: Might as well initially set data provider just to be safe
          setUser(staffProfileData);
          const responseObj: AuthCtxStateTypes = {
            token,
            data: {
              user: staffProfileData,
            },
            isAuthenticated: true,
            isLoading: false,
          };

          const cookieObj = { user: omit(staffProfileData, ['adminDoctorRelationships', 'favoriteExams']) };
          setCookie(cookieDataName, cookieObj, { path: ROUTES.ROOT });
          setResponse(responseObj.data as CookieDataTypes);
          setAuthState(responseObj);
          // NOTE: This will initially set the workspace localstore even before user selected a workspace to ensure consistent active workspace
          saveWorkspace(staffProfileData, { active: false });
          // NOTE: Temporarily disable so we dont need apiClient here
          // saveWorkspace(staffProfileData, { active: false } , apiClient );
          setIsLoading(false);
        }
      } catch (e) {
        console.log(
          `%c😭❌️[ERROR] - Problem found in login authentication: ${e}`,
          'background: #000000; color: magenta'
        );
        invalidateData();
        setIsLoading(false);
        redirectToLogin();
      }
    };

    const isCallLogin = isEmpty(response) && propToken && isEmpty(authData?.user);

    if (isCallLogin) {
      setIsLoading(true);
      onLogin(propToken);
    }
  }, [
    cookieToken,
    cookieTokenName,
    propToken,
    response,
    setCookie,
    setAuthState,
    setUser,
    cookieDataName,
    invalidateData,
    navigate,
    urlRedirectParams,
    authData,
    redirectToLogin,
  ]);

  const onLogout = useCallback(
    async (callbackFn?: () => void): Promise<void> => {
      setIsLoading(true);
      const apiOnLogoutMutation = graphqlRequestClient();
      try {
        if (setAuthStateLoading) {
          setAuthStateLoading(true);
          setTimeout(async () => {
            await apiOnLogoutMutation.request(LogoutSessionDocument, { input: {} } as never);
            invalidateData(callbackFn);
            console.info(
              `%c😊🔓✅[AUTH] - Successfully logged out user.`,
              'background: #000000; color: green'
            );
          }, 0);
        }
      } catch (e) {
        console.log(
          `%c😭❌️[ERROR] - Unable to call onLogout service: ${e}`,
          'background: #000000; color: orange'
        );
        setTimeout(() => {
          navigate(ROUTES.ERROR.ROOT);
        }, 0);
      }
    },
    [navigate, invalidateData, setAuthStateLoading]
  );

  return { data: response, onLogout, isLoading, invalidateData };
};

export default useAuthHandler;
