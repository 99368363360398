import React from 'react';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { useAuthContext } from '@/context';
import { ROUTES } from '@/routes/Router';
import { AppConfig } from '@/config/app.config';
import { useCookies } from 'react-cookie';
import { HeimdallWithSessionByIdDocument } from '@/gql/generated/graphql-hooks';
import graphqlRequestClient from '@/api/client';
import { useAuthHandler } from '@/hooks';
import { nullTextParser, unsecureRedirectPath } from '@/helpers/utils';

export const RouteValidator: React.FC<any> = ({ children }) => {
  const {
    authState: { isAuthenticated },
  } = useAuthContext();
  const { invalidateData: destroyAllAuthData } = useAuthHandler();
  const location = useLocation();
  const cookieTokenName = AppConfig.STORAGE.COOKIE_TOKEN_KEY;
  const [cookies] = useCookies([cookieTokenName]);
  const cookieToken = nullTextParser(cookies?.[cookieTokenName]);
  const apiClient = graphqlRequestClient();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tokenFromParams = nullTextParser(searchParams.get('session'));
  const tokenVal = cookieToken || tokenFromParams;

  const loginRedirect = (delay = 500) => unsecureRedirectPath(ROUTES.LOGIN.ROOT, delay);

  const errorRedirect = () => navigate(ROUTES.ERROR.ROOT, { replace: true });

  if (isAuthenticated && cookieToken && location.pathname !== ROUTES.ERROR.ROOT) {
    apiClient
      .request(HeimdallWithSessionByIdDocument, { uid: tokenVal })
      .then(({ heimdallSession }) => {
        if (!heimdallSession) {
          console.info(
            `%c🔐[AUTH] - API health check - Invalid session and will redirect to login page.`,
            'background: #000000; color: orange'
          );
          destroyAllAuthData();
          loginRedirect();
        }
      })
      .catch((err: Error) => {
        console.error(`%c❌️[ERROR] - API health check - ${err}`, 'background: #000000; color: yellow');
        errorRedirect();
      });
  } else {
    console.info(
      `%c❌️[AUTH] - API health check - Unable to find valid session.`,
      'background: #000000; color: orange'
    );
    loginRedirect();
    return null;
  }

  return children;
};

export default RouteValidator;
