import styled from 'styled-components';
import { DataTable as BODataTable, Box } from '@fivehealth/botero';

type BoxProps = React.ComponentProps<typeof Box>;
type DataTableProps = React.ComponentProps<typeof BODataTable>;

// TODO: Fix all this linting issues
// eslint-disable-next-line @typescript-eslint/no-unsafe-return
const DataTableWrapper = styled(Box)((props) => ({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  ...props.style,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
  '& table': props.tableStyle,
}));

// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
type TableProps = DataTableProps & {
  rootProps?: BoxProps;
};

const DataTable: React.FC<TableProps> = ({ rootProps, ...props }) => (
  <DataTableWrapper {...rootProps}>
    <BODataTable {...props} />
  </DataTableWrapper>
);
export default DataTable;
