import { Box, Flex, H5, FAIcon } from '@fivehealth/botero';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { transformTextToFormalCase } from '@/helpers/utils';
import { WorkspaceStorageTypes } from '@/helpers/types/system.types';

function UserInfo({ user, avatarSrc }: { user?: Pick<WorkspaceStorageTypes, 'name'>; avatarSrc: string }) {
  return (
    <Flex alignItems="center">
      {user ? <H5>{transformTextToFormalCase(user?.name || '')}</H5> : '...'}
      <Box ml={2} mr={2} as="img" src={avatarSrc} borderRadius="50%" width={40} />
      <FAIcon icon={faChevronDown} fontSize="18px" />
    </Flex>
  );
}
export default UserInfo;
