import * as __GraphQLSchemaTypes from './graphql';

import { GraphQLClient } from 'graphql-request';
import { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(
  client: GraphQLClient,
  query: string,
  variables?: TVariables,
  requestHeaders?: RequestInit['headers']
) {
  return async (): Promise<TData> =>
    client.request({
      document: query,
      variables,
      requestHeaders,
    });
}
export type AvailabilityRoom_Fragment = {
  __typename?: 'FaradayRoom';
  uid: string;
  name: string;
  metadata?: any | null;
  aeTitle: string;
  modality: { __typename?: 'FaradayModality'; name: string; shortName: string };
  facility: {
    __typename?: 'FaradayFacility';
    uid: string;
    name: string;
    metadata?: any | null;
    shortCode: string;
    disclaimerMessages?: Array<string> | null;
    rooms: Array<{
      __typename?: 'FaradayRoom';
      uid: string;
      name: string;
      metadata?: any | null;
      modality: { __typename?: 'FaradayModality'; name: string; shortName: string };
    }>;
  };
};

export type AvailabilitySlots_Fragment = {
  __typename?: 'FaradayAvailabilitySlot';
  id?: string | null;
  date: any;
  times: Array<Array<string> | null>;
};

export type AvalibilityQueryVariables = __GraphQLSchemaTypes.Exact<{
  startDatetime?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['DateTime']['input']>;
  endDatetime?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['DateTime']['input']>;
  examUid: __GraphQLSchemaTypes.Scalars['String']['input'];
  facilityUid?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>;
}>;

export type AvalibilityQuery = {
  __typename?: 'Query';
  faradayAvailability?: {
    __typename?: 'FaradayAvailability';
    results?: Array<{
      __typename?: 'FaradayAvailabilityResult';
      exam: {
        __typename?: 'FaradayExam';
        duration: number;
        uid: string;
        name: string;
        disclaimerMessages?: Array<string> | null;
        metadata?: any | null;
      };
      room: {
        __typename?: 'FaradayRoom';
        uid: string;
        name: string;
        metadata?: any | null;
        aeTitle: string;
        facility: { __typename?: 'FaradayFacility'; uid: string; name: string };
      };
      slots?: Array<{
        __typename?: 'FaradayAvailabilitySlot';
        id?: string | null;
        date: any;
        times: Array<Array<string> | null>;
      }> | null;
    }> | null;
  } | null;
};

export type AsyncJob_Fragment = {
  __typename?: 'KronosAsyncJob';
  uid: string;
  status: __GraphQLSchemaTypes.AsyncJobStatus;
  createdOn: any;
  results?: any | null;
  isReady: boolean;
};

export type AvalibilityAsyncQueryVariables = __GraphQLSchemaTypes.Exact<{
  asyncJobUid?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>;
  examUid?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>;
  facilityUid?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>;
  startDatetime?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['DateTime']['input']>;
  endDatetime?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['DateTime']['input']>;
}>;

export type AvalibilityAsyncQuery = {
  __typename?: 'Query';
  faradayAvailabilityAsync?: {
    __typename?: 'FaradayAvailabilityAsync';
    asyncJob?: {
      __typename?: 'KronosAsyncJob';
      uid: string;
      status: __GraphQLSchemaTypes.AsyncJobStatus;
      createdOn: any;
      results?: any | null;
      isReady: boolean;
    } | null;
    results?: Array<{
      __typename?: 'FaradayAvailabilityResult';
      exam: { __typename?: 'FaradayExam'; uid: string; duration: number };
      room: {
        __typename?: 'FaradayRoom';
        uid: string;
        name: string;
        metadata?: any | null;
        aeTitle: string;
        modality: { __typename?: 'FaradayModality'; name: string; shortName: string };
        facility: {
          __typename?: 'FaradayFacility';
          uid: string;
          name: string;
          metadata?: any | null;
          shortCode: string;
          disclaimerMessages?: Array<string> | null;
          rooms: Array<{
            __typename?: 'FaradayRoom';
            uid: string;
            name: string;
            metadata?: any | null;
            modality: { __typename?: 'FaradayModality'; name: string; shortName: string };
          }>;
        };
      };
      slots?: Array<{
        __typename?: 'FaradayAvailabilitySlot';
        id?: string | null;
        date: any;
        times: Array<Array<string> | null>;
      }> | null;
    }> | null;
  } | null;
};

export type FacilityAvailabilityQueryVariables = __GraphQLSchemaTypes.Exact<{
  examUids:
    | Array<__GraphQLSchemaTypes.Scalars['String']['input']>
    | __GraphQLSchemaTypes.Scalars['String']['input'];
}>;

export type FacilityAvailabilityQuery = {
  __typename?: 'Query';
  faradayFacilityAvailability?: Array<{
    __typename?: 'FaradayFacilityAvailability';
    exams: Array<{
      __typename?: 'FaradayExam';
      uid: string;
      name: string;
      disclaimerMessages?: Array<string> | null;
      modality: { __typename?: 'FaradayModality'; shortName: string; name: string };
    }>;
    facility: {
      __typename?: 'FaradayFacility';
      uid: string;
      name: string;
      metadata?: any | null;
      shortCode: string;
      disclaimerMessages?: Array<string> | null;
      rooms: Array<{
        __typename?: 'FaradayRoom';
        uid: string;
        name: string;
        metadata?: any | null;
        modality: { __typename?: 'FaradayModality'; name: string; shortName: string };
      }>;
    };
  } | null> | null;
};

export type ExamAvailabilityQueryVariables = __GraphQLSchemaTypes.Exact<{
  examSearch?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>;
}>;

export type ExamAvailabilityQuery = {
  __typename?: 'Query';
  faradayExamAvailability?: Array<{
    __typename?: 'FaradayExamAvailability';
    exam: {
      __typename?: 'FaradayExam';
      uid: string;
      name: string;
      duration: number;
      isRetired: boolean;
      disclaimerMessages?: Array<string> | null;
      modality: { __typename?: 'FaradayModality'; uid: string; shortName: string; name: string };
      additionalFieldTemplates?: Array<{
        __typename?: 'FaradayAdditionalFieldTemplate';
        id?: string | null;
        key: string;
        name: string;
        description?: string | null;
        categories: Array<{
          __typename?: 'FaradayAditionalFieldCategory';
          id?: string | null;
          key: string;
          name: string;
          description?: string | null;
          fields: Array<{
            __typename?: 'FaradayAdditionalField';
            key: string;
            id?: string | null;
            name: string;
            type: string;
            default?: string | null;
          }>;
        }>;
      }> | null;
    };
  } | null> | null;
};

export type Clinic_Fragment = {
  __typename?: 'FaradayClinic';
  uid: string;
  domain: string;
  address: string;
  name: string;
  hciCode: string;
  phone: string;
};

export type Staffs_Fragment = {
  __typename?: 'FaradayClinic';
  staffs: Array<{
    __typename?: 'FaradayStaffProfile';
    uid: string;
    accountType: __GraphQLSchemaTypes.StaffProfileAccountType;
    createdOn: any;
    deactivatedOn?: any | null;
    email?: string | null;
    favoriteExams: Array<{
      __typename?: 'FaradayExam';
      uid: string;
      name: string;
      duration: number;
      isRetired: boolean;
      metadata?: any | null;
      disclaimerMessages?: Array<string> | null;
      modality: { __typename?: 'FaradayModality'; name: string; shortName: string };
      additionalFieldTemplates?: Array<{
        __typename?: 'FaradayAdditionalFieldTemplate';
        id?: string | null;
        key: string;
        name: string;
        description?: string | null;
        categories: Array<{
          __typename?: 'FaradayAditionalFieldCategory';
          id?: string | null;
          key: string;
          name: string;
          description?: string | null;
          fields: Array<{
            __typename?: 'FaradayAdditionalField';
            key: string;
            id?: string | null;
            name: string;
            type: string;
            default?: string | null;
          }>;
        }>;
      }> | null;
    }>;
  }>;
};

export type ClinicsConnection_Fragment = {
  __typename?: 'FaradayClinicConnection';
  totalCount: number;
  pageInfo: {
    __typename?: 'PageInfo';
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor?: string | null;
    endCursor?: string | null;
  };
};

export type ClinicsQueryVariables = __GraphQLSchemaTypes.Exact<{ [key: string]: never }>;

export type ClinicsQuery = {
  __typename?: 'Query';
  faradayClinics?: {
    __typename?: 'FaradayClinicConnection';
    totalCount: number;
    edges: Array<{
      __typename?: 'FaradayClinicEdge';
      node?: {
        __typename?: 'FaradayClinic';
        uid: string;
        domain: string;
        address: string;
        name: string;
        hciCode: string;
        phone: string;
        staffs: Array<{
          __typename?: 'FaradayStaffProfile';
          uid: string;
          accountType: __GraphQLSchemaTypes.StaffProfileAccountType;
          createdOn: any;
          deactivatedOn?: any | null;
          email?: string | null;
          favoriteExams: Array<{
            __typename?: 'FaradayExam';
            uid: string;
            name: string;
            duration: number;
            isRetired: boolean;
            metadata?: any | null;
            disclaimerMessages?: Array<string> | null;
            modality: { __typename?: 'FaradayModality'; name: string; shortName: string };
            additionalFieldTemplates?: Array<{
              __typename?: 'FaradayAdditionalFieldTemplate';
              id?: string | null;
              key: string;
              name: string;
              description?: string | null;
              categories: Array<{
                __typename?: 'FaradayAditionalFieldCategory';
                id?: string | null;
                key: string;
                name: string;
                description?: string | null;
                fields: Array<{
                  __typename?: 'FaradayAdditionalField';
                  key: string;
                  id?: string | null;
                  name: string;
                  type: string;
                  default?: string | null;
                }>;
              }>;
            }> | null;
          }>;
        }>;
      } | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
  } | null;
};

export type ClinicQueryVariables = __GraphQLSchemaTypes.Exact<{ [key: string]: never }>;

export type ClinicQuery = {
  __typename?: 'Query';
  faradayClinic?: {
    __typename?: 'FaradayClinic';
    uid: string;
    domain: string;
    address: string;
    name: string;
    hciCode: string;
    phone: string;
    staffs: Array<{
      __typename?: 'FaradayStaffProfile';
      uid: string;
      accountType: __GraphQLSchemaTypes.StaffProfileAccountType;
      createdOn: any;
      deactivatedOn?: any | null;
      email?: string | null;
      favoriteExams: Array<{
        __typename?: 'FaradayExam';
        uid: string;
        name: string;
        duration: number;
        isRetired: boolean;
        metadata?: any | null;
        disclaimerMessages?: Array<string> | null;
        modality: { __typename?: 'FaradayModality'; name: string; shortName: string };
        additionalFieldTemplates?: Array<{
          __typename?: 'FaradayAdditionalFieldTemplate';
          id?: string | null;
          key: string;
          name: string;
          description?: string | null;
          categories: Array<{
            __typename?: 'FaradayAditionalFieldCategory';
            id?: string | null;
            key: string;
            name: string;
            description?: string | null;
            fields: Array<{
              __typename?: 'FaradayAdditionalField';
              key: string;
              id?: string | null;
              name: string;
              type: string;
              default?: string | null;
            }>;
          }>;
        }> | null;
      }>;
    }>;
  } | null;
};

export type RegisterDeviceMutationVariables = __GraphQLSchemaTypes.Exact<{
  input: __GraphQLSchemaTypes.FaradayRegisterDeviceInput;
}>;

export type RegisterDeviceMutation = {
  __typename?: 'Mutation';
  faradayRegisterDevice?: {
    __typename?: 'FaradayRegisterDevice';
    device?: {
      __typename?: 'RatatoskrDevice';
      uid: string;
      createdOn: any;
      metadata?: any | null;
      id?: string | null;
      lastActiveOn: any;
      session?: { __typename?: 'HeimdallSession'; uid: string } | null;
    } | null;
    staffProfile?: { __typename?: 'FaradayStaffProfile'; uid: string } | null;
  } | null;
};

export type Exam_Fragment = {
  __typename?: 'FaradayExam';
  uid: string;
  name: string;
  duration: number;
  isRetired: boolean;
  disclaimerMessages?: Array<string> | null;
  modality: { __typename?: 'FaradayModality'; uid: string; shortName: string };
  additionalFieldTemplates?: Array<{
    __typename?: 'FaradayAdditionalFieldTemplate';
    id?: string | null;
    key: string;
    name: string;
    description?: string | null;
    categories: Array<{
      __typename?: 'FaradayAditionalFieldCategory';
      id?: string | null;
      key: string;
      name: string;
      description?: string | null;
      fields: Array<{
        __typename?: 'FaradayAdditionalField';
        key: string;
        id?: string | null;
        name: string;
        type: string;
        default?: string | null;
      }>;
    }>;
  }> | null;
};

export type AdditionalFieldTemplates_Fragment = {
  __typename?: 'FaradayAdditionalFieldTemplate';
  id?: string | null;
  key: string;
  name: string;
  description?: string | null;
  categories: Array<{
    __typename?: 'FaradayAditionalFieldCategory';
    id?: string | null;
    key: string;
    name: string;
    description?: string | null;
    fields: Array<{
      __typename?: 'FaradayAdditionalField';
      key: string;
      id?: string | null;
      name: string;
      type: string;
      default?: string | null;
    }>;
  }>;
};

export type ExamsQueryVariables = __GraphQLSchemaTypes.Exact<{
  name_Icontains?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>;
  modalities?: __GraphQLSchemaTypes.InputMaybe<
    Array<__GraphQLSchemaTypes.Scalars['String']['input']> | __GraphQLSchemaTypes.Scalars['String']['input']
  >;
  first?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Int']['input']>;
  offset?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Int']['input']>;
  last?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Int']['input']>;
  before?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>;
  after?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>;
  deactivatedOn_Isnull?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Boolean']['input']>;
}>;

export type ExamsQuery = {
  __typename?: 'Query';
  faradayExams?: {
    __typename?: 'FaradayExamConnection';
    totalCount: number;
    edges: Array<{
      __typename?: 'FaradayExamEdge';
      node?: {
        __typename?: 'FaradayExam';
        uid: string;
        name: string;
        duration: number;
        isRetired: boolean;
        disclaimerMessages?: Array<string> | null;
        modality: { __typename?: 'FaradayModality'; uid: string; shortName: string; name: string };
        additionalFieldTemplates?: Array<{
          __typename?: 'FaradayAdditionalFieldTemplate';
          id?: string | null;
          key: string;
          name: string;
          description?: string | null;
          categories: Array<{
            __typename?: 'FaradayAditionalFieldCategory';
            id?: string | null;
            key: string;
            name: string;
            description?: string | null;
            fields: Array<{
              __typename?: 'FaradayAdditionalField';
              key: string;
              id?: string | null;
              name: string;
              type: string;
              default?: string | null;
            }>;
          }>;
        }> | null;
      } | null;
    } | null>;
  } | null;
};

export type ExamByUidQueryVariables = __GraphQLSchemaTypes.Exact<{
  uid: __GraphQLSchemaTypes.Scalars['String']['input'];
}>;

export type ExamByUidQuery = {
  __typename?: 'Query';
  faradayExam?: {
    __typename?: 'FaradayExam';
    uid: string;
    name: string;
    duration: number;
    isRetired: boolean;
    disclaimerMessages?: Array<string> | null;
    modality: { __typename?: 'FaradayModality'; uid: string; shortName: string; name: string };
    additionalFieldTemplates?: Array<{
      __typename?: 'FaradayAdditionalFieldTemplate';
      id?: string | null;
      key: string;
      name: string;
      description?: string | null;
      categories: Array<{
        __typename?: 'FaradayAditionalFieldCategory';
        id?: string | null;
        key: string;
        name: string;
        description?: string | null;
        fields: Array<{
          __typename?: 'FaradayAdditionalField';
          key: string;
          id?: string | null;
          name: string;
          type: string;
          default?: string | null;
        }>;
      }>;
    }> | null;
  } | null;
};

export type Facility_Fragment = {
  __typename?: 'FaradayFacility';
  uid: string;
  name: string;
  metadata?: any | null;
  shortCode: string;
  disclaimerMessages?: Array<string> | null;
  rooms: Array<{
    __typename?: 'FaradayRoom';
    uid: string;
    name: string;
    metadata?: any | null;
    modality: { __typename?: 'FaradayModality'; name: string; shortName: string };
  }>;
};

export type FacilitiesQueryVariables = __GraphQLSchemaTypes.Exact<{ [key: string]: never }>;

export type FacilitiesQuery = {
  __typename?: 'Query';
  faradayFacilities?: {
    __typename?: 'FaradayFacilityConnection';
    edges: Array<{
      __typename?: 'FaradayFacilityEdge';
      node?: {
        __typename?: 'FaradayFacility';
        uid: string;
        name: string;
        metadata?: any | null;
        shortCode: string;
        disclaimerMessages?: Array<string> | null;
        rooms: Array<{
          __typename?: 'FaradayRoom';
          uid: string;
          name: string;
          metadata?: any | null;
          modality: { __typename?: 'FaradayModality'; name: string; shortName: string };
        }>;
      } | null;
    } | null>;
  } | null;
};

export type FacilityByUidQueryVariables = __GraphQLSchemaTypes.Exact<{
  uid: __GraphQLSchemaTypes.Scalars['String']['input'];
}>;

export type FacilityByUidQuery = {
  __typename?: 'Query';
  faradayFacility?: {
    __typename?: 'FaradayFacility';
    uid: string;
    name: string;
    metadata?: any | null;
    shortCode: string;
    disclaimerMessages?: Array<string> | null;
    rooms: Array<{
      __typename?: 'FaradayRoom';
      uid: string;
      name: string;
      metadata?: any | null;
      modality: { __typename?: 'FaradayModality'; name: string; shortName: string };
    }>;
  } | null;
};

export type HeimdallWithSessionQueryVariables = __GraphQLSchemaTypes.Exact<{ [key: string]: never }>;

export type HeimdallWithSessionQuery = {
  __typename?: 'Query';
  heimdallSession?: {
    __typename?: 'HeimdallSession';
    id?: string | null;
    uid: string;
    scopes?: any | null;
    expiresOn: any;
    user?: { __typename?: 'GovernessUser'; uid: string } | null;
  } | null;
};

export type HeimdallWithSessionByIdQueryVariables = __GraphQLSchemaTypes.Exact<{
  uid: __GraphQLSchemaTypes.Scalars['String']['input'];
}>;

export type HeimdallWithSessionByIdQuery = {
  __typename?: 'Query';
  heimdallSession?: { __typename?: 'HeimdallSession'; id?: string | null; uid: string } | null;
};

export type HeimdallAuthorizationFlowJwtMutationVariables = __GraphQLSchemaTypes.Exact<{
  input: __GraphQLSchemaTypes.HeimdallAuthorizationFlowInput;
}>;

export type HeimdallAuthorizationFlowJwtMutation = {
  __typename?: 'Mutation';
  heimdallAuthorizationFlow?: {
    __typename?: 'HeimdallAuthorizationFlow';
    redirectTo?: string | null;
    session?: {
      __typename?: 'HeimdallSession';
      uid: string;
      scopes?: any | null;
      expiresOn: any;
      user?: { __typename?: 'GovernessUser'; uid: string } | null;
    } | null;
  } | null;
};

export type HeimdallAuthorizationFlowExchangeMutationVariables = __GraphQLSchemaTypes.Exact<{
  heimdallAuthorizationFlowInput: __GraphQLSchemaTypes.HeimdallAuthorizationFlowInput;
}>;

export type HeimdallAuthorizationFlowExchangeMutation = {
  __typename?: 'Mutation';
  heimdallAuthorizationFlow?: {
    __typename?: 'HeimdallAuthorizationFlow';
    session?: { __typename?: 'HeimdallSession'; uid: string; scopes?: any | null; expiresOn: any } | null;
  } | null;
};

export type LogoutSessionMutationVariables = __GraphQLSchemaTypes.Exact<{
  input: __GraphQLSchemaTypes.HeimdallLogoutInput;
}>;

export type LogoutSessionMutation = {
  __typename?: 'Mutation';
  heimdallLogout?: { __typename?: 'HeimdallLogout'; success?: boolean | null } | null;
};

export type MessageTemplate_Fragment = {
  __typename?: 'FaradayMessageTemplate';
  uid: string;
  name: string;
  template?: any | null;
};

export type MessageTemplatesQueryVariables = __GraphQLSchemaTypes.Exact<{ [key: string]: never }>;

export type MessageTemplatesQuery = {
  __typename?: 'Query';
  faradayMessageTemplates?: {
    __typename?: 'FaradayMessageTemplateConnection';
    edges: Array<{
      __typename?: 'FaradayMessageTemplateEdge';
      node?: {
        __typename?: 'FaradayMessageTemplate';
        uid: string;
        name: string;
        template?: any | null;
      } | null;
    } | null>;
  } | null;
};

export type MessageTemplateByUidQueryVariables = __GraphQLSchemaTypes.Exact<{
  uid: __GraphQLSchemaTypes.Scalars['String']['input'];
}>;

export type MessageTemplateByUidQuery = {
  __typename?: 'Query';
  faradayMessageTemplate?: {
    __typename?: 'FaradayMessageTemplate';
    uid: string;
    name: string;
    template?: any | null;
  } | null;
};

export type MessageTemplateDeliverToPatientMutationVariables = __GraphQLSchemaTypes.Exact<{
  input: __GraphQLSchemaTypes.FaradayMessageTemplateDeliverToPatientInput;
}>;

export type MessageTemplateDeliverToPatientMutation = {
  __typename?: 'Mutation';
  faradayMessageTemplateDeliverToPatient?: {
    __typename?: 'FaradayMessageTemplateDeliverToPatient';
    results: Array<{
      __typename?: 'FaradayMessageTemplateDeliveryResult';
      isDelivered: boolean;
      patientProfile: { __typename?: 'FaradayPatientProfile'; uid: string };
      messageTemplate?: {
        __typename?: 'FaradayMessageTemplate';
        uid: string;
        name: string;
        template?: any | null;
      } | null;
    }>;
  } | null;
};

export type ModalitiesAllQueryVariables = __GraphQLSchemaTypes.Exact<{ [key: string]: never }>;

export type ModalitiesAllQuery = {
  __typename?: 'Query';
  faradayModalities?: {
    __typename?: 'FaradayModalityConnection';
    edges: Array<{
      __typename?: 'FaradayModalityEdge';
      node?: { __typename?: 'FaradayModality'; uid: string; name: string; shortName: string } | null;
    } | null>;
  } | null;
};

export type ModalitiesQueryVariables = __GraphQLSchemaTypes.Exact<{
  first?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Int']['input']>;
  offset?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Int']['input']>;
  last?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Int']['input']>;
  before?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>;
  after?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>;
  shortName_In?: __GraphQLSchemaTypes.InputMaybe<
    | Array<__GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>>
    | __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>
  >;
}>;

export type ModalitiesQuery = {
  __typename?: 'Query';
  faradayModalities?: {
    __typename?: 'FaradayModalityConnection';
    edges: Array<{
      __typename?: 'FaradayModalityEdge';
      node?: { __typename?: 'FaradayModality'; uid: string; name: string; shortName: string } | null;
    } | null>;
  } | null;
};

export type ModalityDetails_Fragment = { __typename?: 'FaradayModality'; name: string; shortName: string };

export type ModalityQueryVariables = __GraphQLSchemaTypes.Exact<{ [key: string]: never }>;

export type ModalityQuery = {
  __typename?: 'Query';
  faradayModality?: { __typename?: 'FaradayModality'; name: string; shortName: string } | null;
};

export type ModalityByUidQueryVariables = __GraphQLSchemaTypes.Exact<{
  uid: __GraphQLSchemaTypes.Scalars['String']['input'];
}>;

export type ModalityByUidQuery = {
  __typename?: 'Query';
  faradayModality?: { __typename?: 'FaradayModality'; uid: string; name: string; shortName: string } | null;
};

export type OrdersExams_Fragment = {
  __typename?: 'FaradayOrderExam';
  uid: string;
  accessionNo: string;
  vueMotionUrl?: string | null;
  imageReadyOn?: any | null;
  reportReadyOn?: any | null;
  lastRemindedOn?: any | null;
  status: __GraphQLSchemaTypes.OrderExamStatus;
  updatedOn: any;
  createdOn: any;
  deactivatedOn?: any | null;
  scheduledFor?: any | null;
  duration?: number | null;
  metadata?: any | null;
  room: {
    __typename?: 'FaradayRoom';
    uid: string;
    name: string;
    metadata?: any | null;
    modality: { __typename?: 'FaradayModality'; name: string; uid: string; shortName: string };
    facility: { __typename?: 'FaradayFacility'; uid: string; name: string; metadata?: any | null };
  };
  exam: {
    __typename?: 'FaradayExam';
    uid: string;
    duration: number;
    name: string;
    metadata?: any | null;
    disclaimerMessages?: Array<string> | null;
    procedureCode: string;
    additionalFieldTemplates?: Array<{
      __typename?: 'FaradayAdditionalFieldTemplate';
      id?: string | null;
      key: string;
      name: string;
      description?: string | null;
      categories: Array<{
        __typename?: 'FaradayAditionalFieldCategory';
        id?: string | null;
        key: string;
        name: string;
        description?: string | null;
        fields: Array<{
          __typename?: 'FaradayAdditionalField';
          key: string;
          id?: string | null;
          name: string;
          type: string;
          default?: string | null;
        }>;
      }>;
    }> | null;
    modality: { __typename?: 'FaradayModality'; shortName: string; name: string };
  };
};

export type OrderPdfForms_Fragment = {
  __typename?: 'FaradayOrderPDF';
  uid: string;
  pdfForm: any;
  pdfFormMasked: any;
  createdOn: any;
  createdBy: {
    __typename?: 'FaradayStaffProfile';
    uid: string;
    fullName: string;
    accountType: __GraphQLSchemaTypes.StaffProfileAccountType;
  };
};

export type OrderCreatedBy_Fragment = {
  __typename?: 'FaradayStaffProfile';
  uid: string;
  fullName: string;
  accountType: __GraphQLSchemaTypes.StaffProfileAccountType;
  designation: string;
  clinic: { __typename?: 'FaradayClinic'; uid: string; name: string };
};

export type OrderCreatedFor_Fragment = {
  __typename?: 'FaradayStaffProfile';
  uid: string;
  fullName: string;
  accountType: __GraphQLSchemaTypes.StaffProfileAccountType;
  designation: string;
  clinic: { __typename?: 'FaradayClinic'; uid: string; name: string };
};

export type OrdersConnection_Fragment = {
  __typename?: 'FaradayOrderConnection';
  totalCount: number;
  pageInfo: {
    __typename?: 'PageInfo';
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor?: string | null;
    endCursor?: string | null;
  };
};

export type OrdersPatient_Fragment = {
  __typename?: 'FaradayPatientProfile';
  uid: string;
  fullName: string;
  firstName: string;
  lastName: string;
  phone?: string | null;
  gender?: string | null;
  isClaustrophobic: boolean;
  dateOfBirth?: any | null;
  nationalId: string;
  deactivatedOn?: any | null;
  comments: string;
  doctors: Array<{
    __typename?: 'FaradayStaffProfile';
    uid: string;
    designation: string;
    accountType: __GraphQLSchemaTypes.StaffProfileAccountType;
  }>;
};

export type OrderDetails_Fragment = {
  __typename?: 'FaradayOrder';
  uid: string;
  createdOn: any;
  updatedOn: any;
  metadata?: any | null;
  orderExams: Array<{
    __typename?: 'FaradayOrderExam';
    uid: string;
    accessionNo: string;
    vueMotionUrl?: string | null;
    imageReadyOn?: any | null;
    reportReadyOn?: any | null;
    lastRemindedOn?: any | null;
    status: __GraphQLSchemaTypes.OrderExamStatus;
    updatedOn: any;
    createdOn: any;
    deactivatedOn?: any | null;
    scheduledFor?: any | null;
    duration?: number | null;
    metadata?: any | null;
    room: {
      __typename?: 'FaradayRoom';
      uid: string;
      name: string;
      metadata?: any | null;
      modality: { __typename?: 'FaradayModality'; name: string; uid: string; shortName: string };
      facility: { __typename?: 'FaradayFacility'; uid: string; name: string; metadata?: any | null };
    };
    exam: {
      __typename?: 'FaradayExam';
      uid: string;
      duration: number;
      name: string;
      metadata?: any | null;
      disclaimerMessages?: Array<string> | null;
      procedureCode: string;
      additionalFieldTemplates?: Array<{
        __typename?: 'FaradayAdditionalFieldTemplate';
        id?: string | null;
        key: string;
        name: string;
        description?: string | null;
        categories: Array<{
          __typename?: 'FaradayAditionalFieldCategory';
          id?: string | null;
          key: string;
          name: string;
          description?: string | null;
          fields: Array<{
            __typename?: 'FaradayAdditionalField';
            key: string;
            id?: string | null;
            name: string;
            type: string;
            default?: string | null;
          }>;
        }>;
      }> | null;
      modality: { __typename?: 'FaradayModality'; shortName: string; name: string };
    };
  }>;
  pdfForms: Array<{
    __typename?: 'FaradayOrderPDF';
    uid: string;
    pdfForm: any;
    pdfFormMasked: any;
    createdOn: any;
    createdBy: {
      __typename?: 'FaradayStaffProfile';
      uid: string;
      fullName: string;
      accountType: __GraphQLSchemaTypes.StaffProfileAccountType;
    };
  }>;
  createdBy: {
    __typename?: 'FaradayStaffProfile';
    uid: string;
    fullName: string;
    accountType: __GraphQLSchemaTypes.StaffProfileAccountType;
    designation: string;
    clinic: { __typename?: 'FaradayClinic'; uid: string; name: string };
  };
  createdFor: {
    __typename?: 'FaradayStaffProfile';
    uid: string;
    fullName: string;
    accountType: __GraphQLSchemaTypes.StaffProfileAccountType;
    designation: string;
    clinic: { __typename?: 'FaradayClinic'; uid: string; name: string };
  };
  patient: {
    __typename?: 'FaradayPatientProfile';
    uid: string;
    fullName: string;
    firstName: string;
    lastName: string;
    phone?: string | null;
    gender?: string | null;
    isClaustrophobic: boolean;
    dateOfBirth?: any | null;
    nationalId: string;
    nationality?: string | null;
    deactivatedOn?: any | null;
    comments: string;
    metadata?: any | null;
    doctors: Array<{
      __typename?: 'FaradayStaffProfile';
      uid: string;
      fullName: string;
      createdOn: any;
      mcrNo?: string | null;
      phone?: string | null;
      email?: string | null;
      designation: string;
      accountType: __GraphQLSchemaTypes.StaffProfileAccountType;
      deactivatedOn?: any | null;
      favoriteExams: Array<{
        __typename?: 'FaradayExam';
        uid: string;
        name: string;
        procedureCode: string;
        modality: { __typename?: 'FaradayModality'; shortName: string; name: string };
      }>;
    }>;
  };
};

export type OrdersQueryVariables = __GraphQLSchemaTypes.Exact<{
  sortBy?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.OrderOrderBy>;
  orderSearch?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>;
  sortDesc?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Boolean']['input']>;
  first?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Int']['input']>;
  offset?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Int']['input']>;
  last?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Int']['input']>;
  before?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>;
  after?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>;
  deactivatedOn_Isnull?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Boolean']['input']>;
  exams_Status_In?: __GraphQLSchemaTypes.InputMaybe<
    | Array<__GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.OrderExamStatus>>
    | __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.OrderExamStatus>
  >;
  exams_Exam_Modality_Uid_In?: __GraphQLSchemaTypes.InputMaybe<
    | Array<__GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>>
    | __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>
  >;
  createdFor_Uid_In?: __GraphQLSchemaTypes.InputMaybe<
    | Array<__GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>>
    | __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>
  >;
  createdBy_Uid_In?: __GraphQLSchemaTypes.InputMaybe<
    | Array<__GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>>
    | __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>
  >;
  patient_Uid_In?: __GraphQLSchemaTypes.InputMaybe<
    | Array<__GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>>
    | __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>
  >;
  createdOn_Gt?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['DateTime']['input']>;
  createdOn_Gte?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['DateTime']['input']>;
  createdOn_Lt?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['DateTime']['input']>;
  createdOn_Lte?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['DateTime']['input']>;
  updatedOn_Gt?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['DateTime']['input']>;
  updatedOn_Gte?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['DateTime']['input']>;
  updatedOn_Lt?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['DateTime']['input']>;
  updatedOn_Lte?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['DateTime']['input']>;
}>;

export type OrdersQuery = {
  __typename?: 'Query';
  faradayOrders?: {
    __typename?: 'FaradayOrderConnection';
    totalCount: number;
    edges: Array<{
      __typename?: 'FaradayOrderEdge';
      node?: {
        __typename?: 'FaradayOrder';
        uid: string;
        createdOn: any;
        updatedOn: any;
        metadata?: any | null;
        orderExams: Array<{
          __typename?: 'FaradayOrderExam';
          uid: string;
          accessionNo: string;
          vueMotionUrl?: string | null;
          imageReadyOn?: any | null;
          reportReadyOn?: any | null;
          lastRemindedOn?: any | null;
          status: __GraphQLSchemaTypes.OrderExamStatus;
          updatedOn: any;
          createdOn: any;
          deactivatedOn?: any | null;
          scheduledFor?: any | null;
          duration?: number | null;
          metadata?: any | null;
          room: {
            __typename?: 'FaradayRoom';
            uid: string;
            name: string;
            metadata?: any | null;
            modality: { __typename?: 'FaradayModality'; name: string; uid: string; shortName: string };
            facility: { __typename?: 'FaradayFacility'; uid: string; name: string; metadata?: any | null };
          };
          exam: {
            __typename?: 'FaradayExam';
            uid: string;
            duration: number;
            name: string;
            metadata?: any | null;
            disclaimerMessages?: Array<string> | null;
            procedureCode: string;
            additionalFieldTemplates?: Array<{
              __typename?: 'FaradayAdditionalFieldTemplate';
              id?: string | null;
              key: string;
              name: string;
              description?: string | null;
              categories: Array<{
                __typename?: 'FaradayAditionalFieldCategory';
                id?: string | null;
                key: string;
                name: string;
                description?: string | null;
                fields: Array<{
                  __typename?: 'FaradayAdditionalField';
                  key: string;
                  id?: string | null;
                  name: string;
                  type: string;
                  default?: string | null;
                }>;
              }>;
            }> | null;
            modality: { __typename?: 'FaradayModality'; shortName: string; name: string };
          };
        }>;
        pdfForms: Array<{
          __typename?: 'FaradayOrderPDF';
          uid: string;
          pdfForm: any;
          pdfFormMasked: any;
          createdOn: any;
          createdBy: {
            __typename?: 'FaradayStaffProfile';
            uid: string;
            fullName: string;
            accountType: __GraphQLSchemaTypes.StaffProfileAccountType;
          };
        }>;
        createdBy: {
          __typename?: 'FaradayStaffProfile';
          uid: string;
          fullName: string;
          accountType: __GraphQLSchemaTypes.StaffProfileAccountType;
          designation: string;
          clinic: { __typename?: 'FaradayClinic'; uid: string; name: string };
        };
        createdFor: {
          __typename?: 'FaradayStaffProfile';
          uid: string;
          fullName: string;
          accountType: __GraphQLSchemaTypes.StaffProfileAccountType;
          designation: string;
          clinic: { __typename?: 'FaradayClinic'; uid: string; name: string };
        };
        patient: {
          __typename?: 'FaradayPatientProfile';
          uid: string;
          fullName: string;
          firstName: string;
          lastName: string;
          phone?: string | null;
          gender?: string | null;
          isClaustrophobic: boolean;
          dateOfBirth?: any | null;
          nationalId: string;
          nationality?: string | null;
          deactivatedOn?: any | null;
          comments: string;
          metadata?: any | null;
          doctors: Array<{
            __typename?: 'FaradayStaffProfile';
            uid: string;
            fullName: string;
            createdOn: any;
            mcrNo?: string | null;
            phone?: string | null;
            email?: string | null;
            designation: string;
            accountType: __GraphQLSchemaTypes.StaffProfileAccountType;
            deactivatedOn?: any | null;
            favoriteExams: Array<{
              __typename?: 'FaradayExam';
              uid: string;
              name: string;
              procedureCode: string;
              modality: { __typename?: 'FaradayModality'; shortName: string; name: string };
            }>;
          }>;
        };
      } | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
  } | null;
};

export type OrderByUidQueryVariables = __GraphQLSchemaTypes.Exact<{
  uid: __GraphQLSchemaTypes.Scalars['String']['input'];
}>;

export type OrderByUidQuery = {
  __typename?: 'Query';
  faradayOrder?: {
    __typename?: 'FaradayOrder';
    uid: string;
    lastPdfForm?: { __typename?: 'FaradayOrderPDF'; uid: string; pdfForm: any } | null;
    pdfForms: Array<{
      __typename?: 'FaradayOrderPDF';
      uid: string;
      pdfForm: any;
      pdfFormMasked: any;
      createdOn: any;
      createdBy: {
        __typename?: 'FaradayStaffProfile';
        uid: string;
        fullName: string;
        accountType: __GraphQLSchemaTypes.StaffProfileAccountType;
      };
    }>;
    orderExams: Array<{
      __typename?: 'FaradayOrderExam';
      uid: string;
      status: __GraphQLSchemaTypes.OrderExamStatus;
    }>;
  } | null;
};

export type CreateOrderMutationVariables = __GraphQLSchemaTypes.Exact<{
  createInput: __GraphQLSchemaTypes.CreateFaradayOrderInput;
}>;

export type CreateOrderMutation = {
  __typename?: 'Mutation';
  faradayOrderCreate?: {
    __typename?: 'CreateFaradayOrder';
    faradayOrder: {
      __typename?: 'FaradayOrder';
      uid: string;
      createdOn: any;
      updatedOn: any;
      metadata?: any | null;
      orderExams: Array<{
        __typename?: 'FaradayOrderExam';
        uid: string;
        accessionNo: string;
        vueMotionUrl?: string | null;
        imageReadyOn?: any | null;
        reportReadyOn?: any | null;
        lastRemindedOn?: any | null;
        status: __GraphQLSchemaTypes.OrderExamStatus;
        updatedOn: any;
        createdOn: any;
        deactivatedOn?: any | null;
        scheduledFor?: any | null;
        duration?: number | null;
        metadata?: any | null;
        room: {
          __typename?: 'FaradayRoom';
          uid: string;
          name: string;
          metadata?: any | null;
          modality: { __typename?: 'FaradayModality'; name: string; uid: string; shortName: string };
          facility: { __typename?: 'FaradayFacility'; uid: string; name: string; metadata?: any | null };
        };
        exam: {
          __typename?: 'FaradayExam';
          uid: string;
          duration: number;
          name: string;
          metadata?: any | null;
          disclaimerMessages?: Array<string> | null;
          procedureCode: string;
          additionalFieldTemplates?: Array<{
            __typename?: 'FaradayAdditionalFieldTemplate';
            id?: string | null;
            key: string;
            name: string;
            description?: string | null;
            categories: Array<{
              __typename?: 'FaradayAditionalFieldCategory';
              id?: string | null;
              key: string;
              name: string;
              description?: string | null;
              fields: Array<{
                __typename?: 'FaradayAdditionalField';
                key: string;
                id?: string | null;
                name: string;
                type: string;
                default?: string | null;
              }>;
            }>;
          }> | null;
          modality: { __typename?: 'FaradayModality'; shortName: string; name: string };
        };
      }>;
      pdfForms: Array<{
        __typename?: 'FaradayOrderPDF';
        uid: string;
        pdfForm: any;
        pdfFormMasked: any;
        createdOn: any;
        createdBy: {
          __typename?: 'FaradayStaffProfile';
          uid: string;
          fullName: string;
          accountType: __GraphQLSchemaTypes.StaffProfileAccountType;
        };
      }>;
      createdBy: {
        __typename?: 'FaradayStaffProfile';
        uid: string;
        fullName: string;
        accountType: __GraphQLSchemaTypes.StaffProfileAccountType;
        designation: string;
        clinic: { __typename?: 'FaradayClinic'; uid: string; name: string };
      };
      createdFor: {
        __typename?: 'FaradayStaffProfile';
        uid: string;
        fullName: string;
        accountType: __GraphQLSchemaTypes.StaffProfileAccountType;
        designation: string;
        clinic: { __typename?: 'FaradayClinic'; uid: string; name: string };
      };
      patient: {
        __typename?: 'FaradayPatientProfile';
        uid: string;
        fullName: string;
        firstName: string;
        lastName: string;
        phone?: string | null;
        gender?: string | null;
        isClaustrophobic: boolean;
        dateOfBirth?: any | null;
        nationalId: string;
        nationality?: string | null;
        deactivatedOn?: any | null;
        comments: string;
        metadata?: any | null;
        doctors: Array<{
          __typename?: 'FaradayStaffProfile';
          uid: string;
          fullName: string;
          createdOn: any;
          mcrNo?: string | null;
          phone?: string | null;
          email?: string | null;
          designation: string;
          accountType: __GraphQLSchemaTypes.StaffProfileAccountType;
          deactivatedOn?: any | null;
          favoriteExams: Array<{
            __typename?: 'FaradayExam';
            uid: string;
            name: string;
            procedureCode: string;
            modality: { __typename?: 'FaradayModality'; shortName: string; name: string };
          }>;
        }>;
      };
    };
  } | null;
};

export type UpdateOrderMutationVariables = __GraphQLSchemaTypes.Exact<{
  updateInput: __GraphQLSchemaTypes.UpdateFaradayOrderInput;
}>;

export type UpdateOrderMutation = {
  __typename?: 'Mutation';
  faradayOrderUpdate?: {
    __typename?: 'UpdateFaradayOrder';
    faradayOrder: {
      __typename?: 'FaradayOrder';
      uid: string;
      metadata?: any | null;
      patient: { __typename?: 'FaradayPatientProfile'; firstName: string; lastName: string };
      orderExams: Array<{
        __typename?: 'FaradayOrderExam';
        uid: string;
        status: __GraphQLSchemaTypes.OrderExamStatus;
        metadata?: any | null;
      }>;
      createdBy: { __typename?: 'FaradayStaffProfile'; uid: string };
    };
  } | null;
};

export type DeleteOrderMutationVariables = __GraphQLSchemaTypes.Exact<{
  deleteInput: __GraphQLSchemaTypes.DeleteFaradayOrderInput;
}>;

export type DeleteOrderMutation = {
  __typename?: 'Mutation';
  faradayOrderDelete?: {
    __typename?: 'DeleteFaradayOrder';
    deleteMutationResult: { __typename?: 'DeleteMutationResult'; uid?: string | null; count: number };
  } | null;
};

export type OrderDraft_Fragment = {
  __typename?: 'FaradayOrderDraft';
  uid: string;
  deactivatedOn?: any | null;
  draft?: any | null;
  createdOn: any;
  updatedOn: any;
  createdBy: { __typename?: 'FaradayStaffProfile'; uid: string; fullName: string };
};

export type OrderDraftsQueryVariables = __GraphQLSchemaTypes.Exact<{ [key: string]: never }>;

export type OrderDraftsQuery = {
  __typename?: 'Query';
  faradayOrderDrafts?: {
    __typename?: 'FaradayOrderDraftConnection';
    totalCount: number;
    edges: Array<{
      __typename?: 'FaradayOrderDraftEdge';
      node?: {
        __typename?: 'FaradayOrderDraft';
        uid: string;
        deactivatedOn?: any | null;
        draft?: any | null;
        createdOn: any;
        updatedOn: any;
        createdBy: { __typename?: 'FaradayStaffProfile'; uid: string; fullName: string };
      } | null;
    } | null>;
  } | null;
};

export type OrderDraftByUidQueryVariables = __GraphQLSchemaTypes.Exact<{
  uid: __GraphQLSchemaTypes.Scalars['String']['input'];
}>;

export type OrderDraftByUidQuery = {
  __typename?: 'Query';
  faradayOrderDraft?: {
    __typename?: 'FaradayOrderDraft';
    uid: string;
    deactivatedOn?: any | null;
    draft?: any | null;
    createdOn: any;
    updatedOn: any;
    createdBy: { __typename?: 'FaradayStaffProfile'; uid: string; fullName: string };
  } | null;
};

export type UpdateOrderDraftMutationVariables = __GraphQLSchemaTypes.Exact<{
  updateInput: __GraphQLSchemaTypes.UpdateFaradayOrderDraftInput;
}>;

export type UpdateOrderDraftMutation = {
  __typename?: 'Mutation';
  faradayOrderDraftUpdate?: {
    __typename?: 'UpdateFaradayOrderDraft';
    faradayOrderDraft: {
      __typename?: 'FaradayOrderDraft';
      uid: string;
      deactivatedOn?: any | null;
      createdBy: { __typename?: 'FaradayStaffProfile'; uid: string };
    };
  } | null;
};

export type CreateOrderDraftMutationVariables = __GraphQLSchemaTypes.Exact<{
  createInput: __GraphQLSchemaTypes.CreateFaradayOrderDraftInput;
}>;

export type CreateOrderDraftMutation = {
  __typename?: 'Mutation';
  faradayOrderDraftCreate?: {
    __typename?: 'CreateFaradayOrderDraft';
    faradayOrderDraft: {
      __typename?: 'FaradayOrderDraft';
      uid: string;
      deactivatedOn?: any | null;
      createdBy: { __typename?: 'FaradayStaffProfile'; uid: string };
    };
  } | null;
};

export type DeleteOrderDraftMutationVariables = __GraphQLSchemaTypes.Exact<{
  deleteInput: __GraphQLSchemaTypes.DeleteFaradayOrderDraftInput;
}>;

export type DeleteOrderDraftMutation = {
  __typename?: 'Mutation';
  faradayOrderDraftDelete?: {
    __typename?: 'DeleteFaradayOrderDraft';
    deleteMutationResult: { __typename?: 'DeleteMutationResult'; uid?: string | null; count: number };
  } | null;
};

export type OrderExamsConnection_Fragment = {
  __typename?: 'FaradayOrderExamConnection';
  totalCount: number;
  pageInfo: {
    __typename?: 'PageInfo';
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor?: string | null;
    endCursor?: string | null;
  };
};

export type OrderExam_Fragment = {
  __typename?: 'FaradayOrderExam';
  uid: string;
  accessionNo: string;
  vueMotionUrl?: string | null;
  imageReadyOn?: any | null;
  reportReadyOn?: any | null;
  lastRemindedOn?: any | null;
  status: __GraphQLSchemaTypes.OrderExamStatus;
  updatedOn: any;
  createdOn: any;
  deactivatedOn?: any | null;
  scheduledFor?: any | null;
  duration?: number | null;
  metadata?: any | null;
  exam: {
    __typename?: 'FaradayExam';
    uid: string;
    duration: number;
    name: string;
    metadata?: any | null;
    disclaimerMessages?: Array<string> | null;
    procedureCode: string;
    additionalFieldTemplates?: Array<{
      __typename?: 'FaradayAdditionalFieldTemplate';
      id?: string | null;
      key: string;
      name: string;
      description?: string | null;
      categories: Array<{
        __typename?: 'FaradayAditionalFieldCategory';
        id?: string | null;
        key: string;
        name: string;
        description?: string | null;
        fields: Array<{
          __typename?: 'FaradayAdditionalField';
          key: string;
          id?: string | null;
          name: string;
          type: string;
          default?: string | null;
        }>;
      }>;
    }> | null;
    modality: { __typename?: 'FaradayModality'; shortName: string; name: string };
  };
};

export type OrderExamsQueryVariables = __GraphQLSchemaTypes.Exact<{
  first?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Int']['input']>;
  offset?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Int']['input']>;
  last?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Int']['input']>;
  before?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>;
  after?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>;
}>;

export type OrderExamsQuery = {
  __typename?: 'Query';
  faradayOrderExams?: {
    __typename?: 'FaradayOrderExamConnection';
    totalCount: number;
    edges: Array<{
      __typename?: 'FaradayOrderExamEdge';
      node?: {
        __typename?: 'FaradayOrderExam';
        uid: string;
        accessionNo: string;
        vueMotionUrl?: string | null;
        imageReadyOn?: any | null;
        reportReadyOn?: any | null;
        lastRemindedOn?: any | null;
        status: __GraphQLSchemaTypes.OrderExamStatus;
        updatedOn: any;
        createdOn: any;
        deactivatedOn?: any | null;
        scheduledFor?: any | null;
        duration?: number | null;
        metadata?: any | null;
        order: {
          __typename?: 'FaradayOrder';
          pdfForms: Array<{
            __typename?: 'FaradayOrderPDF';
            uid: string;
            pdfForm: any;
            pdfFormMasked: any;
            createdOn: any;
            createdBy: {
              __typename?: 'FaradayStaffProfile';
              uid: string;
              fullName: string;
              accountType: __GraphQLSchemaTypes.StaffProfileAccountType;
            };
          }>;
          createdBy: {
            __typename?: 'FaradayStaffProfile';
            uid: string;
            fullName: string;
            accountType: __GraphQLSchemaTypes.StaffProfileAccountType;
            designation: string;
            clinic: { __typename?: 'FaradayClinic'; uid: string; name: string };
          };
        };
        exam: {
          __typename?: 'FaradayExam';
          uid: string;
          duration: number;
          name: string;
          metadata?: any | null;
          disclaimerMessages?: Array<string> | null;
          procedureCode: string;
          additionalFieldTemplates?: Array<{
            __typename?: 'FaradayAdditionalFieldTemplate';
            id?: string | null;
            key: string;
            name: string;
            description?: string | null;
            categories: Array<{
              __typename?: 'FaradayAditionalFieldCategory';
              id?: string | null;
              key: string;
              name: string;
              description?: string | null;
              fields: Array<{
                __typename?: 'FaradayAdditionalField';
                key: string;
                id?: string | null;
                name: string;
                type: string;
                default?: string | null;
              }>;
            }>;
          }> | null;
          modality: { __typename?: 'FaradayModality'; shortName: string; name: string };
        };
      } | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
  } | null;
};

export type OrderExamQueryVariables = __GraphQLSchemaTypes.Exact<{
  uid?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>;
}>;

export type OrderExamQuery = {
  __typename?: 'Query';
  faradayOrderExam?: {
    __typename?: 'FaradayOrderExam';
    uid: string;
    order: {
      __typename?: 'FaradayOrder';
      createdBy: {
        __typename?: 'FaradayStaffProfile';
        uid: string;
        fullName: string;
        accountType: __GraphQLSchemaTypes.StaffProfileAccountType;
        designation: string;
        clinic: { __typename?: 'FaradayClinic'; uid: string; name: string };
      };
    };
  } | null;
};

export type OrderExamCreateMutationVariables = __GraphQLSchemaTypes.Exact<{
  createInput: __GraphQLSchemaTypes.CreateFaradayOrderExamInput;
}>;

export type OrderExamCreateMutation = {
  __typename?: 'Mutation';
  faradayOrderExamCreate?: {
    __typename?: 'CreateFaradayOrderExam';
    faradayOrderExam: {
      __typename?: 'FaradayOrderExam';
      uid: string;
      status: __GraphQLSchemaTypes.OrderExamStatus;
      metadata?: any | null;
      order: {
        __typename?: 'FaradayOrder';
        createdBy: {
          __typename?: 'FaradayStaffProfile';
          uid: string;
          fullName: string;
          accountType: __GraphQLSchemaTypes.StaffProfileAccountType;
          designation: string;
          clinic: { __typename?: 'FaradayClinic'; uid: string; name: string };
        };
      };
      exam: { __typename?: 'FaradayExam'; uid: string };
    };
  } | null;
};

export type OrderExamUpdateMutationVariables = __GraphQLSchemaTypes.Exact<{
  updateInput: __GraphQLSchemaTypes.UpdateFaradayOrderExamInput;
}>;

export type OrderExamUpdateMutation = {
  __typename?: 'Mutation';
  faradayOrderExamUpdate?: {
    __typename?: 'UpdateFaradayOrderExam';
    faradayOrderExam: {
      __typename?: 'FaradayOrderExam';
      uid: string;
      status: __GraphQLSchemaTypes.OrderExamStatus;
      metadata?: any | null;
      order: {
        __typename?: 'FaradayOrder';
        createdBy: {
          __typename?: 'FaradayStaffProfile';
          uid: string;
          fullName: string;
          accountType: __GraphQLSchemaTypes.StaffProfileAccountType;
          designation: string;
          clinic: { __typename?: 'FaradayClinic'; uid: string; name: string };
        };
      };
      exam: { __typename?: 'FaradayExam'; uid: string };
    };
  } | null;
};

export type OrderExamDeleteMutationVariables = __GraphQLSchemaTypes.Exact<{
  deleteInput: __GraphQLSchemaTypes.DeleteFaradayOrderExamInput;
}>;

export type OrderExamDeleteMutation = {
  __typename?: 'Mutation';
  faradayOrderExamDelete?: {
    __typename?: 'DeleteFaradayOrderExam';
    deleteMutationResult: { __typename?: 'DeleteMutationResult'; count: number };
  } | null;
};

export type ReferringDoctor_Fragment = {
  __typename?: 'FaradayParkwayStaff';
  uid: string;
  fullName: string;
  mcrNo: string;
  physicianId: string;
  metadata?: any | null;
};

export type ParkwayOrderDetails_Fragment = {
  __typename?: 'FaradayParkwayOrder';
  uid: string;
  accessionNo: string;
  exam: string;
  patientFullName: string;
  scheduledFor?: any | null;
  status: __GraphQLSchemaTypes.ParkwayOrderStatus;
  room: string;
  procedureCode: string;
  patientMrnNo: string;
  vueMotionUrl?: string | null;
};

export type ParkwayOrdersQueryVariables = __GraphQLSchemaTypes.Exact<{
  parkwayOrderSearch?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>;
  procedureCode?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>;
  first?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Int']['input']>;
  offset?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Int']['input']>;
  last?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Int']['input']>;
  before?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>;
  after?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>;
  facility_Uid_In?: __GraphQLSchemaTypes.InputMaybe<
    | Array<__GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>>
    | __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>
  >;
  modality_Uid_In?: __GraphQLSchemaTypes.InputMaybe<
    | Array<__GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>>
    | __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>
  >;
  referringDoctor_McrNo_In?: __GraphQLSchemaTypes.InputMaybe<
    | Array<__GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>>
    | __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>
  >;
  sortBy?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.OrderOrderBy>;
  sortDesc?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Boolean']['input']>;
  status_In?: __GraphQLSchemaTypes.InputMaybe<
    | Array<__GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.ParkwayOrderStatus>>
    | __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.ParkwayOrderStatus>
  >;
}>;

export type ParkwayOrdersQuery = {
  __typename?: 'Query';
  faradayParkwayOrders?: {
    __typename?: 'FaradayParkwayOrderConnection';
    totalCount: number;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      __typename?: 'FaradayParkwayOrderEdge';
      node?: {
        __typename?: 'FaradayParkwayOrder';
        uid: string;
        accessionNo: string;
        exam: string;
        patientFullName: string;
        scheduledFor?: any | null;
        status: __GraphQLSchemaTypes.ParkwayOrderStatus;
        room: string;
        procedureCode: string;
        patientMrnNo: string;
        vueMotionUrl?: string | null;
        modality: { __typename?: 'FaradayModality'; shortName: string; uid: string; name: string };
        facility?: {
          __typename?: 'FaradayFacility';
          uid: string;
          shortCode: string;
          name: string;
          metadata?: any | null;
        } | null;
        referringDoctor?: {
          __typename?: 'FaradayParkwayStaff';
          uid: string;
          fullName: string;
          mcrNo: string;
          physicianId: string;
          metadata?: any | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type ParkwayOrdersCountQueryVariables = __GraphQLSchemaTypes.Exact<{
  parkwayOrderSearch?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>;
  procedureCode?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>;
  facility_Uid_In?: __GraphQLSchemaTypes.InputMaybe<
    | Array<__GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>>
    | __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>
  >;
  modality_Uid_In?: __GraphQLSchemaTypes.InputMaybe<
    | Array<__GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>>
    | __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>
  >;
  referringDoctor_McrNo_In?: __GraphQLSchemaTypes.InputMaybe<
    | Array<__GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>>
    | __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>
  >;
}>;

export type ParkwayOrdersCountQuery = {
  __typename?: 'Query';
  faradayParkwayOrders?: { __typename?: 'FaradayParkwayOrderConnection'; totalCount: number } | null;
};

export type ParkwayOrderByUidQueryVariables = __GraphQLSchemaTypes.Exact<{
  uid?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>;
}>;

export type ParkwayOrderByUidQuery = {
  __typename?: 'Query';
  faradayParkwayOrders?: {
    __typename?: 'FaradayParkwayOrderConnection';
    totalCount: number;
    edges: Array<{
      __typename?: 'FaradayParkwayOrderEdge';
      node?: {
        __typename?: 'FaradayParkwayOrder';
        uid: string;
        accessionNo: string;
        exam: string;
        patientFullName: string;
        scheduledFor?: any | null;
        status: __GraphQLSchemaTypes.ParkwayOrderStatus;
        room: string;
        procedureCode: string;
        patientMrnNo: string;
        vueMotionUrl?: string | null;
        modality: { __typename?: 'FaradayModality'; shortName: string; uid: string; name: string };
        facility?: {
          __typename?: 'FaradayFacility';
          uid: string;
          shortCode: string;
          name: string;
          metadata?: any | null;
        } | null;
        referringDoctor?: {
          __typename?: 'FaradayParkwayStaff';
          uid: string;
          fullName: string;
          mcrNo: string;
          physicianId: string;
          metadata?: any | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type PatientProfilesConnection_Fragment = {
  __typename?: 'FaradayPatientProfileConnection';
  totalCount: number;
  pageInfo: {
    __typename?: 'PageInfo';
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor?: string | null;
    endCursor?: string | null;
  };
};

export type PatientProfileDoctors_Fragment = {
  __typename?: 'FaradayPatientProfile';
  doctors: Array<{
    __typename?: 'FaradayStaffProfile';
    uid: string;
    fullName: string;
    createdOn: any;
    mcrNo?: string | null;
    phone?: string | null;
    email?: string | null;
    designation: string;
    accountType: __GraphQLSchemaTypes.StaffProfileAccountType;
    deactivatedOn?: any | null;
    favoriteExams: Array<{
      __typename?: 'FaradayExam';
      uid: string;
      name: string;
      procedureCode: string;
      modality: { __typename?: 'FaradayModality'; shortName: string; name: string };
    }>;
  }>;
};

export type PatientProfile_Fragment = {
  __typename?: 'FaradayPatientProfile';
  uid: string;
  fullName: string;
  firstName: string;
  lastName: string;
  phone?: string | null;
  gender?: string | null;
  isClaustrophobic: boolean;
  dateOfBirth?: any | null;
  nationalId: string;
  nationality?: string | null;
  deactivatedOn?: any | null;
  comments: string;
  metadata?: any | null;
  doctors: Array<{
    __typename?: 'FaradayStaffProfile';
    uid: string;
    fullName: string;
    createdOn: any;
    mcrNo?: string | null;
    phone?: string | null;
    email?: string | null;
    designation: string;
    accountType: __GraphQLSchemaTypes.StaffProfileAccountType;
    deactivatedOn?: any | null;
    favoriteExams: Array<{
      __typename?: 'FaradayExam';
      uid: string;
      name: string;
      procedureCode: string;
      modality: { __typename?: 'FaradayModality'; shortName: string; name: string };
    }>;
  }>;
};

export type PatientProfileLastOrder_Fragment = {
  __typename?: 'FaradayOrder';
  uid: string;
  createdOn: any;
  updatedOn: any;
  metadata?: any | null;
  patient: { __typename?: 'FaradayPatientProfile'; uid: string; fullName: string };
  pdfForms: Array<{
    __typename?: 'FaradayOrderPDF';
    uid: string;
    createdOn: any;
    pdfForm: any;
    pdfFormMasked: any;
    createdBy: { __typename?: 'FaradayStaffProfile'; uid: string; fullName: string };
  }>;
  createdBy: {
    __typename?: 'FaradayStaffProfile';
    uid: string;
    fullName: string;
    accountType: __GraphQLSchemaTypes.StaffProfileAccountType;
    designation: string;
  };
  orderExams: Array<{ __typename?: 'FaradayOrderExam'; uid: string }>;
};

export type PatientProfilesQueryVariables = __GraphQLSchemaTypes.Exact<{
  sortBy?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.PatientProfileOrderBy>;
  patientSearch?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>;
  sortDesc?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Boolean']['input']>;
  first?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Int']['input']>;
  offset?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Int']['input']>;
  last?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Int']['input']>;
  before?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>;
  after?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>;
  deactivatedOn_Isnull?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Boolean']['input']>;
  doctors_Uid_In?: __GraphQLSchemaTypes.InputMaybe<
    | Array<__GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>>
    | __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>
  >;
  gender_In?: __GraphQLSchemaTypes.InputMaybe<
    | Array<__GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>>
    | __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>
  >;
}>;

export type PatientProfilesQuery = {
  __typename?: 'Query';
  faradayPatientProfiles?: {
    __typename?: 'FaradayPatientProfileConnection';
    totalCount: number;
    edges: Array<{
      __typename?: 'FaradayPatientProfileEdge';
      node?: {
        __typename?: 'FaradayPatientProfile';
        uid: string;
        fullName: string;
        firstName: string;
        lastName: string;
        phone?: string | null;
        gender?: string | null;
        isClaustrophobic: boolean;
        dateOfBirth?: any | null;
        nationalId: string;
        nationality?: string | null;
        deactivatedOn?: any | null;
        comments: string;
        metadata?: any | null;
        lastOrder?: {
          __typename?: 'FaradayOrder';
          uid: string;
          createdOn: any;
          updatedOn: any;
          metadata?: any | null;
          patient: { __typename?: 'FaradayPatientProfile'; uid: string; fullName: string };
          pdfForms: Array<{
            __typename?: 'FaradayOrderPDF';
            uid: string;
            createdOn: any;
            pdfForm: any;
            pdfFormMasked: any;
            createdBy: { __typename?: 'FaradayStaffProfile'; uid: string; fullName: string };
          }>;
          createdBy: {
            __typename?: 'FaradayStaffProfile';
            uid: string;
            fullName: string;
            accountType: __GraphQLSchemaTypes.StaffProfileAccountType;
            designation: string;
          };
          orderExams: Array<{ __typename?: 'FaradayOrderExam'; uid: string }>;
        } | null;
        doctors: Array<{
          __typename?: 'FaradayStaffProfile';
          uid: string;
          fullName: string;
          createdOn: any;
          mcrNo?: string | null;
          phone?: string | null;
          email?: string | null;
          designation: string;
          accountType: __GraphQLSchemaTypes.StaffProfileAccountType;
          deactivatedOn?: any | null;
          favoriteExams: Array<{
            __typename?: 'FaradayExam';
            uid: string;
            name: string;
            procedureCode: string;
            modality: { __typename?: 'FaradayModality'; shortName: string; name: string };
          }>;
        }>;
      } | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
  } | null;
};

export type PatientProfilesExportDataQueryVariables = __GraphQLSchemaTypes.Exact<{
  sortBy?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.PatientProfileOrderBy>;
  patientSearch?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>;
  sortDesc?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Boolean']['input']>;
  first?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Int']['input']>;
  offset?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Int']['input']>;
  last?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Int']['input']>;
  before?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>;
  after?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>;
  deactivatedOn_Isnull?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Boolean']['input']>;
  doctors_Uid_In?: __GraphQLSchemaTypes.InputMaybe<
    | Array<__GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>>
    | __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>
  >;
  gender_In?: __GraphQLSchemaTypes.InputMaybe<
    | Array<__GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>>
    | __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>
  >;
  email?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>;
}>;

export type PatientProfilesExportDataQuery = {
  __typename?: 'Query';
  faradayPatientProfiles?: { __typename?: 'FaradayPatientProfileConnection'; export?: string | null } | null;
};

export type PatientProfileQueryVariables = __GraphQLSchemaTypes.Exact<{ [key: string]: never }>;

export type PatientProfileQuery = {
  __typename?: 'Query';
  faradayPatientProfile?: {
    __typename?: 'FaradayPatientProfile';
    uid: string;
    fullName: string;
    firstName: string;
    lastName: string;
    phone?: string | null;
    gender?: string | null;
    isClaustrophobic: boolean;
    dateOfBirth?: any | null;
    nationalId: string;
    nationality?: string | null;
    deactivatedOn?: any | null;
    comments: string;
    metadata?: any | null;
    lastOrder?: {
      __typename?: 'FaradayOrder';
      uid: string;
      createdOn: any;
      updatedOn: any;
      metadata?: any | null;
      patient: { __typename?: 'FaradayPatientProfile'; uid: string; fullName: string };
      pdfForms: Array<{
        __typename?: 'FaradayOrderPDF';
        uid: string;
        createdOn: any;
        pdfForm: any;
        pdfFormMasked: any;
        createdBy: { __typename?: 'FaradayStaffProfile'; uid: string; fullName: string };
      }>;
      createdBy: {
        __typename?: 'FaradayStaffProfile';
        uid: string;
        fullName: string;
        accountType: __GraphQLSchemaTypes.StaffProfileAccountType;
        designation: string;
      };
      orderExams: Array<{ __typename?: 'FaradayOrderExam'; uid: string }>;
    } | null;
    doctors: Array<{
      __typename?: 'FaradayStaffProfile';
      uid: string;
      fullName: string;
      createdOn: any;
      mcrNo?: string | null;
      phone?: string | null;
      email?: string | null;
      designation: string;
      accountType: __GraphQLSchemaTypes.StaffProfileAccountType;
      deactivatedOn?: any | null;
      favoriteExams: Array<{
        __typename?: 'FaradayExam';
        uid: string;
        name: string;
        procedureCode: string;
        modality: { __typename?: 'FaradayModality'; shortName: string; name: string };
      }>;
    }>;
  } | null;
};

export type PatientProfileByUidQueryVariables = __GraphQLSchemaTypes.Exact<{
  uid: __GraphQLSchemaTypes.Scalars['String']['input'];
}>;

export type PatientProfileByUidQuery = {
  __typename?: 'Query';
  faradayPatientProfile?: {
    __typename?: 'FaradayPatientProfile';
    uid: string;
    fullName: string;
    firstName: string;
    lastName: string;
    phone?: string | null;
    gender?: string | null;
    isClaustrophobic: boolean;
    dateOfBirth?: any | null;
    nationalId: string;
    nationality?: string | null;
    deactivatedOn?: any | null;
    comments: string;
    metadata?: any | null;
    lastOrder?: {
      __typename?: 'FaradayOrder';
      uid: string;
      createdOn: any;
      updatedOn: any;
      metadata?: any | null;
      patient: { __typename?: 'FaradayPatientProfile'; uid: string; fullName: string };
      pdfForms: Array<{
        __typename?: 'FaradayOrderPDF';
        uid: string;
        createdOn: any;
        pdfForm: any;
        pdfFormMasked: any;
        createdBy: { __typename?: 'FaradayStaffProfile'; uid: string; fullName: string };
      }>;
      createdBy: {
        __typename?: 'FaradayStaffProfile';
        uid: string;
        fullName: string;
        accountType: __GraphQLSchemaTypes.StaffProfileAccountType;
        designation: string;
      };
      orderExams: Array<{ __typename?: 'FaradayOrderExam'; uid: string }>;
    } | null;
    doctors: Array<{
      __typename?: 'FaradayStaffProfile';
      uid: string;
      fullName: string;
      createdOn: any;
      mcrNo?: string | null;
      phone?: string | null;
      email?: string | null;
      designation: string;
      accountType: __GraphQLSchemaTypes.StaffProfileAccountType;
      deactivatedOn?: any | null;
      favoriteExams: Array<{
        __typename?: 'FaradayExam';
        uid: string;
        name: string;
        procedureCode: string;
        modality: { __typename?: 'FaradayModality'; shortName: string; name: string };
      }>;
    }>;
  } | null;
};

export type CreatePatientProfileMutationVariables = __GraphQLSchemaTypes.Exact<{
  createInput: __GraphQLSchemaTypes.CreateFaradayPatientProfileInput;
}>;

export type CreatePatientProfileMutation = {
  __typename?: 'Mutation';
  faradayPatientProfileCreate?: {
    __typename?: 'CreateFaradayPatientProfile';
    faradayPatientProfile: {
      __typename?: 'FaradayPatientProfile';
      uid: string;
      fullName: string;
      firstName: string;
      lastName: string;
      phone?: string | null;
      gender?: string | null;
      isClaustrophobic: boolean;
      comments: string;
      doctors: Array<{
        __typename?: 'FaradayStaffProfile';
        uid: string;
        fullName: string;
        createdOn: any;
        mcrNo?: string | null;
        phone?: string | null;
        email?: string | null;
        designation: string;
        accountType: __GraphQLSchemaTypes.StaffProfileAccountType;
        deactivatedOn?: any | null;
        favoriteExams: Array<{
          __typename?: 'FaradayExam';
          uid: string;
          name: string;
          procedureCode: string;
          modality: { __typename?: 'FaradayModality'; shortName: string; name: string };
        }>;
      }>;
    };
  } | null;
};

export type UpdatePatientProfileMutationVariables = __GraphQLSchemaTypes.Exact<{
  updateInput: __GraphQLSchemaTypes.UpdateFaradayPatientProfileInput;
}>;

export type UpdatePatientProfileMutation = {
  __typename?: 'Mutation';
  faradayPatientProfileUpdate?: {
    __typename?: 'UpdateFaradayPatientProfile';
    faradayPatientProfile: {
      __typename?: 'FaradayPatientProfile';
      uid: string;
      fullName: string;
      firstName: string;
      lastName: string;
      phone?: string | null;
      gender?: string | null;
      isClaustrophobic: boolean;
      dateOfBirth?: any | null;
      nationalId: string;
      nationality?: string | null;
      deactivatedOn?: any | null;
      comments: string;
      metadata?: any | null;
      doctors: Array<{
        __typename?: 'FaradayStaffProfile';
        uid: string;
        fullName: string;
        createdOn: any;
        mcrNo?: string | null;
        phone?: string | null;
        email?: string | null;
        designation: string;
        accountType: __GraphQLSchemaTypes.StaffProfileAccountType;
        deactivatedOn?: any | null;
        favoriteExams: Array<{
          __typename?: 'FaradayExam';
          uid: string;
          name: string;
          procedureCode: string;
          modality: { __typename?: 'FaradayModality'; shortName: string; name: string };
        }>;
      }>;
    };
  } | null;
};

export type DeletePatientProfileMutationVariables = __GraphQLSchemaTypes.Exact<{
  deleteInput: __GraphQLSchemaTypes.DeleteFaradayPatientProfileInput;
}>;

export type DeletePatientProfileMutation = {
  __typename?: 'Mutation';
  faradayPatientProfileDelete?: {
    __typename?: 'DeleteFaradayPatientProfile';
    deleteMutationResult: { __typename?: 'DeleteMutationResult'; uid?: string | null; count: number };
  } | null;
};

export type UpdatePatientDoctorRelationshipMutationVariables = __GraphQLSchemaTypes.Exact<{
  updateRelationsInput: __GraphQLSchemaTypes.FaradayPatientDoctorRelationshipsUpdateInput;
}>;

export type UpdatePatientDoctorRelationshipMutation = {
  __typename?: 'Mutation';
  faradayPatientDoctorRelationshipsUpdate?: {
    __typename?: 'FaradayPatientDoctorRelationshipsUpdate';
    patient: {
      __typename?: 'FaradayPatientProfile';
      doctors: Array<{ __typename?: 'FaradayStaffProfile'; uid: string }>;
    };
  } | null;
};

export type PriceListPageInfoConnection_Fragment = {
  __typename?: 'FaradayPriceListsConnection';
  totalCount: number;
  pageInfo: {
    __typename?: 'PageInfo';
    endCursor?: string | null;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor?: string | null;
  };
};

export type CataloguesPageInfoConnection_Fragment = {
  __typename?: 'FaradayCataloguesConnection';
  totalCount: number;
  pageInfo: {
    __typename?: 'PageInfo';
    endCursor?: string | null;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor?: string | null;
  };
};

export type PriceListQueryVariables = __GraphQLSchemaTypes.Exact<{
  priceListSearch?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>;
  first?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Int']['input']>;
  offset?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Int']['input']>;
  last?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Int']['input']>;
  before?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>;
  after?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>;
  modalityUids?: __GraphQLSchemaTypes.InputMaybe<
    Array<__GraphQLSchemaTypes.Scalars['String']['input']> | __GraphQLSchemaTypes.Scalars['String']['input']
  >;
  facilityUids?: __GraphQLSchemaTypes.InputMaybe<
    Array<__GraphQLSchemaTypes.Scalars['String']['input']> | __GraphQLSchemaTypes.Scalars['String']['input']
  >;
}>;

export type PriceListQuery = {
  __typename?: 'Query';
  faradayPriceLists?: {
    __typename?: 'FaradayPriceListsConnection';
    totalCount: number;
    edges: Array<{
      __typename?: 'FaradayPriceListsEdge';
      node?: {
        __typename?: 'FaradayPriceList';
        id?: string | null;
        entry: {
          __typename?: 'EinsteinChernobylEntry';
          uid: string;
          variables: any;
          deactivatedOn?: any | null;
          createdOn: any;
          tags?: any | null;
          aclDnf?: any | null;
          syncStatus: __GraphQLSchemaTypes.DataSourceEntrySyncStatus;
          isActivated: boolean;
        };
      } | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  } | null;
};

export type CatalogueListQueryVariables = __GraphQLSchemaTypes.Exact<{
  catalogueSearch?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>;
  first?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Int']['input']>;
  offset?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Int']['input']>;
  last?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Int']['input']>;
  before?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>;
  after?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>;
  modalityUids?: __GraphQLSchemaTypes.InputMaybe<
    Array<__GraphQLSchemaTypes.Scalars['String']['input']> | __GraphQLSchemaTypes.Scalars['String']['input']
  >;
}>;

export type CatalogueListQuery = {
  __typename?: 'Query';
  faradayCatalogues?: {
    __typename?: 'FaradayCataloguesConnection';
    totalCount: number;
    edges: Array<{
      __typename?: 'FaradayCataloguesEdge';
      node?: {
        __typename?: 'FaradayCatalogue';
        document: {
          __typename?: 'EinsteinDocumentEntry';
          uid: string;
          title: string;
          variables: any;
          pdfFile: any;
          mimeType: string;
          createdOn: any;
          syncStatus: __GraphQLSchemaTypes.DataSourceEntrySyncStatus;
          filename: string;
          tags?: any | null;
          aclDnf?: any | null;
        };
      } | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  } | null;
};

export type CatalogueListCountQueryVariables = __GraphQLSchemaTypes.Exact<{ [key: string]: never }>;

export type CatalogueListCountQuery = {
  __typename?: 'Query';
  faradayCatalogues?: { __typename?: 'FaradayCataloguesConnection'; totalCount: number } | null;
};

export type StaffProfile_Fragment = {
  __typename?: 'FaradayStaffProfile';
  uid: string;
  fullName: string;
  firstName: string;
  lastName: string;
  designation: string;
  accountType: __GraphQLSchemaTypes.StaffProfileAccountType;
  mcrNo?: string | null;
  physicianId?: string | null;
  phone?: string | null;
  email?: string | null;
  createdOn: any;
  deactivatedOn?: any | null;
  adminDoctorRelationships: Array<{
    __typename?: 'FaradayAdminDoctorRelationship';
    id?: string | null;
    admin: { __typename?: 'FaradayStaffProfile'; uid: string; fullName: string };
    doctor: { __typename?: 'FaradayStaffProfile'; uid: string; fullName: string };
  }>;
  favoriteExams: Array<{
    __typename?: 'FaradayExam';
    uid: string;
    name: string;
    duration: number;
    isRetired: boolean;
    metadata?: any | null;
    disclaimerMessages?: Array<string> | null;
    modality: { __typename?: 'FaradayModality'; name: string; shortName: string };
    additionalFieldTemplates?: Array<{
      __typename?: 'FaradayAdditionalFieldTemplate';
      id?: string | null;
      key: string;
      name: string;
      description?: string | null;
      categories: Array<{
        __typename?: 'FaradayAditionalFieldCategory';
        id?: string | null;
        key: string;
        name: string;
        description?: string | null;
        fields: Array<{
          __typename?: 'FaradayAdditionalField';
          key: string;
          id?: string | null;
          name: string;
          type: string;
          default?: string | null;
        }>;
      }>;
    }> | null;
  }>;
};

export type AdminDoctorRelationship_Fragment = {
  __typename?: 'FaradayAdminDoctorRelationship';
  id?: string | null;
  admin: { __typename?: 'FaradayStaffProfile'; uid: string; fullName: string };
  doctor: { __typename?: 'FaradayStaffProfile'; uid: string; fullName: string };
};

export type FavoriteExams_Fragment = {
  __typename?: 'FaradayStaffProfile';
  favoriteExams: Array<{
    __typename?: 'FaradayExam';
    uid: string;
    name: string;
    duration: number;
    isRetired: boolean;
    metadata?: any | null;
    disclaimerMessages?: Array<string> | null;
    modality: { __typename?: 'FaradayModality'; name: string; shortName: string };
    additionalFieldTemplates?: Array<{
      __typename?: 'FaradayAdditionalFieldTemplate';
      id?: string | null;
      key: string;
      name: string;
      description?: string | null;
      categories: Array<{
        __typename?: 'FaradayAditionalFieldCategory';
        id?: string | null;
        key: string;
        name: string;
        description?: string | null;
        fields: Array<{
          __typename?: 'FaradayAdditionalField';
          key: string;
          id?: string | null;
          name: string;
          type: string;
          default?: string | null;
        }>;
      }>;
    }> | null;
  }>;
};

export type StaffProfileConnection_Fragment = {
  __typename?: 'FaradayStaffProfileConnection';
  totalCount: number;
  pageInfo: {
    __typename?: 'PageInfo';
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor?: string | null;
    endCursor?: string | null;
  };
};

export type StaffProfileQueryVariables = __GraphQLSchemaTypes.Exact<{ [key: string]: never }>;

export type StaffProfileQuery = {
  __typename?: 'Query';
  faradayStaffProfile?: {
    __typename?: 'FaradayStaffProfile';
    uid: string;
    fullName: string;
    firstName: string;
    lastName: string;
    designation: string;
    accountType: __GraphQLSchemaTypes.StaffProfileAccountType;
    mcrNo?: string | null;
    physicianId?: string | null;
    phone?: string | null;
    email?: string | null;
    createdOn: any;
    deactivatedOn?: any | null;
    clinic: {
      __typename?: 'FaradayClinic';
      uid: string;
      domain: string;
      address: string;
      name: string;
      hciCode: string;
      phone: string;
    };
    adminDoctorRelationships: Array<{
      __typename?: 'FaradayAdminDoctorRelationship';
      id?: string | null;
      admin: { __typename?: 'FaradayStaffProfile'; uid: string; fullName: string };
      doctor: { __typename?: 'FaradayStaffProfile'; uid: string; fullName: string };
    }>;
    favoriteExams: Array<{
      __typename?: 'FaradayExam';
      uid: string;
      name: string;
      duration: number;
      isRetired: boolean;
      metadata?: any | null;
      disclaimerMessages?: Array<string> | null;
      modality: { __typename?: 'FaradayModality'; name: string; shortName: string };
      additionalFieldTemplates?: Array<{
        __typename?: 'FaradayAdditionalFieldTemplate';
        id?: string | null;
        key: string;
        name: string;
        description?: string | null;
        categories: Array<{
          __typename?: 'FaradayAditionalFieldCategory';
          id?: string | null;
          key: string;
          name: string;
          description?: string | null;
          fields: Array<{
            __typename?: 'FaradayAdditionalField';
            key: string;
            id?: string | null;
            name: string;
            type: string;
            default?: string | null;
          }>;
        }>;
      }> | null;
    }>;
  } | null;
};

export type StaffProfileByUidQueryVariables = __GraphQLSchemaTypes.Exact<{
  uid: __GraphQLSchemaTypes.Scalars['String']['input'];
}>;

export type StaffProfileByUidQuery = {
  __typename?: 'Query';
  faradayStaffProfile?: {
    __typename?: 'FaradayStaffProfile';
    uid: string;
    fullName: string;
    firstName: string;
    lastName: string;
    designation: string;
    accountType: __GraphQLSchemaTypes.StaffProfileAccountType;
    mcrNo?: string | null;
    physicianId?: string | null;
    phone?: string | null;
    email?: string | null;
    createdOn: any;
    deactivatedOn?: any | null;
    clinic: {
      __typename?: 'FaradayClinic';
      uid: string;
      domain: string;
      address: string;
      name: string;
      hciCode: string;
      phone: string;
    };
    adminDoctorRelationships: Array<{
      __typename?: 'FaradayAdminDoctorRelationship';
      id?: string | null;
      admin: { __typename?: 'FaradayStaffProfile'; uid: string; fullName: string };
      doctor: { __typename?: 'FaradayStaffProfile'; uid: string; fullName: string };
    }>;
    favoriteExams: Array<{
      __typename?: 'FaradayExam';
      uid: string;
      name: string;
      duration: number;
      isRetired: boolean;
      metadata?: any | null;
      disclaimerMessages?: Array<string> | null;
      modality: { __typename?: 'FaradayModality'; name: string; shortName: string };
      additionalFieldTemplates?: Array<{
        __typename?: 'FaradayAdditionalFieldTemplate';
        id?: string | null;
        key: string;
        name: string;
        description?: string | null;
        categories: Array<{
          __typename?: 'FaradayAditionalFieldCategory';
          id?: string | null;
          key: string;
          name: string;
          description?: string | null;
          fields: Array<{
            __typename?: 'FaradayAdditionalField';
            key: string;
            id?: string | null;
            name: string;
            type: string;
            default?: string | null;
          }>;
        }>;
      }> | null;
    }>;
  } | null;
};

export type StaffProfilesQueryVariables = __GraphQLSchemaTypes.Exact<{
  sortBy?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.StaffProfileOrderBy>;
  staffSearch?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>;
  sortDesc?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Boolean']['input']>;
  first?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Int']['input']>;
  offset?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Int']['input']>;
  last?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Int']['input']>;
  before?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>;
  after?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>;
  accountType_In?: __GraphQLSchemaTypes.InputMaybe<
    | Array<__GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.StaffProfileAccountType>>
    | __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.StaffProfileAccountType>
  >;
  deactivatedOn_Isnull?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Boolean']['input']>;
}>;

export type StaffProfilesQuery = {
  __typename?: 'Query';
  faradayStaffProfiles?: {
    __typename?: 'FaradayStaffProfileConnection';
    totalCount: number;
    edges: Array<{
      __typename?: 'FaradayStaffProfileEdge';
      node?: {
        __typename?: 'FaradayStaffProfile';
        uid: string;
        fullName: string;
        firstName: string;
        lastName: string;
        designation: string;
        accountType: __GraphQLSchemaTypes.StaffProfileAccountType;
        mcrNo?: string | null;
        physicianId?: string | null;
        phone?: string | null;
        email?: string | null;
        createdOn: any;
        deactivatedOn?: any | null;
        clinic: {
          __typename?: 'FaradayClinic';
          uid: string;
          domain: string;
          address: string;
          name: string;
          hciCode: string;
          phone: string;
        };
        adminDoctorRelationships: Array<{
          __typename?: 'FaradayAdminDoctorRelationship';
          id?: string | null;
          admin: { __typename?: 'FaradayStaffProfile'; uid: string; fullName: string };
          doctor: { __typename?: 'FaradayStaffProfile'; uid: string; fullName: string };
        }>;
        favoriteExams: Array<{
          __typename?: 'FaradayExam';
          uid: string;
          name: string;
          duration: number;
          isRetired: boolean;
          metadata?: any | null;
          disclaimerMessages?: Array<string> | null;
          modality: { __typename?: 'FaradayModality'; name: string; shortName: string };
          additionalFieldTemplates?: Array<{
            __typename?: 'FaradayAdditionalFieldTemplate';
            id?: string | null;
            key: string;
            name: string;
            description?: string | null;
            categories: Array<{
              __typename?: 'FaradayAditionalFieldCategory';
              id?: string | null;
              key: string;
              name: string;
              description?: string | null;
              fields: Array<{
                __typename?: 'FaradayAdditionalField';
                key: string;
                id?: string | null;
                name: string;
                type: string;
                default?: string | null;
              }>;
            }>;
          }> | null;
        }>;
      } | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
  } | null;
};

export type StaffProfilesUsersCountQueryVariables = __GraphQLSchemaTypes.Exact<{
  sortBy?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.StaffProfileOrderBy>;
  staffSearch?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>;
  sortDesc?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Boolean']['input']>;
  first?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Int']['input']>;
  offset?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Int']['input']>;
  last?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Int']['input']>;
  before?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>;
}>;

export type StaffProfilesUsersCountQuery = {
  __typename?: 'Query';
  faradayStaffProfiles?: { __typename?: 'FaradayStaffProfileConnection'; totalCount: number } | null;
};

export type StaffProfilesPendingRequestCountQueryVariables = __GraphQLSchemaTypes.Exact<{
  sortBy?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.StaffProfileOrderBy>;
  staffSearch?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>;
  sortDesc?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Boolean']['input']>;
  first?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Int']['input']>;
  offset?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Int']['input']>;
  last?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['Int']['input']>;
  before?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>;
}>;

export type StaffProfilesPendingRequestCountQuery = {
  __typename?: 'Query';
  faradayStaffProfiles?: { __typename?: 'FaradayStaffProfileConnection'; totalCount: number } | null;
};

export type CreateStaffProfileMutationVariables = __GraphQLSchemaTypes.Exact<{
  createInput: __GraphQLSchemaTypes.CreateFaradayStaffProfileInput;
}>;

export type CreateStaffProfileMutation = {
  __typename?: 'Mutation';
  faradayStaffProfileCreate?: {
    __typename?: 'CreateFaradayStaffProfile';
    faradayStaffProfile: {
      __typename?: 'FaradayStaffProfile';
      uid: string;
      fullName: string;
      firstName: string;
      lastName: string;
      designation: string;
      accountType: __GraphQLSchemaTypes.StaffProfileAccountType;
      mcrNo?: string | null;
      physicianId?: string | null;
      phone?: string | null;
      email?: string | null;
    };
  } | null;
};

export type BulkCreateStaffProfileMutationVariables = __GraphQLSchemaTypes.Exact<{
  bulkCreateStaffProfiles: __GraphQLSchemaTypes.FaradayBulkCreateStaffProfileInput;
}>;

export type BulkCreateStaffProfileMutation = {
  __typename?: 'Mutation';
  faradayBulkCreateStaffProfile?: {
    __typename?: 'FaradayBulkCreateStaffProfile';
    status: __GraphQLSchemaTypes.CreationStatus;
    totalCount: number;
    errorCount: number;
    creationStatus: Array<{
      __typename?: 'FaradayStaffProfileCreationStatus';
      status: __GraphQLSchemaTypes.CreationStatus;
      info?: string | null;
    }>;
  } | null;
};

export type UpdateStaffProfileMutationVariables = __GraphQLSchemaTypes.Exact<{
  updateInput: __GraphQLSchemaTypes.UpdateFaradayStaffProfileInput;
}>;

export type UpdateStaffProfileMutation = {
  __typename?: 'Mutation';
  faradayStaffProfileUpdate?: {
    __typename?: 'UpdateFaradayStaffProfile';
    faradayStaffProfile: {
      __typename?: 'FaradayStaffProfile';
      uid: string;
      fullName: string;
      firstName: string;
      lastName: string;
      designation: string;
      accountType: __GraphQLSchemaTypes.StaffProfileAccountType;
      mcrNo?: string | null;
      physicianId?: string | null;
      phone?: string | null;
      email?: string | null;
      createdOn: any;
      deactivatedOn?: any | null;
      adminDoctorRelationships: Array<{
        __typename?: 'FaradayAdminDoctorRelationship';
        id?: string | null;
        admin: { __typename?: 'FaradayStaffProfile'; uid: string; fullName: string };
        doctor: { __typename?: 'FaradayStaffProfile'; uid: string; fullName: string };
      }>;
      favoriteExams: Array<{
        __typename?: 'FaradayExam';
        uid: string;
        name: string;
        duration: number;
        isRetired: boolean;
        metadata?: any | null;
        disclaimerMessages?: Array<string> | null;
        modality: { __typename?: 'FaradayModality'; name: string; shortName: string };
        additionalFieldTemplates?: Array<{
          __typename?: 'FaradayAdditionalFieldTemplate';
          id?: string | null;
          key: string;
          name: string;
          description?: string | null;
          categories: Array<{
            __typename?: 'FaradayAditionalFieldCategory';
            id?: string | null;
            key: string;
            name: string;
            description?: string | null;
            fields: Array<{
              __typename?: 'FaradayAdditionalField';
              key: string;
              id?: string | null;
              name: string;
              type: string;
              default?: string | null;
            }>;
          }>;
        }> | null;
      }>;
    };
  } | null;
};

export type DeleteStaffProfileMutationVariables = __GraphQLSchemaTypes.Exact<{
  deleteInput: __GraphQLSchemaTypes.DeleteFaradayStaffProfileInput;
}>;

export type DeleteStaffProfileMutation = {
  __typename?: 'Mutation';
  faradayStaffProfileDelete?: {
    __typename?: 'DeleteFaradayStaffProfile';
    deleteMutationResult: { __typename?: 'DeleteMutationResult'; uid?: string | null; count: number };
  } | null;
};

export type UpdateAdminDoctorRelationshipsMutationVariables = __GraphQLSchemaTypes.Exact<{
  updateRelationshipsInput: __GraphQLSchemaTypes.FaradayAdminDoctorRelationshipsUpdateInput;
}>;

export type UpdateAdminDoctorRelationshipsMutation = {
  __typename?: 'Mutation';
  faradayAdminDoctorRelationshipsUpdate?: {
    __typename?: 'FaradayAdminDoctorRelationshipsUpdate';
    adminDoctorRelationships: Array<{
      __typename?: 'FaradayAdminDoctorRelationship';
      admin: {
        __typename?: 'FaradayStaffProfile';
        uid: string;
        fullName: string;
        accountType: __GraphQLSchemaTypes.StaffProfileAccountType;
      };
      doctor: {
        __typename?: 'FaradayStaffProfile';
        uid: string;
        fullName: string;
        accountType: __GraphQLSchemaTypes.StaffProfileAccountType;
      };
    }>;
  } | null;
};

export type UpdateFavoriteExamsUpdateMutationVariables = __GraphQLSchemaTypes.Exact<{
  input: __GraphQLSchemaTypes.FaradayStaffFavoriteExamsUpdateInput;
}>;

export type UpdateFavoriteExamsUpdateMutation = {
  __typename?: 'Mutation';
  faradayStaffFavoriteExamsUpdate?: {
    __typename?: 'FaradayStaffFavoriteExamsUpdate';
    favoriteExams: Array<{
      __typename?: 'FaradayStaffFavoriteExam';
      id?: string | null;
      exam: { __typename?: 'FaradayExam'; uid: string; name: string };
    }>;
  } | null;
};

export const ModalityDetails_FragmentDoc = `
    fragment modalityDetails_ on FaradayModality {
  name
  shortName
}
    `;
export const Facility_FragmentDoc = `
    fragment facility_ on FaradayFacility {
  uid
  name
  metadata
  shortCode
  disclaimerMessages
  rooms {
    uid
    name
    metadata
    modality {
      ...modalityDetails_
    }
  }
}
    ${ModalityDetails_FragmentDoc}`;
export const AvailabilityRoom_FragmentDoc = `
    fragment availabilityRoom_ on FaradayRoom {
  uid
  name
  metadata
  name
  aeTitle
  modality {
    ...modalityDetails_
  }
  facility {
    ...facility_
  }
}
    ${ModalityDetails_FragmentDoc}
${Facility_FragmentDoc}`;
export const AvailabilitySlots_FragmentDoc = `
    fragment availabilitySlots_ on FaradayAvailabilitySlot {
  id
  date
  times
}
    `;
export const AsyncJob_FragmentDoc = `
    fragment asyncJob_ on KronosAsyncJob {
  uid
  status
  createdOn
  results
  isReady
}
    `;
export const Clinic_FragmentDoc = `
    fragment clinic_ on FaradayClinic {
  uid
  domain
  address
  name
  hciCode
  phone
}
    `;
export const AdditionalFieldTemplates_FragmentDoc = `
    fragment additionalFieldTemplates_ on FaradayAdditionalFieldTemplate {
  id
  key
  name
  description
  categories {
    id
    key
    name
    description
    fields {
      key
      id
      name
      type
      default
    }
  }
}
    `;
export const FavoriteExams_FragmentDoc = `
    fragment favoriteExams_ on FaradayStaffProfile {
  favoriteExams {
    uid
    name
    duration
    isRetired
    metadata
    disclaimerMessages
    modality {
      ...modalityDetails_
    }
    additionalFieldTemplates {
      ...additionalFieldTemplates_
    }
  }
}
    ${ModalityDetails_FragmentDoc}
${AdditionalFieldTemplates_FragmentDoc}`;
export const Staffs_FragmentDoc = `
    fragment staffs_ on FaradayClinic {
  staffs {
    uid
    accountType
    createdOn
    deactivatedOn
    email
    ...favoriteExams_
  }
}
    ${FavoriteExams_FragmentDoc}`;
export const ClinicsConnection_FragmentDoc = `
    fragment clinicsConnection_ on FaradayClinicConnection {
  totalCount
  pageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
}
    `;
export const Exam_FragmentDoc = `
    fragment exam_ on FaradayExam {
  uid
  name
  duration
  isRetired
  disclaimerMessages
  modality {
    uid
    shortName
  }
  additionalFieldTemplates {
    ...additionalFieldTemplates_
  }
}
    ${AdditionalFieldTemplates_FragmentDoc}`;
export const MessageTemplate_FragmentDoc = `
    fragment messageTemplate_ on FaradayMessageTemplate {
  uid
  name
  template
}
    `;
export const OrdersConnection_FragmentDoc = `
    fragment ordersConnection_ on FaradayOrderConnection {
  totalCount
  pageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
}
    `;
export const OrdersPatient_FragmentDoc = `
    fragment ordersPatient_ on FaradayPatientProfile {
  uid
  fullName
  firstName
  lastName
  phone
  gender
  isClaustrophobic
  dateOfBirth
  nationalId
  deactivatedOn
  comments
  doctors {
    uid
    designation
    accountType
  }
}
    `;
export const OrderExam_FragmentDoc = `
    fragment orderExam_ on FaradayOrderExam {
  uid
  accessionNo
  vueMotionUrl
  imageReadyOn
  reportReadyOn
  lastRemindedOn
  status
  updatedOn
  createdOn
  deactivatedOn
  scheduledFor
  duration
  metadata
  exam {
    uid
    duration
    name
    metadata
    additionalFieldTemplates {
      ...additionalFieldTemplates_
    }
    modality {
      shortName
      name
    }
    disclaimerMessages
    procedureCode
  }
}
    ${AdditionalFieldTemplates_FragmentDoc}`;
export const OrdersExams_FragmentDoc = `
    fragment ordersExams_ on FaradayOrderExam {
  ...orderExam_
  room {
    uid
    name
    metadata
    modality {
      name
      uid
      shortName
    }
    facility {
      uid
      name
      metadata
    }
  }
}
    ${OrderExam_FragmentDoc}`;
export const OrderPdfForms_FragmentDoc = `
    fragment orderPdfForms_ on FaradayOrderPDF {
  uid
  pdfForm
  pdfFormMasked
  createdOn
  createdBy {
    uid
    fullName
    accountType
  }
}
    `;
export const OrderCreatedBy_FragmentDoc = `
    fragment orderCreatedBy_ on FaradayStaffProfile {
  uid
  clinic {
    uid
    name
  }
  fullName
  accountType
  designation
}
    `;
export const OrderCreatedFor_FragmentDoc = `
    fragment orderCreatedFor_ on FaradayStaffProfile {
  uid
  clinic {
    uid
    name
  }
  fullName
  accountType
  designation
}
    `;
export const PatientProfileDoctors_FragmentDoc = `
    fragment patientProfileDoctors_ on FaradayPatientProfile {
  doctors {
    uid
    fullName
    createdOn
    mcrNo
    phone
    email
    designation
    accountType
    deactivatedOn
    favoriteExams {
      uid
      name
      procedureCode
      modality {
        shortName
        name
      }
    }
  }
}
    `;
export const PatientProfile_FragmentDoc = `
    fragment patientProfile_ on FaradayPatientProfile {
  uid
  fullName
  firstName
  lastName
  phone
  gender
  isClaustrophobic
  dateOfBirth
  nationalId
  nationality
  deactivatedOn
  comments
  metadata
  ...patientProfileDoctors_
}
    ${PatientProfileDoctors_FragmentDoc}`;
export const OrderDetails_FragmentDoc = `
    fragment orderDetails_ on FaradayOrder {
  uid
  createdOn
  updatedOn
  metadata
  orderExams {
    ...ordersExams_
  }
  pdfForms {
    ...orderPdfForms_
  }
  createdBy {
    ...orderCreatedBy_
  }
  createdFor {
    ...orderCreatedFor_
  }
  patient {
    ...patientProfile_
  }
}
    ${OrdersExams_FragmentDoc}
${OrderPdfForms_FragmentDoc}
${OrderCreatedBy_FragmentDoc}
${OrderCreatedFor_FragmentDoc}
${PatientProfile_FragmentDoc}`;
export const OrderDraft_FragmentDoc = `
    fragment orderDraft_ on FaradayOrderDraft {
  uid
  deactivatedOn
  draft
  createdOn
  updatedOn
  createdBy {
    uid
    fullName
  }
}
    `;
export const OrderExamsConnection_FragmentDoc = `
    fragment orderExamsConnection_ on FaradayOrderExamConnection {
  totalCount
  pageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
}
    `;
export const ReferringDoctor_FragmentDoc = `
    fragment referringDoctor_ on FaradayParkwayStaff {
  uid
  fullName
  mcrNo
  physicianId
  metadata
}
    `;
export const ParkwayOrderDetails_FragmentDoc = `
    fragment parkwayOrderDetails_ on FaradayParkwayOrder {
  uid
  accessionNo
  exam
  patientFullName
  scheduledFor
  status
  room
  procedureCode
  patientMrnNo
  vueMotionUrl
}
    `;
export const PatientProfilesConnection_FragmentDoc = `
    fragment patientProfilesConnection_ on FaradayPatientProfileConnection {
  totalCount
  pageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
}
    `;
export const PatientProfileLastOrder_FragmentDoc = `
    fragment patientProfileLastOrder_ on FaradayOrder {
  uid
  createdOn
  updatedOn
  metadata
  patient {
    uid
    fullName
  }
  pdfForms {
    uid
    createdOn
    createdBy {
      uid
      fullName
    }
    pdfForm
    pdfFormMasked
  }
  createdBy {
    uid
    fullName
    accountType
    designation
  }
  orderExams {
    uid
  }
}
    `;
export const PriceListPageInfoConnection_FragmentDoc = `
    fragment priceListPageInfoConnection_ on FaradayPriceListsConnection {
  pageInfo {
    endCursor
    hasNextPage
    hasPreviousPage
    startCursor
  }
  totalCount
}
    `;
export const CataloguesPageInfoConnection_FragmentDoc = `
    fragment cataloguesPageInfoConnection_ on FaradayCataloguesConnection {
  pageInfo {
    endCursor
    hasNextPage
    hasPreviousPage
    startCursor
  }
  totalCount
}
    `;
export const AdminDoctorRelationship_FragmentDoc = `
    fragment adminDoctorRelationship_ on FaradayAdminDoctorRelationship {
  id
  admin {
    uid
    fullName
  }
  doctor {
    uid
    fullName
  }
}
    `;
export const StaffProfile_FragmentDoc = `
    fragment staffProfile_ on FaradayStaffProfile {
  uid
  fullName
  firstName
  lastName
  designation
  accountType
  mcrNo
  physicianId
  phone
  email
  createdOn
  deactivatedOn
  adminDoctorRelationships {
    ...adminDoctorRelationship_
  }
  ...favoriteExams_
}
    ${AdminDoctorRelationship_FragmentDoc}
${FavoriteExams_FragmentDoc}`;
export const StaffProfileConnection_FragmentDoc = `
    fragment staffProfileConnection_ on FaradayStaffProfileConnection {
  totalCount
  pageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
}
    `;
export const AvalibilityDocument = `
    query Avalibility($startDatetime: DateTime, $endDatetime: DateTime, $examUid: String!, $facilityUid: String) {
  faradayAvailability(
    examUid: $examUid
    facilityUid: $facilityUid
    startDatetime: $startDatetime
    endDatetime: $endDatetime
  ) {
    results {
      exam {
        duration
        uid
        name
        disclaimerMessages
        metadata
      }
      room {
        uid
        name
        metadata
        name
        aeTitle
        facility {
          uid
          name
        }
      }
      slots {
        ...availabilitySlots_
      }
    }
  }
}
    ${AvailabilitySlots_FragmentDoc}`;
export const useAvalibilityQuery = <TData = __GraphQLSchemaTypes.AvalibilityQuery, TError = unknown>(
  client: GraphQLClient,
  variables: __GraphQLSchemaTypes.AvalibilityQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.AvalibilityQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<__GraphQLSchemaTypes.AvalibilityQuery, TError, TData>(
    ['Avalibility', variables],
    fetcher<__GraphQLSchemaTypes.AvalibilityQuery, __GraphQLSchemaTypes.AvalibilityQueryVariables>(
      client,
      AvalibilityDocument,
      variables,
      headers
    ),
    options
  );
export const AvalibilityAsyncDocument = `
    query avalibilityAsync($asyncJobUid: String, $examUid: String, $facilityUid: String, $startDatetime: DateTime, $endDatetime: DateTime) {
  faradayAvailabilityAsync(
    asyncJobUid: $asyncJobUid
    examUid: $examUid
    facilityUid: $facilityUid
    startDatetime: $startDatetime
    endDatetime: $endDatetime
  ) {
    asyncJob {
      ...asyncJob_
    }
    results {
      exam {
        uid
        duration
      }
      room {
        ...availabilityRoom_
      }
      slots {
        ...availabilitySlots_
      }
    }
  }
}
    ${AsyncJob_FragmentDoc}
${AvailabilityRoom_FragmentDoc}
${AvailabilitySlots_FragmentDoc}`;
export const useAvalibilityAsyncQuery = <TData = __GraphQLSchemaTypes.AvalibilityAsyncQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: __GraphQLSchemaTypes.AvalibilityAsyncQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.AvalibilityAsyncQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<__GraphQLSchemaTypes.AvalibilityAsyncQuery, TError, TData>(
    variables === undefined ? ['avalibilityAsync'] : ['avalibilityAsync', variables],
    fetcher<__GraphQLSchemaTypes.AvalibilityAsyncQuery, __GraphQLSchemaTypes.AvalibilityAsyncQueryVariables>(
      client,
      AvalibilityAsyncDocument,
      variables,
      headers
    ),
    options
  );
export const FacilityAvailabilityDocument = `
    query FacilityAvailability($examUids: [String!]!) {
  faradayFacilityAvailability(examUids: $examUids) {
    exams {
      uid
      name
      disclaimerMessages
      modality {
        shortName
        name
      }
    }
    facility {
      ...facility_
    }
  }
}
    ${Facility_FragmentDoc}`;
export const useFacilityAvailabilityQuery = <
  TData = __GraphQLSchemaTypes.FacilityAvailabilityQuery,
  TError = unknown
>(
  client: GraphQLClient,
  variables: __GraphQLSchemaTypes.FacilityAvailabilityQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.FacilityAvailabilityQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<__GraphQLSchemaTypes.FacilityAvailabilityQuery, TError, TData>(
    ['FacilityAvailability', variables],
    fetcher<
      __GraphQLSchemaTypes.FacilityAvailabilityQuery,
      __GraphQLSchemaTypes.FacilityAvailabilityQueryVariables
    >(client, FacilityAvailabilityDocument, variables, headers),
    options
  );
export const ExamAvailabilityDocument = `
    query ExamAvailability($examSearch: String) {
  faradayExamAvailability(examSearch: $examSearch) {
    exam {
      ...exam_
      modality {
        ...modalityDetails_
      }
    }
  }
}
    ${Exam_FragmentDoc}
${ModalityDetails_FragmentDoc}`;
export const useExamAvailabilityQuery = <TData = __GraphQLSchemaTypes.ExamAvailabilityQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: __GraphQLSchemaTypes.ExamAvailabilityQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.ExamAvailabilityQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<__GraphQLSchemaTypes.ExamAvailabilityQuery, TError, TData>(
    variables === undefined ? ['ExamAvailability'] : ['ExamAvailability', variables],
    fetcher<__GraphQLSchemaTypes.ExamAvailabilityQuery, __GraphQLSchemaTypes.ExamAvailabilityQueryVariables>(
      client,
      ExamAvailabilityDocument,
      variables,
      headers
    ),
    options
  );
export const ClinicsDocument = `
    query clinics {
  faradayClinics {
    ...clinicsConnection_
    edges {
      node {
        ...clinic_
        ...staffs_
      }
    }
  }
}
    ${ClinicsConnection_FragmentDoc}
${Clinic_FragmentDoc}
${Staffs_FragmentDoc}`;
export const useClinicsQuery = <TData = __GraphQLSchemaTypes.ClinicsQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: __GraphQLSchemaTypes.ClinicsQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.ClinicsQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<__GraphQLSchemaTypes.ClinicsQuery, TError, TData>(
    variables === undefined ? ['clinics'] : ['clinics', variables],
    fetcher<__GraphQLSchemaTypes.ClinicsQuery, __GraphQLSchemaTypes.ClinicsQueryVariables>(
      client,
      ClinicsDocument,
      variables,
      headers
    ),
    options
  );
export const ClinicDocument = `
    query clinic {
  faradayClinic {
    ...clinic_
    ...staffs_
  }
}
    ${Clinic_FragmentDoc}
${Staffs_FragmentDoc}`;
export const useClinicQuery = <TData = __GraphQLSchemaTypes.ClinicQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: __GraphQLSchemaTypes.ClinicQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.ClinicQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<__GraphQLSchemaTypes.ClinicQuery, TError, TData>(
    variables === undefined ? ['clinic'] : ['clinic', variables],
    fetcher<__GraphQLSchemaTypes.ClinicQuery, __GraphQLSchemaTypes.ClinicQueryVariables>(
      client,
      ClinicDocument,
      variables,
      headers
    ),
    options
  );
export const RegisterDeviceDocument = `
    mutation registerDevice($input: FaradayRegisterDeviceInput!) {
  faradayRegisterDevice(input: $input) {
    device {
      uid
      createdOn
      metadata
      id
      session {
        uid
      }
      lastActiveOn
    }
    staffProfile {
      uid
    }
  }
}
    `;
export const useRegisterDeviceMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    __GraphQLSchemaTypes.RegisterDeviceMutation,
    TError,
    __GraphQLSchemaTypes.RegisterDeviceMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<
    __GraphQLSchemaTypes.RegisterDeviceMutation,
    TError,
    __GraphQLSchemaTypes.RegisterDeviceMutationVariables,
    TContext
  >(
    ['registerDevice'],
    (variables?: __GraphQLSchemaTypes.RegisterDeviceMutationVariables) =>
      fetcher<
        __GraphQLSchemaTypes.RegisterDeviceMutation,
        __GraphQLSchemaTypes.RegisterDeviceMutationVariables
      >(client, RegisterDeviceDocument, variables, headers)(),
    options
  );
export const ExamsDocument = `
    query Exams($name_Icontains: String, $modalities: [String!], $first: Int, $offset: Int, $last: Int, $before: String, $after: String, $deactivatedOn_Isnull: Boolean) {
  faradayExams(
    name_Icontains: $name_Icontains
    modalities: $modalities
    first: $first
    offset: $offset
    last: $last
    before: $before
    after: $after
    deactivatedOn_Isnull: $deactivatedOn_Isnull
  ) {
    totalCount
    edges {
      node {
        ...exam_
        modality {
          ...modalityDetails_
        }
      }
    }
  }
}
    ${Exam_FragmentDoc}
${ModalityDetails_FragmentDoc}`;
export const useExamsQuery = <TData = __GraphQLSchemaTypes.ExamsQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: __GraphQLSchemaTypes.ExamsQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.ExamsQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<__GraphQLSchemaTypes.ExamsQuery, TError, TData>(
    variables === undefined ? ['Exams'] : ['Exams', variables],
    fetcher<__GraphQLSchemaTypes.ExamsQuery, __GraphQLSchemaTypes.ExamsQueryVariables>(
      client,
      ExamsDocument,
      variables,
      headers
    ),
    options
  );
export const ExamByUidDocument = `
    query ExamByUid($uid: String!) {
  faradayExam(uid: $uid) {
    ...exam_
    modality {
      ...modalityDetails_
    }
  }
}
    ${Exam_FragmentDoc}
${ModalityDetails_FragmentDoc}`;
export const useExamByUidQuery = <TData = __GraphQLSchemaTypes.ExamByUidQuery, TError = unknown>(
  client: GraphQLClient,
  variables: __GraphQLSchemaTypes.ExamByUidQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.ExamByUidQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<__GraphQLSchemaTypes.ExamByUidQuery, TError, TData>(
    ['ExamByUid', variables],
    fetcher<__GraphQLSchemaTypes.ExamByUidQuery, __GraphQLSchemaTypes.ExamByUidQueryVariables>(
      client,
      ExamByUidDocument,
      variables,
      headers
    ),
    options
  );
export const FacilitiesDocument = `
    query facilities {
  faradayFacilities {
    edges {
      node {
        ...facility_
      }
    }
  }
}
    ${Facility_FragmentDoc}`;
export const useFacilitiesQuery = <TData = __GraphQLSchemaTypes.FacilitiesQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: __GraphQLSchemaTypes.FacilitiesQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.FacilitiesQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<__GraphQLSchemaTypes.FacilitiesQuery, TError, TData>(
    variables === undefined ? ['facilities'] : ['facilities', variables],
    fetcher<__GraphQLSchemaTypes.FacilitiesQuery, __GraphQLSchemaTypes.FacilitiesQueryVariables>(
      client,
      FacilitiesDocument,
      variables,
      headers
    ),
    options
  );
export const FacilityByUidDocument = `
    query FacilityByUid($uid: String!) {
  faradayFacility(uid: $uid) {
    ...facility_
  }
}
    ${Facility_FragmentDoc}`;
export const useFacilityByUidQuery = <TData = __GraphQLSchemaTypes.FacilityByUidQuery, TError = unknown>(
  client: GraphQLClient,
  variables: __GraphQLSchemaTypes.FacilityByUidQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.FacilityByUidQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<__GraphQLSchemaTypes.FacilityByUidQuery, TError, TData>(
    ['FacilityByUid', variables],
    fetcher<__GraphQLSchemaTypes.FacilityByUidQuery, __GraphQLSchemaTypes.FacilityByUidQueryVariables>(
      client,
      FacilityByUidDocument,
      variables,
      headers
    ),
    options
  );
export const HeimdallWithSessionDocument = `
    query HeimdallWithSession {
  heimdallSession {
    id
    uid
    user {
      uid
    }
    scopes
    expiresOn
  }
}
    `;
export const useHeimdallWithSessionQuery = <
  TData = __GraphQLSchemaTypes.HeimdallWithSessionQuery,
  TError = unknown
>(
  client: GraphQLClient,
  variables?: __GraphQLSchemaTypes.HeimdallWithSessionQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.HeimdallWithSessionQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<__GraphQLSchemaTypes.HeimdallWithSessionQuery, TError, TData>(
    variables === undefined ? ['HeimdallWithSession'] : ['HeimdallWithSession', variables],
    fetcher<
      __GraphQLSchemaTypes.HeimdallWithSessionQuery,
      __GraphQLSchemaTypes.HeimdallWithSessionQueryVariables
    >(client, HeimdallWithSessionDocument, variables, headers),
    options
  );
export const HeimdallWithSessionByIdDocument = `
    query HeimdallWithSessionById($uid: String!) {
  heimdallSession(uid: $uid) {
    id
    uid
  }
}
    `;
export const useHeimdallWithSessionByIdQuery = <
  TData = __GraphQLSchemaTypes.HeimdallWithSessionByIdQuery,
  TError = unknown
>(
  client: GraphQLClient,
  variables: __GraphQLSchemaTypes.HeimdallWithSessionByIdQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.HeimdallWithSessionByIdQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<__GraphQLSchemaTypes.HeimdallWithSessionByIdQuery, TError, TData>(
    ['HeimdallWithSessionById', variables],
    fetcher<
      __GraphQLSchemaTypes.HeimdallWithSessionByIdQuery,
      __GraphQLSchemaTypes.HeimdallWithSessionByIdQueryVariables
    >(client, HeimdallWithSessionByIdDocument, variables, headers),
    options
  );
export const HeimdallAuthorizationFlowJwtDocument = `
    mutation HeimdallAuthorizationFlowJWT($input: HeimdallAuthorizationFlowInput!) {
  heimdallAuthorizationFlow(input: $input) {
    session {
      uid
      scopes
      expiresOn
      user {
        uid
      }
    }
    redirectTo
  }
}
    `;
export const useHeimdallAuthorizationFlowJwtMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    __GraphQLSchemaTypes.HeimdallAuthorizationFlowJwtMutation,
    TError,
    __GraphQLSchemaTypes.HeimdallAuthorizationFlowJwtMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<
    __GraphQLSchemaTypes.HeimdallAuthorizationFlowJwtMutation,
    TError,
    __GraphQLSchemaTypes.HeimdallAuthorizationFlowJwtMutationVariables,
    TContext
  >(
    ['HeimdallAuthorizationFlowJWT'],
    (variables?: __GraphQLSchemaTypes.HeimdallAuthorizationFlowJwtMutationVariables) =>
      fetcher<
        __GraphQLSchemaTypes.HeimdallAuthorizationFlowJwtMutation,
        __GraphQLSchemaTypes.HeimdallAuthorizationFlowJwtMutationVariables
      >(client, HeimdallAuthorizationFlowJwtDocument, variables, headers)(),
    options
  );
export const HeimdallAuthorizationFlowExchangeDocument = `
    mutation HeimdallAuthorizationFlowExchange($heimdallAuthorizationFlowInput: HeimdallAuthorizationFlowInput!) {
  heimdallAuthorizationFlow(input: $heimdallAuthorizationFlowInput) {
    session {
      uid
      scopes
      expiresOn
    }
  }
}
    `;
export const useHeimdallAuthorizationFlowExchangeMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    __GraphQLSchemaTypes.HeimdallAuthorizationFlowExchangeMutation,
    TError,
    __GraphQLSchemaTypes.HeimdallAuthorizationFlowExchangeMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<
    __GraphQLSchemaTypes.HeimdallAuthorizationFlowExchangeMutation,
    TError,
    __GraphQLSchemaTypes.HeimdallAuthorizationFlowExchangeMutationVariables,
    TContext
  >(
    ['HeimdallAuthorizationFlowExchange'],
    (variables?: __GraphQLSchemaTypes.HeimdallAuthorizationFlowExchangeMutationVariables) =>
      fetcher<
        __GraphQLSchemaTypes.HeimdallAuthorizationFlowExchangeMutation,
        __GraphQLSchemaTypes.HeimdallAuthorizationFlowExchangeMutationVariables
      >(client, HeimdallAuthorizationFlowExchangeDocument, variables, headers)(),
    options
  );
export const LogoutSessionDocument = `
    mutation LogoutSession($input: HeimdallLogoutInput!) {
  heimdallLogout(input: $input) {
    success
  }
}
    `;
export const useLogoutSessionMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    __GraphQLSchemaTypes.LogoutSessionMutation,
    TError,
    __GraphQLSchemaTypes.LogoutSessionMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<
    __GraphQLSchemaTypes.LogoutSessionMutation,
    TError,
    __GraphQLSchemaTypes.LogoutSessionMutationVariables,
    TContext
  >(
    ['LogoutSession'],
    (variables?: __GraphQLSchemaTypes.LogoutSessionMutationVariables) =>
      fetcher<__GraphQLSchemaTypes.LogoutSessionMutation, __GraphQLSchemaTypes.LogoutSessionMutationVariables>(
        client,
        LogoutSessionDocument,
        variables,
        headers
      )(),
    options
  );
export const MessageTemplatesDocument = `
    query MessageTemplates {
  faradayMessageTemplates {
    edges {
      node {
        ...messageTemplate_
      }
    }
  }
}
    ${MessageTemplate_FragmentDoc}`;
export const useMessageTemplatesQuery = <TData = __GraphQLSchemaTypes.MessageTemplatesQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: __GraphQLSchemaTypes.MessageTemplatesQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.MessageTemplatesQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<__GraphQLSchemaTypes.MessageTemplatesQuery, TError, TData>(
    variables === undefined ? ['MessageTemplates'] : ['MessageTemplates', variables],
    fetcher<__GraphQLSchemaTypes.MessageTemplatesQuery, __GraphQLSchemaTypes.MessageTemplatesQueryVariables>(
      client,
      MessageTemplatesDocument,
      variables,
      headers
    ),
    options
  );
export const MessageTemplateByUidDocument = `
    query MessageTemplateByUid($uid: String!) {
  faradayMessageTemplate(uid: $uid) {
    ...messageTemplate_
  }
}
    ${MessageTemplate_FragmentDoc}`;
export const useMessageTemplateByUidQuery = <
  TData = __GraphQLSchemaTypes.MessageTemplateByUidQuery,
  TError = unknown
>(
  client: GraphQLClient,
  variables: __GraphQLSchemaTypes.MessageTemplateByUidQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.MessageTemplateByUidQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<__GraphQLSchemaTypes.MessageTemplateByUidQuery, TError, TData>(
    ['MessageTemplateByUid', variables],
    fetcher<
      __GraphQLSchemaTypes.MessageTemplateByUidQuery,
      __GraphQLSchemaTypes.MessageTemplateByUidQueryVariables
    >(client, MessageTemplateByUidDocument, variables, headers),
    options
  );
export const MessageTemplateDeliverToPatientDocument = `
    mutation MessageTemplateDeliverToPatient($input: FaradayMessageTemplateDeliverToPatientInput!) {
  faradayMessageTemplateDeliverToPatient(input: $input) {
    results {
      isDelivered
      patientProfile {
        uid
      }
      messageTemplate {
        ...messageTemplate_
      }
    }
  }
}
    ${MessageTemplate_FragmentDoc}`;
export const useMessageTemplateDeliverToPatientMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    __GraphQLSchemaTypes.MessageTemplateDeliverToPatientMutation,
    TError,
    __GraphQLSchemaTypes.MessageTemplateDeliverToPatientMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<
    __GraphQLSchemaTypes.MessageTemplateDeliverToPatientMutation,
    TError,
    __GraphQLSchemaTypes.MessageTemplateDeliverToPatientMutationVariables,
    TContext
  >(
    ['MessageTemplateDeliverToPatient'],
    (variables?: __GraphQLSchemaTypes.MessageTemplateDeliverToPatientMutationVariables) =>
      fetcher<
        __GraphQLSchemaTypes.MessageTemplateDeliverToPatientMutation,
        __GraphQLSchemaTypes.MessageTemplateDeliverToPatientMutationVariables
      >(client, MessageTemplateDeliverToPatientDocument, variables, headers)(),
    options
  );
export const ModalitiesAllDocument = `
    query modalitiesAll {
  faradayModalities {
    edges {
      node {
        uid
        name
        shortName
      }
    }
  }
}
    `;
export const useModalitiesAllQuery = <TData = __GraphQLSchemaTypes.ModalitiesAllQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: __GraphQLSchemaTypes.ModalitiesAllQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.ModalitiesAllQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<__GraphQLSchemaTypes.ModalitiesAllQuery, TError, TData>(
    variables === undefined ? ['modalitiesAll'] : ['modalitiesAll', variables],
    fetcher<__GraphQLSchemaTypes.ModalitiesAllQuery, __GraphQLSchemaTypes.ModalitiesAllQueryVariables>(
      client,
      ModalitiesAllDocument,
      variables,
      headers
    ),
    options
  );
export const ModalitiesDocument = `
    query modalities($first: Int, $offset: Int, $last: Int, $before: String, $after: String, $shortName_In: [String]) {
  faradayModalities(
    first: $first
    offset: $offset
    last: $last
    before: $before
    after: $after
    shortName_In: $shortName_In
  ) {
    edges {
      node {
        uid
        name
        shortName
      }
    }
  }
}
    `;
export const useModalitiesQuery = <TData = __GraphQLSchemaTypes.ModalitiesQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: __GraphQLSchemaTypes.ModalitiesQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.ModalitiesQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<__GraphQLSchemaTypes.ModalitiesQuery, TError, TData>(
    variables === undefined ? ['modalities'] : ['modalities', variables],
    fetcher<__GraphQLSchemaTypes.ModalitiesQuery, __GraphQLSchemaTypes.ModalitiesQueryVariables>(
      client,
      ModalitiesDocument,
      variables,
      headers
    ),
    options
  );
export const ModalityDocument = `
    query modality {
  faradayModality {
    ...modalityDetails_
  }
}
    ${ModalityDetails_FragmentDoc}`;
export const useModalityQuery = <TData = __GraphQLSchemaTypes.ModalityQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: __GraphQLSchemaTypes.ModalityQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.ModalityQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<__GraphQLSchemaTypes.ModalityQuery, TError, TData>(
    variables === undefined ? ['modality'] : ['modality', variables],
    fetcher<__GraphQLSchemaTypes.ModalityQuery, __GraphQLSchemaTypes.ModalityQueryVariables>(
      client,
      ModalityDocument,
      variables,
      headers
    ),
    options
  );
export const ModalityByUidDocument = `
    query modalityByUid($uid: String!) {
  faradayModality(uid: $uid) {
    uid
    name
    shortName
  }
}
    `;
export const useModalityByUidQuery = <TData = __GraphQLSchemaTypes.ModalityByUidQuery, TError = unknown>(
  client: GraphQLClient,
  variables: __GraphQLSchemaTypes.ModalityByUidQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.ModalityByUidQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<__GraphQLSchemaTypes.ModalityByUidQuery, TError, TData>(
    ['modalityByUid', variables],
    fetcher<__GraphQLSchemaTypes.ModalityByUidQuery, __GraphQLSchemaTypes.ModalityByUidQueryVariables>(
      client,
      ModalityByUidDocument,
      variables,
      headers
    ),
    options
  );
export const OrdersDocument = `
    query Orders($sortBy: OrderOrderBy, $orderSearch: String, $sortDesc: Boolean, $first: Int, $offset: Int, $last: Int, $before: String, $after: String, $deactivatedOn_Isnull: Boolean, $exams_Status_In: [OrderExamStatus], $exams_Exam_Modality_Uid_In: [String], $createdFor_Uid_In: [String], $createdBy_Uid_In: [String], $patient_Uid_In: [String], $createdOn_Gt: DateTime, $createdOn_Gte: DateTime, $createdOn_Lt: DateTime, $createdOn_Lte: DateTime, $updatedOn_Gt: DateTime, $updatedOn_Gte: DateTime, $updatedOn_Lt: DateTime, $updatedOn_Lte: DateTime) {
  faradayOrders(
    exams_Status_In: $exams_Status_In
    sortBy: $sortBy
    orderSearch: $orderSearch
    sortDesc: $sortDesc
    first: $first
    offset: $offset
    last: $last
    before: $before
    after: $after
    deactivatedOn_Isnull: $deactivatedOn_Isnull
    exams_Exam_Modality_Uid_In: $exams_Exam_Modality_Uid_In
    createdFor_Uid_In: $createdFor_Uid_In
    createdBy_Uid_In: $createdBy_Uid_In
    createdOn_Gt: $createdOn_Gt
    createdOn_Gte: $createdOn_Gte
    createdOn_Lt: $createdOn_Lt
    createdOn_Lte: $createdOn_Lte
    updatedOn_Gt: $updatedOn_Gt
    updatedOn_Gte: $updatedOn_Gte
    updatedOn_Lt: $updatedOn_Lt
    updatedOn_Lte: $updatedOn_Lte
    patient_Uid_In: $patient_Uid_In
  ) {
    ...ordersConnection_
    edges {
      node {
        ...orderDetails_
      }
    }
  }
}
    ${OrdersConnection_FragmentDoc}
${OrderDetails_FragmentDoc}`;
export const useOrdersQuery = <TData = __GraphQLSchemaTypes.OrdersQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: __GraphQLSchemaTypes.OrdersQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.OrdersQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<__GraphQLSchemaTypes.OrdersQuery, TError, TData>(
    variables === undefined ? ['Orders'] : ['Orders', variables],
    fetcher<__GraphQLSchemaTypes.OrdersQuery, __GraphQLSchemaTypes.OrdersQueryVariables>(
      client,
      OrdersDocument,
      variables,
      headers
    ),
    options
  );
export const OrderByUidDocument = `
    query OrderByUid($uid: String!) {
  faradayOrder(uid: $uid) {
    uid
    lastPdfForm {
      uid
      pdfForm
    }
    pdfForms {
      uid
      pdfForm
      pdfFormMasked
      createdOn
      createdBy {
        uid
        fullName
        accountType
      }
    }
    orderExams {
      uid
      status
    }
  }
}
    `;
export const useOrderByUidQuery = <TData = __GraphQLSchemaTypes.OrderByUidQuery, TError = unknown>(
  client: GraphQLClient,
  variables: __GraphQLSchemaTypes.OrderByUidQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.OrderByUidQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<__GraphQLSchemaTypes.OrderByUidQuery, TError, TData>(
    ['OrderByUid', variables],
    fetcher<__GraphQLSchemaTypes.OrderByUidQuery, __GraphQLSchemaTypes.OrderByUidQueryVariables>(
      client,
      OrderByUidDocument,
      variables,
      headers
    ),
    options
  );
export const CreateOrderDocument = `
    mutation CreateOrder($createInput: CreateFaradayOrderInput!) {
  faradayOrderCreate(input: $createInput) {
    faradayOrder {
      ...orderDetails_
    }
  }
}
    ${OrderDetails_FragmentDoc}`;
export const useCreateOrderMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    __GraphQLSchemaTypes.CreateOrderMutation,
    TError,
    __GraphQLSchemaTypes.CreateOrderMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<
    __GraphQLSchemaTypes.CreateOrderMutation,
    TError,
    __GraphQLSchemaTypes.CreateOrderMutationVariables,
    TContext
  >(
    ['CreateOrder'],
    (variables?: __GraphQLSchemaTypes.CreateOrderMutationVariables) =>
      fetcher<__GraphQLSchemaTypes.CreateOrderMutation, __GraphQLSchemaTypes.CreateOrderMutationVariables>(
        client,
        CreateOrderDocument,
        variables,
        headers
      )(),
    options
  );
export const UpdateOrderDocument = `
    mutation UpdateOrder($updateInput: UpdateFaradayOrderInput!) {
  faradayOrderUpdate(input: $updateInput) {
    faradayOrder {
      uid
      metadata
      patient {
        firstName
        lastName
      }
      orderExams {
        uid
        status
        metadata
      }
      createdBy {
        uid
      }
    }
  }
}
    `;
export const useUpdateOrderMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    __GraphQLSchemaTypes.UpdateOrderMutation,
    TError,
    __GraphQLSchemaTypes.UpdateOrderMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<
    __GraphQLSchemaTypes.UpdateOrderMutation,
    TError,
    __GraphQLSchemaTypes.UpdateOrderMutationVariables,
    TContext
  >(
    ['UpdateOrder'],
    (variables?: __GraphQLSchemaTypes.UpdateOrderMutationVariables) =>
      fetcher<__GraphQLSchemaTypes.UpdateOrderMutation, __GraphQLSchemaTypes.UpdateOrderMutationVariables>(
        client,
        UpdateOrderDocument,
        variables,
        headers
      )(),
    options
  );
export const DeleteOrderDocument = `
    mutation DeleteOrder($deleteInput: DeleteFaradayOrderInput!) {
  faradayOrderDelete(input: $deleteInput) {
    deleteMutationResult {
      uid
      count
    }
  }
}
    `;
export const useDeleteOrderMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    __GraphQLSchemaTypes.DeleteOrderMutation,
    TError,
    __GraphQLSchemaTypes.DeleteOrderMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<
    __GraphQLSchemaTypes.DeleteOrderMutation,
    TError,
    __GraphQLSchemaTypes.DeleteOrderMutationVariables,
    TContext
  >(
    ['DeleteOrder'],
    (variables?: __GraphQLSchemaTypes.DeleteOrderMutationVariables) =>
      fetcher<__GraphQLSchemaTypes.DeleteOrderMutation, __GraphQLSchemaTypes.DeleteOrderMutationVariables>(
        client,
        DeleteOrderDocument,
        variables,
        headers
      )(),
    options
  );
export const OrderDraftsDocument = `
    query OrderDrafts {
  faradayOrderDrafts {
    totalCount
    edges {
      node {
        ...orderDraft_
      }
    }
  }
}
    ${OrderDraft_FragmentDoc}`;
export const useOrderDraftsQuery = <TData = __GraphQLSchemaTypes.OrderDraftsQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: __GraphQLSchemaTypes.OrderDraftsQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.OrderDraftsQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<__GraphQLSchemaTypes.OrderDraftsQuery, TError, TData>(
    variables === undefined ? ['OrderDrafts'] : ['OrderDrafts', variables],
    fetcher<__GraphQLSchemaTypes.OrderDraftsQuery, __GraphQLSchemaTypes.OrderDraftsQueryVariables>(
      client,
      OrderDraftsDocument,
      variables,
      headers
    ),
    options
  );
export const OrderDraftByUidDocument = `
    query OrderDraftByUid($uid: String!) {
  faradayOrderDraft(uid: $uid) {
    ...orderDraft_
  }
}
    ${OrderDraft_FragmentDoc}`;
export const useOrderDraftByUidQuery = <TData = __GraphQLSchemaTypes.OrderDraftByUidQuery, TError = unknown>(
  client: GraphQLClient,
  variables: __GraphQLSchemaTypes.OrderDraftByUidQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.OrderDraftByUidQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<__GraphQLSchemaTypes.OrderDraftByUidQuery, TError, TData>(
    ['OrderDraftByUid', variables],
    fetcher<__GraphQLSchemaTypes.OrderDraftByUidQuery, __GraphQLSchemaTypes.OrderDraftByUidQueryVariables>(
      client,
      OrderDraftByUidDocument,
      variables,
      headers
    ),
    options
  );
export const UpdateOrderDraftDocument = `
    mutation UpdateOrderDraft($updateInput: UpdateFaradayOrderDraftInput!) {
  faradayOrderDraftUpdate(input: $updateInput) {
    faradayOrderDraft {
      uid
      deactivatedOn
      createdBy {
        uid
      }
    }
  }
}
    `;
export const useUpdateOrderDraftMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    __GraphQLSchemaTypes.UpdateOrderDraftMutation,
    TError,
    __GraphQLSchemaTypes.UpdateOrderDraftMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<
    __GraphQLSchemaTypes.UpdateOrderDraftMutation,
    TError,
    __GraphQLSchemaTypes.UpdateOrderDraftMutationVariables,
    TContext
  >(
    ['UpdateOrderDraft'],
    (variables?: __GraphQLSchemaTypes.UpdateOrderDraftMutationVariables) =>
      fetcher<
        __GraphQLSchemaTypes.UpdateOrderDraftMutation,
        __GraphQLSchemaTypes.UpdateOrderDraftMutationVariables
      >(client, UpdateOrderDraftDocument, variables, headers)(),
    options
  );
export const CreateOrderDraftDocument = `
    mutation CreateOrderDraft($createInput: CreateFaradayOrderDraftInput!) {
  faradayOrderDraftCreate(input: $createInput) {
    faradayOrderDraft {
      uid
      deactivatedOn
      createdBy {
        uid
      }
    }
  }
}
    `;
export const useCreateOrderDraftMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    __GraphQLSchemaTypes.CreateOrderDraftMutation,
    TError,
    __GraphQLSchemaTypes.CreateOrderDraftMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<
    __GraphQLSchemaTypes.CreateOrderDraftMutation,
    TError,
    __GraphQLSchemaTypes.CreateOrderDraftMutationVariables,
    TContext
  >(
    ['CreateOrderDraft'],
    (variables?: __GraphQLSchemaTypes.CreateOrderDraftMutationVariables) =>
      fetcher<
        __GraphQLSchemaTypes.CreateOrderDraftMutation,
        __GraphQLSchemaTypes.CreateOrderDraftMutationVariables
      >(client, CreateOrderDraftDocument, variables, headers)(),
    options
  );
export const DeleteOrderDraftDocument = `
    mutation DeleteOrderDraft($deleteInput: DeleteFaradayOrderDraftInput!) {
  faradayOrderDraftDelete(input: $deleteInput) {
    deleteMutationResult {
      uid
      count
    }
  }
}
    `;
export const useDeleteOrderDraftMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    __GraphQLSchemaTypes.DeleteOrderDraftMutation,
    TError,
    __GraphQLSchemaTypes.DeleteOrderDraftMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<
    __GraphQLSchemaTypes.DeleteOrderDraftMutation,
    TError,
    __GraphQLSchemaTypes.DeleteOrderDraftMutationVariables,
    TContext
  >(
    ['DeleteOrderDraft'],
    (variables?: __GraphQLSchemaTypes.DeleteOrderDraftMutationVariables) =>
      fetcher<
        __GraphQLSchemaTypes.DeleteOrderDraftMutation,
        __GraphQLSchemaTypes.DeleteOrderDraftMutationVariables
      >(client, DeleteOrderDraftDocument, variables, headers)(),
    options
  );
export const OrderExamsDocument = `
    query OrderExams($first: Int, $offset: Int, $last: Int, $before: String, $after: String) {
  faradayOrderExams(
    first: $first
    offset: $offset
    last: $last
    before: $before
    after: $after
  ) {
    ...orderExamsConnection_
    edges {
      node {
        ...orderExam_
        order {
          pdfForms {
            ...orderPdfForms_
          }
          createdBy {
            ...orderCreatedBy_
          }
        }
      }
    }
  }
}
    ${OrderExamsConnection_FragmentDoc}
${OrderExam_FragmentDoc}
${OrderPdfForms_FragmentDoc}
${OrderCreatedBy_FragmentDoc}`;
export const useOrderExamsQuery = <TData = __GraphQLSchemaTypes.OrderExamsQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: __GraphQLSchemaTypes.OrderExamsQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.OrderExamsQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<__GraphQLSchemaTypes.OrderExamsQuery, TError, TData>(
    variables === undefined ? ['OrderExams'] : ['OrderExams', variables],
    fetcher<__GraphQLSchemaTypes.OrderExamsQuery, __GraphQLSchemaTypes.OrderExamsQueryVariables>(
      client,
      OrderExamsDocument,
      variables,
      headers
    ),
    options
  );
export const OrderExamDocument = `
    query OrderExam($uid: String) {
  faradayOrderExam(uid: $uid) {
    uid
    order {
      createdBy {
        ...orderCreatedBy_
      }
    }
  }
}
    ${OrderCreatedBy_FragmentDoc}`;
export const useOrderExamQuery = <TData = __GraphQLSchemaTypes.OrderExamQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: __GraphQLSchemaTypes.OrderExamQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.OrderExamQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<__GraphQLSchemaTypes.OrderExamQuery, TError, TData>(
    variables === undefined ? ['OrderExam'] : ['OrderExam', variables],
    fetcher<__GraphQLSchemaTypes.OrderExamQuery, __GraphQLSchemaTypes.OrderExamQueryVariables>(
      client,
      OrderExamDocument,
      variables,
      headers
    ),
    options
  );
export const OrderExamCreateDocument = `
    mutation orderExamCreate($createInput: CreateFaradayOrderExamInput!) {
  faradayOrderExamCreate(input: $createInput) {
    faradayOrderExam {
      uid
      order {
        createdBy {
          ...orderCreatedBy_
        }
      }
      exam {
        uid
      }
      status
      metadata
    }
  }
}
    ${OrderCreatedBy_FragmentDoc}`;
export const useOrderExamCreateMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    __GraphQLSchemaTypes.OrderExamCreateMutation,
    TError,
    __GraphQLSchemaTypes.OrderExamCreateMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<
    __GraphQLSchemaTypes.OrderExamCreateMutation,
    TError,
    __GraphQLSchemaTypes.OrderExamCreateMutationVariables,
    TContext
  >(
    ['orderExamCreate'],
    (variables?: __GraphQLSchemaTypes.OrderExamCreateMutationVariables) =>
      fetcher<
        __GraphQLSchemaTypes.OrderExamCreateMutation,
        __GraphQLSchemaTypes.OrderExamCreateMutationVariables
      >(client, OrderExamCreateDocument, variables, headers)(),
    options
  );
export const OrderExamUpdateDocument = `
    mutation OrderExamUpdate($updateInput: UpdateFaradayOrderExamInput!) {
  faradayOrderExamUpdate(input: $updateInput) {
    faradayOrderExam {
      uid
      order {
        createdBy {
          ...orderCreatedBy_
        }
      }
      exam {
        uid
      }
      status
      metadata
    }
  }
}
    ${OrderCreatedBy_FragmentDoc}`;
export const useOrderExamUpdateMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    __GraphQLSchemaTypes.OrderExamUpdateMutation,
    TError,
    __GraphQLSchemaTypes.OrderExamUpdateMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<
    __GraphQLSchemaTypes.OrderExamUpdateMutation,
    TError,
    __GraphQLSchemaTypes.OrderExamUpdateMutationVariables,
    TContext
  >(
    ['OrderExamUpdate'],
    (variables?: __GraphQLSchemaTypes.OrderExamUpdateMutationVariables) =>
      fetcher<
        __GraphQLSchemaTypes.OrderExamUpdateMutation,
        __GraphQLSchemaTypes.OrderExamUpdateMutationVariables
      >(client, OrderExamUpdateDocument, variables, headers)(),
    options
  );
export const OrderExamDeleteDocument = `
    mutation OrderExamDelete($deleteInput: DeleteFaradayOrderExamInput!) {
  faradayOrderExamDelete(input: $deleteInput) {
    deleteMutationResult {
      count
    }
  }
}
    `;
export const useOrderExamDeleteMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    __GraphQLSchemaTypes.OrderExamDeleteMutation,
    TError,
    __GraphQLSchemaTypes.OrderExamDeleteMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<
    __GraphQLSchemaTypes.OrderExamDeleteMutation,
    TError,
    __GraphQLSchemaTypes.OrderExamDeleteMutationVariables,
    TContext
  >(
    ['OrderExamDelete'],
    (variables?: __GraphQLSchemaTypes.OrderExamDeleteMutationVariables) =>
      fetcher<
        __GraphQLSchemaTypes.OrderExamDeleteMutation,
        __GraphQLSchemaTypes.OrderExamDeleteMutationVariables
      >(client, OrderExamDeleteDocument, variables, headers)(),
    options
  );
export const ParkwayOrdersDocument = `
    query parkwayOrders($parkwayOrderSearch: String, $procedureCode: String, $first: Int, $offset: Int, $last: Int, $before: String, $after: String, $facility_Uid_In: [String], $modality_Uid_In: [String], $referringDoctor_McrNo_In: [String], $sortBy: OrderOrderBy, $sortDesc: Boolean, $status_In: [ParkwayOrderStatus]) {
  faradayParkwayOrders(
    parkwayOrderSearch: $parkwayOrderSearch
    procedureCode: $procedureCode
    first: $first
    offset: $offset
    last: $last
    before: $before
    after: $after
    facility_Uid_In: $facility_Uid_In
    modality_Uid_In: $modality_Uid_In
    referringDoctor_McrNo_In: $referringDoctor_McrNo_In
    status_In: $status_In
    sortBy: $sortBy
    sortDesc: $sortDesc
  ) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        ...parkwayOrderDetails_
        modality {
          shortName
          uid
          name
        }
        facility {
          uid
          shortCode
          name
          metadata
        }
        referringDoctor {
          ...referringDoctor_
        }
      }
    }
  }
}
    ${ParkwayOrderDetails_FragmentDoc}
${ReferringDoctor_FragmentDoc}`;
export const useParkwayOrdersQuery = <TData = __GraphQLSchemaTypes.ParkwayOrdersQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: __GraphQLSchemaTypes.ParkwayOrdersQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.ParkwayOrdersQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<__GraphQLSchemaTypes.ParkwayOrdersQuery, TError, TData>(
    variables === undefined ? ['parkwayOrders'] : ['parkwayOrders', variables],
    fetcher<__GraphQLSchemaTypes.ParkwayOrdersQuery, __GraphQLSchemaTypes.ParkwayOrdersQueryVariables>(
      client,
      ParkwayOrdersDocument,
      variables,
      headers
    ),
    options
  );
export const ParkwayOrdersCountDocument = `
    query parkwayOrdersCount($parkwayOrderSearch: String, $procedureCode: String, $facility_Uid_In: [String], $modality_Uid_In: [String], $referringDoctor_McrNo_In: [String]) {
  faradayParkwayOrders(
    parkwayOrderSearch: $parkwayOrderSearch
    procedureCode: $procedureCode
    facility_Uid_In: $facility_Uid_In
    modality_Uid_In: $modality_Uid_In
    referringDoctor_McrNo_In: $referringDoctor_McrNo_In
  ) {
    totalCount
  }
}
    `;
export const useParkwayOrdersCountQuery = <
  TData = __GraphQLSchemaTypes.ParkwayOrdersCountQuery,
  TError = unknown
>(
  client: GraphQLClient,
  variables?: __GraphQLSchemaTypes.ParkwayOrdersCountQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.ParkwayOrdersCountQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<__GraphQLSchemaTypes.ParkwayOrdersCountQuery, TError, TData>(
    variables === undefined ? ['parkwayOrdersCount'] : ['parkwayOrdersCount', variables],
    fetcher<
      __GraphQLSchemaTypes.ParkwayOrdersCountQuery,
      __GraphQLSchemaTypes.ParkwayOrdersCountQueryVariables
    >(client, ParkwayOrdersCountDocument, variables, headers),
    options
  );
export const ParkwayOrderByUidDocument = `
    query parkwayOrderByUid($uid: String) {
  faradayParkwayOrders(uid: $uid) {
    totalCount
    edges {
      node {
        ...parkwayOrderDetails_
        modality {
          shortName
          uid
          name
        }
        facility {
          uid
          shortCode
          name
          metadata
        }
        referringDoctor {
          ...referringDoctor_
        }
      }
    }
  }
}
    ${ParkwayOrderDetails_FragmentDoc}
${ReferringDoctor_FragmentDoc}`;
export const useParkwayOrderByUidQuery = <
  TData = __GraphQLSchemaTypes.ParkwayOrderByUidQuery,
  TError = unknown
>(
  client: GraphQLClient,
  variables?: __GraphQLSchemaTypes.ParkwayOrderByUidQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.ParkwayOrderByUidQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<__GraphQLSchemaTypes.ParkwayOrderByUidQuery, TError, TData>(
    variables === undefined ? ['parkwayOrderByUid'] : ['parkwayOrderByUid', variables],
    fetcher<__GraphQLSchemaTypes.ParkwayOrderByUidQuery, __GraphQLSchemaTypes.ParkwayOrderByUidQueryVariables>(
      client,
      ParkwayOrderByUidDocument,
      variables,
      headers
    ),
    options
  );
export const PatientProfilesDocument = `
    query PatientProfiles($sortBy: PatientProfileOrderBy, $patientSearch: String, $sortDesc: Boolean, $first: Int, $offset: Int, $last: Int, $before: String, $after: String, $deactivatedOn_Isnull: Boolean, $doctors_Uid_In: [String], $gender_In: [String]) {
  faradayPatientProfiles(
    sortBy: $sortBy
    patientSearch: $patientSearch
    sortDesc: $sortDesc
    first: $first
    offset: $offset
    last: $last
    before: $before
    after: $after
    deactivatedOn_Isnull: $deactivatedOn_Isnull
    doctors_Uid_In: $doctors_Uid_In
    gender_In: $gender_In
  ) {
    ...patientProfilesConnection_
    edges {
      node {
        ...patientProfile_
        lastOrder {
          ...patientProfileLastOrder_
        }
      }
    }
  }
}
    ${PatientProfilesConnection_FragmentDoc}
${PatientProfile_FragmentDoc}
${PatientProfileLastOrder_FragmentDoc}`;
export const usePatientProfilesQuery = <TData = __GraphQLSchemaTypes.PatientProfilesQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: __GraphQLSchemaTypes.PatientProfilesQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.PatientProfilesQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<__GraphQLSchemaTypes.PatientProfilesQuery, TError, TData>(
    variables === undefined ? ['PatientProfiles'] : ['PatientProfiles', variables],
    fetcher<__GraphQLSchemaTypes.PatientProfilesQuery, __GraphQLSchemaTypes.PatientProfilesQueryVariables>(
      client,
      PatientProfilesDocument,
      variables,
      headers
    ),
    options
  );
export const PatientProfilesExportDataDocument = `
    query PatientProfilesExportData($sortBy: PatientProfileOrderBy, $patientSearch: String, $sortDesc: Boolean, $first: Int, $offset: Int, $last: Int, $before: String, $after: String, $deactivatedOn_Isnull: Boolean, $doctors_Uid_In: [String], $gender_In: [String], $email: String) {
  faradayPatientProfiles(
    sortBy: $sortBy
    patientSearch: $patientSearch
    sortDesc: $sortDesc
    first: $first
    offset: $offset
    last: $last
    before: $before
    after: $after
    deactivatedOn_Isnull: $deactivatedOn_Isnull
    doctors_Uid_In: $doctors_Uid_In
    gender_In: $gender_In
  ) {
    export(email: $email)
  }
}
    `;
export const usePatientProfilesExportDataQuery = <
  TData = __GraphQLSchemaTypes.PatientProfilesExportDataQuery,
  TError = unknown
>(
  client: GraphQLClient,
  variables?: __GraphQLSchemaTypes.PatientProfilesExportDataQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.PatientProfilesExportDataQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<__GraphQLSchemaTypes.PatientProfilesExportDataQuery, TError, TData>(
    variables === undefined ? ['PatientProfilesExportData'] : ['PatientProfilesExportData', variables],
    fetcher<
      __GraphQLSchemaTypes.PatientProfilesExportDataQuery,
      __GraphQLSchemaTypes.PatientProfilesExportDataQueryVariables
    >(client, PatientProfilesExportDataDocument, variables, headers),
    options
  );
export const PatientProfileDocument = `
    query PatientProfile {
  faradayPatientProfile {
    ...patientProfile_
    lastOrder {
      ...patientProfileLastOrder_
    }
  }
}
    ${PatientProfile_FragmentDoc}
${PatientProfileLastOrder_FragmentDoc}`;
export const usePatientProfileQuery = <TData = __GraphQLSchemaTypes.PatientProfileQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: __GraphQLSchemaTypes.PatientProfileQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.PatientProfileQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<__GraphQLSchemaTypes.PatientProfileQuery, TError, TData>(
    variables === undefined ? ['PatientProfile'] : ['PatientProfile', variables],
    fetcher<__GraphQLSchemaTypes.PatientProfileQuery, __GraphQLSchemaTypes.PatientProfileQueryVariables>(
      client,
      PatientProfileDocument,
      variables,
      headers
    ),
    options
  );
export const PatientProfileByUidDocument = `
    query PatientProfileByUid($uid: String!) {
  faradayPatientProfile(uid: $uid) {
    ...patientProfile_
    lastOrder {
      ...patientProfileLastOrder_
    }
  }
}
    ${PatientProfile_FragmentDoc}
${PatientProfileLastOrder_FragmentDoc}`;
export const usePatientProfileByUidQuery = <
  TData = __GraphQLSchemaTypes.PatientProfileByUidQuery,
  TError = unknown
>(
  client: GraphQLClient,
  variables: __GraphQLSchemaTypes.PatientProfileByUidQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.PatientProfileByUidQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<__GraphQLSchemaTypes.PatientProfileByUidQuery, TError, TData>(
    ['PatientProfileByUid', variables],
    fetcher<
      __GraphQLSchemaTypes.PatientProfileByUidQuery,
      __GraphQLSchemaTypes.PatientProfileByUidQueryVariables
    >(client, PatientProfileByUidDocument, variables, headers),
    options
  );
export const CreatePatientProfileDocument = `
    mutation createPatientProfile($createInput: CreateFaradayPatientProfileInput!) {
  faradayPatientProfileCreate(input: $createInput) {
    faradayPatientProfile {
      uid
      fullName
      firstName
      lastName
      phone
      gender
      isClaustrophobic
      comments
      ...patientProfileDoctors_
    }
  }
}
    ${PatientProfileDoctors_FragmentDoc}`;
export const useCreatePatientProfileMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    __GraphQLSchemaTypes.CreatePatientProfileMutation,
    TError,
    __GraphQLSchemaTypes.CreatePatientProfileMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<
    __GraphQLSchemaTypes.CreatePatientProfileMutation,
    TError,
    __GraphQLSchemaTypes.CreatePatientProfileMutationVariables,
    TContext
  >(
    ['createPatientProfile'],
    (variables?: __GraphQLSchemaTypes.CreatePatientProfileMutationVariables) =>
      fetcher<
        __GraphQLSchemaTypes.CreatePatientProfileMutation,
        __GraphQLSchemaTypes.CreatePatientProfileMutationVariables
      >(client, CreatePatientProfileDocument, variables, headers)(),
    options
  );
export const UpdatePatientProfileDocument = `
    mutation updatePatientProfile($updateInput: UpdateFaradayPatientProfileInput!) {
  faradayPatientProfileUpdate(input: $updateInput) {
    faradayPatientProfile {
      ...patientProfile_
    }
  }
}
    ${PatientProfile_FragmentDoc}`;
export const useUpdatePatientProfileMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    __GraphQLSchemaTypes.UpdatePatientProfileMutation,
    TError,
    __GraphQLSchemaTypes.UpdatePatientProfileMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<
    __GraphQLSchemaTypes.UpdatePatientProfileMutation,
    TError,
    __GraphQLSchemaTypes.UpdatePatientProfileMutationVariables,
    TContext
  >(
    ['updatePatientProfile'],
    (variables?: __GraphQLSchemaTypes.UpdatePatientProfileMutationVariables) =>
      fetcher<
        __GraphQLSchemaTypes.UpdatePatientProfileMutation,
        __GraphQLSchemaTypes.UpdatePatientProfileMutationVariables
      >(client, UpdatePatientProfileDocument, variables, headers)(),
    options
  );
export const DeletePatientProfileDocument = `
    mutation deletePatientProfile($deleteInput: DeleteFaradayPatientProfileInput!) {
  faradayPatientProfileDelete(input: $deleteInput) {
    deleteMutationResult {
      uid
      count
    }
  }
}
    `;
export const useDeletePatientProfileMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    __GraphQLSchemaTypes.DeletePatientProfileMutation,
    TError,
    __GraphQLSchemaTypes.DeletePatientProfileMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<
    __GraphQLSchemaTypes.DeletePatientProfileMutation,
    TError,
    __GraphQLSchemaTypes.DeletePatientProfileMutationVariables,
    TContext
  >(
    ['deletePatientProfile'],
    (variables?: __GraphQLSchemaTypes.DeletePatientProfileMutationVariables) =>
      fetcher<
        __GraphQLSchemaTypes.DeletePatientProfileMutation,
        __GraphQLSchemaTypes.DeletePatientProfileMutationVariables
      >(client, DeletePatientProfileDocument, variables, headers)(),
    options
  );
export const UpdatePatientDoctorRelationshipDocument = `
    mutation updatePatientDoctorRelationship($updateRelationsInput: FaradayPatientDoctorRelationshipsUpdateInput!) {
  faradayPatientDoctorRelationshipsUpdate(input: $updateRelationsInput) {
    patient {
      doctors {
        uid
      }
    }
  }
}
    `;
export const useUpdatePatientDoctorRelationshipMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    __GraphQLSchemaTypes.UpdatePatientDoctorRelationshipMutation,
    TError,
    __GraphQLSchemaTypes.UpdatePatientDoctorRelationshipMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<
    __GraphQLSchemaTypes.UpdatePatientDoctorRelationshipMutation,
    TError,
    __GraphQLSchemaTypes.UpdatePatientDoctorRelationshipMutationVariables,
    TContext
  >(
    ['updatePatientDoctorRelationship'],
    (variables?: __GraphQLSchemaTypes.UpdatePatientDoctorRelationshipMutationVariables) =>
      fetcher<
        __GraphQLSchemaTypes.UpdatePatientDoctorRelationshipMutation,
        __GraphQLSchemaTypes.UpdatePatientDoctorRelationshipMutationVariables
      >(client, UpdatePatientDoctorRelationshipDocument, variables, headers)(),
    options
  );
export const PriceListDocument = `
    query PriceList($priceListSearch: String, $first: Int, $offset: Int, $last: Int, $before: String, $after: String, $modalityUids: [String!], $facilityUids: [String!]) {
  faradayPriceLists(
    priceListSearch: $priceListSearch
    first: $first
    offset: $offset
    last: $last
    before: $before
    after: $after
    facilityUids: $facilityUids
    modalityUids: $modalityUids
  ) {
    ...priceListPageInfoConnection_
    edges {
      node {
        id
        entry {
          uid
          variables
          deactivatedOn
          createdOn
          tags
          aclDnf
          syncStatus
          isActivated
        }
      }
    }
  }
}
    ${PriceListPageInfoConnection_FragmentDoc}`;
export const usePriceListQuery = <TData = __GraphQLSchemaTypes.PriceListQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: __GraphQLSchemaTypes.PriceListQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.PriceListQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<__GraphQLSchemaTypes.PriceListQuery, TError, TData>(
    variables === undefined ? ['PriceList'] : ['PriceList', variables],
    fetcher<__GraphQLSchemaTypes.PriceListQuery, __GraphQLSchemaTypes.PriceListQueryVariables>(
      client,
      PriceListDocument,
      variables,
      headers
    ),
    options
  );
export const CatalogueListDocument = `
    query CatalogueList($catalogueSearch: String, $first: Int, $offset: Int, $last: Int, $before: String, $after: String, $modalityUids: [String!]) {
  faradayCatalogues(
    catalogueSearch: $catalogueSearch
    first: $first
    offset: $offset
    last: $last
    before: $before
    after: $after
    modalityUids: $modalityUids
  ) {
    ...cataloguesPageInfoConnection_
    edges {
      node {
        document {
          uid
          title
          variables
          pdfFile
          mimeType
          createdOn
          syncStatus
          filename
          tags
          aclDnf
          syncStatus
        }
      }
    }
  }
}
    ${CataloguesPageInfoConnection_FragmentDoc}`;
export const useCatalogueListQuery = <TData = __GraphQLSchemaTypes.CatalogueListQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: __GraphQLSchemaTypes.CatalogueListQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.CatalogueListQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<__GraphQLSchemaTypes.CatalogueListQuery, TError, TData>(
    variables === undefined ? ['CatalogueList'] : ['CatalogueList', variables],
    fetcher<__GraphQLSchemaTypes.CatalogueListQuery, __GraphQLSchemaTypes.CatalogueListQueryVariables>(
      client,
      CatalogueListDocument,
      variables,
      headers
    ),
    options
  );
export const CatalogueListCountDocument = `
    query CatalogueListCount {
  faradayCatalogues {
    totalCount
  }
}
    `;
export const useCatalogueListCountQuery = <
  TData = __GraphQLSchemaTypes.CatalogueListCountQuery,
  TError = unknown
>(
  client: GraphQLClient,
  variables?: __GraphQLSchemaTypes.CatalogueListCountQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.CatalogueListCountQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<__GraphQLSchemaTypes.CatalogueListCountQuery, TError, TData>(
    variables === undefined ? ['CatalogueListCount'] : ['CatalogueListCount', variables],
    fetcher<
      __GraphQLSchemaTypes.CatalogueListCountQuery,
      __GraphQLSchemaTypes.CatalogueListCountQueryVariables
    >(client, CatalogueListCountDocument, variables, headers),
    options
  );
export const StaffProfileDocument = `
    query StaffProfile {
  faradayStaffProfile {
    ...staffProfile_
    clinic {
      ...clinic_
    }
  }
}
    ${StaffProfile_FragmentDoc}
${Clinic_FragmentDoc}`;
export const useStaffProfileQuery = <TData = __GraphQLSchemaTypes.StaffProfileQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: __GraphQLSchemaTypes.StaffProfileQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.StaffProfileQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<__GraphQLSchemaTypes.StaffProfileQuery, TError, TData>(
    variables === undefined ? ['StaffProfile'] : ['StaffProfile', variables],
    fetcher<__GraphQLSchemaTypes.StaffProfileQuery, __GraphQLSchemaTypes.StaffProfileQueryVariables>(
      client,
      StaffProfileDocument,
      variables,
      headers
    ),
    options
  );
export const StaffProfileByUidDocument = `
    query StaffProfileByUid($uid: String!) {
  faradayStaffProfile(uid: $uid) {
    ...staffProfile_
    clinic {
      ...clinic_
    }
  }
}
    ${StaffProfile_FragmentDoc}
${Clinic_FragmentDoc}`;
export const useStaffProfileByUidQuery = <
  TData = __GraphQLSchemaTypes.StaffProfileByUidQuery,
  TError = unknown
>(
  client: GraphQLClient,
  variables: __GraphQLSchemaTypes.StaffProfileByUidQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.StaffProfileByUidQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<__GraphQLSchemaTypes.StaffProfileByUidQuery, TError, TData>(
    ['StaffProfileByUid', variables],
    fetcher<__GraphQLSchemaTypes.StaffProfileByUidQuery, __GraphQLSchemaTypes.StaffProfileByUidQueryVariables>(
      client,
      StaffProfileByUidDocument,
      variables,
      headers
    ),
    options
  );
export const StaffProfilesDocument = `
    query StaffProfiles($sortBy: StaffProfileOrderBy, $staffSearch: String, $sortDesc: Boolean, $first: Int, $offset: Int, $last: Int, $before: String, $after: String, $accountType_In: [StaffProfileAccountType], $deactivatedOn_Isnull: Boolean) {
  faradayStaffProfiles(
    sortBy: $sortBy
    staffSearch: $staffSearch
    sortDesc: $sortDesc
    first: $first
    offset: $offset
    last: $last
    before: $before
    after: $after
    accountType_In: $accountType_In
    deactivatedOn_Isnull: $deactivatedOn_Isnull
  ) {
    ...staffProfileConnection_
    edges {
      node {
        ...staffProfile_
        clinic {
          ...clinic_
        }
      }
    }
  }
}
    ${StaffProfileConnection_FragmentDoc}
${StaffProfile_FragmentDoc}
${Clinic_FragmentDoc}`;
export const useStaffProfilesQuery = <TData = __GraphQLSchemaTypes.StaffProfilesQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: __GraphQLSchemaTypes.StaffProfilesQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.StaffProfilesQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<__GraphQLSchemaTypes.StaffProfilesQuery, TError, TData>(
    variables === undefined ? ['StaffProfiles'] : ['StaffProfiles', variables],
    fetcher<__GraphQLSchemaTypes.StaffProfilesQuery, __GraphQLSchemaTypes.StaffProfilesQueryVariables>(
      client,
      StaffProfilesDocument,
      variables,
      headers
    ),
    options
  );
export const StaffProfilesUsersCountDocument = `
    query StaffProfilesUsersCount($sortBy: StaffProfileOrderBy, $staffSearch: String, $sortDesc: Boolean, $first: Int, $offset: Int, $last: Int, $before: String) {
  faradayStaffProfiles(
    sortBy: $sortBy
    staffSearch: $staffSearch
    sortDesc: $sortDesc
    first: $first
    offset: $offset
    last: $last
    before: $before
  ) {
    totalCount
  }
}
    `;
export const useStaffProfilesUsersCountQuery = <
  TData = __GraphQLSchemaTypes.StaffProfilesUsersCountQuery,
  TError = unknown
>(
  client: GraphQLClient,
  variables?: __GraphQLSchemaTypes.StaffProfilesUsersCountQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.StaffProfilesUsersCountQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<__GraphQLSchemaTypes.StaffProfilesUsersCountQuery, TError, TData>(
    variables === undefined ? ['StaffProfilesUsersCount'] : ['StaffProfilesUsersCount', variables],
    fetcher<
      __GraphQLSchemaTypes.StaffProfilesUsersCountQuery,
      __GraphQLSchemaTypes.StaffProfilesUsersCountQueryVariables
    >(client, StaffProfilesUsersCountDocument, variables, headers),
    options
  );
export const StaffProfilesPendingRequestCountDocument = `
    query StaffProfilesPendingRequestCount($sortBy: StaffProfileOrderBy, $staffSearch: String, $sortDesc: Boolean, $first: Int, $offset: Int, $last: Int, $before: String) {
  faradayStaffProfiles(
    sortBy: $sortBy
    staffSearch: $staffSearch
    sortDesc: $sortDesc
    first: $first
    offset: $offset
    last: $last
    before: $before
  ) {
    totalCount
  }
}
    `;
export const useStaffProfilesPendingRequestCountQuery = <
  TData = __GraphQLSchemaTypes.StaffProfilesPendingRequestCountQuery,
  TError = unknown
>(
  client: GraphQLClient,
  variables?: __GraphQLSchemaTypes.StaffProfilesPendingRequestCountQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.StaffProfilesPendingRequestCountQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<__GraphQLSchemaTypes.StaffProfilesPendingRequestCountQuery, TError, TData>(
    variables === undefined
      ? ['StaffProfilesPendingRequestCount']
      : ['StaffProfilesPendingRequestCount', variables],
    fetcher<
      __GraphQLSchemaTypes.StaffProfilesPendingRequestCountQuery,
      __GraphQLSchemaTypes.StaffProfilesPendingRequestCountQueryVariables
    >(client, StaffProfilesPendingRequestCountDocument, variables, headers),
    options
  );
export const CreateStaffProfileDocument = `
    mutation createStaffProfile($createInput: CreateFaradayStaffProfileInput!) {
  faradayStaffProfileCreate(input: $createInput) {
    faradayStaffProfile {
      uid
      fullName
      firstName
      lastName
      designation
      accountType
      mcrNo
      physicianId
      phone
      email
    }
  }
}
    `;
export const useCreateStaffProfileMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    __GraphQLSchemaTypes.CreateStaffProfileMutation,
    TError,
    __GraphQLSchemaTypes.CreateStaffProfileMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<
    __GraphQLSchemaTypes.CreateStaffProfileMutation,
    TError,
    __GraphQLSchemaTypes.CreateStaffProfileMutationVariables,
    TContext
  >(
    ['createStaffProfile'],
    (variables?: __GraphQLSchemaTypes.CreateStaffProfileMutationVariables) =>
      fetcher<
        __GraphQLSchemaTypes.CreateStaffProfileMutation,
        __GraphQLSchemaTypes.CreateStaffProfileMutationVariables
      >(client, CreateStaffProfileDocument, variables, headers)(),
    options
  );
export const BulkCreateStaffProfileDocument = `
    mutation bulkCreateStaffProfile($bulkCreateStaffProfiles: FaradayBulkCreateStaffProfileInput!) {
  faradayBulkCreateStaffProfile(input: $bulkCreateStaffProfiles) {
    status
    totalCount
    errorCount
    creationStatus {
      status
      info
    }
  }
}
    `;
export const useBulkCreateStaffProfileMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    __GraphQLSchemaTypes.BulkCreateStaffProfileMutation,
    TError,
    __GraphQLSchemaTypes.BulkCreateStaffProfileMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<
    __GraphQLSchemaTypes.BulkCreateStaffProfileMutation,
    TError,
    __GraphQLSchemaTypes.BulkCreateStaffProfileMutationVariables,
    TContext
  >(
    ['bulkCreateStaffProfile'],
    (variables?: __GraphQLSchemaTypes.BulkCreateStaffProfileMutationVariables) =>
      fetcher<
        __GraphQLSchemaTypes.BulkCreateStaffProfileMutation,
        __GraphQLSchemaTypes.BulkCreateStaffProfileMutationVariables
      >(client, BulkCreateStaffProfileDocument, variables, headers)(),
    options
  );
export const UpdateStaffProfileDocument = `
    mutation UpdateStaffProfile($updateInput: UpdateFaradayStaffProfileInput!) {
  faradayStaffProfileUpdate(input: $updateInput) {
    faradayStaffProfile {
      ...staffProfile_
    }
  }
}
    ${StaffProfile_FragmentDoc}`;
export const useUpdateStaffProfileMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    __GraphQLSchemaTypes.UpdateStaffProfileMutation,
    TError,
    __GraphQLSchemaTypes.UpdateStaffProfileMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<
    __GraphQLSchemaTypes.UpdateStaffProfileMutation,
    TError,
    __GraphQLSchemaTypes.UpdateStaffProfileMutationVariables,
    TContext
  >(
    ['UpdateStaffProfile'],
    (variables?: __GraphQLSchemaTypes.UpdateStaffProfileMutationVariables) =>
      fetcher<
        __GraphQLSchemaTypes.UpdateStaffProfileMutation,
        __GraphQLSchemaTypes.UpdateStaffProfileMutationVariables
      >(client, UpdateStaffProfileDocument, variables, headers)(),
    options
  );
export const DeleteStaffProfileDocument = `
    mutation DeleteStaffProfile($deleteInput: DeleteFaradayStaffProfileInput!) {
  faradayStaffProfileDelete(input: $deleteInput) {
    deleteMutationResult {
      uid
      count
    }
  }
}
    `;
export const useDeleteStaffProfileMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    __GraphQLSchemaTypes.DeleteStaffProfileMutation,
    TError,
    __GraphQLSchemaTypes.DeleteStaffProfileMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<
    __GraphQLSchemaTypes.DeleteStaffProfileMutation,
    TError,
    __GraphQLSchemaTypes.DeleteStaffProfileMutationVariables,
    TContext
  >(
    ['DeleteStaffProfile'],
    (variables?: __GraphQLSchemaTypes.DeleteStaffProfileMutationVariables) =>
      fetcher<
        __GraphQLSchemaTypes.DeleteStaffProfileMutation,
        __GraphQLSchemaTypes.DeleteStaffProfileMutationVariables
      >(client, DeleteStaffProfileDocument, variables, headers)(),
    options
  );
export const UpdateAdminDoctorRelationshipsDocument = `
    mutation UpdateAdminDoctorRelationships($updateRelationshipsInput: FaradayAdminDoctorRelationshipsUpdateInput!) {
  faradayAdminDoctorRelationshipsUpdate(input: $updateRelationshipsInput) {
    adminDoctorRelationships {
      admin {
        uid
        fullName
        accountType
      }
      doctor {
        uid
        fullName
        accountType
      }
    }
  }
}
    `;
export const useUpdateAdminDoctorRelationshipsMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    __GraphQLSchemaTypes.UpdateAdminDoctorRelationshipsMutation,
    TError,
    __GraphQLSchemaTypes.UpdateAdminDoctorRelationshipsMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<
    __GraphQLSchemaTypes.UpdateAdminDoctorRelationshipsMutation,
    TError,
    __GraphQLSchemaTypes.UpdateAdminDoctorRelationshipsMutationVariables,
    TContext
  >(
    ['UpdateAdminDoctorRelationships'],
    (variables?: __GraphQLSchemaTypes.UpdateAdminDoctorRelationshipsMutationVariables) =>
      fetcher<
        __GraphQLSchemaTypes.UpdateAdminDoctorRelationshipsMutation,
        __GraphQLSchemaTypes.UpdateAdminDoctorRelationshipsMutationVariables
      >(client, UpdateAdminDoctorRelationshipsDocument, variables, headers)(),
    options
  );
export const UpdateFavoriteExamsUpdateDocument = `
    mutation UpdateFavoriteExamsUpdate($input: FaradayStaffFavoriteExamsUpdateInput!) {
  faradayStaffFavoriteExamsUpdate(input: $input) {
    favoriteExams {
      id
      exam {
        uid
        name
      }
    }
  }
}
    `;
export const useUpdateFavoriteExamsUpdateMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    __GraphQLSchemaTypes.UpdateFavoriteExamsUpdateMutation,
    TError,
    __GraphQLSchemaTypes.UpdateFavoriteExamsUpdateMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<
    __GraphQLSchemaTypes.UpdateFavoriteExamsUpdateMutation,
    TError,
    __GraphQLSchemaTypes.UpdateFavoriteExamsUpdateMutationVariables,
    TContext
  >(
    ['UpdateFavoriteExamsUpdate'],
    (variables?: __GraphQLSchemaTypes.UpdateFavoriteExamsUpdateMutationVariables) =>
      fetcher<
        __GraphQLSchemaTypes.UpdateFavoriteExamsUpdateMutation,
        __GraphQLSchemaTypes.UpdateFavoriteExamsUpdateMutationVariables
      >(client, UpdateFavoriteExamsUpdateDocument, variables, headers)(),
    options
  );
