import { memo, useState, FC } from 'react';
import { Badge, Box, Text, Tooltip, theme } from '@fivehealth/botero';
import { omit } from 'lodash';
import {
  faExclamationTriangle as errorIcon,
  faExclamationCircle as duplicatedIcon,
  IconDefinition,
} from '@fortawesome/pro-regular-svg-icons';
import { StatusColorSchemeConst, StatusColorSchemeConstType } from '@/theme/theme';
import { OrderExamStatusValueType, OrderExamStatusConstType } from '@/helpers/types/ui.types';
import IconWithLabel from './IconWithLabelBadge';
import { BoxProps } from 'rebass';

export type ColorTypes = 'alert' | 'default' | 'public' | 'resolve';

type BadgeStatusValueType = {
  color: StatusColorSchemeConstType<string>;
  bg?: StatusColorSchemeConstType<string>;
  opacity?: number | string;
  type?: ColorTypes;
  icon?: IconDefinition;
  iconSize?: number | string;
};

type BadgeStatusObjType = {
  [key in OrderExamStatusConstType]: BadgeStatusValueType;
};

export const bagdgeStatusMapCfg: BadgeStatusObjType = {
  [OrderExamStatusValueType.CANCELLED]: {
    color: StatusColorSchemeConst.danger,
    type: 'alert',
  },
  [OrderExamStatusValueType.RIS_MODIFIED]: {
    color: StatusColorSchemeConst.warning,
    opacity: 0.6,
    type: 'alert',
  },
  [OrderExamStatusValueType.RIS_CANCELLED]: {
    color: StatusColorSchemeConst.danger,
    type: 'alert',
    opacity: 0.6,
  },
  [OrderExamStatusValueType.RIS_RESCHEDULED]: {
    color: StatusColorSchemeConst.primary,
    type: 'alert',
    opacity: 0.5,
  },
  [OrderExamStatusValueType.RIS_SCHEDULED]: {
    color: StatusColorSchemeConst.primary,
    type: 'alert',
    opacity: 0.6,
  },
  [OrderExamStatusValueType.DUPLICATE]: {
    color: StatusColorSchemeConst.danger,
    icon: duplicatedIcon,
    iconSize: 16,
    type: 'alert',
    opacity: 0.8,
  },
  [OrderExamStatusValueType.ERROR]: {
    color: StatusColorSchemeConst.danger,
    type: 'alert',
    icon: errorIcon,
    iconSize: 16,
  },
  [OrderExamStatusValueType.ERROR_SCHEDULE]: {
    color: StatusColorSchemeConst.danger,
    type: 'alert',
    icon: errorIcon,
    iconSize: 16,
  },
  [OrderExamStatusValueType.ERROR_RESCHEDULE]: {
    color: StatusColorSchemeConst.danger,
    type: 'alert',
    icon: errorIcon,
    iconSize: 16,
  },
  [OrderExamStatusValueType.IMAGE_READY]: {
    color: StatusColorSchemeConst.success,
    bg: 'successWithOpacity.08',
  },
  [OrderExamStatusValueType.PENDING_CANCEL]: {
    color: StatusColorSchemeConst.danger,
    type: 'alert',
    opacity: 0.8,
  },
  [OrderExamStatusValueType.PENDING_RESCHEDULE]: {
    color: StatusColorSchemeConst.warning,
    bg: 'lightestShade',
    opacity: 0.6,
  },
  [OrderExamStatusValueType.PENDING_SCHEDULE]: {
    color: StatusColorSchemeConst.warning,
    bg: 'warningWithOpacity.08',
  },
  [OrderExamStatusValueType.REPORT_READY]: {
    color: StatusColorSchemeConst.success,
    opacity: 0.8,
    bg: 'successWithOpacity.08',
  },
  [OrderExamStatusValueType.RESCHEDULED]: {
    color: StatusColorSchemeConst.primary,
    opacity: 0.8,
    bg: 'warningWithOpacity.08',
  },
  [OrderExamStatusValueType.SCHEDULED]: {
    color: StatusColorSchemeConst.primary,
    bg: 'primaryWithOpacity.08',
  },
};

export const getStatusBadgeColor = (status: string) =>
  theme.colors[bagdgeStatusMapCfg[OrderExamStatusValueType[status]]?.color];

type StatusBadgeProps = {
  status: OrderExamStatusConstType;
  noLabel?: boolean;
  variant?: 'badge' | 'dot';
  renderLabel?: (status: string) => React.ReactNode | null;
  labelProps?: BoxProps;
  props?: BoxProps;
};

const StatusBadge: FC<StatusBadgeProps> = ({
  status,
  renderLabel,
  noLabel = false,
  variant = 'badge',
  labelProps,
  ...props
}) => {
  const children = !noLabel ? status : '';

  const iconWithLabelProps = { ...bagdgeStatusMapCfg[status], renderLabel, children };
  const [show, setShow] = useState(false);
  return (
    <Box onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)} {...props}>
      <Tooltip
        show={noLabel ? show : null}
        modifiers={{}}
        tooltip={
          <Text fontSize={14} color="white">
            {status}
          </Text>
        }>
        {variant === 'badge' ? (
          <Badge fontWeight={500} {...iconWithLabelProps} />
        ) : (
          <IconWithLabel {...omit(iconWithLabelProps, 'bg')} bodyProps={{ ...labelProps }} />
        )}
      </Tooltip>
    </Box>
  );
};

export default memo(StatusBadge);
