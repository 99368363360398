import React, { useEffect, useState } from 'react';
import { Box, Flex, H2, PrimaryButton, theme } from '@fivehealth/botero';
import { useTranslation } from 'react-i18next';
import { useAuthContext, useDataContext } from '@/context';
import { FaradayClinic } from '@/gql/generated/graphql';
import Modal from '@/components/Modals/Modal';
import { getCookieData, localWorkspace } from '@/helpers/utils/storage.utils';
import useSessionExchange from '@/hooks/useSessionExchange';
import { WorkspaceSelect } from '@/components/Select/WorkspaceSelect';
import LoadingImg from '@/assets/settings_loader.json';
import Lottie from 'react-lottie-player';

type WorkspaceModalProps = {
  open: boolean;
  onClose: () => void;
};

type WorkspaceItemType = {
  value: FaradayClinic;
  label: JSX.Element | React.ReactElement | string;
  selected?: boolean;
};

const WorkspaceModal: React.FC<WorkspaceModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { setIsLoading: authSetLoading } = useAuthContext();

  const activeWorkspaceStorage = localWorkspace.getActive();
  const [isStrictMode, setIsStrictMode] = useState(true);
  const [isDisable, setIsDisable] = useState(false);

  const {
    data: { clinicList },
  } = useDataContext();

  const {
    authState: { data, token, isAuthenticated },
  } = useAuthContext();

  // NOTE: Initially select the first clinic in the list
  const [selectedWorkspace, setSelectedWorkspace] = useState<WorkspaceItemType | null>({
    value: null as unknown as FaradayClinic,
    label: 'Please select a workspace',
  });

  const { mutate: mutator } = useSessionExchange();

  useEffect(() => {
    if (clinicList && clinicList?.length > 0 && !selectedWorkspace?.value) {
      setSelectedWorkspace({
        value: clinicList[0] as FaradayClinic, // data?.user?.clinic,
        label: (clinicList[0] as FaradayClinic)?.name?.toUpperCase(),
      });
    }
  }, [clinicList, selectedWorkspace]);

  const onSubmit = async () => {
    if (selectedWorkspace?.value && token && isAuthenticated && mutator && authSetLoading) {
      setIsStrictMode(false);
      setIsDisable(true);
      const inactiveWorkspace = localWorkspace.getByUid(selectedWorkspace?.value?.uid);
      // NOTE: If selected workspace is already the active workspace clinic then no need to mutate
      if (inactiveWorkspace?.clinic?.uid !== selectedWorkspace?.value?.uid) {
        const resp = await mutator(selectedWorkspace?.value, token);
        if (resp?.session?.uid) {
          setTimeout(() => {
            window.location.reload();
          }, 0);
        }
      } else {
        localWorkspace?.set(inactiveWorkspace);
        setTimeout(() => {
          authSetLoading(false);
        }, 700);
      }
      setTimeout(() => {
        onClose();
      }, 300);
    }
  };

  const isSelectCurrentSelected = (isDisable || clinicList?.length === 1) && !selectedWorkspace?.value?.uid;

  return (
    <Modal
      disableBackdropClick
      isStrictMode={isStrictMode}
      modalProps={{
        style: {
          padding: 0,
          maxWidth: 640,
          width: 'calc(100% - 32px)',
        },
      }}
      open={open}>
      {!isSelectCurrentSelected && clinicList ? (
        <Box maxWidth={640} minWidth={250} p={6} borderRadius={16}>
          <Flex alignItems="center" justifyContent="flex-start" mb={6}>
            <H2>{`${t('Welcome ')} `}</H2>

            <Box as="span" ml={1}>
              <H2>{getCookieData()?.user?.fullName || ''}</H2>
            </Box>
          </Flex>
          <Flex pb={4}>
            <Box>
              Select the clinic workspace you want to log into. You can switch between workspaces anytime by
              clicking on the Clinic Name in the header bar.
            </Box>
          </Flex>
          <WorkspaceSelect
            activeWorkspaceStorage={activeWorkspaceStorage}
            clinicList={clinicList}
            selectedWorkspace={selectedWorkspace}
            setSelectedWorkspace={setSelectedWorkspace}
            user={data?.user}
            onSelect={() => {
              setIsDisable(false);
            }}
            inputProps={{
              label: 'Select workspace',
            }}
          />

          <Flex pt={2} justifyContent="end" alignContent="flex-end">
            <Box pl={2}>
              <PrimaryButton
                as="a"
                maxWidth="fit-content"
                borderRadius={8}
                marginLeft="auto"
                disabled={isSelectCurrentSelected || !clinicList}
                style={{
                  textDecoration: 'none',
                  color: 'white',
                  fontSize: 16,
                  fontWeight: 500,
                  background: isSelectCurrentSelected ? '#B3E7FF' : theme.colors.primary,
                }}
                onClick={onSubmit}>
                {t('Login to workspace')}
              </PrimaryButton>
            </Box>
          </Flex>
        </Box>
      ) : (
        <Box maxWidth={640} minWidth={250} p={6} borderRadius={16} my={6}>
          <Flex alignItems="center" justifyContent="center">
            <Lottie play animationData={LoadingImg} style={{ width: 150 }} />
          </Flex>
        </Box>
      )}
    </Modal>
  );
};

export default WorkspaceModal;
