import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faTimes as closeIcon } from '@fortawesome/pro-regular-svg-icons';
import moment from 'moment';
import { Flex, Box, H2, Button, Body, Text, SecondaryOutlinedButton, FAIcon, theme } from '@fivehealth/botero';
import Modal from '@/components/Modals/Modal';
import graphqlRequestClient from '@/api/client';
import { FaradayOrderExam } from '@/gql/generated/graphql';
import { useOrderExamDeleteMutation } from '@/gql/generated/graphql-hooks';
import { parseErrorMessageValue, transformTextToFormalCase } from '@/helpers/utils';
import { DATE_FORMAT_TXT } from '@/config/constants/misc.constants';
import SuccessImg from '@/assets/botmd-party-success.svg';
import LoadingImg from '@/assets/settings_loader.json';
import CryingBotSVG from '@/assets/crying-avatar.svg';
import Lottie from 'react-lottie-player';
import ExamBadge from '@/components/Badge/ExamBadge';
import { OrderStatusBadger } from '@/pages/Orders/OrdersCard';
import { queryKeyConst } from '@/pages/Orders/Orders';
import { QueryClient } from '@tanstack/react-query';
import { APIResponseType } from '@/helpers/types/ui.types';
import { OrdersCardBaseProps } from '@/pages/Orders/OrdersCardList';

type OrderCancelExamModalProps = {
  open: boolean;
  onClose: () => void;
  data: FaradayOrderExam;
  queryClient?: QueryClient;
  refetch?: OrdersCardBaseProps['refetch'];
};

const OrderCancelExamModal: FC<OrderCancelExamModalProps> = ({
  open,
  refetch,
  queryClient,
  onClose,
  data,
}) => {
  const { t } = useTranslation();
  const client = graphqlRequestClient();
  const [errMsg, setErrMsg] = useState('');
  const {
    mutate: orderExamCancelMutate,
    isLoading,
    isError,
    isSuccess,
  } = useOrderExamDeleteMutation(client, {
    mutationKey: ['cancelExamKey'],
    onSuccess: () => {
      queryClient?.invalidateQueries([...queryKeyConst.ordersList, ...queryKeyConst.order, ['cancelExamKey']]);
    },
    onError: (err: APIResponseType<any, any>) => {
      if (err) {
        setErrMsg(parseErrorMessageValue(err));
      }
      queryClient?.invalidateQueries([...queryKeyConst.ordersList, ...queryKeyConst.order, ['cancelExamKey']]);
    },
  });

  const handleOnClose = async () => {
    setErrMsg('');
    onClose();
    if (refetch) {
      await refetch();
    }
  };

  const handleOnSubmit = async () => {
    try {
      orderExamCancelMutate({ deleteInput: { uid: data?.uid } });
    } catch (e) {
      /* empty */
    }
  };

  const renderContent = () => (
    <>
      <Flex mb={3} justifyContent="space-between">
        <H2>{t(`Cancel Exam`)}</H2>
        <Button bg="transparent" onClick={handleOnClose}>
          <FAIcon icon={closeIcon} color="darkestShade" />
        </Button>
      </Flex>
      <Box mb={4}>
        <Body>{t('Are you sure you want to cancel this exam?')}</Body>

        <Box
          mt={4}
          display="grid"
          style={{
            gridTemplateColumns: `repeat(2,2fr)`,
            gridGap: '8px 24px',
            width: 420,
          }}>
          <Text width={110} color={theme.colors.darkestShade}>
            {t('Accession No')}
          </Text>
          <Text ml={-7} color={theme.colors.fullShade}>
            {transformTextToFormalCase(data?.accessionNo || '-')}
          </Text>

          <Text width={110} color={theme.colors.darkestShade}>
            {t('Status')}
          </Text>
          <Text ml={-7} color={theme.colors.fullShade}>
            <OrderStatusBadger orderExam={data} />
          </Text>

          <Text width={110} color={theme.colors.darkestShade}>
            {t('Exam')}
          </Text>
          <Text ml={-7} color={theme.colors.fullShade}>
            {data?.exam?.name}
          </Text>

          {data?.exam?.modality && (
            <>
              <Text width={110} color={theme.colors.darkestShade}>
                {t('Modality')}
              </Text>
              <Text>
                <Box ml={-8}>
                  <ExamBadge variant="badge" modality={data?.exam?.modality?.shortName || ''}>
                    {`${data?.exam.modality.shortName || ''} - ${data?.exam?.modality?.name || ''}`}
                  </ExamBadge>
                </Box>
              </Text>
            </>
          )}

          <Text width={110} color={theme.colors.darkestShade}>
            {t('Scheduled For')}
          </Text>
          <Text ml={-7} color={theme.colors.fullShade}>
            {moment(data?.scheduledFor).format(DATE_FORMAT_TXT.FORMAL_2)}
          </Text>
        </Box>
      </Box>

      <Flex justifyContent="flex-end">
        <SecondaryOutlinedButton borderRadius={8} mr={2} onClick={handleOnClose}>
          {t('Cancel')}
        </SecondaryOutlinedButton>
        <Button borderRadius={8} onClick={handleOnSubmit} color="emptyShade" bg="danger">
          {t('Cancel exam')}
        </Button>
      </Flex>
    </>
  );

  const renderLoader = () => (
    <>
      <Flex mb={4} justifyContent="space-between">
        <H2>{t(`Cancel Exam - Processing`)}</H2>
        <Button bg="transparent" onClick={handleOnClose}>
          <FAIcon icon={closeIcon} color="darkestShade" />
        </Button>
      </Flex>
      <Box mb={4}>
        <Flex justifyContent="center">
          <Lottie play animationData={LoadingImg} style={{ width: 170 }} />
        </Flex>
        <Flex justifyContent="center" mt={4}>
          <Text fontSize={16} fontWeight={500} color={theme.colors.darkestShade}>
            {t('Cancelling order...')}
          </Text>
        </Flex>
      </Box>
    </>
  );

  const renderSuccess = () => (
    <Box mb={2}>
      <Flex justifyContent="center" mt={2}>
        <Box as="img" width={170} src={SuccessImg} />
      </Flex>
      <Flex justifyContent="center" mt={4}>
        <Text fontSize={16} fontWeight={600} color={theme.colors.fullShade}>
          {t('Exam cancelled')}
        </Text>
      </Flex>

      <Flex justifyContent="center" mt={2}>
        <Text fontSize={16} fontWeight={500} color={theme.colors.darkestShade}>
          {moment(new Date()).format(DATE_FORMAT_TXT.FORMAL_1)}
        </Text>
      </Flex>

      <Flex justifyContent="center" mt={4}>
        <Button
          borderRadius={8}
          onClick={() => {
            handleOnClose();
          }}
          color="emptyShade"
          bg="primary">
          {t('Done')}
        </Button>
      </Flex>
    </Box>
  );

  const renderError = () => (
    <Box mb={2}>
      <Flex justifyContent="center" mt={2}>
        <Box as="img" width={120} src={CryingBotSVG} />
      </Flex>
      <Flex justifyContent="center" mt={4}>
        <Text fontSize={16} fontWeight={600} color={theme.colors.fullShade}>
          {t('Problem found in cancelling exam')}
        </Text>
      </Flex>

      <Flex justifyContent="center" mt={2}>
        <Text fontSize={16} fontWeight={500} color={theme.colors.darkestShade} style={{ textAlign: 'center' }}>
          {errMsg || t('Please ensure you have a stable connection or call admin support and try again.')}
        </Text>
      </Flex>

      <Flex justifyContent="center" mt={4}>
        <SecondaryOutlinedButton borderRadius={8} mr={2} onClick={handleOnClose}>
          {t('Okay')}
        </SecondaryOutlinedButton>
      </Flex>
    </Box>
  );

  const isShowContent = !isLoading && !errMsg && !isSuccess;
  return (
    <Modal
      modalProps={{
        style: {
          padding: 32,
          boxSizing: 'border-box',
          maxWidth: 650,
          width: 'calc(100% - 32px)',
        },
      }}
      open={open}
      onClose={onClose}>
      <Box>
        {isLoading && renderLoader()}
        {errMsg && isError && renderError()}
        {isSuccess && renderSuccess()}
        {isShowContent && renderContent()}
      </Box>
    </Modal>
  );
};

export default OrderCancelExamModal;
