import React from 'react';
import { theme, H3 } from '@fivehealth/botero';
import Input, { InputProps } from '@/components/Input/Input';
import { FaradayClinic } from '@/gql/generated/graphql';
import { WorkspaceStorageTypes } from '@/helpers/types/system.types';
import { DataCtxStateTypes } from '@/context';
import Select, { Props as RSelectProps } from 'react-select';
import { chain } from 'lodash';

export type WorkspaceItemType = {
  value: FaradayClinic;
  label: JSX.Element | React.ReactElement | string;
  isDisabled?: boolean;
  isFixed?: boolean;
};

type WorkspaceSelectProps = {
  onSelect: (val?: WorkspaceItemType) => void;
  activeWorkspaceStorage: WorkspaceStorageTypes | null | undefined;
  user?: DataCtxStateTypes['user'];
  clinicList: DataCtxStateTypes['clinicList'];
  selectedWorkspace: WorkspaceItemType | null;
  setSelectedWorkspace: (val: WorkspaceItemType | null) => void;
  selectProps?: RSelectProps;
  inputProps?: InputProps;
};
export const WorkspaceSelect: React.FC<WorkspaceSelectProps> = ({
  onSelect,
  activeWorkspaceStorage,
  user,
  clinicList,
  selectedWorkspace,
  setSelectedWorkspace,
  selectProps,
  inputProps,
}) => {
  const activeClinicUid = activeWorkspaceStorage?.clinic?.uid
    ? activeWorkspaceStorage?.clinic?.uid
    : user?.clinic?.uid;

  const mappedClinics = chain(clinicList)
    .map((j) => ({
      value: j,
      label: `${j.name.toUpperCase()}`,
      isFixed: j.uid === activeClinicUid,
    }))
    .sortBy('label')
    .value();

  const handleOnchange = (val: WorkspaceItemType) => {
    if (mappedClinics?.length === 1) return;
    setSelectedWorkspace(val);
    if (onSelect) {
      onSelect(val);
    }
  };

  return mappedClinics && mappedClinics?.length > 0 ? (
    <Input
      data-testid="workspace_clinic_input_field"
      disabled={!mappedClinics}
      rootProps={{ mx: 1 }}
      style={{
        marginBottom: 8,
      }}
      labelProps={{ mb: '4px' }}
      label={inputProps?.label || ''}
      inputComponent={
        <Select
          data-testid="workspace_clinic_input_select"
          options={mappedClinics}
          menuPlacement="auto"
          menuPosition="fixed"
          isClearable
          isSearchable
          placeholder="Select workspace"
          value={selectedWorkspace}
          components={{ IndicatorSeparator: () => null }}
          hideSelectedOptions
          onChange={(val) => handleOnchange(val as WorkspaceItemType)}
          noOptionsMessage={() => null}
          styles={{
            control: (base) => ({
              ...base,
              borderRadius: 8,
              color: 'black',
            }),
            // singleValue: (base) => ({
            //   ...base,
            //   color: theme.colors.primary,
            // }),
            option: (base, state) => {
              // NOTE: For some reason there is no data type defs in react-select so we force any for now
              const isSelected = (state.data as any).isFixed;
              return {
                ...base,
                color: isSelected ? theme.colors.primary : null,
                cursor: 'pointer',
                display: 'inline-block',
                paddingLeft: '10px',
                ':before': {
                  position: 'absolute',
                  left: '5px',
                },
                ':active': {
                  color: '#fff',
                  backgroundColor: theme.colors.primary,
                },
                ':after': {
                  color: '#fff',
                  backgroundColor: theme.colors.primary,
                },
              };
            },
          }}
          {...selectProps}
        />
      }
      {...inputProps}
    />
  ) : (
    <H3 ml={2}>...</H3>

    // <H3 ml={2}>
    //   {' '}
    //   <SpinningIcon spin />
    // </H3>
  );
};
