import { FAIcon } from '@fivehealth/botero';
import { faSyncAlt } from '@fortawesome/pro-regular-svg-icons';
import styled, { keyframes } from 'styled-components';
import React from 'react';
import { FontAwesomeIconLibProps } from '@/helpers/types/lib.types';

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Container = styled(FAIcon)`
  animation: ${(props) => (props.spin ? rotate360 : '')} 1s linear infinite;
  transform: translateZ(0);
  color: ${(props) => props.color};
  font-size: ${(props) => props.fontSize};
  display: inline-block;
`;

export type SpinningIconProps = {
  iconProps?: Partial<FontAwesomeIconLibProps>;
  spin?: boolean;
  icon?: React.ReactNode;
};

const SpinningIcon: React.FunctionComponent<SpinningIconProps> = ({ spin = true, icon, iconProps }) => (
  <Container
    spin={spin}
    icon={icon || faSyncAlt}
    fontSize="14px"
    cursor="pointer"
    color="darkestShade"
    {...iconProps}
  />
);

export default SpinningIcon;
