import { TemporaryDowtimeBanner } from '@/components/Banner/TemporaryDowntimeBanner';
import { Suspense, useCallback, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Box, Flex, H3, Text } from '@fivehealth/botero';
import Sidebar from '@/components/Sidebar/Sidebar';
import Header from '@/components/Header/Header';
import { TRANSITION } from '@/config/constants/styles.constants';
import { getWorkspacesData, localWorkspace, saveWorkspace } from '@/helpers/utils/storage.utils';
import { useAuthContext, useDataContext } from '@/context';
import WorkspaceModal from '@/components/Modals/WorkspaceModal';
import { ROUTES } from '@/routes/Router';
import AnimatedLoadingLottie from '@/components/Loader/AnimatedLoadingLottie';
import { useMediaQuery } from 'react-responsive';
import CryingBotSVG from '@/assets/crying-avatar.svg';

function Layout() {
  const isMobileView = useMediaQuery({ query: '(max-width: 900px)' });
  const [workspaceModalOpen, setWorkspaceModalOpen] = useState(false);
  const [sideBarExpand, setSideBarExpand] = useState<boolean>();
  const workspaceData = getWorkspacesData();
  const location = useLocation();
  // const [renderPreviewPane, setRenderPreviewPane] = useState(false);
  // NOTE: Show the chatwidget only in the main page
  // const [open, setOpen] = useState(location.pathname === ROUTES.ORDERS.ROOT);

  const {
    data: { user },
  } = useDataContext();
  const {
    authState: { isAuthenticated, isLoading: authIsLoading },
  } = useAuthContext();

  const activeWorkspace = localWorkspace.getActive();

  const isUnauthorized = !isAuthenticated && location.pathname === ROUTES.LOGIN.ROOT;

  // NOTE: Temporarily removed as we dont need the chabot widget for now
  // const [open, setOpen] = useState(false); // NOTE: Temporary hide this coz very annoying
  // const toggleOpen = useCallback(() => setOpen(!open), [open]);
  // useEffect(() => {
  //   window.addEventListener('message', (event) => {
  //     if (event.data.closeWidget) {
  //       toggleOpen();
  //     }
  //     if (event.data?.listener) {
  //       // const previewMode = { previewMode: true };
  //       // setTimeout(() => {
  //       //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //       //   // @ts-expect-error
  //       //   window.frames[0].postMessage(previewMode, '*');
  //       // }, 100);
  //       setTimeout(() => {
  //         setRenderPreviewPane(true);
  //       }, 200);
  //     }
  //   });
  // }, [toggleOpen]);

  useEffect(() => {
    const hasNoWorkspaceActive = workspaceData?.length === 0 || !activeWorkspace;
    // NOTE: This condition (workspaceData | workspaceModalOpen | isAuthenticated | authIsLoading) should always be true to avoid infinite loop in react deps
    if (
      hasNoWorkspaceActive &&
      !workspaceModalOpen &&
      isAuthenticated &&
      authIsLoading &&
      !localWorkspace.getActive()
    ) {
      setWorkspaceModalOpen(true);
    }
  }, [isAuthenticated, authIsLoading, workspaceData, workspaceModalOpen, activeWorkspace, location.pathname]);

  useEffect(() => {
    if (workspaceModalOpen && !!user) {
      // NOTE: This will initially set the workspace localstore even before user selected a workspace to ensure consistent active workspace
      saveWorkspace(user, { active: false });
    }
  }, [user, workspaceModalOpen]);

  const renderFallback = useCallback(() => <AnimatedLoadingLottie />, []);

  const onCloseWorkspaceModal = useCallback(() => {
    setWorkspaceModalOpen(false);
  }, []);

  const renderSuspenseOutlet = () => (
    <Suspense fallback={renderFallback()}>
      <Outlet />
    </Suspense>
  );

  const renderMainContent = () =>
    !isUnauthorized ? (
      <>
        <WorkspaceModal open={workspaceModalOpen} onClose={onCloseWorkspaceModal} />
        <Sidebar display={['none', 'initial']} sidebarRef={(ref: any) => setSideBarExpand(ref?.expand)} />
        <Flex
          flexDirection="column"
          alignItems="center"
          ml={['auto', sideBarExpand ? 280 : 80]}
          height="100vh"
          width="100%"
          style={{ transition: `margin-left ${TRANSITION}` }}>
          <Flex width="100%" flexDirection="column" maxWidth={1920} px={[2, 4, '60px']}>
            <Box mb={4}>
              <Header currentUser={authIsLoading ? undefined : (localWorkspace.getActive() as never)} />
            </Box>
            {
              // NOTE: Enable this if needed
              // <TemporaryDowtimeBanner />
            }
            <TemporaryDowtimeBanner isHideable />
            {renderSuspenseOutlet()}
          </Flex>
        </Flex>
        {
          // TODO: We temporarily disable chatbot for now
          // open && <ChatbotWidget open={open} renderPreviewPane={renderPreviewPane} toggleOpen={toggleOpen} />
        }
      </>
    ) : (
      renderSuspenseOutlet()
    );
  return (
    <Flex width="100%" height="100vh">
      {isMobileView ? (
        <Flex
          width="100%"
          height="100%"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          flexDirection="column"
          p={2}>
          <Box as="img" width={120} src={CryingBotSVG} mb={2} />

          {
            // NOTE: Enable this if needed
            // <Box mt={2}>
            //   <TemporaryDowtimeBanner />
            // </Box>
          }

          <Box mt={2}>
            <TemporaryDowtimeBanner isHideable />
          </Box>

          <H3 style={{ textAlign: 'center' }}>Sorry, unable to support mobile or small screen size.</H3>
          <Text style={{ textAlign: 'center' }} mt={2}>
            To properly view the page please use a desktop with a higher screen resolution.
          </Text>
        </Flex>
      ) : (
        renderMainContent()
      )}
    </Flex>
  );
}

export default Layout;
