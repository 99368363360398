import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faTimes as closeIcon } from '@fortawesome/pro-regular-svg-icons';
import moment from 'moment';
import { Flex, Box, H2, Button, Text, SecondaryOutlinedButton, FAIcon, theme } from '@fivehealth/botero';
import Modal from '@/components/Modals/Modal';
import graphqlRequestClient from '@/api/client';
import {
  FaradayMessageTemplate,
  FaradayMessageTemplateDeliverToPatientInput,
  FaradayOrder,
  FaradayPatientProfile,
} from '@/gql/generated/graphql';
import {
  useMessageTemplateDeliverToPatientMutation,
  useMessageTemplatesQuery,
} from '@/gql/generated/graphql-hooks';
import {
  queryUtil,
  transformTextToFormalCase,
  formatPhoneNumber,
  parseErrorMessageValue,
} from '@/helpers/utils';
import { DATE_FORMAT_TXT } from '@/config/constants/misc.constants';
import { APIResponseType, LabelValueOptionsType } from '@/helpers/types/ui.types';
import MessageSendingCompleteGIF from '@/assets/message-sending-complete.gif';
import MessageSendingGIF from '@/assets/message-sending.gif';
import CryingBotSVG from '@/assets/crying-avatar.svg';
import { isEmpty, startCase } from 'lodash';

type SendOrderFormModalProps = {
  open: boolean;
  onClose: () => void;
  patientProfileData?: FaradayPatientProfile;
  order: FaradayOrder;
};

const SendOrderFormModal: FC<SendOrderFormModalProps> = ({ open, onClose, patientProfileData, order }) => {
  const { t } = useTranslation();
  const client = graphqlRequestClient();
  const [errMsg, setErrMsg] = useState('');
  const [selectedMsgTemplate, setSelectedMsgTemplate] =
    useState<LabelValueOptionsType<FaradayMessageTemplate> | null>(null);

  const {
    mutateAsync: sendMsgTemplateMutation,
    isLoading: isSendMsgTemplateLoading,
    isError: isSendMsgTemplateError,
    isSuccess: isSendMsgTemplateSuccess,
  } = useMessageTemplateDeliverToPatientMutation(client, {
    onError: (err: APIResponseType<any, any>) => {
      if (err) {
        setErrMsg(parseErrorMessageValue(err));
      }
    },
  });

  const { data: msgTemplateLabelValueList } = useMessageTemplatesQuery<
    LabelValueOptionsType<FaradayMessageTemplate>[]
  >(
    client,
    {},
    {
      select: ({ faradayMessageTemplates }) =>
        queryUtil
          .edgeNodeOmitter<typeof faradayMessageTemplates, FaradayMessageTemplate[]>(faradayMessageTemplates)
          .map((o) => ({
            label: startCase(o.name),
            value: o,
          }))
          .filter((f) => f.value?.name === 'appointment_confirmation'),
    }
  );
  const handleOnClose = () => {
    onClose();
  };

  const handleOnSubmit = async () => {
    try {
      const input: FaradayMessageTemplateDeliverToPatientInput = {
        messageTemplateUid: selectedMsgTemplate?.value?.uid as string,
        patientProfileUid: patientProfileData?.uid as string,
        userVariables: [] as never[],
        orderUid: order?.uid,
      };

      await sendMsgTemplateMutation({ input });
    } catch (e) {
      /* empty */
    }
  };

  if (isEmpty(selectedMsgTemplate) && !isEmpty(msgTemplateLabelValueList)) {
    setSelectedMsgTemplate(msgTemplateLabelValueList?.[0] as LabelValueOptionsType<FaradayMessageTemplate>);
  }

  const renderInputTemplate = () => (
    <>
      <Flex mb={4} justifyContent="space-between">
        <H2>{t(`Send order form to patient`)}</H2>
        <Button bg="transparent" onClick={handleOnClose}>
          <FAIcon icon={closeIcon} color="darkestShade" />
        </Button>
      </Flex>
      <Box mb={4}>
        <Box
          display="grid"
          style={{
            gridTemplateColumns: `repeat(2,2fr)`,
            gridGap: '8px 24px',
            width: 300,
          }}>
          <Text color={theme.colors.darkestShade}>{t('Patient')}</Text>
          <Text color={theme.colors.fullShade}>
            {transformTextToFormalCase(patientProfileData?.fullName || '')}
          </Text>

          <Text color={theme.colors.darkestShade}>{t('Phone number')}</Text>
          <Text color={theme.colors.fullShade}>{formatPhoneNumber(patientProfileData?.phone || '')}</Text>

          <Text color={theme.colors.darkestShade}>{t('Message via')}</Text>
          <Text color={theme.colors.fullShade}>
            {(selectedMsgTemplate?.value as unknown as any)?.template?.message?.type || 'Whatsapp'}
          </Text>

          <Text color={theme.colors.darkestShade}>{t('Accession(s)')}</Text>
          <Flex flexDirection="column">
            {order?.orderExams?.map((o) => (
              <Text key={o.accessionNo} color={theme.colors.fullShade}>{`# ${o.accessionNo || '-'}`}</Text>
            ))}
          </Flex>
        </Box>
        {
          // NOTE: No need for the template dropdown
          // <Box mt={4}>
          //   <Input
          //     label={t('Send message')}
          //     // errorMessage={errorsParam.users?.[i]?.accountType?.message}
          //     inputComponent={
          //       <Select
          //         styles={{
          //           control: (baseStyles) => ({
          //             ...baseStyles,
          //             borderRadius: 8,
          //           }),
          //           valueContainer: (base) => ({
          //             ...base,
          //             fontSize: 14,
          //           }),
          //           menuList: (base) => ({
          //             ...base,
          //             fontSize: 14,
          //           }),
          //         }}
          //         components={{ IndicatorSeparator: () => null }}
          //         menuPlacement="auto"
          //         menuPosition="fixed"
          //         placeholder={t('Select')}
          //         value={msgTemplateLabelValueList?.[0]}
          //         onChange={(selected) => {
          //           setSelectedMsgTemplate(selected);
          //         }}
          //         defaultValue={msgTemplateLabelValueList?.[0]}
          //         options={msgTemplateLabelValueList}
          //       />
          //     }
          //   />
          // </Box>
        }
      </Box>

      <Flex justifyContent="flex-end">
        <SecondaryOutlinedButton borderRadius={8} mr={2} onClick={handleOnClose}>
          {t('Cancel')}
        </SecondaryOutlinedButton>
        <Button
          borderRadius={8}
          onClick={handleOnSubmit}
          color="emptyShade"
          bg="primary"
          disabled={!msgTemplateLabelValueList?.[0]}>
          {t('Send now')}
        </Button>
      </Flex>
    </>
  );

  const renderSendingTplLoader = () => (
    <>
      <Flex mb={4} justifyContent="space-between">
        <H2>{t(`Send order form to patient`)}</H2>
        <Button bg="transparent" onClick={handleOnClose}>
          <FAIcon icon={closeIcon} color="darkestShade" />
        </Button>
      </Flex>
      <Box mb={4}>
        <Flex justifyContent="center">
          <Box as="img" width={170} src={MessageSendingGIF} />
        </Flex>
        <Flex justifyContent="center" mt={4}>
          <Text fontSize={16} fontWeight={500} color={theme.colors.darkestShade}>
            {t('Sending message to patient...')}
          </Text>
        </Flex>
      </Box>
    </>
  );

  const renderSentTpl = () => (
    <Box mb={2}>
      <Flex justifyContent="center" mt={2}>
        <Box as="img" width={170} src={MessageSendingCompleteGIF} />
      </Flex>
      <Flex justifyContent="center" mt={4}>
        <Text fontSize={16} fontWeight={600} color={theme.colors.fullShade}>
          {t('Order form sent')}
        </Text>
      </Flex>

      <Flex justifyContent="center" mt={2}>
        <Text fontSize={16} fontWeight={500} color={theme.colors.darkestShade}>
          {moment(new Date()).format(DATE_FORMAT_TXT.FORMAL_1)}
        </Text>
      </Flex>

      <Flex justifyContent="center" mt={4}>
        <Button
          borderRadius={8}
          onClick={() => {
            handleOnClose();
          }}
          color="emptyShade"
          bg="primary">
          {t('Done')}
        </Button>
      </Flex>
    </Box>
  );

  const renderSendingErr = () => (
    <Box mb={2}>
      <Flex justifyContent="center" mt={2}>
        <Box as="img" width={120} src={CryingBotSVG} />
      </Flex>
      <Flex justifyContent="center" mt={4}>
        <Text fontSize={16} fontWeight={600} color={theme.colors.fullShade}>
          {t('Error: Server not found')}
        </Text>
      </Flex>

      <Flex justifyContent="center" mt={2}>
        <Text fontSize={16} fontWeight={500} color={theme.colors.darkestShade} style={{ textAlign: 'center' }}>
          {errMsg || t('Message not sent. Please ensure you have a stable connection and try again.')}
        </Text>
      </Flex>

      <Flex justifyContent="center" mt={4}>
        <SecondaryOutlinedButton borderRadius={8} mr={2} onClick={handleOnClose}>
          {t('Cancel')}
        </SecondaryOutlinedButton>
        <Button
          borderRadius={8}
          onClick={() => {
            handleOnSubmit();
          }}
          color="emptyShade"
          bg="primary">
          {t('Try again')}
        </Button>
      </Flex>
    </Box>
  );

  const isShowContent = !isSendMsgTemplateLoading && !errMsg && !isSendMsgTemplateSuccess;

  return (
    <Modal
      modalProps={{
        style: {
          padding: 32,
          boxSizing: 'border-box',
          maxWidth: 650,
          width: 'calc(100% - 32px)',
        },
      }}
      open={open}
      onClose={onClose}>
      {isSendMsgTemplateLoading && renderSendingTplLoader()}
      {errMsg && isSendMsgTemplateError && renderSendingErr()}
      {isSendMsgTemplateSuccess && renderSentTpl()}
      {isShowContent && renderInputTemplate()}
    </Modal>
  );
};

export default SendOrderFormModal;
