import React, { PropsWithChildren } from 'react';
import { Dialog } from '@fivehealth/botero';

export type ModalProps = {
  open: boolean;
  onClose?: () => void;
  modalProps?: any;
  disableBackdropClick?: boolean;
  isStrictMode?: boolean; // NOTE: This property will make disable all the close modal event and restricted it in specific condition
};

const Modal: React.FC<PropsWithChildren<ModalProps>> = ({
  open,
  onClose,
  modalProps,
  disableBackdropClick = false,
  isStrictMode = false,
  children,
}) => {
  const overlayProps = {
    overlayProps: {
      onClick: () => {
        if (!disableBackdropClick && onClose) {
          onClose();
        }
      },
    },
  };

  const onCloseHandler = () => {
    if (!isStrictMode && onClose) {
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onCloseHandler} {...overlayProps} {...modalProps}>
      {children}
    </Dialog>
  );
};

export default Modal;
