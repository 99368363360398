import moment from 'moment/moment';
import parsePhoneNumber from 'libphonenumber-js';
import { isEmpty, startCase, trim, truncate } from 'lodash';
import { getEnumKeyByEnumVal } from '@/helpers/utils/object.utils';
import { APIResponseType } from '@/helpers/types/ui.types';

/**
 * Tweak page name.
 * @param str
 */
export const tweakPageName = (str: string) => {
  const pageName = str.replace(/-/g, ' ');
  return pageName.charAt(0).toUpperCase() + pageName.slice(1);
};

/**
 * Make text transform to proper title format
 * @param str
 * @param separator
 */
export const transformTextToFormalCase = (str: string, separator = ' ') =>
  str
    .split(separator)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');

// const finalSentence = mySentence.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
/**
 * Add slash to dates
 * @param date
 */
export const addSlashesToDate = (date: string): string => {
  const len = date.length;
  if (len === 3 && date[1] === '/') return `0${date}`;
  if (len === 3 && date[2] !== '/') return `${date.slice(0, 2)}/${date.slice(2)}`;
  if (len === 6 && date[4] === '/') return `${date.slice(0, 3)}0${date.slice(3, 6)}`;
  if (len === 6 && date[5] !== '/') return `${date.slice(0, 5)}/${date.slice(5)}`;
  return date;
};

/**
 * Phone number text formatter
 * @param value
 */
export const formatPhoneNumber = (value: string) => {
  if (!value) {
    return '';
  }
  const phoneNumber = parsePhoneNumber(value);
  return phoneNumber?.formatInternational();
};

export const displayPhoneText = (phone: string) =>
  formatPhoneNumber(phone.startsWith('+') ? phone : `+${phone}`);

/**
 * Generate some random number.
 */
export const generateRandomNumber = () => Math.floor(Math.random() * 7 + new Date().getTime());

/**
 * Add two times with a duration in seconds.
 * Returs time in this form 00:00
 * @param startTime
 * @param durationInSeconds
 */
export const addTimeSecondsDuration = (startTime: string, durationInSeconds: number | string) =>
  moment(startTime, 'HHmm').add(durationInSeconds?.toString(), 'seconds').format('HH:mm');

/**
 * Valid email string checker.
 * @param email
 */
export const validateEmail = (email: string) => {
  /* eslint no-useless-escape: 0 */
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

/**
 * Regex to check and validate if the text is a valid phone number
 * @param value
 */
export const validPhoneNumberRegex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/;

/**
 * Get the order exam status display text.
 * @param enumType
 * @param enumVal
 */
export const getOrderExamStatusText = <T extends Record<string, any>>(enumType: T, enumVal: string) =>
  trim(startCase(getEnumKeyByEnumVal(enumType, enumVal as any)));

/**
 * Format time 2351 to 23:51
 * @param time
 * @returns
 */
export const militaryTimeFormat = (time: number | string | null | undefined) =>
  !time ? '' : `${time?.toString().slice(0, 2)}:${time?.toString().slice(2, 4)}`;

/**
 * Conver at  'null' string to null object.
 * @param param
 */
export const nullTextParser = (param: string | null) => (param?.toLowerCase() === 'null' ? null : param);

// /**
//  * Parse date and time.
//  * @param date
//  * @param time
//  */
// export const dateTimeISOParser = (date: string, time: string) => `${date}T${time || ''}:00+08:00`;

/**
 * Get text display for selected time slot range from start to end  from an string array
 * Example: "00:00 - 00:00"
 * @param time
 */
export const getTextSelectedTimeSlot = (time: string[]) => {
  let result = { start: '', end: '' };
  if (time) {
    result = {
      start: militaryTimeFormat(time[0] as string),
      end: militaryTimeFormat(time[1] as string),
    };
  }
  if (!isEmpty(time) && time[0]?.toUpperCase() === 'WALK') {
    result = {
      start: time[0],
      end: time[1] as string,
    };
  }
  return `${result?.start} - ${result?.end}`;
};

/**
 * Parse error message from graphql api
 * @param apiResponse
 */
export const parseErrorMessageValue = (apiResponse: APIResponseType) => {
  try {
    const errors = apiResponse?.response?.errors; // ?.map((o) => o.message);
    let msg = '';
    if (errors) {
      msg = errors[0]?.message as string;
    }

    return msg.replace(/[\[\]']+/g, '');
  } catch (error) {
    return 'Oops! Something went wrong. Please try again later.';
  }
};

/**
 * Text ellipsis for long string with a appended ommision text
 * @param text
 * @param length
 * @param omission
 */
export const textEllipsis = (text: string, length: number, omission = '...') =>
  truncate(text, { length, omission });
