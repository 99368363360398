import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import { GraphQLClient } from 'graphql-request';
import { QueryObserverResult } from '@tanstack/react-query';
import moment from 'moment';
import {
  Badge,
  Body,
  Box,
  Button,
  Dialog,
  FAIcon,
  Flex,
  H4,
  PrimaryButton,
  SecondaryOutlinedButton,
  Text,
  theme,
  Tooltip,
} from '@fivehealth/botero';
import {
  getTextSelectedTimeSlot,
  gotoReplaceUrlLink,
  militaryTimeFormat,
  unsecureRedirectPath,
} from '@/helpers/utils';
import { DATE_FORMAT_TXT } from '@/config/constants/misc.constants';
import { OutlinedBox } from '@/components/Box/OutlinedBox';
import { AdditionalFieldsType, ExamCollectionType, OrderStateType } from '@/pages/OrderCreate/OrderCreate';
import {
  faCalendarAlt as faCalendar,
  faCommentLines as faCommentFindings,
  faMapMarkerAlt as faMapMarker,
  faTimes as faClose,
  faUserMd,
} from '@fortawesome/pro-regular-svg-icons';
import { groupBy, isEmpty, isEqual } from 'lodash';
import {
  FacilityAvailabilityValueType,
  NavigateOrderStateType,
  StepDynamicActionConst,
} from '@/helpers/types/ui.types';
import {
  AvalibilityQueryVariables,
  Exact,
  FaradayAvailability,
  FaradayFacilityAvailability,
  FaradayOrderExam,
} from '@/gql/generated/graphql';
import { ROUTES } from '@/routes/Router';
import ExamBadge from '@/components/Badge/ExamBadge';
import PageHeader from '@/components/PageHeader/PageHeader';
import MessageBanner from '@/components/Banner/MessageBanner';
import BotmdPartySuccess from '@/assets/botmd-party-success.svg';
import BotmdFailed from '@/assets/crying-avatar.svg';

/**
 * Transiftion default configuration.
 */
export const getTransitionCfg = () => {
  const duration = 200;

  const defaultStyle = {
    // transition: `all ${duration}ms ease-in-out`,
    transition: 'all 0.5s ease-in-out',
    background: 'white',
    opacity: 0,
  };

  const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  };
  return {
    duration,
    defaultStyle,
    transitionStyles,
  };
};

export type StepActionsType<TNextParam = unknown> = {
  isReady: () => boolean;
  next: (param?: TNextParam) => void;
  previous?: () => void;
};

export type StepBoxContainerProps = {
  id: number | string; // NOTE: Maybe used the exam uid?
  cursor: number;
  title: string;
  description?: string;
  backLabel?: string;
  backUrl?: string; // NOTE: The url link (location.href) of the previous page before going to dynamic creation flow
  boxProps?: React.ComponentProps<typeof Box>;
  children: React.ReactNode;
  setOrderState: React.Dispatch<Partial<OrderStateType>>;
  orderState?: OrderStateType;
  client?: GraphQLClient;
};

export const PETInterventionBannerContent = () => (
  <Box>
    <Text as="span" color={theme.colors.primary} fontWeight={600}>
      Note:{' '}
    </Text>
    <Text as="span" color={theme.colors.primary}>
      PET, Nuclear medicine exams and interventional procedures are not available for online ordering. For
      patients who require special care (e.g. Sedation/Infectious) and patients with metal implants (for MRI
      exams), please call Parkway Radiology Call Centre at{' '}
      <Text as="span" color={theme.colors.primary} fontWeight={600}>
        +65 6388 4333
      </Text>
      .
    </Text>
  </Box>
);
/**
 * Navigates from back or foward across the order creation flow.
 * @param cursor
 */
export const stepNavigator = (cursor: number) => ({
  forward: Number(cursor) + 1,
  backward: Number(cursor) === 0 ? 0 : Number(cursor) - 1,
});

/**
 * Stebox container for the whole order creation flow.
 * @param id
 * @param cursor
 * @param title
 * @param description
 * @param backLabel
 * @param backUrl
 * @param children
 * @param boxProps
 * @param setOrderState
 * @constructor
 */
export const StepBoxContainer: React.FC<StepBoxContainerProps> = ({
  id,
  cursor,
  title,
  description,
  backLabel,
  backUrl,
  children,
  boxProps,
  setOrderState,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const nodeRef = useRef(null);

  return (
    <Transition nodeRef={nodeRef} in={cursor === id} timeout={getTransitionCfg().duration}>
      {(state) => (
        <div
          ref={nodeRef}
          style={{
            ...getTransitionCfg().defaultStyle,
            ...getTransitionCfg().transitionStyles[state],
          }}>
          {cursor === id && (
            <Box mt={0} id={`order_create_section_step-${cursor}-${id}`} {...boxProps}>
              <MessageBanner
                open={cursor === 3 || cursor === 4}
                content={t('Booking slots are not reserved until you have submitted the entire order.')}
                bannerProps={{ mb: 2 }}
              />

              <Box mb={2}>
                <PageHeader
                  title={title}
                  hasBackButton={!!backLabel}
                  backButtonProps={
                    backLabel
                      ? {
                          onClick: () => {
                            if (cursor === 1) {
                              if (backUrl) {
                                gotoReplaceUrlLink(backUrl);
                              } else {
                                navigate(ROUTES.ORDERS.ROOT);
                              }
                            } else {
                              setOrderState({
                                step: {
                                  cursor: stepNavigator(Number(cursor)).backward,
                                },
                              });
                            }
                          },
                          label: backLabel,
                        }
                      : undefined
                  }
                />
                <Box fontSize={16} fontWeight={400} mt={2} color="fullShade">
                  {description}
                </Box>
              </Box>

              <Flex flexDirection="row" justifyContent="end" width="auto" maxWidth={1130}>
                {cursor === 5 && (
                  <PrimaryButton
                    borderRadius={8}
                    ml={2}
                    onClick={() => {
                      unsecureRedirectPath(ROUTES.ORDERS.ROOT);
                    }}>
                    {t('Back to Orders')}
                  </PrimaryButton>
                )}
              </Flex>
              {children}
            </Box>
          )}
        </div>
      )}
    </Transition>
  );
};

/**
 * Patient detail summary in step 2.
 * @param patientName
 * @param urgent
 * @param clinical_findings
 * @param orderBy
 * @constructor
 */
export const PatientDetailsSummary = ({
  patientName,
  urgent,
  clinical_findings,
  orderBy,
}: {
  patientName: string;
  urgent: boolean;
  clinical_findings: string;
  orderBy: string;
}) => (
  <Box alignItems="left">
    <Flex flexDirection="column" alignItems="start">
      <Box pb={2}>
        <Body fontSize={13} pb={0.5} fontWeight={400} alignItems="center" color={theme.colors.darkestShade}>
          Patient
        </Body>
        <Body fontSize={14} fontWeight={400} alignItems="center" color={theme.colors.fullShade}>
          {patientName}
        </Body>
      </Box>

      <Box pb={2}>
        <Body fontSize={13} pb={0.5} fontWeight={400} alignItems="center" color={theme.colors.darkestShade}>
          Urgent?
        </Body>
        <Body fontSize={14} fontWeight={400} alignItems="center" color={theme.colors.fullShade}>
          {urgent ? 'Yes' : 'No'}
        </Body>
      </Box>

      <Box pb={2}>
        <Body fontSize={13} pb={0.5} fontWeight={400} alignItems="center" color={theme.colors.darkestShade}>
          Clinical findings
        </Body>
        <Body fontSize={14} fontWeight={400} alignItems="center" color={theme.colors.fullShade}>
          {clinical_findings}
        </Body>
      </Box>

      <Box pb={1}>
        <Body fontSize={13} pb={0.5} fontWeight={400} alignItems="center" color={theme.colors.darkestShade}>
          Ordered by
        </Body>
        <Body fontSize={14} fontWeight={400} alignItems="center" color={theme.colors.fullShade}>
          {orderBy}
        </Body>
      </Box>
    </Flex>
  </Box>
);

/**
 * Favorite text button component.
 * @param label
 * @param onClick
 * @param disabled
 * @constructor
 */
export const FavoriteButton = ({
  label,
  onClick,
  disabled,
}: {
  label: string;
  onClick?: (param: any) => void;
  disabled: boolean;
}) => (
  <Button bg="transparent" mt={0} py={0} px={0} color="primary" onClick={onClick} disabled={disabled}>
    <Text fontSize={12} color={theme.colors.primary}>
      {label}
    </Text>
  </Button>
);

/**
 * Maximum favourite exams to be added.
 */
export const MAX_FAV_EXAMS = 10 as const;

/**
 * Render exam box header component.
 * @param idx
 * @param label
 * @param handleDeleteExam
 * @param isEditMode
 */
export const renderExamBoxHeader = (
  idx: number,
  label: string,
  handleDeleteExam: (index: number) => void,
  isEditMode: boolean
) => (
  <Box
    style={{
      borderBottom: `1px solid ${theme.colors.mediumShade}`,
    }}>
    <Flex width="100%" flexDirection="row" justifyContent="space-between">
      <Box ml={2} mt="10px" fontSize={12} color="gray">{`${idx + 1}) ${label || ''}`}</Box>
      <Tooltip tooltip="Remove exam">
        <Box>
          <Button
            bg="transparent"
            color="primary"
            disabled={isEditMode}
            onClick={() => {
              handleDeleteExam(idx);
            }}>
            <FAIcon icon={faClose} fontWeight={300} color="danger" />
          </Button>
        </Box>
      </Tooltip>
    </Flex>
  </Box>
);

/**
 * Label header for dropdown select component groups.
 * @param label
 * @param count
 */
export const renderExamFormatGroupLabel = (label: string, count: number) => (
  <Flex flexDirection="row" justifyContent="space-between">
    <Box>{label}</Box>
    {label?.toLowerCase() === 'favorites' && (
      <Box style={{ background: '#80808017', borderRadius: 7, padding: 4 }}>
        {`${count} / ${MAX_FAV_EXAMS}`}
      </Box>
    )}
  </Flex>
);

/**
 * Exam labeler for exam status/badge.
 * @param modalityName
 * @param examName
 */
export const getExamLabeler = (modalityName: string, examName: string) => `${modalityName} - ${examName}`;

/**
 * Get the scheduled date time in iso format.
 * @param schedule
 * @param format
 */
const getSchedFormattedDate = (
  schedule: ExamCollectionType['schedule'],
  format: (typeof DATE_FORMAT_TXT)[keyof typeof DATE_FORMAT_TXT]
) => {
  const dateFormatted = moment(schedule?.date || '').format(format);

  if (schedule?.time && schedule?.time[0]?.toUpperCase() === 'WALK') {
    return `${dateFormatted} , ${schedule?.time[0]}-${schedule?.time[1]}`;
  }

  const time = getTextSelectedTimeSlot(schedule?.time as string[]);
  return !schedule?.date ? '-' : `${dateFormatted} , ${time}`;
};

type SelectedExamPickerProps = {
  t: any;
  orderState: OrderStateType;
  setOrderState: React.Dispatch<Partial<OrderStateType>>;
  handleScheduleActions?: StepActionsType | null | undefined;
  staticMode?: boolean;
  headerTitle?: string;
  disabled?: boolean;
  showCount?: boolean;
  renderCustomCount?: (data: Partial<OrderStateType>) => React.ReactNode;
  isLoading?: boolean;
  availabilityDataRefetch?: <TPageData>(
    options?: TPageData
  ) => Promise<QueryObserverResult<FaradayAvailability[]>>;
  availabilityQueryVars?: Partial<Exact<AvalibilityQueryVariables>>;
  setAvailabilityQueryVars?: React.Dispatch<React.SetStateAction<Partial<Exact<AvalibilityQueryVariables>>>>;
  onSelect?: (data: ExamCollectionType) => void;
  readonlyExams?: FaradayOrderExam[]; // NOTE: Only use for edit exam and edit add exam
};

/**
 * Component for selected exam in the order creation flow.
 * @param t
 * @param orderState
 * @param handleScheduleActions
 * @param staticMode
 * @param headerTitle
 * @param disabled
 * @param showCount
 * @param renderCustomCount
 * @param onSelect
 * @param readonlyExams
 * @constructor
 */
export const SelectedExamPicker: React.FC<SelectedExamPickerProps> = ({
  t,
  orderState,
  handleScheduleActions,
  staticMode = false,
  headerTitle = '',
  disabled,
  showCount,
  renderCustomCount,
  onSelect,
  readonlyExams,
}) => {
  async function handleSelectedExam(selectedExam: ExamCollectionType) {
    if (staticMode || disabled) return;
    if (onSelect) onSelect(selectedExam);
  }

  const getCursor = () => {
    let result;
    if (staticMode) {
      result = 'defult';
    }
    if (disabled) {
      result = 'not-allowed';
    }
    if (!staticMode && !disabled) {
      result = 'pointer';
    }

    return result;
  };

  return (
    <OutlinedBox
      boxProps={{
        mr: 4,
        p: 2,
        height: 'fit-content',
      }}>
      {renderCustomCount && !showCount ? (
        renderCustomCount(orderState)
      ) : (
        <Flex justifyContent="flex-start" width={300}>
          <H4 mb={2}>{t(headerTitle || 'Selected exams')}</H4>
          <Box
            fontSize={12}
            fontWeight={600}
            px={1}
            style={{
              background: '#eaeaea',
              borderRadius: 8,
              height: 18,
              marginLeft: 6,
              marginTop: 2,
            }}>
            {(orderState.exams?.length > 0 && handleScheduleActions?.isReady()) || showCount
              ? orderState.exams?.length
              : 0}
          </Box>
        </Flex>
      )}

      {orderState?.exams?.length > 0 &&
        orderState.exams?.map(
          (selectedExam, idx) =>
            selectedExam?.exam?.value && (
              <React.Fragment key={`selected_exam_rounded_box-${idx}-${selectedExam?.id || ''}`}>
                <OutlinedBox
                  boxProps={{ my: 2, style: { cursor: getCursor(), width: 314 } }}
                  isStatic={staticMode || disabled}
                  isSelected={selectedExam.isSelected && !staticMode && !disabled}
                  onClick={() => {
                    handleSelectedExam(selectedExam);
                  }}>
                  <Flex flexDirection="row" justifyContent="space-between">
                    <ExamBadge
                      key={selectedExam?.exam?.value?.uid}
                      variant="badge"
                      modality={selectedExam.exam?.value?.modality?.shortName as string}
                      rootProps={{ ml: 0, width: 260 }}>
                      {selectedExam.exam?.value?.name}
                    </ExamBadge>
                  </Flex>

                  <Box>
                    <Box pt={2} pb={1}>
                      <Flex flexDirection="row" justifyContent="flex-start">
                        <FAIcon
                          fontSize={12}
                          icon={faCommentFindings}
                          style={{
                            color: selectedExam?.isSelected && !staticMode ? theme.colors.primary : null,
                            marginTop: 3,
                          }}
                        />
                        <Text
                          fontSize={14}
                          pl={1}
                          color={selectedExam?.isSelected && !staticMode ? theme.colors.primary : null}>
                          {selectedExam?.comments || '-'}
                        </Text>
                      </Flex>
                    </Box>
                    <Box py="4px">
                      <Flex flexDirection="row" justifyContent="flex-start">
                        <FAIcon
                          fontSize={12}
                          icon={faUserMd}
                          style={{
                            color: selectedExam?.isSelected && !staticMode ? theme.colors.primary : null,
                            marginTop: 3,
                          }}
                        />
                        <Text
                          fontSize={14}
                          pl={1}
                          color={selectedExam?.isSelected && !staticMode ? theme.colors.primary : null}>
                          {selectedExam?.radiologist || '-'}
                        </Text>
                      </Flex>
                    </Box>
                    <Box py="4px">
                      <Flex flexDirection="row" justifyContent="flex-start">
                        <FAIcon
                          fontSize={12}
                          icon={faMapMarker}
                          style={{
                            color: selectedExam?.isSelected && !staticMode ? theme.colors.primary : null,
                            marginTop: 3,
                          }}
                        />
                        <Text
                          fontSize={14}
                          pl={1}
                          color={selectedExam?.isSelected && !staticMode ? theme.colors.primary : null}>
                          {!isEmpty(selectedExam?.selectedFacility)
                            ? ((selectedExam?.selectedFacility as unknown as FacilityAvailabilityValueType)
                                ?.value.facility?.name as string)
                            : '-'}
                        </Text>
                      </Flex>
                    </Box>
                    <Box py="4px">
                      <Flex flexDirection="row" justifyContent="flex-start">
                        <FAIcon
                          fontSize={12}
                          icon={faCalendar}
                          style={{
                            color: selectedExam.isSelected && !staticMode ? theme.colors.primary : null,
                            marginTop: 3,
                          }}
                        />
                        <Text
                          fontSize={14}
                          pl={1}
                          color={selectedExam.isSelected && !staticMode ? theme.colors.primary : null}>
                          {selectedExam.schedule
                            ? getSchedFormattedDate(selectedExam?.schedule, DATE_FORMAT_TXT.FORMAL_2)
                            : '-'}
                        </Text>
                      </Flex>
                    </Box>
                  </Box>
                </OutlinedBox>
              </React.Fragment>
            )
        )}

      {!isEmpty(readonlyExams) &&
        readonlyExams?.map((selectedExam, idx) => (
          <React.Fragment key={`readonly_selected_exam_rounded_box-${idx}-${selectedExam?.id || ''}`}>
            <OutlinedBox
              boxProps={{ my: 2, style: { cursor: 'default', width: 314, borderColor: '#e5e4e4' } }}
              isStatic>
              <Flex flexDirection="row" justifyContent="space-between">
                <ExamBadge
                  key={selectedExam?.uid}
                  variant="badge"
                  modality={selectedExam?.exam?.modality?.shortName}
                  rootProps={{ ml: 0, width: 260 }}>
                  {selectedExam.exam?.name}
                </ExamBadge>
              </Flex>

              <Box>
                <Box pt={2} pb={1}>
                  <Flex flexDirection="row" justifyContent="flex-start">
                    <FAIcon
                      fontSize={12}
                      icon={faCommentFindings}
                      style={{
                        marginTop: 3,
                      }}
                    />
                    <Text fontSize={14} pl={1}>
                      {selectedExam?.metadata?.comments || '-'}
                    </Text>
                  </Flex>
                </Box>
                <Box py="4px">
                  <Flex flexDirection="row" justifyContent="flex-start">
                    <FAIcon
                      fontSize={12}
                      icon={faUserMd}
                      style={{
                        marginTop: 3,
                      }}
                    />

                    <Text fontSize={14} pl={1}>
                      {selectedExam?.metadata?.attention_radiologist || '-'}
                    </Text>
                  </Flex>
                </Box>
                <Box py="4px">
                  <Flex flexDirection="row" justifyContent="flex-start">
                    <FAIcon
                      fontSize={12}
                      icon={faMapMarker}
                      style={{
                        marginTop: 3,
                      }}
                    />
                    <Text fontSize={14} pl={1}>
                      {selectedExam?.room?.facility?.name || '-'}
                    </Text>
                  </Flex>
                </Box>
                <Box py="4px">
                  <Flex flexDirection="row" justifyContent="flex-start">
                    <FAIcon
                      fontSize={12}
                      icon={faCalendar}
                      style={{
                        marginTop: 3,
                      }}
                    />
                    <Text fontSize={14} pl={1}>
                      {/* eslint-disable-next-line no-nested-ternary */}
                      {selectedExam?.exam?.modality?.shortName?.toString()?.toUpperCase() === 'PX' ||
                      selectedExam?.exam?.modality?.shortName?.toString()?.toUpperCase() === 'XR'
                        ? getSchedFormattedDate(
                            {
                              date: moment(selectedExam?.scheduledFor).format(DATE_FORMAT_TXT.YYYY_MM_DD_DASH),
                              time: ['WALK', 'IN ONLY', ''],
                            },
                            DATE_FORMAT_TXT.FORMAL_2
                          )
                        : selectedExam.scheduledFor
                        ? getSchedFormattedDate(
                            {
                              date: moment(selectedExam?.scheduledFor).format(DATE_FORMAT_TXT.YYYY_MM_DD_DASH),
                              time: [
                                moment(selectedExam?.scheduledFor).format(DATE_FORMAT_TXT.DATE_TIME_1_CLEAN),
                                moment(selectedExam?.scheduledFor)
                                  .add(selectedExam?.exam?.duration, 'seconds')
                                  .format(DATE_FORMAT_TXT.DATE_TIME_1_CLEAN),
                                '',
                              ],
                            },
                            DATE_FORMAT_TXT.FORMAL_2
                          )
                        : '-'}
                    </Text>
                  </Flex>
                </Box>
              </Box>
            </OutlinedBox>
          </React.Fragment>
        ))}
    </OutlinedBox>
  );
};

export const SlotTimesButton = ({
  selected = false,
  disabled = false,
  onClick,
  slot,
}: {
  selected?: boolean;
  disabled?: boolean;
  onClick?: (param: string[]) => void;
  slot: string[];
}) =>
  selected ? (
    <PrimaryButton borderRadius={8} height={40} my={1} width={248} maxWidth={248} id={`${slot[2]}`}>
      <Text
        fontSize={14}
        fontWeight={500}
        color="white"
        px={2}
        style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
        {slot[0]?.toUpperCase() === 'WALK'
          ? `${slot[0]}-${slot[1]}`
          : `${militaryTimeFormat(slot[0] as string)} - ${militaryTimeFormat(slot[1] as string)}`}
      </Text>
    </PrimaryButton>
  ) : (
    <SecondaryOutlinedButton
      id={`${slot?.toString()}`}
      borderRadius={8}
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation();
        if (onClick) onClick(slot);
      }}
      color={theme.colors.primary}
      height={40}
      borderColor={theme.colors.primary}
      backgroundColor={selected ? theme.colors.primary : 'white'}
      autoFocus={selected}
      width={248}
      maxWidth={248}
      disabled={disabled}
      my={1}>
      <Text
        fontSize={14}
        fontWeight={500}
        color={theme.colors.primary}
        px={2}
        style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
        {slot[0]?.toUpperCase() === 'WALK'
          ? `${slot[0]}-${slot[1]}`
          : `${militaryTimeFormat(slot[0] as string)} - ${militaryTimeFormat(slot[1] as string)}`}
      </Text>
    </SecondaryOutlinedButton>
  );

export const LocationFacilityAllExamLabel = ({ selectedName }: { selectedName: string }) => (
  <Box width="auto">
    <Flex flexDirection="column" justifyContent="flex-start">
      <Text mt={1} color="inherit">
        {selectedName}
      </Text>
      <Badge color={theme.colors.success} backgroundColor="#DCF7E8" width={140} mt={1} ml="-1px" fontSize={14}>
        All exams available
      </Badge>
    </Flex>
  </Box>
);

export const LocationFacilitySingleExamLabel = ({ data }: { data: FaradayFacilityAvailability }) => (
  <Box>
    <Text mt={1} color="inherit">
      {data?.facility?.name || ''}
    </Text>
    <Flex justifyContent="flex-start" width={560}>
      {data.exams?.map((k) => (
        <Box key={`location_opt_specific_badge_modality_${k?.uid}`} ml={-1} pr="6px" pt="2px" mt={1}>
          <ExamBadge variant="badge" modality={k.modality?.shortName} rootProps={{ fontSize: 12 }}>
            {`${k.name}`}
          </ExamBadge>
        </Box>
      ))}
    </Flex>
  </Box>
);

/**
 * /NOTE: Compoare time slots
 * time = ['2230', '2330', 'rommUid123'] - [<start time>, <end time>, <room uid>]
 * @param from
 * @param to
 */
export const isSlotTimeSelected = (from: string[], to: string[]) => isEqual(from, to);

/**
 * Get the action mode for an edit order
 * @param navState
 */
export const getEditActionMode = (navState: Partial<NavigateOrderStateType>) => ({
  order: () => navState?.step?.action === StepDynamicActionConst.EDIT_ORDER && !isEmpty(navState?.order),
  exam: () => navState?.step?.action === StepDynamicActionConst.EDIT_EXAM && !isEmpty(navState?.order),
  addExam: () => navState?.step?.action === StepDynamicActionConst.ADD_EXAM && !isEmpty(navState?.order),
});

/**
 * Dialog modal confirmation for order creation flow.
 * @param t
 * @param open
 * @param onClose
 * @param onAfterClose
 * @param isEdit
 * @constructor
 */
export const DialogConfirmation = ({
  t,
  open,
  onClose,
  onAfterClose,
  isEdit,
}: {
  t: any;
  open: boolean;
  onClose: (val: boolean) => void;
  onAfterClose: () => void;
  isEdit: boolean;
}) => (
  <Dialog open={open} onClose={onClose} style={{ width: 600 }} isStrictMode>
    <Box px={2} pb={2}>
      <Flex flexDirection="column">
        <Flex justifyContent="center">
          <Box as="img" width={126} height={120} src={BotmdPartySuccess} />
        </Flex>
        <Flex justifyContent="center" mt={2}>
          <Text fontSize={20} fontWeight={600}>
            {t(!isEdit ? 'Appointment booking confirmed' : 'Appointment booking updated')}
          </Text>
        </Flex>
        <Flex justifyContent="center" px={2} pb={4} pt={2}>
          <Body fontSize={16} fontWeight={400} textAlign="center">
            {t(
              'A digital order form has been sent to the Patient’s WhatsApp. Appointments can be modified up to 11:59pm the day before the exam.'
            )}
          </Body>
        </Flex>
        <Flex justifyContent="center">
          <PrimaryButton
            borderRadius={8}
            ml={2}
            onClick={() => {
              onClose(false);
              onAfterClose();
            }}
            style={{ width: 200 }}>
            {t('View order form')}
          </PrimaryButton>
        </Flex>
      </Flex>
    </Box>
  </Dialog>
);

/**
 * Error dialog modal for order creation flow.
 * @param navigate
 * @param t
 * @param open
 * @param onClose
 * @param message
 * @param title
 * @param goBackOpt
 * @constructor
 */
export const DialogError = ({
  navigate,
  t,
  open,
  onClose,
  message,
  title,
  goBackOpt = true,
}: {
  navigate: NavigateFunction;
  t: any;
  open: boolean;
  onClose: (val: boolean) => void;
  message: React.ReactNode | string;
  title: string;
  goBackOpt?: boolean;
}) => (
  <Dialog open={open} onClose={onClose} style={{ width: 650 }}>
    <Box px={2} pb={2}>
      <Flex flexDirection="column">
        <Flex justifyContent="center" mt={4}>
          <Box as="img" width={126} height={120} src={BotmdFailed} />
        </Flex>
        <Flex justifyContent="center" mt={2}>
          <Text fontSize={20} fontWeight={600}>
            {title}
          </Text>
        </Flex>
        <Flex justifyContent="center" px={2} pb={4} pt={2}>
          <Box fontSize={16} fontWeight={400} textAlign="center">
            {message}
          </Box>
        </Flex>
        <Flex justifyContent="center">
          <SecondaryOutlinedButton
            borderRadius={8}
            ml={2}
            onClick={() => {
              // TODO: Call and show the pdf form generated after booking
              onClose(false);
            }}
            style={{ width: 100 }}>
            {t('Close')}
          </SecondaryOutlinedButton>
          {goBackOpt && (
            <PrimaryButton
              borderRadius={8}
              ml={2}
              onClick={() => {
                onClose(false);
                navigate(ROUTES.ORDERS.ROOT);
              }}
              style={{ width: 200 }}>
              {t('Go back to Orders View')}
            </PrimaryButton>
          )}
        </Flex>
      </Flex>
    </Box>
  </Dialog>
);

/**
 * Exams additional field templates grouped and mapped by categories
 * Example:
 * - ct_cardiac:
 *     bb:
 *       bb0: true
 *       bb1: true
 *       bb2: true
 *       bb3: true
 *       bb4: true
 *       bb5: true
 *     ng:
 *       ng0: true
 *       ng1: true
 *       ng2: true
 * @param fieldsTypes
 */
export const getGroupExamsAddtionalFieldsMap = (fieldsTypes: AdditionalFieldsType[]) => {
  let additionalFieldTemplates = {};
  try {
    if (isEmpty(fieldsTypes)) return [];
    const categoryGroup = groupBy(fieldsTypes, 'category.key');
    if (!isEmpty(categoryGroup)) {
      additionalFieldTemplates = Object.entries(categoryGroup)?.map(([keyName, valObj]) => ({
        [keyName]: valObj?.map((j) => ({ [j?.key as string]: j?.value })),
      }));
    }
    return additionalFieldTemplates;
  } catch (e) {
    return {};
  }
};
