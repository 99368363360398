import React from 'react';
import { Flex, H5, Body, Box } from '@fivehealth/botero';
import BotAvatar from '@/assets/bot-table-avatar.svg';
import { useTranslation } from 'react-i18next';

type NoResultsProps = {
  avatar: string;
  title: string;
  avatarProps?: React.CSSProperties;
  noDesc?: boolean;
};

const NoResults: React.FC<NoResultsProps> = ({ avatar = BotAvatar, title, avatarProps, noDesc }) => {
  const { t } = useTranslation();
  return (
    <Flex pt={30} justifyContent="center" borderTop="1px solid #d5d9de">
      <Flex alignItems="center" flexDirection="column">
        <Box as="img" src={avatar} height={96} width={96} alt="Bot Avatar" {...avatarProps} />
        <H5 mt={20}>{t(title || 'No results found')}</H5>
        {!noDesc && (
          <Body small textAlign="center">
            {t("Try adjusting your search or filter to find what you're looking for.")}
          </Body>
        )}
      </Flex>
    </Flex>
  );
};

export default NoResults;
