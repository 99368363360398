import React, { memo, useMemo } from 'react';
import get from 'lodash/get';
import { Box, Flex, SecondaryOutlinedButton } from '@fivehealth/botero';
import { useTranslation } from 'react-i18next';
import { useFilterQuery } from '@/hooks';
import Filter, { FilterObjectType, FilterOption, FilterStateType } from '@/components/Filter/Filter';
import SearchBar from '@/components/Search/Search';
import FilterButton from '@/components/Filter/FilterButton';

import { getExamModalityLabelValues, queryParamsToObj, useRenderForView } from '@/helpers/utils';
import { NonEmptyArray } from '@/helpers/types/object.types';
import {
  Exact,
  FaradayModality,
  FaradayParkwayOrder,
  OrderExamStatus,
  ParkwayOrdersQueryVariables,
  ParkwayOrderStatus,
} from '@/gql/generated/graphql';
import { useDataContext } from '@/context';

import {
  FetchNextPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
  QueryClient,
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from '@tanstack/react-query';
import SpinningIcon from '@/components/Loader/SpinningIcon';
import { clone, isEmpty, omit, startCase } from 'lodash';
import OrdersParkwayTable, { OrdersParkwayTableLoader } from '@/pages/Orders/OrdersParkwayTable';

type OrdersParkwayProps = {
  queryVariables: Partial<ParkwayOrdersQueryVariables>;
  setQueryVariables: React.Dispatch<React.SetStateAction<Partial<Exact<any>>>>;
  queryClient: QueryClient;
  orderListCount: number;
  ordersData: FaradayParkwayOrder[];
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<InfiniteData<any>>>;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
  fetchNextPage: (options?: FetchNextPageOptions | undefined) => Promise<InfiniteQueryObserverResult<any>>;
  isFetching: boolean;
  defaultQueryVariables: Partial<ParkwayOrdersQueryVariables>;
};

const OrdersParkway: React.FC<OrdersParkwayProps> = ({
  defaultQueryVariables,
  queryVariables,
  setQueryVariables,
  orderListCount,
  ordersData,
  refetch,
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
  isFetching,
}) => {
  const { t } = useTranslation();
  const { renderForView } = useRenderForView();

  const {
    data: { modalityList },
  } = useDataContext();

  const orderExamStatusLabelValue = useMemo(
    () => [
      {
        label: startCase(OrderExamStatus.ImageReady.toString().toLowerCase()),
        value: OrderExamStatus.ImageReady,
      },
      {
        label: startCase(OrderExamStatus.ReportReady.toString().toLowerCase()),
        value: OrderExamStatus.ReportReady,
      },
    ],
    []
  );

  const orderExamModalityLabelValue = getExamModalityLabelValues(modalityList as FaradayModality[]).map(
    (o) => ({
      label: o.label,
      value: o.value?.uid,
    })
  );

  const initialFiltersArray: NonEmptyArray<FilterObjectType> = useMemo(
    () => [
      {
        title: 'Exam Status',
        value: 'parkwayStatus',
        options: (orderExamStatusLabelValue as FilterOption[]) || [],
      },

      {
        title: 'Modalities',
        value: 'parkwayModality',
        options: (orderExamModalityLabelValue as FilterOption[]) || [],
      },
    ],
    [orderExamModalityLabelValue, orderExamStatusLabelValue]
  );

  const { filter } = useFilterQuery(initialFiltersArray);

  const parkwayTableProps: React.ComponentProps<typeof OrdersParkwayTable> = {
    isLoading: isFetching,
    count: orderListCount,
    ordersData: clone(ordersData) as any,
    queryVariables: queryVariables as ParkwayOrdersQueryVariables,
    setQueryVariables,
    refetch,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  };

  function handleSearch(search: number | string) {
    if (search !== queryVariables.parkwayOrderSearch) {
      setQueryVariables({ ...queryVariables, parkwayOrderSearch: search as string });
      setTimeout(() => {
        refetch();
      }, 500);
    }
  }

  function handleSubmitFilter(filterParam: FilterStateType) {
    const isReset = isEmpty(filterParam);

    if (isReset) {
      setTimeout(() => {
        const qVars = {
          ...omit(queryVariables, ['status_In', 'modality_Uid_In', 'offset', 'first']),
          ...defaultQueryVariables,
        };
        setQueryVariables(qVars);
        refetch();
      }, 0);
    } else {
      let qVarsObj: Partial<ParkwayOrdersQueryVariables> = clone(
        omit(queryVariables, ['status_In', 'modality_Uid_In', 'offset', 'first'])
      );

      if (!isEmpty(filterParam.parkwayModality)) {
        qVarsObj = { ...qVarsObj, modality_Uid_In: filterParam.parkwayModality };
      } else {
        qVarsObj = omit(clone(qVarsObj), ['modality_Uid_In']);
      }

      if (!isEmpty(filterParam.parkwayStatus)) {
        qVarsObj = { ...clone(qVarsObj), status_In: filterParam.parkwayStatus as ParkwayOrderStatus[] };
      } else {
        qVarsObj = omit(clone(qVarsObj), ['status_In']);
      }
      setQueryVariables({ ...qVarsObj });
      setTimeout(() => {
        refetch();
      }, 500);
    }
  }

  function handleReload() {
    setTimeout(() => {
      window.location.reload();
    }, 0);
  }

  return (
    <Box mb={8}>
      <Box mt={-2} mb={2}>
        <Flex style={{ gap: 16 }} height={88} alignItems="center" justifyContent="space-between">
          <SearchBar
            value={(get(queryParamsToObj(), 'search.0', '') as string) || ''}
            onSearch={handleSearch}
            data-testid="nonbotmd_search_input"
            placeholder={t(`Search Non-Bot MD Orders`)}
          />

          <SecondaryOutlinedButton
            color="fullShade"
            borderColor="mediumShade"
            borderRadius={8}
            alt="Reload"
            style={{ opacity: isFetching ? 0.6 : 1 }}
            onClick={handleReload}>
            <SpinningIcon spin={isFetching} />
          </SecondaryOutlinedButton>

          <Flex style={{ gap: 16 }}>
            <Filter
              width={renderForView(['100%', '90%', 600]) as number | string}
              label={(toggle) => <FilterButton onClick={toggle} disabled={isFetching || !modalityList} />}
              filters={initialFiltersArray}
              filter={filter.get()}
              setFilter={filter.set}
              onSubmit={handleSubmitFilter}
            />
          </Flex>
        </Flex>
      </Box>

      <OrdersParkwayTableLoader {...parkwayTableProps} />
    </Box>
  );
};

export default memo(OrdersParkway);
