import { Navigate } from 'react-router-dom';
import { useAuthContext } from '@/context';
import { AppConfig } from '@/config/app.config';
import { useCookies } from 'react-cookie';
import { ROUTES } from '@/routes/Router';
import { nullTextParser } from '@/helpers/utils';

function RootPage() {
  const {
    authState: { isAuthenticated },
  } = useAuthContext();
  const cookieTokenName = AppConfig.STORAGE.COOKIE_TOKEN_KEY;
  const [cookies] = useCookies([cookieTokenName]);
  const cookieToken = nullTextParser(cookies?.[cookieTokenName]);

  if (isAuthenticated && cookieToken) {
    return <Navigate to={ROUTES.ORDERS.ROOT} replace />;
  }
  return <Navigate to={ROUTES.LOGIN.ROOT} replace />;
}

export default RootPage;
