import React, { forwardRef } from 'react';
import { Flex, Text, Body } from '@fivehealth/botero';
import InputField from './InputField';
import styled from 'styled-components';
import SpinningIcon, { SpinningIconProps } from '@/components/Loader/SpinningIcon';

export type InputProps = React.ComponentProps<typeof InputField> & {
  label?: React.ReactNode | string | null | undefined;
  labelRight?: React.ReactNode | string | null | undefined;
  errorMessage?: React.ReactNode | string | null | undefined;
  inputComponent?: React.ReactNode;
  rootProps?: React.ComponentProps<typeof Flex>;
  labelProps?: React.ComponentProps<typeof Text>;
  labelPropsRight?: React.ComponentProps<typeof Text>;
  errorTextProps?: React.ComponentProps<typeof Body>;
};

const ModInputField = styled(InputField)(({ theme }) => ({
  '&:focus-visible': {
    outlineColor: theme.colors.primary,
  },
}));

export const InputLabelSpinner: React.FC<{
  loading: boolean;
  iconProps?: SpinningIconProps;
  text: string;
  hide?: boolean;
}> = ({ loading, iconProps, text, hide = false }) => (
  <div style={{ display: 'flex' }}>
    <span style={{ paddingRight: 8 }}>{text}</span>
    {!hide && <SpinningIcon spin={loading} {...iconProps} />}
  </div>
);

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      labelRight,
      errorMessage,
      inputComponent,
      rootProps,
      labelProps,
      labelPropsRight,
      errorTextProps,
      ...rest
    },
    ref
  ) => (
    <Flex flexDirection="column" {...rootProps}>
      <Flex flexDirection="row" justifyContent="space-between">
        <Text color="darkestShade" style={{ fontWeight: 600, fontSize: 12 }} pb={1} {...labelProps}>
          {label}
        </Text>
        <Text color="darkestShade" style={{ fontWeight: 400, fontSize: 11 }} {...labelPropsRight}>
          {labelRight}
        </Text>
      </Flex>

      {inputComponent || (
        <ModInputField
          ref={ref as React.Ref<HTMLInputElement>}
          style={{
            boxSizing: 'border-box',
            ...rest.style,
          }}
          {...rest}
          width="100%"
        />
      )}
      <Body
        color="danger"
        mt="4px"
        ml="4px"
        lineHeight="12px"
        fontSize={12}
        minHeight={12}
        {...errorTextProps}>
        {errorMessage}
      </Body>
    </Flex>
  )
);

export default Input;
