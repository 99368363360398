import { useMediaQuery } from 'react-responsive';
import get from 'lodash/get';
import { LabelValueOptionsType } from '@/helpers/types/ui.types';
import { ToastOptions } from 'react-toastify';
import { StylesConfig } from 'react-select';
import { getStoredAvatarProfile } from '@/helpers/utils/storage.utils';
import { FaradayModality, FaradayStaffProfile } from '@/gql/generated/graphql';
import { getLabelValuesMapper } from '@/helpers/utils/object.utils';
import { sortBy } from 'lodash';
import { transformTextToFormalCase } from '@/helpers/utils/text.utils';

/**
 * Check what media query config or resolution the window is.
 */
export const useIsView = (width = ['40em', '60em']) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${width[0]})` });
  const isTablet = useMediaQuery({ query: `(max-width: ${width[1] || '60em'})` });
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isWindowWidth = (windowWidth: string) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useMediaQuery({ query: `(max-width: ${windowWidth})` });
  return { isMobile, isTablet, isWindowWidth };
};

/**
 * Get profile pic for avatar
 * @param param
 */
export const getProfilePic = (param: any): string => {
  if (typeof param === 'object') {
    const profilePhoto = get(param, 'profilePhoto');
    if (profilePhoto) {
      return profilePhoto as string;
    }
    const avatar = get(param, 'metadata.avatar');
    if (avatar) {
      return `/${avatar}.svg`;
    }
  } else if (typeof param === 'string') {
    return getStoredAvatarProfile()?.toString();
  }
  return '/blue-avatar-logo.svg';
};

/**
 * Get the doctors list for components like select dropdown components with label/value types
 * Can return object to value field if isObject argument is set
 * @param list
 * @param isObject
 */
export const getDoctorListLabelValues = (
  list: FaradayStaffProfile[],
  isObject = false
): LabelValueOptionsType<Partial<FaradayStaffProfile>>[] =>
  getLabelValuesMapper<FaradayStaffProfile>(
    list,
    (o) => `Dr. ${transformTextToFormalCase(o.fullName)}`,
    (o) => (isObject ? o : (o.uid as any))
  );

/**
 * Get all the modality list and mapped as label/value object.
 * @param list
 */
export const getExamModalityLabelValues = (
  list: FaradayModality[]
): LabelValueOptionsType<Partial<FaradayModality>>[] =>
  sortBy(
    getLabelValuesMapper<FaradayModality>(
      list,
      (o) => o.shortName,
      // (o) => o?.uid as any

      (o) => o
    ),
    ['label']
  );

/**
 * This gives the difference of two select option list.
 * Mainly use in dropdown select options that will update the options when it is selected.
 * @param oldList
 * @param newList
 * @returns
 */
export const selectOptionsFilterDifference = <
  TOptions extends { value: Partial<{ uid: string | undefined }> }
>(
  oldList: TOptions[],
  newList: TOptions[]
) =>
  [...oldList].filter(
    (o) => o?.value?.uid !== newList?.find((k: any) => o?.value?.uid === k?.value?.uid)?.value?.uid
  );

/**
 * DEPRECATED: This is a basic default and simple toastify options (no fancy animations)
 */
export const toastifyLameModeOptions: ToastOptions = {
  position: 'top-center',
  autoClose: 5000,
  closeButton: true,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  theme: 'light',
  // transition: cssTransition({ collapse: false, enter: 'zoomIn', exit: 'zoomOut' }),
};

export const baseSelectStylesCfg: StylesConfig = {
  control: (base) => ({
    ...base,
    borderRadius: 8,
  }),
  valueContainer: (base) => ({
    ...base,
    fontSize: 14,
  }),
  menuList: (base) => ({
    ...base,
    fontSize: 14,
  }),
  option: (styles, { isDisabled }) => ({
    ...styles,
    color: isDisabled ? '#c6c5c5' : 'black',
    // backgroundColor: isSelected ? '#989898' : 'white',
    backgroundColor: 'white', // isSelected ? 'rgba(47,123,240,0.09)' : 'white',
    ':hover': {
      backgroundColor: 'rgba(47,123,240,0.17)',
      color: isDisabled ? '#aeaeae' : 'black',
    },
  }),
};

/**
 * Get the customized and static filter status for inactive and active params.
 * @param filterParam
 */
export const getFilterStatusParam = (filterParam: Record<string, string[]>) => {
  let isActive: boolean | null = null;
  if (filterParam.status?.filter((o) => o === 'inactive')?.length === 1) {
    isActive = false;
  }
  if (filterParam.status?.filter((o) => o === 'active')?.length === 1) {
    isActive = true;
  }
  if (filterParam.status?.length === 2 || filterParam.status?.length === 0) {
    isActive = null;
  }
  return isActive;
};
