import React, { useState, useEffect, useCallback } from 'react';
import { Box, FAIcon, InputField, useDebounce, theme } from '@fivehealth/botero';
import { faSearch, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { useLocation } from 'react-router-dom';
import { tweakPageName } from '@/helpers/utils/text.utils';
import EventsConstants from '@/config/constants/events.constants';
// import { CleoClinician, PageInfo } from '@/gql/generated/graphql';

// TODO: Recheck type requirements
type SearchBarProps = {
  value?: string;
  placeholder: string;
  onTextChange?: (text: string) => void;
  onSearch: (text: number | string) => void;
  style?: React.CSSProperties;
  maxWidth?: number | string;
  reset?: boolean;
};

const SearchBar: React.FC<SearchBarProps> = ({
  value: initialValue = '',
  placeholder,
  onTextChange,
  onSearch,
  style,
  reset = false,
}) => {
  const [value, setValue] = useState(initialValue);
  const [active, setActive] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const debouncedValue = useDebounce(value as never, 600);
  const location = useLocation();
  const pageName = location.pathname.split('/')[1] || '';

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onTextChange?.(e.target.value);
  };

  const invokeSearch = useCallback(() => {
    onSearch(debouncedValue);
  }, [debouncedValue, onSearch]);

  useEffect(() => {
    if (reset && value !== '') {
      setValue('');
    }
    invokeSearch();
  }, [invokeSearch, reset, value]);

  return (
    <Box flex={1}>
      <InputField
        onFocus={() => setActive(true)}
        onBlur={() => setActive(false)}
        value={value}
        width={[220, '50%']}
        color="fullShade"
        logEventProps={{
          page: tweakPageName(pageName),
          eventName: EventsConstants.SEARCH_PATIENTS,
          term: value,
        }}
        onChange={onChange}
        startIcon={<FAIcon icon={faSearch} fontSize={16} color="fullShade" />}
        endIcon={
          value ? (
            <FAIcon
              icon={faTimes}
              fontSize={14}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setValue('');
                onTextChange?.('');
              }}
            />
          ) : null
        }
        style={{
          outline: 'none',
          borderColor: active ? theme.colors.primary : null,
          ...style,
        }}
        placeholder={placeholder}
      />
    </Box>
  );
};

export default SearchBar;
