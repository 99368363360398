import React, { SyntheticEvent, useEffect, useState } from 'react';
import { faArrowRight, faArrowLeft } from '@fortawesome/pro-regular-svg-icons';

import { FaradayOrder, FaradayPatientProfile, OrdersQueryVariables } from '@/gql/generated/graphql';
import { Flex, Box, FAIcon, PrimaryButton } from '@fivehealth/botero';
import { QueryClient, QueryObserverResult, RefetchOptions, RefetchQueryFilters } from '@tanstack/react-query';
import { appendObjToQueryParams } from '@/helpers/utils';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Pagination, PaginationProps } from 'semantic-ui-react';
import OrderCard from '@/pages/Orders/OrdersCard';
import NoResults from '@/components/NoResults/NoResults';
import BotCryingAvatar from '@/assets/crying-avatar.svg';
import AnimatedLoadingLottie from '@/components/Loader/AnimatedLoadingLottie';
import { initDefaultQueryVars } from '@/pages/Orders/Orders';
import { isEmpty } from 'lodash';
import { Text } from 'rebass';
import { ROUTES } from '@/routes/Router';

interface OrdersCardListPaginationsProps extends OrdersCardBaseProps {
  isDisabled?: boolean;
  currentPage?: number | string;
  setCurrentPage?: React.Dispatch<number | string>;
  paginatioProps?: PaginationProps;
}

export const OrdersCardListPagination: React.FC<OrdersCardListPaginationsProps> = ({
  isDisabled = false,
  data,
  queryVariables,
  setQueryVariables,
  currentPage,
  setCurrentPage,
  paginatioProps,
  orderListCount,
}) => {
  const [page, setPage] = useState(currentPage || 1);
  const totalPages = Math.ceil((orderListCount || 0) / Number(initDefaultQueryVars?.first));
  const qVarPageOffset = Number(queryVariables ? queryVariables.offset : 0);

  // TODO: Again, mistakes were made. Please rectify me!
  useEffect(() => {
    if (currentPage !== 1 && qVarPageOffset === 0) {
      setPage(1);
      if (setCurrentPage) {
        setCurrentPage(1);
      }
    }
  }, [currentPage, qVarPageOffset, setCurrentPage]);

  const handlePaginationChange = (e: SyntheticEvent, { activePage }: Record<string, number>) => {
    e.stopPropagation();
    const newOffSet =
      Number(activePage) * Number(initDefaultQueryVars.first) - Number(initDefaultQueryVars.first);
    const currentPageOffset = Number(activePage) === 1 ? 0 : newOffSet;
    setPage(Number(activePage as number));
    if (setCurrentPage) {
      setCurrentPage(Number(activePage as number));
    }
    appendObjToQueryParams({ offset: currentPageOffset, page: Number(activePage as number) });
    if (setQueryVariables) {
      setQueryVariables({ ...queryVariables, offset: currentPageOffset });
    }
  };

  const marginRightVal = data && Number(data?.length) >= Number(initDefaultQueryVars.first) / 2 ? 8 : 0;
  const activePage = currentPage && setCurrentPage ? currentPage : page;

  const arrowStyle = (pages: number) => ({ color: Number(activePage) === pages ? 'lightgray' : 'gray' });

  return data && data?.length <= 0 ? null : (
    <Flex mb={4} mr={marginRightVal} mt={2} justifyContent="start">
      <Pagination
        size="mini"
        disabled={isDisabled}
        activePage={Number(activePage)}
        onPageChange={handlePaginationChange}
        totalPages={totalPages}
        firstItem={null}
        lastItem={null}
        siblingRange={1}
        boundaryRange={3}
        nextItem={{
          disabled: Number(activePage) === totalPages,
          content: <FAIcon style={arrowStyle(totalPages)} fontSize={12} icon={faArrowRight} />,
        }}
        prevItem={{
          disabled: Number(activePage) === 1,
          content: <FAIcon style={arrowStyle(1)} fontSize={12} icon={faArrowLeft} />,
        }}
        {...paginatioProps}
      />
      <Text fontWeight={400} color="#bcbcbc" ml={2} mt="6px">{`Show ${activePage}-${data?.length || 0} ${
        Number(orderListCount || 0) <= data?.length ? '' : `of ${orderListCount}`
      }`}</Text>
    </Flex>
  );
};

export type OrdersCardBaseProps = {
  data: FaradayOrder[];
  queryVariables?: Partial<OrdersQueryVariables>;
  setQueryVariables?: React.Dispatch<Partial<OrdersQueryVariables>>;
  isLoading?: boolean;
  queryClient?: QueryClient;
  refetch?: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<FaradayOrder[]>>;

  orderListCount?: number;
  patient?: FaradayPatientProfile;
};

type OrdersCardListProps = OrdersCardBaseProps & {
  orderListError?: unknown;
};

export const OrdersCardList: React.FC<OrdersCardListProps> = ({
  data,
  isLoading,
  queryClient,
  queryVariables,
  setQueryVariables,
  orderListCount,
  orderListError,
  refetch,
  patient,
}) => {
  const navigate = useNavigate();
  const [qUrlParams] = useSearchParams();
  const urlParamPage = qUrlParams.get('page') || 1;
  const [page, setPage] = useState<number | string>(urlParamPage);

  const paginationProps = {
    data,
    isLoading,
    queryVariables,
    setQueryVariables,
    orderListCount,
    currentPage: page,
    setCurrentPage: setPage,
  };

  const renderCustomSuspense = () => {
    if (!isEmpty(orderListError)) {
      return (
        <Box mt={1}>
          <NoResults
            noDesc
            avatar={BotCryingAvatar}
            title="No orders created! "
            avatarProps={{ marginTop: 100 }}
          />
          <Flex justifyContent="center" mt={2}>
            <PrimaryButton
              borderRadius={8}
              onClick={() => {
                navigate(ROUTES.ORDER_CREATE.ROOT);
              }}>
              Create new order
            </PrimaryButton>
          </Flex>
        </Box>
      );
    }
    return <AnimatedLoadingLottie flexProps={{ mt: '80px' }} />;
  };
  return !orderListError && !isLoading ? (
    <Box height="auto">
      <Box mt={-2} mb={-1}>
        <OrdersCardListPagination {...paginationProps} />
      </Box>

      {data &&
        data?.length > 0 &&
        data?.map((order, index) => (
          <React.Fragment key={`order_card_list-${order?.uid}-${index}`}>
            <OrderCard
              queryClient={queryClient}
              data={order}
              queryVariables={queryVariables as OrdersQueryVariables}
              isLoading={isLoading}
              refetch={refetch}
              patient={patient}
            />
          </React.Fragment>
        ))}

      <Box mt={3}>
        <OrdersCardListPagination {...paginationProps} />
      </Box>

      {data && data?.length === 0 && !isLoading && (
        <Box mt={1}>
          <NoResults
            noDesc
            avatar={BotCryingAvatar}
            title="No orders created! "
            avatarProps={{ marginTop: 100 }}
          />
          <Flex justifyContent="center" mt={2}>
            <PrimaryButton
              borderRadius={8}
              onClick={() => {
                navigate(ROUTES.ORDER_CREATE.ROOT);
              }}>
              Create new order
            </PrimaryButton>
          </Flex>
        </Box>
      )}
    </Box>
  ) : (
    renderCustomSuspense()
  );
};
