import { find, omit } from 'lodash';
import { AppConfig } from '@/config/app.config';
import { CookieDataTypes, WorkspaceStorageTypes } from '@/helpers/types/system.types';
import { FaradayStaffProfile } from '@/gql/generated/graphql';
import { nullTextParser } from '@/helpers/utils/text.utils';

/**
 * Alternative way to get applications cookie storage token instead of using hooks.
 * @param cookieName
 */
export const getCookieToken = (cookieName: string = AppConfig.STORAGE.COOKIE_TOKEN_KEY): string => {
  const cookieData = new URLSearchParams(document.cookie.replaceAll('&', '%26').replaceAll('; ', '&'));
  return nullTextParser(cookieData.get(cookieName || '')) as string;
};

/**
 * Alternative way to get applications cookie storage as data object instead of using hooks.
 * @param cookieName
 */
export const getCookieData = (
  cookieName: string = AppConfig.STORAGE.COOKIE_DATA_KEY
): CookieDataTypes | null => {
  const cookieData = new URLSearchParams(document.cookie.replaceAll('&', '%26').replaceAll('; ', '&'));
  const data = cookieData?.get(cookieName) || '';

  const parseData =
    data?.toLowerCase() === 'undefined' || data?.toLowerCase() === 'null' || !data ? '{}' : data;
  return JSON.parse(parseData) as CookieDataTypes;
};

/**
 * Get workspace data fromt the local storage.
 */
export const getWorkspacesData = (): WorkspaceStorageTypes[] | null | undefined => {
  const localData = localStorage.getItem(AppConfig.STORAGE.LOCAL_WORKSPACE_KEY);
  return JSON.parse(JSON.parse(JSON.stringify(localData))) || ([] as WorkspaceStorageTypes[]);
};

/**
 * Utility for storage workspaces.
 */
export const localWorkspace = {
  /**
   * Get workspace by uid.
   * @param uid
   * @returns {null|*}
   */
  get: (uid: string): WorkspaceStorageTypes | null | undefined => {
    const data = getWorkspacesData();

    if (localStorage && data && uid) {
      return data.find((o: { uid: string }) => o.uid === uid);
    }
    return null;
  },
  /**
   * Check if workspace storage is not empty.
   * @returns {boolean}
   */
  isEmpty: (): boolean => {
    if (localStorage) {
      const data = getWorkspacesData();
      return !!data && data.length > 0;
    }
    return false;
  },
  /**
   * Get the last or current active or selected workspace.
   * @returns {null|*}
   */
  getActive: (): WorkspaceStorageTypes | null | undefined => {
    const data = getWorkspacesData();
    if (data) {
      return data.find((o) => o?.active);
    }
    return null;
  },
  /**
   * Get the workspace by uid.
   * @returns {null|*}
   */
  getByUid: (clinicUid: string): WorkspaceStorageTypes | null | undefined => {
    const data = getWorkspacesData();
    if (data) {
      return data.find((o) => o?.clinic?.uid === clinicUid);
    }
    return null;
  },
  /**
   * Get all workspaces in storage.
   * @returns {null|array}
   */
  getAll: (): WorkspaceStorageTypes[] | null | undefined => {
    if (localStorage) return getWorkspacesData();
    return null;
  },
  /**
   * Set selected workspace in storage.
   * @param param
   * @param opt
   */
  set: (param: WorkspaceStorageTypes, opt?: { active: boolean }): void => {
    if (param) {
      const storeData = getWorkspacesData();
      const activeOverride = typeof opt?.active === 'boolean' ? opt?.active : true;
      if (storeData && storeData.length >= 1) {
        const storeLookup = find(storeData, { uid: param.uid });
        if (!storeLookup) {
          storeData.push({ ...param, dateActivated: new Date() });
        }
        const storeMap = storeData.map((o: { uid: string }) =>
          param.uid === o.uid
            ? { ...o, dateActivated: new Date(), active: activeOverride }
            : { ...o, active: false }
        );
        localStorage.setItem(AppConfig.STORAGE.LOCAL_WORKSPACE_KEY, JSON.stringify(storeMap));
      } else {
        localStorage.setItem(
          AppConfig.STORAGE.LOCAL_WORKSPACE_KEY,
          JSON.stringify([{ ...param, dateActivated: new Date(), active: activeOverride }])
        );
      }
    }
  },
  /**
   * Set a workspace as selected by domain.
   * @param domain
   */
  setActiveByDomain: (domain: string): void => {
    if (domain) {
      const storeData = getWorkspacesData();
      if (storeData && storeData.length > 0) {
        const storeLookup = find(storeData, { domain });
        if (storeLookup) {
          const storeMap = storeData.map((o: { domain: string }) =>
            domain === o.domain ? { ...o, active: true } : { ...o, active: false }
          );
          localStorage.setItem(AppConfig.STORAGE.LOCAL_WORKSPACE_KEY, JSON.stringify(storeMap));
        }
      }
    }
  },
  /**
   * Delete a workspace in storage.
   * @param domain
   */
  deleteByDomain: (domain: string): void => {
    if (domain) {
      const storeData = getWorkspacesData();
      if (storeData && storeData.length > 0) {
        const storeLookup = find(storeData, { domain });
        if (storeLookup) {
          const storeMap = storeData.filter((o: { domain: string }) => domain !== o.domain);
          localStorage.setItem(AppConfig.STORAGE.LOCAL_WORKSPACE_KEY, JSON.stringify(storeMap));
        }
      }
    }
  },
  /**
   * Delete and clear all workspaces in storage.
   */
  deleteAll: (): void => {
    localStorage.removeItem(AppConfig.STORAGE.LOCAL_WORKSPACE_KEY);
  },
};

export const saveWorkspace = async (
  user: FaradayStaffProfile,
  opt?: { active: boolean }
  // apiClient?: GraphQLClient
) => {
  const newWorkspace: WorkspaceStorageTypes = {
    uid: user.uid,
    name: user?.fullName,
    domain: user?.clinic?.domain,
    logo: 'http://some-logo/link',
    dateActivated: new Date().toDateString(),
    clinic: omit(user?.clinic || {}, 'staffs') as never,
  };

  // NOTE: We temporarily no need to call this api
  // if (apiClient) {
  //   // NOTE: Register device for the pusher channel
  //   const { providerApplication, deviceMetadata } = AppConfig.CHATBOT_REGISTER_DEVICE;
  //   const deviceRegInput = {
  //     input: {
  //       providerApplication,
  //       deviceMetadata,
  //       pusher: {
  //         channel: `private-${uuidv4().split('-').join('')}`,
  //       },
  //     },
  //   };
  //   const { faradayRegisterDevice } = await apiClient.request(RegisterDeviceDocument, deviceRegInput);
  //   newWorkspace.system = {
  //     deviceUid: faradayRegisterDevice?.device?.uid || '',
  //     pusherChannel: deviceRegInput.input.pusher.channel,
  //   };
  // }

  localWorkspace.set(newWorkspace, opt);
};

/**
 * Get locally stored avatar profile picture.
 */
export const getStoredAvatarProfile = () =>
  localStorage.getItem(AppConfig.STORAGE.LOCAL_PROFILE_KEY) || '/blue-avatar-logo.svg';
