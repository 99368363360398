import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Flex, H3, Text, SecondaryOutlinedButton, PrimaryButton } from '@fivehealth/botero';
import BotCryingAvatar from '@/assets/crying-avatar.svg';

import { emailCustomerSupport } from '@/helpers/utils';
import { IS_PRODUCTION } from '@/config/app.config';

const env = window.location.href.includes('localhost')
  ? `Localhost (${process.env.NODE_ENV})`
  : window.location.hostname;

type ErrorPageProps = {
  headerTitle: string;
  clinic?: {
    name: string;
  };
  user?: {
    name: string;
  };
  resetErrorBoundary?: () => void;
  details?: {
    error?: {
      message?: string;
      stack?: string;
    };
  };
};

function ErrorPage({ headerTitle, clinic, user, resetErrorBoundary, details }: ErrorPageProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const { pathname } = useLocation();
  const originalPathname = useRef(pathname);
  const ERR_TITLE = 'Oh no, there was an error!';

  // NOTE: This will reset error boundary and make route available
  useEffect(() => {
    if (pathname !== originalPathname.current && resetErrorBoundary) resetErrorBoundary();
  }, [pathname, resetErrorBoundary]);

  const handleOnClickSupport = () => {
    emailCustomerSupport(`${clinic?.name || 'Parkway'} - ${user?.name || t('Support')}`);
  };

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      borderRadius="8px"
      px={2}
      width="100%"
      height="100%"
      style={{
        verticalAlign: 'middle',
        margin: 'auto',
        marginTop: 'calc(25% - 13%)',
      }}>
      <Box as="img" src={BotCryingAvatar} height={152} width={170} mb={2} alt="Bot Avatar" />
      <Box m={2}>
        <H3
          fontSize={[20, 24]}
          lineHeight="36px"
          fontWeight={600}
          color="#111824"
          mb={2}
          style={{ textAlign: 'center' }}>
          {t(headerTitle || ERR_TITLE)}
        </H3>
      </Box>
      <Box mx={2} mt={1} mb={1} width={[330, 500]} px={2}>
        <Text color="#697481" fontSize={[14, 16]} fontWeight={400} style={{ textAlign: 'center' }}>
          <Text>
            Something went wrong. Please refresh this page or re-login. If the issue persists, please reach out
            to our
            <Text
              alignItems="center"
              onClick={handleOnClickSupport}
              hover={{ opacity: 0.6 }}
              style={{ color: '#256BF6', cursor: 'pointer' }}>
              {t('Customer support')}
            </Text>
          </Text>
        </Text>
      </Box>

      {!IS_PRODUCTION() && (
        <>
          <Text
            alignItems="center"
            onClick={() => {
              setShowError(!showError);
            }}
            hover={{ opacity: 0.6 }}
            style={{
              color: 'gray',
              fontSize: 10,
              border: '1px solid rgb(211, 211, 211)',
              borderRadius: 14,
              padding: '4px 7px',
              cursor: 'pointer',
              marginTop: 10,
            }}>
            {t(showError ? 'Hide details' : 'Show details')}
          </Text>
          {showError ? (
            <Box>
              <Text
                color="gray"
                fontSize={[10, 12]}
                fontWeight1={400}
                style={{ textAlign: 'center', marginTop: 10 }}>
                Build environment: {env}
              </Text>
              <Box
                my={2}
                width={[330, 500]}
                height={[220, 300]}
                px={2}
                overflow="auto"
                style={{ border: '1px solid rgb(219 219 219)' }}>
                <Text color="red" fontSize={[10, 12]} fontWeight1={400} style={{ textAlign: 'left' }}>
                  {details?.error?.stack || details?.error?.message}
                </Text>
              </Box>
            </Box>
          ) : null}
        </>
      )}

      <Flex
        p={2}
        alignItems="center"
        justifyContent="center"
        flexDirection="row"
        fontSize={14}
        fontWeight={500}>
        <SecondaryOutlinedButton
          onClick={() => {
            navigate('/');
          }}
          m={1}
          style={{ fontSize: 14, fontWeight: 500, minWidth: 150 }}>
          {t('Back to home')}
        </SecondaryOutlinedButton>
        <PrimaryButton
          onClick={() => {
            if (resetErrorBoundary) resetErrorBoundary();
            navigate(-1);
          }}
          m={1}
          style={{ fontSize: 14, fontWeight: 500, minWidth: 150 }}>
          {t('Refresh page')}
        </PrimaryButton>
      </Flex>
    </Flex>
  );
}
export default ErrorPage;

export const renderErrorPage = ({
  resetErrorBoundary,
  user,
  errorStackTrace,
}: {
  resetErrorBoundary?: any;
  user?: any;
  errorStackTrace?: any;
}) => (
  <ErrorPage
    user={user}
    details={errorStackTrace}
    resetErrorBoundary={resetErrorBoundary}
    headerTitle="Parkway Error Page"
  />
);

export const handleError = (error: any, info: any) => {
  if (!IS_PRODUCTION()) {
    // TODO: Consider implementing and integrating sentry to store all error logs
    // eslint-disable-next-line
    console.log(`%cERROR: ${error?.message}`, 'color: orange');
    // eslint-disable-next-line
    console.log(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      `%cSTACKTRACE: ${info?.componentStack.toString()}`,
      'color: green'
    );
  }
};

export const onRenderErrorFallback = ({ resetErrorBoundary, errorStackTrace, user }: any) =>
  renderErrorPage({ resetErrorBoundary, errorStackTrace, user });

export const onErrorCallback = (error: any, info: any, setErrorStackTrace: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  setErrorStackTrace({ error, info });
  handleError(error, info);
};
