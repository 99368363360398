import { FC, memo, PropsWithChildren } from 'react';
import { FAIcon, Flex, Box, Body } from '@fivehealth/botero';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import type { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { BoxProps, FlexProps } from 'rebass';

interface IconWithLabelBadgeProps {
  color?: string;
  icon?: IconDefinition;
  iconSize?: number | string;
  opacity?: number | string;
  rootProps?: FlexProps; // FIXME: Need to get types from rebass
  bodyProps?: BoxProps; // FIXME: Need to get types from rebass
  renderLabel?: (status: string) => React.ReactNode;
}

const IconWithLabelBadge: FC<PropsWithChildren<IconWithLabelBadgeProps>> = ({
  children,
  color,
  icon = faCircle,
  iconSize = 12,
  opacity,
  renderLabel,
  bodyProps,
  ...rootProps
}) => (
  <Flex
    justifyContent="flex-start"
    alignItems="flex-start"
    flexDirection="inherit"
    id={`icon_label_badge-${color}`}
    style={{ marginTop: '-2px' }}
    {...rootProps}>
    <FAIcon height={iconSize} width={iconSize} icon={icon} color={color} style={{ opacity, marginTop: 1 }} />
    <Box>
      <Body as="span" small color="fullShade" ml={-1} pl={2} {...bodyProps}>
        {renderLabel ? renderLabel(children as string) : children}
      </Body>
    </Box>
  </Flex>
);

export default memo(IconWithLabelBadge);
