import React, { createContext, useCallback, useContext, useState } from 'react';
import { FaradayClinic, FaradayModality, FaradayStaffProfile } from '@/gql/generated/graphql';

export type DataCtxStateTypes = {
  user: FaradayStaffProfile | null | undefined;
  clinic: FaradayClinic | null | undefined;
  clinicList: FaradayClinic[] | [] | null | undefined;
  doctorList: FaradayStaffProfile[] | null | undefined;
  modalityList: FaradayModality[] | null | undefined;
};

export type DataCtxValueTypes = {
  data: DataCtxStateTypes;
  resetData: () => void;
  setData?: (param: DataCtxStateTypes) => void;
  setUser?: (param: DataCtxStateTypes['user']) => void;
  setClinic?: (param: DataCtxStateTypes['clinic']) => void;
  setClinicList?: (param: DataCtxStateTypes['clinicList']) => void;
  setDoctorList?: (param: DataCtxStateTypes['doctorList']) => void;
  setModalityList?: (param: DataCtxStateTypes['modalityList']) => void;
};

const DataContext = createContext<DataCtxValueTypes>({} as DataCtxValueTypes);

function DataProvider({ children }: { children: React.ReactNode }) {
  const [state, setState] = useState<DataCtxStateTypes>({
    user: null,
    clinic: null,
    clinicList: null,
    doctorList: null,
    modalityList: null,
  });

  const setUser = useCallback(
    (value: FaradayStaffProfile | null | undefined) => {
      setState({ ...state, user: value as never });
    },
    [state]
  );

  const setClinic = useCallback(
    (value: FaradayClinic | null | undefined) => {
      setState({ ...state, clinic: value as never });
    },
    [state]
  );

  const setClinicList = useCallback(
    (value: FaradayClinic[] | [] | null | undefined) => {
      setState({ ...state, clinicList: value as never });
    },
    [state]
  );

  const setDoctorList = useCallback(
    (value: FaradayStaffProfile[] | [] | null | undefined) => {
      setState({ ...state, doctorList: value as never });
    },
    [state]
  );

  const setModalityList = useCallback(
    (value: FaradayModality[] | [] | null | undefined) => {
      setState({ ...state, modalityList: value as never });
    },
    [state]
  );

  const setData = useCallback((value: DataCtxStateTypes | null | undefined) => {
    setState({ ...value } as unknown as never);
  }, []);

  const resetData = useCallback(() => {
    setState(null as never);
  }, []);

  const getCtxValues = useCallback(
    (): DataCtxValueTypes => ({
      data: state,
      resetData,
      setData,
      setUser,
      setClinic,
      setClinicList,
      setDoctorList,
      setModalityList,
    }),
    [resetData, setClinic, setClinicList, setData, setDoctorList, setModalityList, setUser, state]
  );

  return <DataContext.Provider value={getCtxValues()}>{children}</DataContext.Provider>;
}

const useDataContext = () => useContext(DataContext);

export { DataProvider, useDataContext };
