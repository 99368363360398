import React, { memo } from 'react';
import { Body, Box, Flex } from '@fivehealth/botero';
import { faSquare as iconBadge } from '@fortawesome/pro-solid-svg-icons';
import { omit } from 'lodash';
import IconWithLabel from './IconWithLabelBadge';
import { FaradayModality } from '@/gql/generated/graphql';
import { BoxProps, FlexProps } from 'rebass';

export const BadgeColorScheme = {
  A: { primary: 'rgb(100,62,36)', accent: 'rgba(100,62,36,0.1)' },
  B: { primary: '#722F2F', accent: '#F7EDED' },
  C: { primary: 'rgba(54, 81, 175, 1)', accent: 'rgba(235, 238, 249, 1)' }, //
  D: { primary: 'rgb(37,98,67)', accent: 'rgba(37,98,67,0.1)' },
  E: { primary: 'rgb(139,118,2)', accent: 'rgba(139,118,2,0.1)' },
  G: { primary: 'rgb(74,91,15)', accent: 'rgba(74,91,15,0.1)' },
  H: { primary: 'rgb(63,32,118)', accent: 'rgba(63,32,118,0.1)' },
  I: { primary: 'rgb(155,18,75)', accent: 'rgba(155,18,75,0.1)' },
  L: { primary: 'rgb(27,109,109)', accent: 'rgba(27,109,109,0.1)' },
  M: { primary: 'rgba(229, 132, 132, 1)', accent: 'rgba(255, 232, 232, 1)' }, //
  N: { primary: 'rgb(139,9,29)', accent: 'rgba(139,9,29,0.1)' }, //
  O: { primary: 'rgb(20,139,80)', accent: 'rgba(20,139,80,0.1)' },
  P: { primary: '#b50a87', accent: '#fef0fb' }, //
  R: { primary: 'rgb(111,76,41)', accent: 'rgba(111,76,41,0.1)' },
  S: { primary: 'rgb(41,17,125)', accent: 'rgba(41,17,125,0.1)' },
  T: { primary: 'rgb(90,10,41)', accent: 'rgba(90,10,41,0.1)' },
  U: { primary: 'rgba(32, 117, 103, 1)', accent: 'rgba(225, 247, 243, 1)' }, //
  V: { primary: '#0154B6', accent: '#EAF4FF' },
  X: { primary: '#750CB0', accent: '#F9F0FE' },
  DEFAULT: { primary: 'rgba(1, 84, 182, 1)', accent: 'rgba(234, 244, 255, 1)' },
} as const;

export const getAllModalityKeyValues = (list: FaradayModality[]) =>
  list?.map((o) => ({ [o.shortName]: o.shortName }));

type BadgeColorSchemeConstType = (typeof BadgeColorScheme)[keyof typeof BadgeColorScheme];

type BadgeModalityValueType = {
  color: BadgeColorSchemeConstType;
};

export const getBadgeModalityColorScheme = (modality: string): BadgeModalityValueType => ({
  color: BadgeColorScheme[modality[0]?.toUpperCase() as string] || BadgeColorScheme.DEFAULT,
});

type BadgeProps = {
  text?: string;
  color?: string;
  bg?: string;
  containerProps?: BoxProps;
  bodyProps?: BoxProps;
};
export const Badge = ({ text, color, bg, containerProps, bodyProps }: BadgeProps) => (
  <Body
    small
    bg={bg}
    color={color}
    as="span"
    px={1}
    py="2px"
    ml={1}
    borderRadius={6}
    {...containerProps}
    {...bodyProps}>
    {text}
  </Body>
);

export const BadgeSquare = ({ color, bg, containerProps }: BadgeProps) => (
  <Box bg={bg} color={color} height={10} width={10} borderRadius={2} opacity={0.7} {...containerProps} />
);

type ExamBadgeProps = {
  modality: string;
  noLabel?: boolean; // TODO: Maybe use keyword 'never' for this for conditional props
  variant?: 'badge' | 'dot';
  children?: React.ReactNode; // NOTE: This is just not optional
  rootProps?: FlexProps; // FIXME: Need to get types from rebass
};

const ExamBadge: React.FC<ExamBadgeProps> = ({ modality, noLabel, variant = 'dot', rootProps, children }) => {
  const examName = children ? ` - ${children as string}` : '';
  const label = !noLabel ? `${modality} ${examName}` : '';
  const modalityColorScheme = getBadgeModalityColorScheme(modality);
  const scheme = {
    color: modalityColorScheme.color.primary,
    bg: modalityColorScheme.color.accent,
    bodyProps: { ...rootProps },
  };
  const props = { ...scheme, children: label };
  return (
    <Flex {...rootProps}>
      {variant === 'badge' ? (
        <Badge text={children as string} {...scheme} />
      ) : (
        <IconWithLabel icon={iconBadge} {...omit(props, ['bg'])} opacity={0.8} />
      )}
    </Flex>
  );
};
export default memo(ExamBadge);
