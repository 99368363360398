import { FC, useEffect, useState } from 'react';
import { Box, Flex, FAIcon, Drawer, ActionMenu, ActionMenuItem } from '@fivehealth/botero';
import { faBars as menuIcon, faTimes as closeIcon } from '@fortawesome/pro-regular-svg-icons';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Sidebar from '@/components/Sidebar/Sidebar';

import UserInfo from './UserInfo';
import {
  useRenderForView,
  useIsView,
  getProfilePic,
  tweakPageName,
  unsecureRedirectPath,
  getCookieData,
} from '@/helpers/utils';

import { CookieDataTypes, WorkspaceStorageTypes } from '@/helpers/types/system.types';
import { useAuthHandler } from '@/hooks';
import { getStoredAvatarProfile } from '@/helpers/utils/storage.utils';
import { ROUTES } from '@/routes/Router';
import AvatarProfileModal from '@/components/Modals/AvatarProfileModal';
import EventsConstants from '@/config/constants/events.constants';
import WorkspaceClinicSelector from '@/components/Header/WorkspaceClinicSelector';
import { useDataContext } from '@/context';

type HeaderDropdownProps = {
  user?: any;
  label: React.ReactNode;
  style?: React.CSSProperties;
  avatar: string;
  setAvatar: (avatar: string) => void;
};

const HeaderDropdown: FC<HeaderDropdownProps> = ({ label, avatar, setAvatar }) => {
  const { t } = useTranslation();

  const [profileModal, setProfileModal] = useState(false);
  const pageName = window.location.pathname.split('/')[1] || '';
  const { onLogout: securedLogout } = useAuthHandler();

  const handleLogout = async () => {
    const redirect = () => unsecureRedirectPath(ROUTES.LOGIN.ROOT);
    await securedLogout(redirect);
  };

  return (
    <>
      <AvatarProfileModal
        openModal={profileModal}
        closeModal={() => {
          setProfileModal(false);
        }}
        isUpdating={false}
        avatar={avatar}
        setAvatar={setAvatar}
      />
      <ActionMenu label={label}>
        <ActionMenuItem
          data-testid="change-profile-picture"
          logEventProps={{
            page: tweakPageName(pageName),
            event: EventsConstants.CHANGE_PROFILE_PICTURE,
            subSource: 'Header Dropdown',
          }}
          onClick={() => {
            setProfileModal(true);
          }}>
          {t('Change profile picture')}
        </ActionMenuItem>

        <ActionMenuItem
          data-testid="logout"
          logEventProps={{
            page: tweakPageName(pageName),
            event: EventsConstants.LOGOUT,
            subSource: 'Header Dropdown',
          }}
          onClick={() => {
            handleLogout();
          }}>
          {t('Logout')}
        </ActionMenuItem>
      </ActionMenu>
    </>
  );
};

function Header({ currentUser }: { currentUser?: WorkspaceStorageTypes }) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [avatar, setAvatar] = useState(getProfilePic(getStoredAvatarProfile()));
  const {
    data: { user },
  } = useDataContext();

  const location = useLocation();
  const { renderForView } = useRenderForView();
  const { isMobile } = useIsView();

  useEffect(() => {
    setDrawerOpen(false);
  }, [location]);

  return (
    <>
      <Box id="nav" mt={2}>
        <Flex justifyContent="space-between" alignItems="center" height={64}>
          {renderForView({
            mobile: (
              <>
                <HeaderDropdown
                  avatar={avatar}
                  setAvatar={setAvatar}
                  label={
                    <Box
                      as="img"
                      src={getProfilePic(getStoredAvatarProfile()) || '/blue-avatar-logo.svg'}
                      borderRadius="50%"
                      width={32}
                    />
                  }
                />
                <Box>
                  <WorkspaceClinicSelector
                    boxProps={{ width: 'auto', mt: 1, ml: 0 }}
                    data={getCookieData() as CookieDataTypes}
                  />
                </Box>
                <Box cursor="pointer" pl={2} py={1} onClick={() => setDrawerOpen(true)}>
                  <FAIcon icon={menuIcon} color="fullShade" fontSize={20} />
                </Box>
              </>
            ),
            desktop: (
              <>
                <Box>
                  <WorkspaceClinicSelector data={getCookieData() as CookieDataTypes} />
                </Box>
                <HeaderDropdown
                  user={currentUser}
                  avatar={avatar}
                  setAvatar={setAvatar}
                  label={<UserInfo user={{ name: user?.fullName as string }} avatarSrc={avatar} />}
                />
              </>
            ),
          })}
        </Flex>
      </Box>
      {isMobile ? (
        <Box
          position="relative"
          backgroundColor="#D5D7DE"
          width="calc(100% + 32px)"
          height="1px"
          marginLeft="-16px"
        />
      ) : null}

      <Box display={['initial', 'none']}>
        <Drawer width={280} open={drawerOpen} onClose={() => setDrawerOpen(false)}>
          <Sidebar
            showExpandButton={false}
            defaultExpand
            headerRight={
              <FAIcon
                icon={closeIcon}
                fontSize="24px"
                cursor="pointer"
                pl={2}
                py={1}
                onClick={() => setDrawerOpen(false)}
              />
            }
          />
        </Drawer>
      </Box>
    </>
  );
}

export default Header;
