import { useTranslation } from 'react-i18next';
import { SecondaryOutlinedButton, FAIcon, LogEventPropsTypes } from '@fivehealth/botero';
import { faSlidersH as leftIcon, faChevronDown as rightIcon } from '@fortawesome/pro-regular-svg-icons';

type FilterButtonProps = {
  onClick: () => void;
  logEventProps?: LogEventPropsTypes<any>;
  disabled?: boolean;
};

const FilterButton: React.FC<FilterButtonProps> = ({ onClick, logEventProps, disabled }) => {
  const { t } = useTranslation();
  return (
    <SecondaryOutlinedButton
      disabled={disabled}
      style={{ opacity: disabled ? 0.5 : 1 }}
      color="fullShade"
      borderColor="mediumShade"
      borderRadius={8}
      onClick={onClick}
      data-testid="orders_filter_button"
      logEventProps={logEventProps}
      startIcon={<FAIcon fontSize={16} icon={leftIcon} color="darkestShade" />}
      endIcon={<FAIcon fontSize={14} icon={rightIcon} color="darkestShade" />}>
      {t('Filter')}
    </SecondaryOutlinedButton>
  );
};

export default FilterButton;
