import { useRef } from 'react';
import {
  ActionMenu,
  ActionMenuItem,
  Box,
  FAIcon,
  Flex,
  SecondaryOutlinedButton,
  Text,
} from '@fivehealth/botero';
import { map, startCase } from 'lodash';
import { faSortAlphaDown as dscIcon, faSortAlphaUp as ascIcon } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';

export type SortQueryVariables<TSortBy, TSortOrder> = {
  sortBy: TSortBy;
  sortOrderDsc: TSortOrder;
};

interface BasicSortActionMenuProps<TSortBy, TSortOrder> extends SortQueryVariables<TSortBy, TSortOrder> {
  handleOnSort: (sortBy: TSortBy, sortOrderDsc: boolean) => void;
  options: Record<string, any>[];
  isDisabled?: boolean;
  open?: boolean;
}

function BasicSortActionMenu<TSortBy, TSortOrder>({
  handleOnSort,
  sortBy,
  sortOrderDsc,
  options,
  isDisabled,
}: BasicSortActionMenuProps<TSortBy, TSortOrder>) {
  const { t } = useTranslation();
  const dropdownRef = useRef();
  const onSetDropDownRef = (ref: any) => {
    dropdownRef.current = ref;
  };

  const getSortByOptions = (selected: SortQueryVariables<typeof sortBy, typeof sortOrderDsc>) => {
    const sortByLabelValue = [...options];

    const getLabel = (text: string, sortedByName: string) => (
      <Flex justifyContent="space-between" alignItems="stretch" width="100%">
        <Box ml={1}>
          <Text>{startCase(text?.toLowerCase())}</Text>
        </Box>
        <Box>
          {(selected.sortBy as unknown as string) === sortedByName && (
            <FAIcon color="darkestShade" icon={selected.sortOrderDsc ? dscIcon : ascIcon} fontSize={16} />
          )}
        </Box>
      </Flex>
    );
    return sortByLabelValue?.map((o) => ({
      label: getLabel(o.label, o.value),
      value: { sortBy: o.value, sortOrderDsc: true } as unknown as SortQueryVariables<
        typeof sortBy,
        typeof sortOrderDsc
      >,
    }));
  };

  return (
    <ActionMenu
      disabled={isDisabled}
      dropdownRef={onSetDropDownRef}
      label={
        <SecondaryOutlinedButton
          color="fullShade"
          borderColor="mediumShade"
          borderRadius={8}
          alt="Sort"
          style={{ opacity: isDisabled ? 0.5 : 1 }}
          startIcon={<FAIcon color="darkestShade" icon={sortOrderDsc ? dscIcon : ascIcon} fontSize={16} />}>
          <Text fontSize={14} fontWeight={600}>
            {t(startCase((sortBy as unknown as string)?.toLowerCase() ?? ''))}
          </Text>
        </SecondaryOutlinedButton>
      }>
      {map(
        getSortByOptions({
          sortBy,
          sortOrderDsc,
        }),
        ({ label, value }) => (
          <ActionMenuItem
            key={value?.sortBy as unknown as string}
            onClick={() => {
              const desc = sortBy === value?.sortBy ? !sortOrderDsc : sortOrderDsc;
              handleOnSort(value?.sortBy, Boolean(desc));
              if (dropdownRef?.current) (dropdownRef?.current as any)?.setOpen(false);
            }}>
            {label}
          </ActionMenuItem>
        )
      )}
    </ActionMenu>
  );
}

export default BasicSortActionMenu;
