import { Badge } from '@fivehealth/botero';

type TabBadgeProps = {
  tabId: string;
  number: number | string;
};
function TabBadge({ tabId, number }: TabBadgeProps) {
  const text = (number || 0).toString();
  return (
    <Badge
      display={!text ? 'none' : 'inherit'}
      fontWeight={600}
      fontSize={12}
      color="fullShade"
      ml={1}
      borderRadius={8}
      backgroundColor="#E8EAED"
      height={22}
      alignItems="center"
      justifyContent="center"
      data-testid={`tab_badge_count:${tabId}:${text}`}
      style={{ paddingBottom: 3 }}>
      {text}
    </Badge>
  );
}

export default TabBadge;
