import React from 'react';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';

import {
  FaradayOrder,
  FaradayOrderExam,
  OrderExamStatus,
  StaffProfileAccountType,
} from '@/gql/generated/graphql';
import { ActionMenu, ActionMenuItem, Box, FAIcon, SecondaryOutlinedButton } from '@fivehealth/botero';

import { NavigateOrderStateType, StepDynamicActionConst } from '@/helpers/types/ui.types';
import { isEmpty, isEqual, sortBy, trim } from 'lodash';
import { createRouteWithParam, ROUTES } from '@/routes/Router';
import { useNavigate } from 'react-router-dom';
import { OrderModalProps } from '@/pages/Orders/OrdersCard';
import { useDataContext } from '@/context';
import { isDateTimeOneHourBefore, isSameCurrDate } from '@/helpers/utils';
import moment from 'moment';

interface OrderActionMenusProps extends OrderModalProps {
  order: FaradayOrder;
  exam: FaradayOrderExam;
  renderLabel?: (data?: FaradayOrder) => React.ReactNode | null;
  setErrorMsg?: React.Dispatch<React.SetStateAction<React.ReactElement<any, any> | undefined>>;
}

export const OrderActionMenus: React.FC<OrderActionMenusProps> = ({
  order,
  setCancelOrderModalOpen,
  setMessageModalOpen,
  renderLabel,
  patient,
  setErrorMsg,
}) => {
  const navigate = useNavigate();
  const getStatus = (status: OrderExamStatus) => order?.orderExams.find((o) => o.status === status);

  const {
    data: { user },
  } = useDataContext();
  // eslint-disable-next-line no-restricted-globals
  const navState: NavigateOrderStateType = { order, patient: order.patient, parentUrl: location.href };

  const isSuperAdmin = user?.accountType === StaffProfileAccountType.Superadmin;
  const isCreatedByMe = order?.createdBy?.uid === user?.uid;

  let menuItems: {
    label: string;
    action: () => void;
    divider?: boolean;
    color?: string;
  }[] = [];

  if (isEmpty(patient) || order?.patient?.uid !== patient?.uid) {
    menuItems.push({
      label: 'View patient profile',
      action: () => {
        navigate(createRouteWithParam(ROUTES.PATIENTS_LIST.PATIENT, order.patient.uid), {
          state: { ...navState },
        });
      },
    });
  }

  if (
    (getStatus(OrderExamStatus.Scheduled)?.status || getStatus(OrderExamStatus.Rescheduled)?.status) &&
    (isCreatedByMe || isSuperAdmin)
  ) {
    menuItems.push({
      label: 'Send order form',
      action: () => {
        if (setMessageModalOpen) {
          setMessageModalOpen(true);
        }
      },
    });
  }

  // NOTE: Show edit order option only if you are the one who created the order and status are scheduled
  if (
    (getStatus(OrderExamStatus.Rescheduled)?.status ||
      getStatus(OrderExamStatus.Scheduled)?.status ||
      getStatus(OrderExamStatus.PendingSchedule)?.status) &&
    (isCreatedByMe || isSuperAdmin)
  ) {
    menuItems.push({
      label: 'Edit order',
      action: () => {
        const scheduledExams = order.orderExams.filter(
          (o) =>
            isSameCurrDate(moment(new Date(o.scheduledFor)).format()) &&
            (o.status === OrderExamStatus.Rescheduled || o.status === OrderExamStatus.Scheduled)
        );

        // NOTE: Need to check ALL exams if its current then we show the error same day modification not allowed
        if (scheduledExams?.length === order?.orderExams?.length && setErrorMsg) {
          setErrorMsg(<Box mt={2}>Same Day order modifications are not allowed. </Box>);
        } else {
          navigate(createRouteWithParam(ROUTES.ORDER_CREATE.ROOT, order.uid), {
            state: {
              ...navState,
              step: {
                cursor: 1,
                excludeCursor: [2, 3],
                action: StepDynamicActionConst.EDIT_ORDER,
                backUrl: '',
              },
            } as NavigateOrderStateType,
          });
        }
      },
    });

    menuItems.push({
      label: 'Add exam',
      action: () => {
        const scheduledExams = order.orderExams.filter(
          (o) =>
            isSameCurrDate(moment(new Date(o.scheduledFor)).format()) &&
            (o.status === OrderExamStatus.Rescheduled || o.status === OrderExamStatus.Scheduled)
        );

        // NOTE: Need to check ALL exams if its the past then we show the error same day modification not allowed
        if (scheduledExams?.length === order?.orderExams?.length && setErrorMsg) {
          setErrorMsg(<Box mt={2}>Same Day modifications are not allowed. </Box>);
        } else {
          navigate(createRouteWithParam(ROUTES.ORDER_CREATE.ROOT, order.uid), {
            state: {
              ...navState,
              step: {
                cursor: 2,
                excludeCursor: [2, 3],
                action: StepDynamicActionConst.ADD_EXAM,
                backUrl: '',
              },
            } as NavigateOrderStateType,
          });
        }
      },
    });
  }

  menuItems = sortBy(menuItems, ['label']);

  if (
    (getStatus(OrderExamStatus.Scheduled)?.status ||
      getStatus(OrderExamStatus.Rescheduled)?.status ||
      getStatus(OrderExamStatus.PendingReschedule)?.status ||
      getStatus(OrderExamStatus.Duplicate)?.status) &&
    (isCreatedByMe || isSuperAdmin)
  ) {
    menuItems.push({
      label: 'Cancel order',
      action: () => {
        if (setCancelOrderModalOpen) setCancelOrderModalOpen(true);
      },
      divider: true,
      color: 'danger',
    });
  }

  return !isEmpty(menuItems) ? (
    <ActionMenu
      label={
        !renderLabel ? (
          <SecondaryOutlinedButton color="fullShade" width={12} p={0} height={20} border={0}>
            <FAIcon color="darkestShade" icon={faEllipsisV} fontSize={18} />
          </SecondaryOutlinedButton>
        ) : (
          renderLabel(order)
        )
      }>
      {menuItems.map((item) => (
        <ActionMenuItem
          key={`action_menu_item-${trim(item.label)}`}
          color={item.color ? item.color : null}
          divider={item.divider}
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            item.action();
          }}>
          {item.label}
        </ActionMenuItem>
      ))}
    </ActionMenu>
  ) : (
    <SecondaryOutlinedButton color="fullShade" width={12} p={0} height={20} border={0} disabled>
      <FAIcon color="darkestShade" icon={faEllipsisV} fontSize={18} disabled />
    </SecondaryOutlinedButton>
  );
};

interface OrderExamActionMenusProps extends OrderModalProps {
  order: FaradayOrder;
  exam: FaradayOrderExam;
  renderLabel?: (data?: FaradayOrderExam) => React.ReactNode | null;
  setSelectedExam?: React.Dispatch<React.SetStateAction<FaradayOrderExam>>;
  setErrorMsg?: React.Dispatch<React.SetStateAction<React.ReactElement<any, any> | undefined>>;
}

export const OrderExamActionMenus: React.FC<OrderExamActionMenusProps> = ({
  order,
  exam,
  setCancelExamModalOpen,
  setSelectedExam,
  renderLabel,
  setErrorMsg,
}) => {
  const navigate = useNavigate();

  const {
    data: { user },
  } = useDataContext();

  // eslint-disable-next-line no-restricted-globals
  const navState: NavigateOrderStateType = { order, exam, patient: order.patient, parentUrl: location.href };

  const { uid, status, accessionNo } = exam;

  const isSuperAdmin = user?.accountType === StaffProfileAccountType.Superadmin;
  const isNotCreatedByMe = order?.createdBy?.uid !== user?.uid;

  const isEditNotAllowed = () => {
    const result =
      isEqual(status.toString(), OrderExamStatus.ReportReady.toString()) ||
      isEqual(status.toString(), OrderExamStatus.ImageReady.toString()) ||
      isEqual(status.toString(), OrderExamStatus.ErrorCancel.toString()) ||
      isNotCreatedByMe;

    if (status?.toString()?.toUpperCase().includes('RIS')) {
      return true;
    }
    if (
      isEqual(status.toString(), OrderExamStatus.Cancelled.toString()) ||
      isEqual(status.toString(), OrderExamStatus.PendingCancel.toString())
    ) {
      return true;
    }
    if (result && isSuperAdmin) {
      return false;
    }

    return result;
  };

  return (
    <ActionMenu
      disabled={isEditNotAllowed()}
      label={
        !renderLabel ? (
          <SecondaryOutlinedButton
            height={30}
            color="fullShade"
            p={0}
            border={0}
            style={{ cursor: isEditNotAllowed() ? 'not-allowed' : 'pointer' }}>
            <FAIcon
              color="darkestShade"
              icon={faEllipsisV}
              fontSize={18}
              style={{ cursor: isEditNotAllowed() ? 'not-allowed' : 'pointer' }}
            />
          </SecondaryOutlinedButton>
        ) : (
          renderLabel(exam)
        )
      }>
      {status === OrderExamStatus.ImageReady || status === OrderExamStatus.ReportReady ? null : (
        <ActionMenuItem
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            if (isSameCurrDate(moment(new Date(exam?.scheduledFor)).format())) {
              if (setErrorMsg) {
                setErrorMsg(<Box mt={2}>You are not allowed to edit an exam on the day of appointment. </Box>);
              }
            } else {
              navigate(createRouteWithParam(ROUTES.ORDER_CREATE.ROOT, uid), {
                state: {
                  ...navState,
                  step: { cursor: 2, action: 'EDIT_EXAM', backUrl: '' },
                } as NavigateOrderStateType,
              });
            }
          }}
          fontSize={14}>
          Edit exam details
        </ActionMenuItem>
      )}

      {status === OrderExamStatus.Cancelled ||
      status === OrderExamStatus.PendingCancel ||
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      status === OrderExamStatus.Error ||
      !accessionNo ? null : (
        <ActionMenuItem
          divider
          color="danger"
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();

            if (isDateTimeOneHourBefore(exam.scheduledFor)) {
              if (setErrorMsg) {
                setErrorMsg(<Box mt={2}>Not allowed to cancel appointments 1 hour before.</Box>);
              }
            } else {
              if (setSelectedExam) {
                setSelectedExam(exam);
              }
              if (setCancelExamModalOpen) {
                setCancelExamModalOpen(true);
              }
            }
          }}
          fontSize={14}>
          Cancel exam
        </ActionMenuItem>
      )}
    </ActionMenu>
  );
};
