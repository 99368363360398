import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { isEqual, map } from 'lodash';
import { toast } from 'react-toastify';
import { faTimes as closeIcon, faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { Flex, Box, H2, Button, SecondaryOutlinedButton, FAIcon, theme } from '@fivehealth/botero';

import { AppConfig } from '@/config/app.config';
import Modal from '@/components/Modals/Modal';

const StyledBorder = styled(Flex)`
  border-radius: 50%;
  border: 2px solid transparent;
  transition: border 0.1s linear;
  cursor: pointer;
  ${({ active }: { active: boolean }) =>
    active &&
    css`
      border-color: ${theme.colors.primary};
    `}
  &:hover {
    border-color: ${theme.colors.primary};
  }
`;

interface AvatarImageProps {
  active: boolean;
  onClick: () => void;
  src: string;
}

const AllAvatarImages = [
  { id: 'AVATAR_BLUE_MALE', src: '/avatar_blue_male.svg' },
  { id: 'AVATAR_YELLOW_MALE', src: '/avatar_yellow_male.svg' },
  { id: 'AVATAR_PINK_MALE', src: '/avatar_pink_male.svg' },
  { id: 'AVATAR_GREY_MALE', src: '/avatar_grey_male.svg' },
  { id: 'AVATAR_BLUE_FEMALE', src: '/avatar_blue_female.svg' },
  { id: 'AVATAR_YELLOW_FEMALE', src: '/avatar_yellow_female.svg' },
  { id: 'AVATAR_PINK_FEMALE', src: '/avatar_pink_female.svg' },
  { id: 'AVATAR_GREY_FEMALE', src: '/avatar_grey_female.svg' },
  { id: 'AVATAR_BUG', src: '/avatar_bug.svg' },
];

function AvatarImage({ active, onClick, src, ...props }: AvatarImageProps) {
  return (
    <StyledBorder mx={1} mb={2} active={active} position="relative">
      {active && (
        <Flex
          position="absolute"
          top={1}
          right={-12}
          bg="white"
          borderRadius="50%"
          border="2px solid"
          borderColor="white">
          <FAIcon icon={faCheckCircle} color="primary" />
        </Flex>
      )}
      <Box
        border="4px solid"
        borderColor="white"
        borderRadius="50%"
        width="50px"
        as="img"
        src={src}
        onClick={onClick}
        {...props}
      />
    </StyledBorder>
  );
}

type AvatarProfileModalProps = {
  openModal: boolean;
  closeModal: () => void;
  isUpdating: boolean;
  avatar?: string;
  setAvatar: (avatar: string) => void;
};

const AvatarProfileModal: FC<AvatarProfileModalProps> = ({
  openModal,
  closeModal,
  avatar,
  setAvatar,
  isUpdating,
}) => {
  const { t } = useTranslation();
  const [selectedAvatar, setSelectedAvatar] = useState(avatar);

  const handlecloseModal = () => {
    closeModal();
  };

  const handleOnSubmit = () => {
    if (selectedAvatar) {
      localStorage.setItem(AppConfig.STORAGE.LOCAL_PROFILE_KEY, selectedAvatar);
      setAvatar(selectedAvatar);
    }
    toast.success(`Successfully updated avatar profile picture`);
    setTimeout(() => {
      handlecloseModal();
    }, 2000);
  };

  return (
    <Modal
      modalProps={{
        style: {
          padding: 32,
          boxSizing: 'border-box',
          maxWidth: 650,
          width: 'calc(100% - 32px)',
        },
      }}
      open={openModal}
      onClose={handlecloseModal}>
      <Flex mb={4} justifyContent="space-between">
        <H2>{t(`Change profile picture`)}</H2>
        <Button bg="transparent" onClick={handlecloseModal}>
          <FAIcon icon={closeIcon} color="darkestShade" />
        </Button>
      </Flex>
      <Box mb={4}>
        <Flex mt={3} mx={-1} flexWrap="wrap">
          {map(AllAvatarImages, (avatarObj, index) => (
            <AvatarImage
              key={index}
              onClick={() => setSelectedAvatar(avatarObj.src)}
              active={isEqual(selectedAvatar, avatarObj.src)}
              src={avatarObj.src}
            />
          ))}
        </Flex>
      </Box>

      <Flex justifyContent="flex-end">
        <SecondaryOutlinedButton borderRadius={8} mr={2} onClick={closeModal}>
          {t('Cancel')}
        </SecondaryOutlinedButton>
        <Button
          borderRadius={8}
          onClick={handleOnSubmit}
          color="emptyShade"
          bg="primary"
          disabled={!selectedAvatar}>
          {isUpdating ? t('Updating...') : t('Updated profile')}
        </Button>
      </Flex>
    </Modal>
  );
};

export default AvatarProfileModal;
